import _ from "lodash";
import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomSelect, { SelectedOption } from "./CustomSelect";

function getObject(product, { listenersCount = true }) {
  return {
    id: product._id,
    image: product.artwork.thumbnail,
    label: (
      <>
        {product.name}&nbsp;
        {listenersCount && <small>({product.stats.listenersCount.current})</small>}
      </>
    )
  };
}

export function SelectedProduct({ products, productId, listenersCount = true, className = "", ...props }) {
  let product = products.find(p => p._id == productId);

  if(!product)
    return <></>;

  return <SelectedOption preview option={getObject(product, { listenersCount })} className={"product-dropdown-option-preview " + className} {...props} />;
}

function ProductDropdown({ products, listenersCount = true, className = "", ...props }) {
  if(!products)
    return <></>;

  let options = products.map(p => getObject(p, { listenersCount }));

  return (
    <CustomSelect excludeSelected options={options} className={"product-dropdown " + className} emptyText="You don’t have any shows." emptyDropdownText="There are no more shows to select." {...props} />
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products
    })
  )(withRouter(ProductDropdown))
);
