import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, call, takeLatest } from "redux-saga/effects";
import actions from "../actions";
import actionTypes from "../action-types";
import api from "../api";

const initialAuthState = {
  plan: {},
};

export const reducer = persistReducer(
  { storage, key: "products", whitelist: []},

  (state = initialAuthState, action) => {    
    switch (action.type) {    
      case actionTypes.user.GetPlan: {
      return {
        ...state,
        plan: action.payload.plan,
      };
    }

    case actionTypes.user.SetDownloadCount: {
      
      let newState = {
        ...state,
        downloadCount: action.payload.count.data
      };

      return newState;
    }

    case actionTypes.user.SetPortalLink: {
      
      let newState = {
        ...state,
        portalLink: action.payload.link.link,
      };

      return newState;
    }
    default:
      return state;
    }
  }
);

export function* saga() {  
  yield takeLatest(actionTypes.auth.GetUserByToken, function* getProducts() {
    const data = yield call(() => {
        return api.user.getPlan(true)
      }
    );

    put(actions.user.setPlan(data))
  });
}
