import React from "react";
import SVG from "react-inlinesvg";
import UpgradeLink from "../../../../generic/UpgradeLink";

export default function StepApp({ formik, user }) {
    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title m-0">Podcast Player</h3>
                {formik.values.appOnly
                    ? (
                        <p className="text-muted">Allow your listeners to access your show only on the Hiro player app (available for both Apple and Android). This is best if you want to use all the features available.</p>
                    )
                    : (
                        <p className="text-muted">Allow your listeners to access your show on all podcast players. This is best if you don’t need automated events for your show.</p>
                    )}
            </div>

            <div className="card-body listener-access-email">
                <div className="d-flex episode-access-options">
                    <label className={"option " + (!formik.values.appOnly ? "active" : "")}>
                        <input type="radio" name="appOnly" value={false} onChange={ev => formik.setFieldValue("appOnly", !ev.target.checked)} />
                        <SVG src="/media/def-image/icons/app.svg" />
                        All podcast players
                    </label>

                    <label className={"option " + (!user.planFeatures.allowAppOnly ? "disabled " : "") + (formik.values.appOnly ? "active " : "")}>
                        <input type="radio" name="appOnly" value={true} onChange={ev => formik.setFieldValue("appOnly", ev.target.checked)} />
                        <SVG src="/media/def-image/icons/app-muscle.svg" />
                        Only Hiro player app
                    </label>
                </div>

                {!user.planFeatures.allowAppOnly && <UpgradeLink user={user} />}
            </div>
        </div>
    );
}