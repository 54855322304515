import React from "react";
import { Modal } from "react-bootstrap";
import Lottie from "react-lottie";
import successAnimation from "./successAnimation";

export default function PurchaseSuccessModal({ show, close, message, hasProducts, hideOptions }) {
  return (
    <Modal
      show={show}
      onHide={close}
      size="md"
      centered
      className="user-success-modal"
    >
      <Modal.Body className="text-center py-15">
        <Lottie options={{
          animationData: successAnimation,
          loop: false,
          autoplay: true
        }} height={107} width={107} />
        <h2 className="my-10 fs-2">Congratulations! 💪</h2>
        <p className="mb-10">
          {message || <>You’re all set and your account has been updated.<br />Thank you!</>}
          </p>

        {hideOptions
          ? (
            <button type="button" className="btn btn-primary" onClick={close}>Close</button>
          )
          : (
            hasProducts
              ? (
                <button type="button" className="btn btn-primary" onClick={close}>Continue</button>
              )
              : (
                <>
                  <a href="/create-product" className="btn btn-primary mb-5">Create first audio feed</a><br />
                  <button type="button" className="btn btn-transparent" onClick={close}>I’ll do this later</button>
                </>
              )
          )}
      </Modal.Body>
    </Modal>
  );
}
