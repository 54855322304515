const successAnimation = {
    "v": "5.7.1", "fr": 30, "ip": 0, "op": 60, "w": 300, "h": 300, "nm": "Comp 1", "ddd": 0, "assets": [], "layers": [{ "ddd": 0, "ind": 1, "ty": 4, "nm": "4", "sr": 1, "ks": { "o": { "a": 0, "k": 100, "ix": 11 }, "r": { "a": 0, "k": 0, "ix": 10 }, "p": { "a": 0, "k": [151, 151, 0], "ix": 2 }, "a": { "a": 0, "k": [0, 0, 0], "ix": 1 }, "s": { "a": 0, "k": [100, 100, 100], "ix": 6 } }, "ao": 0, "shapes": [{ "ty": "gr", "it": [{ "ind": 0, "ty": "sh", "ix": 1, "ks": { "a": 0, "k": { "i": [[0, 0], [-22.5, -7.5], [0, 0]], "o": [[0, 0], [22.5, 7.5], [0, 0]], "v": [[61, 78.5], [78, 100.5], [94, 115.5]], "c": false }, "ix": 2 }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false }, { "ty": "st", "c": { "a": 0, "k": [0.431372578939, 0.725720753389, 1, 1], "ix": 3 }, "o": { "a": 0, "k": 100, "ix": 4 }, "w": { "a": 0, "k": 5, "ix": 5 }, "lc": 2, "lj": 1, "ml": 4, "bm": 0, "nm": "Stroke 1", "mn": "ADBE Vector Graphic - Stroke", "hd": false }, { "ty": "tr", "p": { "a": 0, "k": [0, 0], "ix": 2 }, "a": { "a": 0, "k": [0, 0], "ix": 1 }, "s": { "a": 0, "k": [100, 100], "ix": 3 }, "r": { "a": 0, "k": 0, "ix": 6 }, "o": { "a": 0, "k": 100, "ix": 7 }, "sk": { "a": 0, "k": 0, "ix": 4 }, "sa": { "a": 0, "k": 0, "ix": 5 }, "nm": "Transform" }], "nm": "Shape 1", "np": 3, "cix": 2, "bm": 0, "ix": 1, "mn": "ADBE Vector Group", "hd": false }, { "ty": "tm", "s": { "a": 1, "k": [{ "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 28.254, "s": [0] }, { "t": 51, "s": [100] }], "ix": 1 }, "e": { "a": 1, "k": [{ "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 17, "s": [0] }, { "t": 46.2109375, "s": [100] }], "ix": 2 }, "o": { "a": 0, "k": 0, "ix": 3 }, "m": 1, "ix": 2, "nm": "Trim Paths 1", "mn": "ADBE Vector Filter - Trim", "hd": false }], "ip": 17, "op": 617, "st": 17, "bm": 0 }, {
        "ddd": 0, "ind": 2, "ty": 4, "nm": "3", "sr": 1, "ks": { "o": { "a": 0, "k": 100, "ix": 11 }, "r": { "a": 0, "k": 203, "ix": 10 }, "p": { "a": 0, "k": [251.408, 59.449, 0], "ix": 2 }, "a": { "a": 0, "k": [-103.936, 89.696, 0], "ix": 1 }, "s": { "a": 0, "k": [91.456, 89.381, 100], "ix": 6 } }, "ao": 0, "shapes": [{
            "ty": "gr", "it": [{ "ind": 0, "ty": "sh", "ix": 1, "ks": { "a": 0, "k": { "i": [[0, 0], [29.459, 25.084], [5, -25.75], [5.75, -0.75]], "o": [[0, 0], [-25.25, -21.5], [-5.515, 28.405], [-5.75, 0.75]], "v": [[-75, 68.75], [-107.25, 86.5], [-94, 84], [-128.75, 113]], "c": false }, "ix": 2 }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false },
            { "ty": "st", "c": { "a": 0, "k": [1, 0.713725490196, 0.211764720842, 1], "ix": 3 }, "o": { "a": 0, "k": 100, "ix": 4 }, "w": { "a": 0, "k": 5, "ix": 5 }, "lc": 2, "lj": 1, "ml": 4, "bm": 0, "nm": "Stroke 1", "mn": "ADBE Vector Graphic - Stroke", "hd": false }, { "ty": "tr", "p": { "a": 0, "k": [0, 0], "ix": 2 }, "a": { "a": 0, "k": [0, 0], "ix": 1 }, "s": { "a": 0, "k": [100, 100], "ix": 3 }, "r": { "a": 0, "k": 0, "ix": 6 }, "o": { "a": 0, "k": 100, "ix": 7 }, "sk": { "a": 0, "k": 0, "ix": 4 }, "sa": { "a": 0, "k": 0, "ix": 5 }, "nm": "Transform" }], "nm": "Shape 1", "np": 3, "cix": 2, "bm": 0, "ix": 1, "mn": "ADBE Vector Group", "hd": false
        }, { "ty": "tm", "s": { "a": 1, "k": [{ "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 28.254, "s": [0] }, { "t": 51, "s": [100] }], "ix": 1 }, "e": { "a": 1, "k": [{ "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 17, "s": [0] }, { "t": 46.2109375, "s": [100] }], "ix": 2 }, "o": { "a": 0, "k": 0, "ix": 3 }, "m": 1, "ix": 2, "nm": "Trim Paths 1", "mn": "ADBE Vector Filter - Trim", "hd": false }], "ip": 17, "op": 617, "st": 17, "bm": 0
    }, {
        "ddd": 0, "ind": 3, "ty": 4, "nm": "2", "sr": 1, "ks": { "o": { "a": 0, "k": 100, "ix": 11 }, "r": { "a": 0, "k": 0, "ix": 10 }, "p": { "a": 0, "k": [-21, 347, 0], "ix": 2 }, "a": { "a": 0, "k": [0, 0, 0], "ix": 1 }, "s": { "a": 0, "k": [100, 100, 100], "ix": 6 } }, "ao": 0, "shapes": [{ "ty": "gr", "it": [{ "ind": 0, "ty": "sh", "ix": 1, "ks": { "a": 0, "k": { "i": [[0, 0], [20.5, 4.5], [0, 0], [18.25, 9.25], [3.5, -3.25]], "o": [[0, 0], [-20.5, -4.5], [0, 0], [-18.25, -9.25], [-3.5, 3.25]], "v": [[90.75, -122.25], [83.5, -110.5], [81.25, -97.25], [69.75, -93.25], [61, -75.75]], "c": false }, "ix": 2 }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false }, { "ty": "st", "c": { "a": 0, "k": [0.745098039216, 0.643137254902, 1, 1], "ix": 3 }, "o": { "a": 0, "k": 100, "ix": 4 }, "w": { "a": 0, "k": 5, "ix": 5 }, "lc": 2, "lj": 1, "ml": 4, "bm": 0, "nm": "Stroke 1", "mn": "ADBE Vector Graphic - Stroke", "hd": false }, { "ty": "tr", "p": { "a": 0, "k": [0, 0], "ix": 2 }, "a": { "a": 0, "k": [0, 0], "ix": 1 }, "s": { "a": 0, "k": [100, 100], "ix": 3 }, "r": { "a": 0, "k": 0, "ix": 6 }, "o": { "a": 0, "k": 100, "ix": 7 }, "sk": { "a": 0, "k": 0, "ix": 4 }, "sa": { "a": 0, "k": 0, "ix": 5 }, "nm": "Transform" }], "nm": "Shape 1", "np": 3, "cix": 2, "bm": 0, "ix": 1, "mn": "ADBE Vector Group", "hd": false }, {
            "ty": "tm", "s": {
                "a": 1, "k": [{
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] }, "t": 28.254, "s": [0]
                }, { "t": 51, "s": [100] }], "ix": 1
            }, "e": { "a": 1, "k": [{ "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 17, "s": [0] }, { "t": 46.2109375, "s": [100] }], "ix": 2 }, "o": { "a": 0, "k": 0, "ix": 3 }, "m": 1, "ix": 2, "nm": "Trim Paths 1", "mn": "ADBE Vector Filter - Trim", "hd": false
        }], "ip": 17, "op": 617, "st": 17, "bm": 0
    }, { "ddd": 0, "ind": 4, "ty": 4, "nm": "1", "sr": 1, "ks": { "o": { "a": 0, "k": 100, "ix": 11 }, "r": { "a": 0, "k": 0, "ix": 10 }, "p": { "a": 0, "k": [147, 146, 0], "ix": 2 }, "a": { "a": 0, "k": [0, 0, 0], "ix": 1 }, "s": { "a": 0, "k": [109.756, 101.856, 100], "ix": 6 } }, "ao": 0, "shapes": [{ "ty": "gr", "it": [{ "ind": 0, "ty": "sh", "ix": 1, "ks": { "a": 0, "k": { "i": [[0, 0], [33.75, 1]], "o": [[0, 0], [-33.75, -1]], "v": [[-57.75, -75.75], [-90.75, -107.5]], "c": false }, "ix": 2 }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false }, { "ty": "st", "c": { "a": 0, "k": [1, 0.431372578939, 0.513725490196, 1], "ix": 3 }, "o": { "a": 0, "k": 100, "ix": 4 }, "w": { "a": 0, "k": 5, "ix": 5 }, "lc": 2, "lj": 1, "ml": 4, "bm": 0, "nm": "Stroke 1", "mn": "ADBE Vector Graphic - Stroke", "hd": false }, { "ty": "tr", "p": { "a": 0, "k": [-1.5, -0.25], "ix": 2 }, "a": { "a": 0, "k": [0, 0], "ix": 1 }, "s": { "a": 0, "k": [100, 100], "ix": 3 }, "r": { "a": 0, "k": 0, "ix": 6 }, "o": { "a": 0, "k": 100, "ix": 7 }, "sk": { "a": 0, "k": 0, "ix": 4 }, "sa": { "a": 0, "k": 0, "ix": 5 }, "nm": "Transform" }], "nm": "Shape 1", "np": 3, "cix": 2, "bm": 0, "ix": 1, "mn": "ADBE Vector Group", "hd": false }, { "ty": "tm", "s": { "a": 1, "k": [{ "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 28.254, "s": [0] }, { "t": 51, "s": [100] }], "ix": 1 }, "e": { "a": 1, "k": [{ "i": { "x": [0.667], "y": [1] }, "o": { "x": [0.333], "y": [0] }, "t": 17, "s": [0] }, { "t": 46.2109375, "s": [100] }], "ix": 2 }, "o": { "a": 0, "k": 0, "ix": 3 }, "m": 1, "ix": 2, "nm": "Trim Paths 1", "mn": "ADBE Vector Filter - Trim", "hd": false }], "ip": 17, "op": 617, "st": 17, "bm": 0 }, {
        "ddd": 0, "ind": 5, "ty": 4, "nm": "checkmark/success Outlines", "sr": 1, "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 }, "r": { "a": 0, "k": 0, "ix": 10 }, "p": { "a": 0, "k": [150.06, 150.061, 0], "ix": 2 }, "a": { "a": 0, "k": [99, 99, 0], "ix": 1 }, "s": {
                "a": 1, "k": [{ "i": { "x": [0.667, 0.667, 0.667], "y": [1, 1, 1] }, "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] }, "t": 0, "s": [100, 100, 100] }, {
                    "i":
                        { "x": [0.667, 0.667, 0.667], "y": [1, 1, 1] }, "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] }, "t": 12, "s": [27, 27, 100]
                }, { "t": 21, "s": [100, 100, 100] }], "ix": 6
            }
        }, "ao": 0, "shapes": [{ "ty": "gr", "it": [{ "ind": 0, "ty": "sh", "ix": 1, "ks": { "a": 0, "k": { "i": [[0, 0], [0.831, 1.409], [0, 0], [-0.622, 2.004], [-1.844, 1.043], [-2.083, -0.474], [-1.215, -1.74], [0, 0], [-0.949, 0.537], [0, 0], [-2.041, -0.632], [-1.075, -1.826], [0.463, -2.047], [1.756, -1.181]], "o": [[-1.424, 0.805], [0, 0], [-0.93, -1.899], [0.622, -2.006], [1.844, -1.042], [2.084, 0.474], [0, 0], [0.553, 0.939], [0, 0], [1.921, -0.899], [2.042, 0.631], [1.076, 1.823], [-0.463, 2.047], [0, 0]], "v": [[-26.138, 30.587], [-30.199, 29.498], [-58.899, -19.189], [-59.378, -25.261], [-55.542, -30.001], [-49.433, -30.884], [-44.302, -27.441], [-23.618, 7.647], [-20.911, 8.371], [47.571, -30.344], [53.736, -30.76], [58.584, -26.94], [59.537, -20.918], [56.086, -15.898]], "c": true }, "ix": 2 }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false }, { "ty": "fl", "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 }, "o": { "a": 0, "k": 100, "ix": 5 }, "r": 1, "bm": 0, "nm": "Fill 1", "mn": "ADBE Vector Graphic - Fill", "hd": false }, { "ty": "tr", "p": { "a": 0, "k": [99.943, 103.024], "ix": 2 }, "a": { "a": 0, "k": [0, 0], "ix": 1 }, "s": { "a": 0, "k": [83.631, 83.631], "ix": 3 }, "r": { "a": 0, "k": -12.6, "ix": 6 }, "o": { "a": 0, "k": 100, "ix": 7 }, "sk": { "a": 0, "k": 0, "ix": 4 }, "sa": { "a": 0, "k": 0, "ix": 5 }, "nm": "Transform" }], "nm": "Group 1", "np": 2, "cix": 2, "bm": 0, "ix": 1, "mn": "ADBE Vector Group", "hd": false }, {
            "ty": "gr", "it": [{ "ind": 0, "ty": "sh", "ix": 1, "ks": { "a": 0, "k": { "i": [[-47.479, -12.722], [12.721, -47.478], [47.478, 12.722], [-12.723, 47.478]], "o": [[47.479, 12.722], [-12.723, 47.479], [-47.478, -12.723], [12.721, -47.479]], "v": [[23.036, -85.967], [85.969, 23.035], [-23.035, 85.967], [-85.966, -23.035]], "c": true }, "ix": 2 }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false }, {
                "ty": "fl", "c": {
                    "a": 1, "k": [{ "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.167], "y": [0.167] }, "t": 0, "s": [0.901960790157, 0.901960790157, 0.901960790157, 1] }, { "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.167], "y": [0.167] }, "t": 10, "s": [0.901960790157, 0.901960790157, 0.901960790157, 1] }, { "t": 29, "s": [0.262745112181, 0.823529422283, 0.588235318661, 1] }],
                    "ix": 4
                }, "o": { "a": 0, "k": 100, "ix": 5 }, "r": 1, "bm": 0, "nm": "Fill 1", "mn": "ADBE Vector Graphic - Fill", "hd": false
            }, { "ty": "tr", "p": { "a": 0, "k": [98.939, 98.939], "ix": 2 }, "a": { "a": 0, "k": [0, 0], "ix": 1 }, "s": { "a": 0, "k": [100, 100], "ix": 3 }, "r": { "a": 0, "k": 0, "ix": 6 }, "o": { "a": 0, "k": 100, "ix": 7 }, "sk": { "a": 0, "k": 0, "ix": 4 }, "sa": { "a": 0, "k": 0, "ix": 5 }, "nm": "Transform" }], "nm": "Group 2", "np": 2, "cix": 2, "bm": 0, "ix": 2, "mn": "ADBE Vector Group", "hd": false
        }], "ip": 0, "op": 600, "st": 0, "bm": 0
    }, {
        "ddd": 0, "ind": 6, "ty": 4, "nm": "large-circles/success Outlines", "sr": 1, "ks": { "o": { "a": 1, "k": [{ "i": { "x": [0.833], "y": [0.833] }, "o": { "x": [0.167], "y": [0.167] }, "t": 42, "s": [100] }, { "t": 52, "s": [2] }], "ix": 11 }, "r": { "a": 0, "k": 0, "ix": 10 }, "p": { "a": 0, "k": [148, 146, 0], "ix": 2 }, "a": { "a": 0, "k": [95.5, 98, 0], "ix": 1 }, "s": { "a": 1, "k": [{ "i": { "x": [0.667, 0.667, 0.667], "y": [1, 1, 1] }, "o": { "x": [0.009, 0.009, 0.333], "y": [1.621, 1.621, 0] }, "t": 17, "s": [62, 62, 100] }, { "t": 50, "s": [124, 124, 100] }], "ix": 6 } }, "ao": 0, "shapes": [{ "ty": "gr", "it": [{ "ind": 0, "ty": "sh", "ix": 1, "ks": { "a": 0, "k": { "i": [[-6.209, 1.338], [-1.337, -6.209], [6.209, -1.337], [1.338, 6.209]], "o": [[6.208, -1.337], [1.338, 6.209], [-6.209, 1.338], [-1.337, -6.209]], "v": [[-2.421, -11.243], [11.242, -2.422], [2.421, 11.241], [-11.243, 2.421]], "c": true }, "ix": 2 }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false }, { "ty": "fl", "c": { "a": 0, "k": [0.745000023935, 0.642999985639, 1, 1], "ix": 4 }, "o": { "a": 0, "k": 100, "ix": 5 }, "r": 1, "bm": 0, "nm": "Fill 1", "mn": "ADBE Vector Graphic - Fill", "hd": false }, { "ty": "tr", "p": { "a": 0, "k": [173.998, 145.423], "ix": 2 }, "a": { "a": 0, "k": [0, 0], "ix": 1 }, "s": { "a": 0, "k": [71, 71], "ix": 3 }, "r": { "a": 0, "k": 0, "ix": 6 }, "o": { "a": 0, "k": 100, "ix": 7 }, "sk": { "a": 0, "k": 0, "ix": 4 }, "sa": { "a": 0, "k": 0, "ix": 5 }, "nm": "Transform" }], "nm": "Group 1", "np": 2, "cix": 2, "bm": 0, "ix": 1, "mn": "ADBE Vector Group", "hd": false }, {
            "ty": "gr", "it": [{
                "ind": 0, "ty": "sh", "ix": 1, "ks": {
                    "a": 0, "k": { "i": [[-6.209, 1.337], [-1.338, -6.209], [6.209, -1.338], [1.338, 6.209]], "o": [[6.209, -1.338], [1.337, 6.209], [-6.209, 1.337], [-1.337, -6.208]], "v": [[-2.421, -11.242], [11.242, -2.422], [2.422, 11.242], [-11.243, 2.422]], "c": true },
                    "ix": 2
                }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false
            }, { "ty": "fl", "c": { "a": 0, "k": [0.430999995213, 0.725, 1, 1], "ix": 4 }, "o": { "a": 0, "k": 100, "ix": 5 }, "r": 1, "bm": 0, "nm": "Fill 1", "mn": "ADBE Vector Graphic - Fill", "hd": false }, { "ty": "tr", "p": { "a": 0, "k": [63.997, 183.011], "ix": 2 }, "a": { "a": 0, "k": [0, 0], "ix": 1 }, "s": { "a": 0, "k": [70, 70], "ix": 3 }, "r": { "a": 0, "k": 0, "ix": 6 }, "o": { "a": 0, "k": 100, "ix": 7 }, "sk": { "a": 0, "k": 0, "ix": 4 }, "sa": { "a": 0, "k": 0, "ix": 5 }, "nm": "Transform" }], "nm": "Group 2", "np": 2, "cix": 2, "bm": 0, "ix": 2, "mn": "ADBE Vector Group", "hd": false
        }, { "ty": "gr", "it": [{ "ind": 0, "ty": "sh", "ix": 1, "ks": { "a": 0, "k": { "i": [[-6.209, 1.337], [-1.337, -6.208], [6.209, -1.337], [1.338, 6.209]], "o": [[6.209, -1.337], [1.338, 6.209], [-6.209, 1.338], [-1.337, -6.209]], "v": [[-2.421, -11.243], [11.242, -2.423], [2.421, 11.242], [-11.243, 2.421]], "c": true }, "ix": 2 }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false }, { "ty": "fl", "c": { "a": 0, "k": [1, 0.713999968884, 0.211999990426, 1], "ix": 4 }, "o": { "a": 0, "k": 100, "ix": 5 }, "r": 1, "bm": 0, "nm": "Fill 1", "mn": "ADBE Vector Graphic - Fill", "hd": false }, { "ty": "tr", "p": { "a": 0, "k": [103.291, 12.83], "ix": 2 }, "a": { "a": 0, "k": [0, 0], "ix": 1 }, "s": { "a": 0, "k": [70, 70], "ix": 3 }, "r": { "a": 0, "k": 0, "ix": 6 }, "o": { "a": 0, "k": 100, "ix": 7 }, "sk": { "a": 0, "k": 0, "ix": 4 }, "sa": { "a": 0, "k": 0, "ix": 5 }, "nm": "Transform" }], "nm": "Group 3", "np": 2, "cix": 2, "bm": 0, "ix": 3, "mn": "ADBE Vector Group", "hd": false }, {
            "ty": "gr", "it": [{ "ind": 0, "ty": "sh", "ix": 1, "ks": { "a": 0, "k": { "i": [[-6.208, 1.337], [-1.337, -6.209], [6.209, -1.338], [1.338, 6.209]], "o": [[6.209, -1.337], [1.338, 6.209], [-6.209, 1.337], [-1.337, -6.209]], "v": [[-2.423, -11.242], [11.241, -2.422], [2.421, 11.242], [-11.243, 2.422]], "c": true }, "ix": 2 }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false }, { "ty": "fl", "c": { "a": 0, "k": [0.430999995213, 0.725, 1, 1], "ix": 4 }, "o": { "a": 0, "k": 100, "ix": 5 }, "r": 1, "bm": 0, "nm": "Fill 1", "mn": "ADBE Vector Graphic - Fill", "hd": false }, {
                "ty": "tr", "p": { "a": 0, "k": [178.004, 59.795], "ix": 2 }, "a": { "a": 0, "k": [0, 0], "ix": 1 }, "s": { "a": 0, "k": [67, 67], "ix": 3 }, "r": { "a": 0, "k": 0, "ix": 6 }, "o": { "a": 0, "k": 100, "ix": 7 },
                "sk": { "a": 0, "k": 0, "ix": 4 }, "sa": { "a": 0, "k": 0, "ix": 5 }, "nm": "Transform"
            }], "nm": "Group 4", "np": 2, "cix": 2, "bm": 0, "ix": 4, "mn": "ADBE Vector Group", "hd": false
        }, { "ty": "gr", "it": [{ "ind": 0, "ty": "sh", "ix": 1, "ks": { "a": 0, "k": { "i": [[-6.209, 1.337], [-1.338, -6.208], [6.209, -1.337], [1.338, 6.209]], "o": [[6.209, -1.337], [1.337, 6.209], [-6.209, 1.338], [-1.337, -6.209]], "v": [[-2.421, -11.243], [11.243, -2.423], [2.422, 11.242], [-11.243, 2.421]], "c": true }, "ix": 2 }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false }, { "ty": "fl", "c": { "a": 0, "k": [1, 0.430999995213, 0.513999968884, 1], "ix": 4 }, "o": { "a": 0, "k": 100, "ix": 5 }, "r": 1, "bm": 0, "nm": "Fill 1", "mn": "ADBE Vector Graphic - Fill", "hd": false }, { "ty": "tr", "p": { "a": 0, "k": [12.829, 84.352], "ix": 2 }, "a": { "a": 0, "k": [0, 0], "ix": 1 }, "s": { "a": 0, "k": [68, 68], "ix": 3 }, "r": { "a": 0, "k": 0, "ix": 6 }, "o": { "a": 0, "k": 100, "ix": 7 }, "sk": { "a": 0, "k": 0, "ix": 4 }, "sa": { "a": 0, "k": 0, "ix": 5 }, "nm": "Transform" }], "nm": "Group 5", "np": 2, "cix": 2, "bm": 0, "ix": 5, "mn": "ADBE Vector Group", "hd": false }], "ip": 17, "op": 617, "st": 17, "bm": 0
    }, {
        "ddd": 0, "ind": 7, "ty": 4, "nm": "checkmark/success Outlines", "sr": 1, "ks": { "o": { "a": 0, "k": 100, "ix": 11 }, "r": { "a": 0, "k": 0, "ix": 10 }, "p": { "a": 0, "k": [150.073, 150.073, 0], "ix": 2 }, "a": { "a": 0, "k": [99, 99, 0], "ix": 1 }, "s": { "a": 1, "k": [{ "i": { "x": [0.203, 0.203, 0.667], "y": [0.507, 0.507, 1] }, "o": { "x": [0.915, 0.915, 0.333], "y": [0.651, 0.651, 0] }, "t": 50, "s": [93.357, 93.357, 100] }, { "t": 59, "s": [121.357, 121.357, 100] }], "ix": 6 } }, "ao": 0, "shapes": [{
            "ty": "gr", "it": [{
                "ind": 0, "ty": "sh", "ix": 1, "ks": {
                    "a": 0, "k": {
                        "i": [[0, 0], [0.831, 1.409], [0, 0], [-0.622, 2.004], [-1.844, 1.043], [-2.083, -0.474], [-1.215, -1.74], [0, 0], [-0.949, 0.537], [0, 0], [-2.041, -0.632], [-1.075, -1.826], [0.463, -2.047], [1.756, -1.181]], "o": [[-1.424, 0.805], [0, 0], [-0.93, -1.899], [0.622, -2.006], [1.844, -1.042], [2.084, 0.474], [0, 0], [0.553, 0.939], [0, 0], [1.921, -0.899], [2.042, 0.631], [1.076, 1.823], [-0.463, 2.047], [0, 0]], "v": [[-26.138, 30.587], [-30.199, 29.498], [-58.899, -19.189], [-59.378, -25.261], [-55.542, -30.001], [-49.433, -30.884], [-44.302, -27.441], [-23.618, 7.647], [-20.911, 8.371], [47.571, -30.344], [53.736, -30.76],
                        [58.584, -26.94], [59.537, -20.918], [56.086, -15.898]], "c": true
                    }, "ix": 2
                }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false
            }, { "ty": "fl", "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 }, "o": { "a": 0, "k": 100, "ix": 5 }, "r": 1, "bm": 0, "nm": "Fill 1", "mn": "ADBE Vector Graphic - Fill", "hd": false }, { "ty": "tr", "p": { "a": 0, "k": [98.943, 102.024], "ix": 2 }, "a": { "a": 0, "k": [0, 0], "ix": 1 }, "s": { "a": 0, "k": [100, 100], "ix": 3 }, "r": { "a": 0, "k": 0, "ix": 6 }, "o": { "a": 0, "k": 100, "ix": 7 }, "sk": { "a": 0, "k": 0, "ix": 4 }, "sa": { "a": 0, "k": 0, "ix": 5 }, "nm": "Transform" }], "nm": "Group 1", "np": 2, "cix": 2, "bm": 0, "ix": 1, "mn": "ADBE Vector Group", "hd": false
        }, { "ty": "gr", "it": [{ "ind": 0, "ty": "sh", "ix": 1, "ks": { "a": 0, "k": { "i": [[-47.479, -12.722], [12.721, -47.478], [47.478, 12.722], [-12.723, 47.478]], "o": [[47.479, 12.722], [-12.723, 47.479], [-47.478, -12.723], [12.721, -47.479]], "v": [[23.036, -85.967], [85.969, 23.035], [-23.035, 85.967], [-85.966, -23.035]], "c": true }, "ix": 2 }, "nm": "Path 1", "mn": "ADBE Vector Shape - Group", "hd": false }, { "ty": "fl", "c": { "a": 0, "k": [0.452748945648, 0.901960784314, 0.712987563189, 1], "ix": 4 }, "o": { "a": 0, "k": 100, "ix": 5 }, "r": 1, "bm": 0, "nm": "Fill 1", "mn": "ADBE Vector Graphic - Fill", "hd": false }, { "ty": "tr", "p": { "a": 0, "k": [98.939, 98.939], "ix": 2 }, "a": { "a": 0, "k": [0, 0], "ix": 1 }, "s": { "a": 0, "k": [100, 100], "ix": 3 }, "r": { "a": 0, "k": 0, "ix": 6 }, "o": { "a": 0, "k": 100, "ix": 7 }, "sk": { "a": 0, "k": 0, "ix": 4 }, "sa": { "a": 0, "k": 0, "ix": 5 }, "nm": "Transform" }], "nm": "Group 2", "np": 2, "cix": 2, "bm": 0, "ix": 2, "mn": "ADBE Vector Group", "hd": false }], "ip": 50, "op": 650, "st": 50, "bm": 0
    }], "markers": []
};

export default successAnimation;