import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

function ColorPicker({ value, onChange, disabled = false, className }) {
    let colors = [
        {
            code: "#3F4254",
            dark: true
        },
        {
            code: "#F64E60",
            dark: false
        },
        {
            code: "#EF7C35",
            dark: false
        },
        {
            code: "#FFA800",
            dark: false
        },
        {
            code: "#F9D247",
            dark: false
        },
        {
            code: "#B8E24A",
            dark: false
        },
        {
            code: "#08C98F",
            dark: false
        },
        {
            code: "#99DAFF",
            dark: false
        },
        {
            code: "#3699FF",
            dark: false
        },
        {
            code: "#4775EC",
            dark: false
        },
        {
            code: "#605DF0",
            dark: false
        },
        {
            code: "#8950FC",
            dark: false
        },
        {
            code: "#EE83F8",
            dark: false
        },
        {
            code: "#F178B6",
            dark: false
        },
        {
            code: "#607893",
            dark: false
        }
    ],
    autoSelected = !value || !value.code;

    return (
        <div className={"color-picker " + (disabled ? "disabled  " : "") + (className || "")}>
            <button type="button" key={-1} onClick={ev => onChange(null)} className={"color-auto " + (autoSelected ? "selected" : "")}>
                {autoSelected ? <SVG src={toAbsoluteUrl('/media/def-image/create-product-icons/selected.svg')} /> : <></>} auto
            </button>
            {colors.map((item, i) => {
                let selected = value && value.code == item.code;
                return (
                    <button type="button" key={i} style={{ backgroundColor: item.code }} onClick={ev => onChange(item)} className={(item.dark ? "dark " : "") + (selected ? "selected" : "")}>
                        {selected ? <SVG src={toAbsoluteUrl('/media/def-image/create-product-icons/selected.svg')} /> : <></>}
                    </button>
                );
            })}
        </div>
    );
}

export default ColorPicker;