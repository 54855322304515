import * as Yup from "yup";

export const user = {
  organizationSettings: Yup.object().shape({
    companyName: Yup.string().notRequired(),
    phone: Yup.string().notRequired(),
    website: Yup.string().notRequired(),
  }),

  optOutSettings: Yup.object().shape({
  }),

  whatsappOptOutSettings: Yup.object().shape({
  }),

  autoCharge: Yup.object().shape({
    autoChargeAmount: Yup.number("Enter a valid number.").min(0),
    autoChargeWhen: Yup.number("Enter a valid number.").min(0),
    autoChargeLimit: Yup.number("Enter a valid number.").min(0)
  }),

  profileDetails: Yup.object().shape({
    //pictureUrl: Yup.string().notRequired(),
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    email: Yup.string().email("Enter a valid email address.").required("Email is required.")
  }),

  changeEmail: Yup.object().shape({
    email: Yup.string().email("Enter a valid email address.").required("Email is required.")
  }),

  changePassword: Yup.object().shape({
    //password: Yup.string().required("Enter your current password."),    
    newPassword: Yup.string().min(5, "Must be at least 5 characters long.").required("Enter the new password."),
    confirmNewPassword: Yup.string().required("Enter the new password again.").oneOf([ Yup.ref("newPassword"), null ], "Passwords don’t match.")
  }),

  publicProfile: {
    domain: Yup.object().shape({
      uri: Yup.string()
        .min(2)
        //.required("Enter your desired URL.")
        .matches(/^[a-z0-9-]+$/, "Only letters, numbers and dashes are allowed, and it can’t start or end with a dash.")
        .matches(/^[a-z0-9]/, "It can’t start or end with a dash.")
        .matches(/[a-z0-9]$/, "It can’t start or end with a dash.")
    }),
    logo: Yup.object().shape({
      //logoUrl:Yup.string().notRequired()
    }),
    social: Yup.object().shape({
      facebook: Yup.string()
        .notRequired()
        .matches(/^https?:\/\//, "Please, enter a valid URL."),
      instagram: Yup.string()
        .notRequired()
        .matches(/^https?:\/\//, "Please, enter a valid URL."),
      twitter: Yup.string()
        .notRequired()
        .matches(/^https?:\/\//, "Please, enter a valid URL."),
      linkedin: Yup.string()
        .notRequired()
        .matches(/^https?:\/\//, "Please, enter a valid URL."),
      youtube: Yup.string()
        .notRequired()
        .matches(/^https?:\/\//, "Please, enter a valid URL."),
      tiktok: Yup.string()
        .notRequired()
        .matches(/^https?:\/\//, "Please, enter a valid URL."),
      spotify: Yup.string()
        .notRequired()
        .matches(/^https?:\/\//, "Please, enter a valid URL.")
    }),
    bio: Yup.object().shape({
      bio: Yup.string().notRequired()
    }),
    about: Yup.object().shape({
      about: Yup.string().notRequired()
    }),
    affiliate: Yup.object().shape({
      fprCode: Yup.string().notRequired()
    }),
  }
};

export const userPassword = Yup.object().shape({
  pwd: Yup.string().min(5).required("Current password is required"),
  password: Yup.string().min(5).required("New Password is required"),
  cPassword: Yup.string()
    .required("Password confirmation is required")
    .when("password", {
      is: (val) => (val && val.length > 0),

      then: Yup.string().oneOf(
        [ Yup.ref("password") ],
        "Password and Confirm Password didn’t match"
      ),
    }),
});
