import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../redux/actions";
import { Link } from "react-router-dom/cjs/react-router-dom";

function PhoneNumberModal({ dispatch }) {
  let [show, setShow] = useState(false),
    [isWhatsapp, setIsWhatsapp] = useState(),
    [isSmsOrWhatsapp, setIsSmsOrWhatsapp] = useState(),
    [isSms, setIsSms] = useState(true);

  useEffect(() => {
    let handler = ev => {
      setIsWhatsapp(ev.whatsapp);
      setIsSmsOrWhatsapp(ev.smsOrWhatsapp);
      setIsSms(ev.sms);
      setShow(true);
    };

    PhoneNumberModalEvents.on("showPhoneNumberModal", handler);
    return () => PhoneNumberModalEvents.off("showPhoneNumberModal", handler);
  }, []);

  return <>
    <Modal show={show} onHide={() => setShow(false)} size="md" centered className="new-modals phonenumber-modal">
      <Modal.Body>
        <button type="button" className="close" onClick={() => setShow(false)}><SVG src="/media/def-image/close-sm.svg" className="svg-icon" /></button>

        <div className="d-flex gap justify-content-center mb-8">
          {(isSms || isSmsOrWhatsapp) && <SVG src="/media/def-image/icons/phone-sq.svg" />}
          {(isWhatsapp || isSmsOrWhatsapp) && <SVG src="/media/def-image/icons/wa-sq.svg" />}
        </div>

        {isSmsOrWhatsapp && (
          <>
            <h1>Connect Phone or WhatsApp</h1>
            <p>Connect your Telnyx or Twilio account to access SMS features. Or add a Business WhatsApp account to access WhatsApp features.</p>
          </>
        )}

        {isSms && (
          <>
            <h1>Connect Phone Number</h1>
            <p>Connect your Telnyx or Twilio account to access SMS features. </p>
          </>
        )}

        {isWhatsapp && (
          <>
            <h1>Connect WhatsApp</h1>
            <p>Add a Business WhatsApp account to access WhatsApp features.</p>
          </>
        )}

        <div className="modal-buttons">
          <button type="button" className="btn btn-quiet" onClick={() => setShow(false)}>Cancel</button>
          <Link to="/user/whatsapp" className="btn btn-primary" onClick={() => setShow(false)}>Connect</Link>
        </div>
      </Modal.Body>
    </Modal>
  </>;
}

export default injectIntl(connect(
  state => ({
    user: state.auth.user
  }),
  dispatch => ({
    dispatch,
    ...actions.product
  })
)(PhoneNumberModal));

export const PhoneNumberModalEvents = {
  on(event, callback) {
    document.addEventListener(event, e => callback(e.detail));
  },
  off(event, callback) {
    document.removeEventListener(event, callback);
  },
  dispatch(event, data = null) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  /**
   * Emits the signal to open the popup.
   */
  dispatchShowSms(message) {
    this.dispatch("showPhoneNumberModal", { sms: true, ...message });
  },
  /**
   *
   */
  dispatchShowWhatsapp(message = {}) {
    this.dispatch("showPhoneNumberModal", { whatsapp: true, ...message });
  },
  /**
   *
   */
  dispatchShowSmsOrWhatsapp(message = {}) {
    this.dispatch("showPhoneNumberModal", { smsOrWhatsapp: true, ...message });
  }
};