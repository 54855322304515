import React, { useEffect, useState } from 'react';
import axios from 'axios';
import mime from 'mime-types';
import api from "../../../redux/api";
import { ProgressBar } from "react-bootstrap";
import { Buffer } from "buffer";

export default (props) => {
  const [ percentUploaded, setPercentUploaded ] = useState(0);
  const [ abort, setAbort ] = useState(false);
  const [ abortSignedUrl ] = useState(axios.CancelToken.source());
  const [ abortSignedRequest ] = useState(axios.CancelToken.source());

  /**
   * Aborts the current upload request, if it's ongoning.
   * @params {boolean} [force=false] - Forces cancellation.
   */
  const cancelUpload=(force)=>{
    if(typeof force=="undefined") force=false;
    var abortOnUnmount=typeof props.abortOnUnmount=="undefined"?true:props.abortOnUnmount,
      canCancel=props.file.uploadBegun&&props.file.cancelTokens;

    if(!canCancel||(!force&&!abortOnUnmount)) return;

    props.file.cancelTokens.map(token=>token.cancel());
    props.file.cancelTokens=null;
    props.file.isAbortedOrRemoved=true;
    setAbort(true);
  };

  useEffect(() => {
    //Initialize props.file's properties for internal use
    [
      ["uploadBegun",false],
      ["isAbortedOrRemoved",false],
      ["isComplete",false],
      ["progress",0],
      ["cancelTokens",null],
      ["setPercentUploaded",setPercentUploaded]
    ].map(property=>typeof props.file[property[0]]=="undefined"?props.file[property[0]]=property[1]:null);

    if(props.file.uploadBegun) {
      //Recover current progress and tell axios to use our instance's setPercentUploaded

      setPercentUploaded(props.file.progress);
      props.file.setPercentUploaded=setPercentUploaded;
      //TODO Enable the CSS transition after setting the value (I disabled it altogether)

      setAbort(props.file.isAbortedOrRemoved);
    } else if (props.canStartUpload === true && !(props.hasBeenUploaded || false)) {
      uploadFile(props.file, props.base64Data)
        .then((amazonS3Url) => {
          onUploadComplete(props.id, amazonS3Url);
        });
    }

    return ()=>{
      cancelUpload();
    };
  }, [ props.canStartUpload ]);

  const onUploadComplete = (id, amazonS3Url) => {
    props.file.isComplete=true;
    if (props.onUploadComplete) props.onUploadComplete(id, amazonS3Url);
  };

  function bytesToSize(bytes) {
    let sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    if (!bytes) return '0 Byte';
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

    return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
  }

  const uploadFile = (file, base64Data) => {
    const contentType = mime.contentType(file.name);

    if (props.file.errors) {
      return false;
    }

    props.file.uploadBegun=true;
    props.file.cancelTokens=[
      abortSignedUrl,
      abortSignedRequest
    ];

    let hasBase64Data = !!(typeof base64Data == "string" && base64Data);

    return api.generic.signedUrl({
      contentType,
      uploadType: props.uploadType,
      fileName: file.name,
      cancelToken: abortSignedUrl.token,
      base64: hasBase64Data
    }).then(res => {

      if (res.data) {
        let
          returnData    = res.data.returnData,
          signedRequest = returnData.signedRequest,
          amazonS3Url   = returnData.url;

        const options = {
          headers: { 'Content-Type': contentType },
          cancelToken: abortSignedRequest.token,

          onUploadProgress: function(progressEvent) {
            props.file.progress=Math.round((progressEvent.loaded * 100) / progressEvent.total);
            props.file.setPercentUploaded(props.file.progress);
          }
        };

        let xhr=axios.put(signedRequest, hasBase64Data ? new Buffer(base64Data.split(",")[1], "base64") : file, options).then(res => {
          return amazonS3Url;
        });

        return xhr;
      }
    });
  };

  let isValid=props.isValid,
    isFailed=abort||props.file.errors,
    isComplete=percentUploaded==100,
    isUploading=isValid&&!isComplete&&!isFailed;

  return <div className="dropzone-upload-file-item">
    {props.progressType=="bulk"&&<h3>{props.file.name}</h3>}
    <div className="progress-text">
      {abort?"Cancelled":
        (isValid
          ? bytesToSize(props.file.size/100*percentUploaded)+" of "+bytesToSize(props.file.size)+" uploaded"
          : "Invalid file")}
      {(props.canAbort&&!isFailed)&&<button
        type="button"
        className="btn btn-remove"
        onClick={() => {
          cancelUpload(true);
          onUploadComplete(props.id, false);
        }}
      ><em className="fa fa-times-circle"/></button>}
    </div>
    <ProgressBar
      className={isValid&&!isFailed?(isComplete?"completed":""):"invalid"}
      now={percentUploaded}
      label={percentUploaded+"%"}
      animated={isUploading}/>
  </div>;
}
