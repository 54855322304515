import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import common from "./common";

function PersonalizationToolbox({ onHide, onData, show = false, className = "", autoClose = true, fixedPosition = false, disablePersonalizationField }) {
    let [showInsertNameForm, setShowInsertNameForm] = useState(false),
        [showInsertFirstNameForm, setShowInsertFirstNameForm] = useState(false),
        nameRef = useRef(),
        firstNameRef = useRef();

    let insert = v => {
        onData(v);
        if(autoClose)
            onHide();
    };

    let nameOnKeyDown = ev => {
        if(ev.which == 13) {
            ev.preventDefault();
            insertName();
        }
    };

    let firstNameOnKeyDown = ev => {
        if(ev.which == 13) {
            ev.preventDefault();
            insertFirstName();
        }
    };

    let insertName = () => {
        let name = nameRef.current.value;

        if(!name || !name.trim())
            insert("[name]");
        else
            insert("[name|" + name + "]");
    };

    let insertFirstName = () => {
        let name = firstNameRef.current.value;

        if(!name || !name.trim())
            insert("[first-name]");
        else
            insert("[first-name|" + name + "]");
    };

    useEffect(common.useEffect(fixedPosition, onHide), [fixedPosition]);

    useEffect(() => {
        if(!show) {
            setShowInsertNameForm(false);
            setShowInsertFirstNameForm(false);
        }
    }, [show]);

    return (
        <>
            {show && (
                <div className={"dropdown toolbox-dropdown " + (!!fixedPosition ? "fixed" : "") + className} style={common.getStyle(fixedPosition)}>
                    {!showInsertNameForm && !showInsertFirstNameForm && (
                        <>
                            {(!disablePersonalizationField || !disablePersonalizationField.includes("name")) && <button className="btn btn-toolbox-item btn-block" type="button" onClick={() => insert("[name]")}>Listener full name</button>}
                            {(!disablePersonalizationField || !disablePersonalizationField.includes("name-fallback")) && <button className="btn btn-toolbox-item btn-block" type="button" onClick={() => setShowInsertNameForm(true)}>Listener full name with fallback</button>}
                            {(!disablePersonalizationField || !disablePersonalizationField.includes("first-name")) && <button className="btn btn-toolbox-item btn-block" type="button" onClick={() => insert("[first-name]")}>Listener first name</button>}
                            {(!disablePersonalizationField || !disablePersonalizationField.includes("first-name-fallback")) && <button className="btn btn-toolbox-item btn-block" type="button" onClick={() => setShowInsertFirstNameForm(true)}>Listener first name with fallback</button>}
                            {(!disablePersonalizationField || !disablePersonalizationField.includes("email")) && <button className="btn btn-toolbox-item btn-block" type="button" onClick={() => insert("[email]")}>Listener email</button>}
                            {(!disablePersonalizationField || !disablePersonalizationField.includes("install-url")) && <button className="btn btn-toolbox-item btn-block" type="button" onClick={() => insert("[install-url]")}>Install URL</button>}
                        </>
                    )}
                    {showInsertNameForm && (
                        <div className="name-dropdown">
                            <label>Fallback (no name available)</label>
                            <div className="d-flex">
                                <input ref={nameRef} autoFocus type="text" className="form-control fill" onKeyDown={nameOnKeyDown} />
                                <button type="button" className="btn btn-primary-light ml-2" onClick={insertName}>Insert</button>
                            </div>
                        </div>
                    )}
                    {showInsertFirstNameForm && (
                        <div className="name-dropdown">
                            <label>Fallback (no first or full name available)</label>
                            <div className="d-flex">
                                <input ref={firstNameRef} autoFocus type="text" className="form-control fill" onKeyDown={firstNameOnKeyDown} />
                                <button type="button" className="btn btn-primary-light ml-2" onClick={insertFirstName}>Insert</button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default PersonalizationToolbox;