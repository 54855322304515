export default {
    useEffect: (fixedPosition, onHide) => () => {
        let fn = ev => {
            if(ev.which == 27)
                onHide();
        };
        
        let fn2 = ev => onHide();
        
        document.addEventListener("keydown", fn);
        if(!!fixedPosition) {
            document.querySelector("#root").addEventListener("scroll", fn2);
            window.addEventListener("resize", fn2);
        }

        return () => {
            document.removeEventListener("keydown", fn);
            if(!!fixedPosition) {
                document.querySelector("#root").removeEventListener("scroll", fn2);
                window.removeEventListener("resize", fn2);
            }
        }
    },
    getStyle: fixedPosition => fixedPosition ? { left: fixedPosition.x, top: fixedPosition.y } : {}
};