import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

export default class CreateFirstProductModal extends React.Component {
  render() {
    return <Modal
      show={this.props.show}
      onHide={this.props.close}
      size="md"
      centered
      className="confirmModal create-first-product-modal"
    >
      <Modal.Body>
        <div className="pt-5 pb-0">Create your first audio feed. Then you can add listeners and episodes to the feed.</div>
      </Modal.Body>
      <Modal.Footer>
        <div className="pb-2">
          <Link to="/create-product" className="btn btn-primary mr-2">Create new product</Link>
          <button type="button" className="btn btn-secondary" onClick={this.props.close}>Cancel</button>
        </div>
      </Modal.Footer>
    </Modal>
  }
}
