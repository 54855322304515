import React, { useState, useEffect, useRef } from 'react';
import { AsideComponent } from './components/asideComponent';
import { ProductWidgetComponent } from './components/productWidget';
import api from "../../../redux/api";
import PublicPageLayout from "../generic/PublicPageLayout";
import { useTranslation } from 'react-i18next';

export const AuthorPage = props => {
    const { routes, userId } = props,
        [author, setAuthor] = useState(null),
        [categoryId, setCategoryId] = useState(new URL(window.location.href).searchParams.get("category")),
        { t, i18n } = useTranslation();

    useEffect(() => {
        const filter = userId
            ? { id: userId }
            : { uri: props.match.params.uri };

        api.salesPage.getInstructor(filter)
            .then(res => {
                if(!res.success || !res.data) window.location.href = "/404";
                setAuthor(res.data);
            }).catch(() => {
                window.location.href = "/404";
            });
    }, []);

    useEffect(() => {
        if(author)
            i18n.changeLanguage(author.salesPageLanguage || "en");
    }, [author]);

    if(!author) return <PublicPageLayout className="author-page" loading />;

    const liveProducts = author && author.products.filter((product) => !product.unlisted && !product.draft && product.salesPageLive && author.planFeatures.salesPage && (!categoryId || product.categoryId == categoryId));

    const productsListJSX = liveProducts && liveProducts.map((product, i) => (
        <ProductWidgetComponent
            key={product._id}
            author={author}
            product={product}
        />
    ));

    const productsJSX = <div className="content-item products-section">
        <div className="row align-items-center mb-12">
            <div className="col-lg-7">
                <h1 className="m-0">{t("Audio Feeds")}</h1>
            </div>
            {author.categories.length > 0 && (
                <div className="col-lg-5">
                    <div className="d-flex align-items-center">
                        <label>{t("Category")}</label>
                        <select className="custom-select ml-4" defaultValue={categoryId} onChange={ev => setCategoryId(ev.target.value)}>
                            <option value="">{t("All")}</option>
                            {author.categories.map((category, i) => <option value={category._id}>{category.name}</option>)}
                        </select>
                    </div>
                </div>
            )}
        </div>
        {liveProducts && liveProducts.length > 0
            ? <div className="row products-list">{productsListJSX}</div>
            : (
                <p className="empty">
                    {categoryId
                        ? t("There are no shows in this category.")
                        : t("The creator doesn’t have any public audio feeds yet.")}
                </p>
            )}
    </div>;

    const authorJSX = author && (
        <AsideComponent
            author={author}
            name={author.firstName ? author.firstName + (author.lastName ? " " + author.lastName : "") : author.email}
            avatar={author.pictureUrl}
            website={author.website}
            social={author.social}
            productsCount={author.stats.productsCount}
            episodesCount={author.stats.episodesCount}
            listenersCount={author.stats.listenersCount.current}
            downloadsCount={author.stats.downloads.total}
        />
    );

    const bioJSX = author && author.bio && (
        <div className="content-item">
            <h1>{t("About me")}</h1>
            <div dangerouslySetInnerHTML={{ __html: author.bio }} />
        </div>
    );

    const aboutJSX = author && author.about && (
        <div className="content-item">
            <h1>{t("My audio feeds")}</h1>
            <div dangerouslySetInnerHTML={{ __html: author.about }} />
        </div>
    );

    document.title = (author.firstName ? author.firstName + (author.lastName ? " " + author.lastName : "") : "") + " - Hiro";

    return <PublicPageLayout color={author.color} logo={author && author.logo && author.planFeatures.fullSalesPage && author.logo.imageUrl} author={author} className="author-page">
        <div className="col-md-4 col-lg-3 left-sidebar">
            {authorJSX}
        </div>
        <div className="col-md-8 col-lg-9 main-content">
            {productsJSX}
            {bioJSX}
            {aboutJSX}
        </div>
    </PublicPageLayout>;
}
