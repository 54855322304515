import React, { useEffect } from "react";
import { Message } from "./common";
import UserNameMessage from "./UserNameMessage";

function WelcomeMessage({ user, next }) {
    useEffect(() => {
        setTimeout(() => next(UserNameMessage), 1000);
    }, []);

    return (
        <>
            <Message delay={500}>
                Welcome to Hiro!
            </Message>
        </>
    );
}

export default WelcomeMessage;