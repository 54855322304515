import React, { useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import "react-phone-number-input/style.css";
import { isMobile } from "react-device-detect";
import { useFormik } from "formik";
import api from "../../../redux/api";
import { useLocation } from "react-router-dom";
import { toastMessage } from "../../helpers";
import * as Yup from "yup";

let validationSchema = Yup.object().shape({
  password: Yup.string().min(8, "Use at least 8 characters").required("Use at least 8 characters").matches(/^(?=.*\d)(?=.*[a-z])/, "Use a combination of letters and numbers")
});

function ResetPassword(props) {
  let [ loading, setLoading ] = useState(false),
    [ success, setSuccess ] = useState(false),
    [ revealPassword, setRevealPassword ] = useState(false),
    formik = useFormik({
      initialValues: {
        password: ""
      },
      validationSchema,
      onSubmit: async values => {
        setLoading(true);

        let res = await api.app.resetPassword(props.match.params.code, values.password);

        setLoading(false);

        if(!res || !res.success)
          return toastMessage.error((res && res.error) || "Unkown error.");

        setSuccess(true);
      }
    });

  let getInputClasses = name => {
    if(formik.touched[name] && formik.errors[name])
      return "is-invalid";

    return "";
  };

  return (
    <>
      <div className={"app-page " + (isMobile ? "" : "desktop")}>
        <div>
          <SVG src={toAbsoluteUrl("/media/logos/logo.svg")} className="logo" />
          {success
          ? (
            <>
              <h3>Success!</h3>
              <p>You can now go back to the app and log in with your new password.</p>
              {isMobile && <a href="hiro://library" className="btn mt-10 btn-primary">Open App</a>}
            </>
          )
          : (
            <form onSubmit={formik.handleSubmit}>
              <label className="form-label">Create a new password</label>
              <div className="password-wrapper">
                <input className={"form-control has-eye " + getInputClasses("password")} type={revealPassword ? "text" : "password"} {...formik.getFieldProps("password")} />
                <button type="button" className="btn eye" onClick={ev => setRevealPassword(v => !v)}><img src={toAbsoluteUrl("/media/def-image/icons/eye.svg")} /></button>
              </div>
              {formik.touched.password && formik.errors.password
                ? (
                  <div className="validation-error">{formik.errors.password}</div>
                )
                : null}
              <button disabled={!formik.dirty || !formik.isValid} className={"btn mt-10 btn-primary " + (loading ? "loading spinner" : "")}>Submit</button>
            </form>
          )}
        </div>
      </div>
    </>
  );
}

export default ResetPassword;