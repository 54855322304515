import React from "react";
import i18n from "../i18n";
import _ from "lodash";

/**
 * Returns the validation-related classnames for an input field.
 * @param {Object} formik
 * @param {string} fieldname
 * @returns {string}
 */
export const getInputClassName = (formik, fieldname, className = "") => {
  className = "form-control " + className + " ";
  if(_.get(formik.touched, fieldname)) {
    if(_.get(formik.errors, fieldname))
      return className + "is-invalid";
    return className + "is-valid";
  }
  return className;
};

/**
 * Returns the input field feedback as JSX.
 * @param {Object} formik
 * @param {string} fieldName
 * @returns {Object}
 */
export const displayFeedback = (formik, fieldName, className = "") => _.get(formik.touched, fieldName) && _.get(formik.errors, fieldName) ? (
  <div className={"invalid-feedback " + className}>{i18n.t(_.get(formik.errors, fieldName))}</div>
) : null;
