import { useFetch } from "../../app/helpers";

export const LOGIN_URL = "auth";
export const REGISTER_URL = "auth/register";
export const REQUEST_PASSWORD_URL = "auth/forgot-password";
export const GET_USER_URL = "get-current-instructor";

export default {
  signedUrl: data => useFetch.post('aws-s3/signedUrl', data),
  validateAttachment: url => useFetch.post("/validate-attachment", { url }, true, true)
};
