import { Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import UnsavedFormGuard from "../../../../layout/components/UnsavedFormGuard";

export default function TrackingGAModal({ show, onHide, user, useGlobal = false, value, onSubmit, saving = false, where }) {
    const ref = useRef(),
        [savingFrom, setSavingFrom] = useState("form");

    function submit(values) {
        save("form");
    }

    function save(from) {
        setSavingFrom(from);
        let values = ref.current.values;
        onSubmit({
            value: !useGlobal || values.type == "custom"
                ? values.id
                : values.type
        });
    }

    const valueIsKeyword = value == "global" || value == "disabled" || value == "custom";

    return (
        <>
            <Modal show={show} onHide={onHide} size="md" centered className="new-modals tracking-modal">
                <Formik
                    initialValues={{
                        type: valueIsKeyword
                            ? value
                            :  (
                                value ? "custom" : ""
                            ),
                        id: valueIsKeyword
                            ? ""
                            : value
                    }}
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={true}
                    onSubmit={submit}
                    innerRef={ref}>
                    {formik => <Form>
                        <Modal.Body>
                            <button type="button" className="close" onClick={onHide}>
                                <SVG src="/media/def-image/close-sm.svg" className="svg-icon" />
                            </button>

                            <div className="text-center mb-12">
                                <SVG src="/media/def-image/google.svg" className="logo" />
                                <h1 className="my-6">Connect Google Analytics</h1>
                                <p>Enter your Google Analytics Measurement ID to add analytics to your {where}</p>
                            </div>

                            {useGlobal && (
                                <div className="form-group mb-0">
                                    <label className="form-label">Tracking Code</label>
                                    <select className="custom-select" {...formik.getFieldProps("type")}>
                                        <option></option>
                                        <option value="global">Use global Tracking Code ({user.analyticsId || "Not set"})</option>
                                        <option value="custom">Enter new Tracking Code</option>
                                        <option value="disabled">Disable</option>
                                    </select>
                                </div>
                            )}

                            {(formik.values.type == "custom" || !useGlobal) && (
                                <div className="form-group mt-4">
                                    <label className="form-label">Measurement ID <em>*</em></label>
                                    <input type="text" autoFocus className={"form-control " + (formik.touched.id && (formik.errors.id ? "is-invalid" : "is-valid"))} {...formik.getFieldProps("id")} placeholder="G-1234567890" />
                                    {formik.touched.id && formik.errors.id && <div className="field-error">{formik.errors.id}</div>}
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary mr-2" onClick={onHide}>Cancel</button>
                            <button type="submit" className={"btn btn-primary " + (saving && savingFrom == "form" ? "loading spinner" : "")} disabled={!formik.isValid || !formik.dirty}>Save</button>
                        </Modal.Footer>
                    </Form>}
                </Formik>
            </Modal>

            <UnsavedFormGuard formikRef={ref} onSaveAsync={() => save("modal")} loading={() => saving && savingFrom == "modal"} />
        </>
    );
}