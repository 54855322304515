import Progress from "./Progress";
import Schedule from "./Schedule";
import Abandoned from "./Abandoned";
import moment from "moment";
import { formatLength } from "../../../helpers";
import _ from "lodash";
import Episode from "./Episode";

let byType = {
    "new-listener": {
        type: "new-listener",
        title: "Listener gets access",
        subtitle: "The trigger occurs when a listener gets access to a show",
        icon: "/media/def-image/automations/access-trigger.svg",
        component: null,
        getDescription: () => null,
        validate: values => null
    },
    "episode-released": {
        type: "episode-released",
        title: "New episode is available",
        subtitle: "Trigger when a specific episode is available to the listener",
        icon: "/media/def-image/automations/episode-released-action.svg",
        component: Episode,
        getDescription: (trigger, { product }) => trigger && trigger.parameters && trigger.parameters.episodes && trigger.parameters.episodes.length && product
            ? (
                trigger.parameters.episodes.length == 1
                    ? (product.episodes.find(e => e._id == trigger.parameters.episodes[0]) || { title: "(Invalid episode)" }).title
                    : trigger.parameters.episodes.length + " episodes"
            )
            : "All episodes",
        validate: values => null
    },
    "progress": {
        type: "progress",
        title: "Play through rate",
        subtitle: "Trigger when the play through rate meets a certain criteria",
        icon: "/media/def-image/automations/play-trigger.svg",
        component: Progress,
        getDescription: (trigger, { product }) => trigger && trigger.parameters && trigger.parameters.episode && product
            ? (product.episodes.find(e => e._id == trigger.parameters.episode) || { title: "(Invalid episode)" }).title + " at " + formatLength(trigger.parameters.progress)
            : null,
        validate: values => {
            let errors = {};

            if(!values.parameters.episode)
                _.set(errors, "parameters.episode", "Select an episode.");

            return errors;
        }
    },
    "listener-abandons": {
        type: "listener-abandons",
        title: "Listener stops",
        subtitle: "Trigger whenever a listener becomes inactive",
        icon: "/media/def-image/automations/abandon-trigger.svg",
        component: Abandoned,
        getDescription: trigger => trigger.parameters && (!trigger.parameters.when || trigger.parameters.when == "delay")
            ? trigger.parameters.delay + " " + (trigger.parameters.period || "minutes") + " after last listen"
            : "Immediately",
        validate: values => {
            let errors = {};

            if(!values.parameters.delay || isNaN(values.parameters.delay))
                _.set(errors, "parameters.delay", "Enter the delay.");

            return errors;
        }
    },
    "listener-starts": {
        type: "listener-starts",
        title: "Listener starts show",
        subtitle: "Trigger when a new listener starts the show.",
        icon: "/media/def-image/automations/start-trigger.svg",
        component: null,
        getDescription: () => null,
        validate: values => null
    },
    "date": {
        type: "date",
        title: "On a specific date",
        subtitle: "When a specific date is reached",
        icon: "/media/def-image/automations/date-trigger.svg",
        component: Schedule,
        getDescription: trigger => trigger && trigger.parameters && trigger.parameters.date
            ? moment(trigger.parameters.date).toDate().toLocaleString()
            : null,
        validate: values => {
            let errors = {};

            if(!values.parameters.date)
                _.set(errors, "parameters.date", "Select a date and time.");

            return errors;
        }
    },
    "listener-completes-show": {
        type: "listener-completes-show",
        title: "Listener finishes show",
        subtitle: "Trigger whenever a listener finishes all episodes",
        icon: "/media/def-image/automations/finish-trigger.svg",
        component: null,
        getDescription: () => null,
        validate: values => null
    }
};

let list = [
    byType["new-listener"],
    byType["episode-released"],
    byType["progress"],
    byType["listener-abandons"],
    byType["listener-starts"],
    byType["date"],
    byType["listener-completes-show"]
];

export default {
    byType,
    list,
    getOptions: () => list.map(o => ({
        id: o.type,
        label: o.title,
        subtitle: o.subtitle,
        image: o.icon
    }))
};