import React, { useEffect, useRef, useState } from "react";
import api from "../../../../redux/api";
import { Message, Send, sleep, Writting } from "./common";
import UploadPictureMessage from "./UploadPictureMessage";

function CreatorNameMessage({ next, scroll, update }) {
    let [firstName, setFirstName] = useState(""),
        [firstNameInvalid, setFirstNameInvalid] = useState(false),
        [lastName, setLastName] = useState(""),
        [lastNameInvalid, setLastNameInvalid] = useState(false),
        lastNameRef = useRef(),
        [sent, setSent] = useState(false),
        [loading, setLoading] = useState(false),
        [error, setError] = useState(null);

    let firstNameOnChange = ev => {
        setFirstName(ev.target.value);
        setFirstNameInvalid(!ev.target.value.trim());
    };

    let firstNameOnKeyDown = ev => {
        if(ev.which == 13) {
            ev.preventDefault();
            lastNameRef.current.focus();
        }
    };

    let lastNameOnChange = ev => {
        setLastName(ev.target.value);
        setLastNameInvalid(!ev.target.value.trim());
    };

    let lastNameOnKeyDown = ev => {
        if(ev.which == 13) {
            ev.preventDefault();
            submit();
        }
    };

    let submit = async () => {
        if(!firstName || firstNameInvalid || !lastName || lastNameInvalid)
            return;

        setSent(true);
        setLoading(true);

        await sleep(2500);

        let data = { firstName, lastName };

        let res = await api.user.onboardingStep("step-creator-name", data);

        setLoading(false);

        if(!res || !res.success) {
            setError((res && res.error) || "I’m sorry, I wasn’t able to process that. Please, try again.");
            setTimeout(() => next(CreatorNameMessage), 1000);
            return;
        }

        next(UploadPictureMessage, data);

        update();
    };

    useEffect(() => {
        scroll();
    }, [sent, loading, error]);

    return (
        <>
            <Message>
                What is the creator’s name? You can always change this later.
            </Message>
            {!sent && (
                <Message delay={500}>
                    <div className={"input w-300px " + (firstNameInvalid ? "invalid " : "") + (!firstName ? "empty " : "")}>
                        <label>Creator first name</label>
                        <input type="text" autoFocus className="form-control" onChange={firstNameOnChange} onKeyDown={firstNameOnKeyDown} />
                    </div>
                    <div className={"input " + (lastNameInvalid ? "invalid " : "") + (!lastName ? "empty " : "")}>
                        <label>Creator last name</label>
                        <input type="text" ref={lastNameRef} className="form-control" onChange={lastNameOnChange} onKeyDown={lastNameOnKeyDown} />
                    </div>
                    <div className={"input btn-only " + (firstNameInvalid || lastNameInvalid ? "invalid " : "") + (!firstName || !lastName ? "empty " : "")}>
                        <Send onClick={submit} />
                    </div>
                </Message>
            )}
            {sent && (
                <>
                    <Message incoming>
                        {firstName} {lastName}
                    </Message>
                </>
            )}
            {loading && <Writting delay={1500} />}
            {error && (
                <Message>{error}</Message>
            )}
        </>
    );
}

export default CreatorNameMessage;