import React, { useEffect, useRef } from "react";

export default function useTimeout(callback, delay, useInterval = false) {
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        if(!delay)
            return;

        const fn = useInterval
            ? setInterval
            : setTimeout;

        const clearFn = useInterval
            ? clearInterval
            : clearTimeout;

        const timer = fn(() => callbackRef.current(), delay);

        return () => clearFn(timer);
    }, [delay, useInterval]);
}