import React, { useEffect, useRef, useState } from "react";
import api from "../../../../../redux/api";
import { Message, Send, Writting } from "../../../modals/onboarding/common";
import FinalMessage from "../FinalMessage";

export default function NameSuggestionsMessage({ user, next, state }) {
    const [answer, setAnswer] = useState(null),
        [sent, setSent] = useState(false),
        [namesLoading, setNamesLoading] = useState(true),
        [names, setNames] = useState([]),
        [error, setError] = useState(false),
        [loading, setLoading] = useState(false),
        [requestMore, setRequestMore] = useState(false),
        [projectId, setProjectId] = useState(null);

    const submit = (name = null, ev = null) => {
        if(ev)
            ev.preventDefault();

        if(name) {
            setSent(true);
            setAnswer(name);
        }

        setLoading(true);

        let namesStr = names.map((name, i) => (i + 1) + ". " + name).join("\n");

        setTimeout(() => {
            setLoading(false);
            next(FinalMessage, { nameSuggestions: !requestMore && namesStr, selectedName: name, projectId });
        }, 2000);
    };

    const moreOnClick = ev => {
        ev.preventDefault();
        
        setSent(true);
        setAnswer("Suggest five more.");
        setRequestMore(true);
        setTimeout(() => submit(), 1000);
    };

    const loadNames = async () => {
        let res = await api.ai.nameSuggestions(state);

        setNamesLoading(false);

        if(!res || !res.success) {
            setError((res && res.error) || "I’m not able to generate title suggestions right now, but I’ll come up with a list later!");
            submit();
        } else {
            setNames(res.names);
            setProjectId(res.projectId);
        }
    };

    useEffect(() => {
        loadNames();
    }, []);

    return (
        <>
            <Message>
                Hang on, I’ll come up with some title ideas for you!
            </Message>

            {namesLoading
                ? <Writting delay={500} />
                : (!!names.length && !sent && (
                    <div className="options col">
                        {names.map((name, i) => <a href="#" onClick={ev => submit(name, ev)} key={i}>{name}</a>)}
                        <a href="#" onClick={moreOnClick}>Suggest five more</a>
                    </div>
                ))}

            {error && <Message>{error}</Message>}

            {sent && <Message incoming>{answer}</Message>}

            {requestMore && <Message delay={1000}>Ok, I’ll come up with more ideas later!</Message>}

            {loading && <Writting delay={requestMore ? 2000 : 1000} />}
        </>
    );
}
