import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import actions from "../../../../../redux/actions";
import api from "../../../../../redux/api";
import { getInputClassName, toastMessage } from "../../../../helpers";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import PasswordStrengthBar from "react-password-strength-bar";

//TODO fix this awful markup

const initialValues = {
	password: "",
	confirmPassword: ""
};

function ResetPassword(props) {
	let [revealPassword, setRevealPassword] = useState(false),
		[revealConfirmPassword, setRevealConfirmPassword] = useState(false);
	const { token } = useParams();
	const ResetPasswordSchema = Yup.object().shape({
		password: Yup.string()
			.min(3, "Minimum 8 characters")
			.required("Required field."),
		confirmPassword: Yup.string()
			.required("Required field.")
			.oneOf([Yup.ref("password"), null], "Passwords don‘t match."),
	});

	const formik = useFormik({
		initialValues,
		validationSchema: ResetPasswordSchema,
		onSubmit: async (values, { setSubmitting }) => {
			let res = await api.auth.requestPassword(token, values.password);

			if(res && res.success) {
				toastMessage.success("Your password has been updated.");
				props.history.push("/auth/login");
			}

			setSubmitting(false);
		},
	});

	return (
		<>
			<h1>Change password</h1>

			<form onSubmit={formik.handleSubmit}>
				<div className="form-group fv-plugins-icon-container">
					<label className="form-label">Password</label>
					<div className="password-wrapper">
						<input type={revealPassword ? "text" : "password"}
							className={getInputClassName(formik, "password")}
							name="password"
							{...formik.getFieldProps("password")}
						/>
						<button type="button" className="btn eye" onClick={() => setRevealPassword(v => !v)}>
							<img src={toAbsoluteUrl("/media/def-image/icons/eye.svg")} />
						</button>
					</div>
					{formik.touched.password && formik.errors.password && <div className="field-error">{formik.errors.password}</div>}
					<PasswordStrengthBar className="password-strength" password={formik.values.password} barColors={["", "#16A9F5", "#16A9F5", "#16A9F5", "#16A9F5"]} />
					<p className="text-gray small m-0 text-center">Use 8 or more characters with a mix of letters, numbers & symbols</p>
				</div>
				<div className="form-group fv-plugins-icon-container">
					<label className="form-label">Confirm Password</label>
					<div className="password-wrapper">
						<input
							type={revealConfirmPassword ? "text" : "password"}
							className={getInputClassName(formik, "confirmPassword")}
							name="confirmPassword"
							{...formik.getFieldProps("confirmPassword")}
						/>
						<button type="button" className="btn eye" onClick={() => setRevealConfirmPassword(v => !v)}><img src={toAbsoluteUrl("/media/def-image/icons/eye.svg")} /></button>
					</div>
					{formik.touched.confirmPassword && formik.errors.confirmPassword && <div className="field-error">{formik.errors.confirmPassword}</div>}
				</div>
				<div className="form-group form-group-btn d-flex flex-wrap mb-8">
					<button
						type="submit"
						className={"btn btn-primary " + (formik.isSubmitting ? "loading spinner" : "")}
					>
						Set new password
					</button>
				</div>
			</form>

			<div>
				<Link to="/auth/login" className="font-weight-semibold">Return to sign in</Link>
			</div>
		</>
	);
}

export default injectIntl(connect(null, actions.auth)(ResetPassword));
