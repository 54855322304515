import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import UpgradeLink from "../../../generic/UpgradeLink";
import { getAuthorUrl } from "../../../../helpers";
import UserPicture from "../../../../layout/components/UserPicture";

export function ProfileCard() {
  const user = useSelector(({ auth }) => auth.user, shallowEqual);

  let tendencyArrow = stats => {
    let v = "none";
    if(stats && stats.current != 0) {
      if(!stats.lastMonth || stats.current > stats.lastMonth)
        v = "up";
      if(stats.current < stats.lastMonth)
        v = "down";
    }

    let name = {
      up: "Up-2",
      down: "Down-2",
      none: "Minus"
    }[v],
      color = {
        up: "svg-icon-success",
        down: "svg-icon-danger",
        none: ""
      }[v];
    return {
      svg: "/media/svg/icons/Navigation/" + name + ".svg",
      color
    };
  };

  if(!user)
    return <></>;

  return (
    <div className="card card-profile-header mb-10">
      <div className="card-body py-9">
        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
          <div className="me-5">
            <UserPicture user={user} />
          </div>
          <div className="flex-grow-1">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center mt-2">
                <label className="d-block fs-2 fw-bold me-1">{user.firstName ? user.firstName + " " + user.lastName : <span className="text-muted">(No name)</span>} {user.challengesCount && user.currentChallengeEnds && new Date(user.currentChallengeEnds) >= new Date() ? <span className="gray-badge">challenger</span> : <></>}</label>
              </div>
              <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                <label className="d-flex align-items-center text-gray-400 mb-0 me-3">
                  {(() => {
                    let label = "Free account";
                    if(!user.noPlan) label = user.planFeatures.title + " Membership";
                    return <><SVG src={toAbsoluteUrl("/media/def-image/icons/star-circle.svg")} className="svg-icon svg-icon-4 me-2" /> {label}</>;
                  })()}
                </label>
                {/*
                {user.freeTrial ? <label className="gray-badge mb-0 me-3">trialing</label> : <></>}
                <UpgradeLink label="change plans" user={user} className="ml-2" />
                */}
              </div>
            </div>

            <div className="stats mt-1">
              <div className="item">
                <div className="value">
                  <SVG src={tendencyArrow(user.stats.downloads).svg} className={"arrow " + tendencyArrow(user.stats.downloads).color} />
                  {parseInt(user.stats.downloads.current).toLocaleString()}
                </div>
                <label>Downloads</label>
              </div>
              <div className="item">
                <div className="value">
                  <SVG src={tendencyArrow(user.stats.listenersCount).svg} className={"arrow " + tendencyArrow(user.stats.listenersCount).color} />
                  {parseInt(user.stats.listenersCount.current).toLocaleString()}
                </div>
                <label>Listeners</label>
              </div>
            </div>

          </div>
          <div>
            {(user.publicUrl.customDomain || user.publicUrl.uri) && user.planFeatures.salesPage && <a href={getAuthorUrl(user)} className="btn btn-primary" target="_blank">View Profile Page</a>}
          </div>
        </div>
      </div>
    </div>
  );
}