import _ from "lodash";
import Picker from "emoji-picker-react";
import React, { useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import PersonalizationToolbox from "./toolbox/PersonalizationToolbox";
import EmojiToolbox from "./toolbox/EmojiToolbox";
import MediaToolbox from "./toolbox/MediaToolbox";

function MessageField({ emoji = true, personalization = true, disablePersonalizationField, media = false, className = "", maxLength = null, name = null, formik = null, onChange = null, value = null }) {
    let ref = useRef(),
        [showEmojiPicker, setShowEmojiPicker] = useState(false),
        [showPersonalization, setShowPersonalization] = useState(false),
        [showMedia, setShowMedia] = useState(false);

    let getValue = () => {
        if(value)
            return value;
        if(formik && name)
            return _.get(formik.values, name) || "";
        return "";
    };

    let textareaOnChange = ev => {
        if(formik && name)
            formik.setFieldValue(name, ev.target.value);
        if(onChange)
            onChange(ev.target.value);
    };

    let insert = text => {
        let value = ref.current.value.substring(0, ref.current.selectionStart)
            + text
            + ref.current.value.substring(ref.current.selectionEnd);

        ref.current.value = value;
        if(formik && name)
            formik.setFieldValue(name, value);
        if(onChange)
            onChange(value);
    };

    let closeAll = () => {
        setShowEmojiPicker(false);
        setShowPersonalization(false);
        setShowMedia(false);
    };

    return (
        <div className={"message-field " + className}>
            <div className="toolbox">
                <div className="d-flex fill">
                    {emoji && (
                        <div>
                            <button type="button" className="btn btn-toolbox-item" onClick={() => {
                                setShowEmojiPicker(v => !v);
                                setShowPersonalization(false);
                                setShowMedia(false);
                            }}>
                                <SVG src="/media/def-image/icons/emoji.svg" /> Emoji
                            </button>
                            <EmojiToolbox show={showEmojiPicker} onHide={closeAll} onData={insert} />
                        </div>
                    )}
                    {personalization && (
                        <div>
                            <button type="button" className="btn btn-toolbox-item" onClick={() => {
                                setShowEmojiPicker(false);
                                setShowPersonalization(v => !v);
                                setShowMedia(false);
                            }}>
                                <SVG src="/media/def-image/icons/personalization.svg" /> Personalization
                            </button>
                            <PersonalizationToolbox show={showPersonalization} onHide={closeAll} onData={insert} disablePersonalizationField={disablePersonalizationField} />
                        </div>
                    )}
                    {media && (
                        <div>
                            <button type="button" className="btn btn-toolbox-item" onClick={() => {
                                setShowEmojiPicker(false);
                                setShowPersonalization(false);
                                setShowMedia(v => !v);
                            }}>
                                <SVG src="/media/def-image/icons/media.svg" /> Media
                            </button>
                            <MediaToolbox show={showMedia} onHide={closeAll} onData={insert} />
                        </div>
                    )}
                </div>
                {maxLength && (
                    <div className="text-right text-muted">
                        {Math.max(maxLength - getValue().length, 0)} / {maxLength}
                    </div>
                )}
            </div>
            <textarea
                className="form-control"
                ref={ref}
                type="range"
                value={getValue()}
                onChange={textareaOnChange} />
        </div>
    );
}

export default MessageField;