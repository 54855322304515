import React, { useState } from "react";
import { useFormik } from "formik";
import api from "../../../redux/api";
import actions from "../../../redux/actions";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

function Login(props) {
	let [ loading, setLoading ] = useState(false),
		formik = useFormik({
			initialValues: {
				user: "",
				password: ""
			},
			onSubmit: async () => {
				setLoading(true);
				let res = null;
				try {
					res = await api.admin.login(formik.values.user, formik.values.password);
				} catch(x) {}
				setLoading(false);
				if(res) props.setAdminLogin(res.token, res.user);
			}
		});

	return <div className="container admin-page login-container d-flex justify-content-center align-items-center">
		<form className="p-10 rounded text-center" onSubmit={formik.handleSubmit}>
			<input type="text" placeholder="Username" autoFocus className="form-control form-control-sm mb-3" {...formik.getFieldProps("user")}/>
			<input type="password" placeholder="Password" className="form-control form-control-sm mb-3" {...formik.getFieldProps("password")}/>
			<button type="submit" className="btn btn-sm btn-primary" disabled={loading}>Log in</button>
		</form>
	</div>;
}

export default injectIntl(connect(null, actions.auth)(Login));
