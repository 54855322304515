import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import api from "../../../redux/api";
import { toastMessage } from "../../helpers";
import SVG from "react-inlinesvg";


export default function RenameForm({ project, onComplete, onCancel }) {
    const [loading, setLoading] = useState(false),
        inputRef = useRef();

    useEffect(() => {
        if(inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [inputRef]);

    const onKeyDown = ev => {
        if(ev.which == 13) {
            ev.preventDefault();
            rename();
        }

        if(ev.which == 27) {
            ev.stopPropagation();
            ev.preventDefault();
            onCancel();
        }
    };

    const rename = async () => {
        setLoading(true);

        let res = await api.ai.update(project._id, { name: inputRef.current.value });

        if(!res || !res.success)
            toastMessage.error((res && res.error) || "Unable to connect to the server.");
        else
            toastMessage.success("Project updated!");

        setLoading(false);
        onComplete(inputRef.current.value);
    };

    return (
        <div className="d-flex mw-340 rename-form">
            <input type="text" className="form-control form-control-sm fill" autoFocus onKeyDown={onKeyDown} defaultValue={project.name} ref={inputRef} />
            <button type="button" className={"btn btn-primary btn-sm ml-2 " + (loading ? "loading spinner spinner-sm" : "")} onClick={() => rename()}>Save</button>
        </div>
    );
}
