import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { Form, Formik, useFormik } from "formik";
import actions from "../../../../redux/actions";
import schemas from "../../../schemas";
import api from "../../../../redux/api";
import { getSearchRegExp, toastMessage, updateCurrentUser } from "../../../helpers";
import SettingsNavigation from "../../../layout/navigation/SettingsNavigation";
import UnsavedFormGuard from "../../../layout/components/UnsavedFormGuard";
import TagsInput from "react-tagsinput";
import SVG from "react-inlinesvg";
import { Link, useRouteMatch, useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Dropdown } from "react-bootstrap";
import ConfirmModal from "../../modals/ConfirmModal";
import moment from "moment";
import useTimeout from "../../../helpers/useTimeout";
import { NeedsUpgradeEvents } from "../../modals/NeedsUpgrade";
import ProfileNavigation from "../../../layout/navigation/ProfileNavigation";
import { UpgradeEvents } from "../../modals/UpgradeModal";
import { checkLimits } from "../../../plans";

function DomainsSettings() {
  const user = useSelector((state) => state.auth.user, shallowEqual),
    [loading, setLoading] = useState(true),
    dispatch = useDispatch(),
    createMatch = useRouteMatch("/user/domains/create"),
    history = useHistory(),
    [confirmDelete, setConfirmDelete] = useState(null),
    [data, setData] = useState([]),
    [search, setSearch] = useState(null),
    location = useLocation();

  useTimeout(() => loadData(false), 30000, true);

  async function deleteItem(id) {
    setLoading("table");
    setConfirmDelete(null);

    let res = await api.user.removeDomain(id);

    setLoading(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success("The domain has been removed.");

    updateCurrentUser(dispatch);
    loadData("table");
  }

  async function loadData(loading = true) {
    setLoading(loading);

    let res = await api.user.listDomains();

    setLoading(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    setData(res.data);
    setLoading(false);
  }

  function getFilteredData() {
    return data.filter(item => !search || getSearchRegExp(search).test(item.host));
  }

  async function verify(item) {
    setLoading("item_" + item._id);

    let res = await api.user.verifyDomain(item._id);

    updateCurrentUser(dispatch);

    setLoading(false);

    if(!res || !res.success || !res.verified) {
      history.push("#verification-in-progress");
    } else {
      toastMessage.success("The domain has been activated!");
      history.push("#");
      loadData();
    }
  }

  async function addDomainOnClick(ev) {
    ev.preventDefault();
    if(await checkLimits.canAddDomain(user))
      history.push("/user/domains/create");
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <ProfileNavigation active="domains" />

      {loading === true
        ? <div className="loading-block spinner" />
        : (
          !data.length
            ? (
              <div className="container-inner domains-page empty-state">
                <SVG src="/media/def-image/empty-domains.svg" />
                <h3>Connect Your Domains</h3>
                <p>Connect your existing domains to your Show Sales Pages and Profile Page</p>
                <a href="#" onClick={addDomainOnClick} className="btn btn-primary">Add Domain</a>
              </div>
            )
            : (
              <div className="container-inner domains-page">
                {location.hash == "#verification-in-progress" && (
                  <div className="alert mb-10 alert-success d-flex align-items-center m-0">
                    <div>
                      <h4>Verification in progress</h4>
                      It can take up to an hour to detect the changes in your domain. If you did the configuration correctly, it will get verified automatically soon.
                    </div>
                  </div>
                )}

                <div className="card p-9">
                  <div className="card-toolbar">
                    <a href="#" onClick={addDomainOnClick} className="btn btn-primary">Connect Domain</a>
                    <div className="fill" />
                    <input type="text" className="form-control search" placeholder="Search Domains" onChange={ev => setSearch(ev.target.value)} />
                  </div>

                  {loading == "table"
                    ? <div className="loading-block spinner" />
                    : (
                      <div className="table-responsive">
                        <table className="table table-head-custom table-vertical-center">
                          <thead>
                            <tr>
                              <th>Domain Name</th>
                              <th>Status</th>
                              <th>Date Added</th>
                              <th width="10"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {getFilteredData().map((item, i) => (
                              <tr key={i}>
                                <td>
                                  {item.host}
                                </td>
                                <td>
                                  <span className={"badge " + (item.status == "verified" ? "green-badge" : "gray-badge")}>
                                    {item.status == "verified" ? "Connected" : "Pending"}
                                  </span>
                                </td>
                                <td>
                                  {moment(item.createdAt).locale("en").format("ll")}
                                </td>
                                <td className="pr-0 text-right">
                                  <Dropdown className="dropdown table-options-dropdown fixed-dropdown dropdown-inline">
                                    <Dropdown.Toggle className={loading == "item_" + item._id ? " loading spinner spinner-dark" : ""}>
                                      <SVG src="/media/def-image/icons/menu-2.svg" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
                                      <Link to={"/user/domains/" + item._id} className="dropdown-item">
                                        <div className="icon">
                                          <SVG src="/media/def-image/icons/eye-2.svg" />
                                        </div>
                                        Details
                                      </Link>
                                      {item.status != "verified" && (
                                        <Dropdown.Item href="#" onClick={() => verify(item)}>
                                          <div className="icon">
                                            <SVG src="/media/def-image/icons/verify.svg" />
                                          </div>
                                          Verify
                                        </Dropdown.Item>
                                      )}
                                      <Dropdown.Item href="#" onClick={() => setConfirmDelete(item)}>
                                        <div className="icon">
                                          <SVG src="/media/def-image/icons/delete.svg" />
                                        </div>
                                        Remove
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                </div>
              </div >
            ))
      }

      <ConfirmModal
        show={!!confirmDelete}
        message={<>Do you want to permanently delete this domain?</>}
        onConfirm={() => deleteItem(confirmDelete._id)}
        onCancel={() => setConfirmDelete(null)} />
    </>
  );
}

export default connect(null, actions.auth)(DomainsSettings);
