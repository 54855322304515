import _ from "lodash";
import React, { useRef, useState } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import actions from "../../../../redux/actions";
import schemas from "../../../schemas";
import api from "../../../../redux/api";
import { toastMessage } from "../../../helpers";
import ProfileNavigation from "../../../layout/navigation/ProfileNavigation";
import UnsavedFormGuard from "../../../layout/components/UnsavedFormGuard";
import SettingsNavigation from "../../../layout/navigation/SettingsNavigation";

function Password() {
  let auth = useSelector((state) => state.auth.user, shallowEqual),
    user = auth?.owner || auth,
    [ loading, setloading ] = useState(0),
    [ changeEmail, setChangeEmail ] = useState(false),
    [ changePassword, setChangePassword ] = useState(false),
    dispatch = useDispatch(),
    formikRefs = {
      email: useRef(),
      password: useRef()
    };
    
  let getInputClasses = (formik, fieldname) => {
    if(formik.touched[ fieldname ] && formik.errors[ fieldname ])
      return "is-invalid";
    if(formik.touched[ fieldname ] && !formik.errors[ fieldname ])
      return "is-valid";
    return "";
  };

  let sendChangeEmailForm = async (userData, loading = 2) => {
    setloading(loading);

    let res = await api.user.editCurrentUser({
      updateSuperUser: true,
      action: "changeEmail",
      user: userData,
    });

    if(res.status != 403 && res.status != 500) {
      toastMessage.success("Your email address has been updated!");
      setChangeEmail(false);
      dispatch(actions.auth.fulfillUser((await api.auth.getUserByToken(true)).data));
    }
    
    setloading(0);
  };

  let sendChangePasswordForm = async (userData, loading = 3) => {
    setloading(loading);

    let res = api.user.editCurrentUser({
      updateSuperUser: true,
      action: "changePassword",
      user: userData,
    });

    if(res && res.status != 403 && res.status != 500) {
      toastMessage.success("Your password has been updated!");
      setChangePassword(false);
      dispatch(actions.auth.fulfillUser((await api.auth.getUserByToken(true)).data));
    }
    
    setloading(0);
  };

  let displayFeedback = (formik, fieldName) => formik.touched[ fieldName ] && formik.errors[ fieldName ] ? (
    <div className="invalid-feedback">{formik.errors[ fieldName ]}</div>
  ) : null;

  let saveAll = async () => {
    if(changeEmail)
      await sendChangeEmailForm(formikRefs.email.current.values, "modal");

    if(changePassword)
      await sendChangePasswordForm(formikRefs.email.current.values, "modal");
  };

  return (
    <>
      <SettingsNavigation active="password" />

      <div className="container-inner">
        <div className="card card-profile-header mb-10">
          <div className="card-header">
            <h3 className="card-title fw-bold m-0">Sign In Method</h3>
          </div>
          <div className="card-body pt-9 pb-10">

            <div className={(changeEmail ? "d-none" : "d-flex") + " flex-wrap align-items-center"}>
              <div className="">
                <div className="fs-6 fw-bold mb-1">Email Address</div>
                <div className="text-gray">{user.email}</div>
              </div>
              <div className="ms-auto">
                <button type="button" className="btn btn-secondary" onClick={() => setChangeEmail(true)}>Change Email</button>
              </div>
            </div>
            <div className={"flex-row-fluid " + (changeEmail ? "" : "d-none")}>
              <Formik
                initialValues={{
                  email: user.email
                }}
                validationSchema={schemas.user.user.changeEmail}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={true}
                onSubmit={sendChangeEmailForm}
                innerRef={formikRefs.email}
              >
                {formik => <Form>
                  <div className="form-group mb-6">
                    <label className="form-label">New Email Address <em>*</em></label>
                    <input
                      type="text"
                      className={`form-control ${getInputClasses(formik, "email")}`}
                      {...formik.getFieldProps("email")}
                    />
                    {displayFeedback(formik, "email")}
                  </div>
                  <div className="d-flex align-items-end">
                    <div className="fill"/>
                    <button type="submit" className={"btn btn-primary mr-2 " + (loading == 2 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)}>Update Email</button>
                    <button type="button" className="btn btn-secondary" onClick={() => {
                      setChangeEmail(false);
                      formik.resetForm();
                    }}>Cancel</button>
                  </div>
                </Form>}
              </Formik>
            </div>

            <div className="separator my-6"></div>

            <div className={(changePassword ? "d-none" : "d-flex") + " flex-wrap align-items-center"}>
              <div className="">
                <div className="fs-6 fw-bold mb-1">Password</div>
                <div className="text-gray">{user.pwd ? "".padStart(user.passwordLength || 8, "*") : "(Not Set)"}</div>
              </div>
              <div className="ms-auto">
                <button type="button" className="btn btn-secondary" onClick={() => setChangePassword(true)}>{user.pwd ? "Change Password" : "Create Password"}</button>
              </div>
            </div>
            <div className={"flex-row-fluid " + (changePassword ? "" : "d-none")}>
              <Formik
                initialValues={{
                  password: "",
                  newPassword: "",
                  confirmNewPassword: ""
                }}
                validationSchema={schemas.user.user.changePassword}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={true}
                onSubmit={sendChangePasswordForm}
                innerRef={formikRefs.password}
              >
                {formik => <Form>
                  <div className="row mb-6">
                    <div className={"mb-6 mb-lg-0 col-12 col-lg-4 " + (user.pwd ? "" : "d-none")}>
                      <div className="form-group">
                        <label className="form-label">Current Password <em>*</em></label>
                        <input
                          type="password"
                          className={`form-control ${getInputClasses(formik, "password")}`}
                          {...formik.getFieldProps("password")}
                        />
                        {displayFeedback(formik, "password")}
                      </div>
                    </div>
                    <div className={"mb-6 mb-lg-0 col-12 col-lg-" + (user.pwd ? "4" : "6")}>
                      <div className="form-group">
                        <label className="form-label">New Password <em>*</em></label>
                        <input
                          type="password"
                          className={`form-control ${getInputClasses(formik, "newPassword")}`}
                          {...formik.getFieldProps("newPassword")}
                        />
                        {displayFeedback(formik, "newPassword")}
                      </div>
                    </div>
                    <div className={"mb-6 mb-lg-0 col-12 col-lg-" + (user.pwd ? "4" : "6")}>
                      <div className="form-group">
                        <label className="form-label">Confirm New Password <em>*</em></label>
                        <input
                          type="password"
                          className={`form-control ${getInputClasses(formik, "confirmNewPassword")}`}
                          {...formik.getFieldProps("confirmNewPassword")}
                        />
                        {displayFeedback(formik, "confirmNewPassword")}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-end">
                    <div className="fill"/>
                    <button type="submit" className={"btn btn-primary mr-2 " + (loading == 3 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)}>Update Password</button>
                    <button type="button" className="btn btn-secondary" onClick={() => {
                      setChangePassword(false);
                      formik.resetForm();
                    }}>Cancel</button>
                  </div>
                </Form>}
              </Formik>
            </div>

            <div className="separator my-6"></div>

            {user.signinMethods && user.signinMethods.includes("google") ? <>
              <div className="d-flex flex-wrap align-items-center">
                <div className="">
                  <div className="fs-6 fw-bold mb-1">Google</div>
                  <div className="text-gray">{user.email}</div>
                </div>
                <div className="ms-auto">
                </div>
              </div>
            </> : <></>}

          </div>
        </div>
      </div>

      <UnsavedFormGuard formikRef={Object.values(formikRefs)} onSaveAsync={saveAll} loading={loading == "modal"} />
    </>
  );
}

export default connect(null, actions.auth)(Password);
