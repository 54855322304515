import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Form } from "react-bootstrap";
import actions from "../../../../../redux/actions";
import api from "../../../../../redux/api";
import { ReCaptcha } from "react-recaptcha-v3";
import GoogleLogin from "../../../../GoogleLogin";
import { getInputClassName } from "../../../../helpers";

//TODO fix this awful markup

const initialValues = {
	email: "",
	password: ""
};

let recaptchaShouldSubmit = false;

function Login(props) {
	const { intl } = props;
	const [loading, setLoading] = useState(false);
	const [agreesToTerms, setAgreesToTerms] = useState(true);
	//const [nowAgreesToTerms, setNowAgreesToTerms] = useState(false);
	let recaptchaRef = useRef(null);
	let [googleError, setGoogleError] = useState(null),
		history = useHistory();

	function getLicenseKey() {
		let match = window.location.hash.match(/activate-appsumo-([a-f0-9-]+)/);
		if(match)
			return match[1];
	}

	useEffect(() => {
		props.logout();

		recaptchaShouldSubmit = false;
		if(/google_at/.test(window.location.hash)) setLoading(true);
	}, []);

	const LoginSchema = Yup.object().shape({
		email: Yup.string()
			.email("Invalid email format.")
			.required("Required field."),
		password: Yup.string()
			.required("Required field."),
	});

	const formik = useFormik({
		initialValues,
		validationSchema: LoginSchema,
		onSubmit: () => {
			setLoading(true);
			recaptchaShouldSubmit = true;
			recaptchaRef.current.execute();
		}
	});

	let loginSuccess = res => {
		props.setLogin(res.token, res.user);
		history.push("/home" + window.location.search + (window.location.hash || "#onboarding"));
	};

	let responseGoogle = response => {
		if(!response) return;
		setLoading(true);
		api.auth
			.login(null, null, agreesToTerms, false, { google: response }, null, getLicenseKey())
			.then(res => {
				if(res)
					loginSuccess(res);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	let failedGoogle = () => {
		setGoogleError(true);
	};

	let doLogin = token => {
		let values = formik.values;
		api.auth
			.login(values.email, values.password, agreesToTerms, false, null, token, getLicenseKey())
			.then((res) => {
				//try {
				//  let responseData = JSON.parse(res.responseText);
				//
				//  setAgreedToTerms(responseData.mustAgreeToTerms)
				//  setNowAgreesToTerms(responseData.mustAgreeToTerms)
				//  formik.setFieldValue("nowAgreesToTerms",responseData.mustAgreeToTerms)
				//} catch (err) {
				//  console.log(err)
				//}

				window.trackdesk?.("hiro", "externalCid", {
					externalCid: values.email,
					revenueOriginId: "5be0139a-2fd9-4b43-b79e-0d0cdf53719b"
				});

				setLoading(false);
				if(res)
					loginSuccess(res);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	let recaptchaCallback = token => {
		if(!recaptchaShouldSubmit) return;
		doLogin(token);
	};

	return (
		<>
			<h1>
				{getLicenseKey()
					? "Activate your AppSumo deal"
					: "Welcome back"}
			</h1>

			<Form
				onSubmit={formik.handleSubmit}
				className="form fv-plugins-bootstrap fv-plugins-framework"
			>
				<GoogleLogin
					onSuccess={responseGoogle}
					onFailure={failedGoogle}
					scope="profile email"
					state="login"
				/>
				{googleError ? (
					<div className="fv-plugins-message-container">
						<div className="fv-help-block">Login with google failed.</div>
					</div>
				) : ""}
				<div className="form-or">or</div>
				<div className="form-group fv-plugins-icon-container">
					<label className="form-label">Email</label>
					<input
						type="email"
						className={getInputClassName(formik, "email")}
						name="email"
						tabIndex="1"
						{...formik.getFieldProps("email")}
					/>
					{formik.touched.email && formik.errors.email && <div className="field-error">{formik.errors.email}</div>}
				</div>
				<div className="form-group fv-plugins-icon-container">
					<div className="form-group-title">
						<label className="form-label">
							Password
							<Link to="/auth/forgot-password" className="float-right fw-normal">Forgot password?</Link>
						</label>
					</div>
					<input
						type="password"
						className={getInputClassName(formik, "password")}
						name="password"
						tabIndex="2"
						{...formik.getFieldProps("password")}
					/>
					{formik.touched.password && formik.errors.password && <div className="field-error">{formik.errors.password}</div>}
				</div>
				<div className="form-group d-flex flex-wrap justify-content-between align-items-center mb-8">
					<button type="submit" className={"btn btn-primary " + (loading ? "loading spinner" : "")}>
						{getLicenseKey()
							? "Sign In & Activate License"
							: "Sign In"}
					</button>
				</div>
			</Form>

			<div>
				New Here? <Link to={"/auth/signup" + window.location.hash} className="fw-semibold">Create an Account</Link>
			</div>

			<ReCaptcha
				ref={recaptchaRef}
				sitekey={process.env.REACT_APP_RECAPTCHA}
				verifyCallback={recaptchaCallback}
			/>
		</>
	);
}

export default injectIntl(connect(null, actions.auth)(Login));
