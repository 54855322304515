import React, { useEffect, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, useHistory, useLocation, useRouteMatch, withRouter } from "react-router-dom";
import actions from "../../../redux/actions";
import SVG from "react-inlinesvg";
import api from "../../../redux/api";
import { ProductLogo } from "../../../_metronic/layout/components/productLogo";
import ConfirmModal from "../modals/ConfirmModal";
import { Dropdown } from "react-bootstrap";
import { toastMessage } from "../../helpers";
import NewContactDropdown from "../../layout/dropdowns/NewContactDropdown";
import BulkUploadListenersModal from "../products/listeners/generic/BulkUploadListenersModal";
import ContactEditForm from "./ContactEditForm";
import { CSVDownload } from "react-csv";
import moment from "moment";
import OnHoldBadge from "../generic/OnHoldBadge";

let debounceTimer;

const csvHeaders = [
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Phone", key: "phoneNumber" },
  { label: "Created At", key: "createdAt" },
  { label: "Shows", key: "products" },
  { label: "Tags", key: "tags" },
  { label: "Is SMS", key: "isSms" },
  { label: "Is WhastApp", key: "isWhatsapp" }
];

function Contacts({ user, products, dispatch, fulfillUser }) {
  let [loading, setLoading] = useState(true),
    editMatch = useRouteMatch("/contacts/edit"),
    editIdMatch = useRouteMatch("/contacts/:id/edit"),
    location = useLocation(),
    history = useHistory(),
    [list, setList] = useState([]),
    [selected, setSelected] = useState([]),
    [showConfirmDelete, setShowConfirmDelete] = useState(false),
    [showConfirmDeleteSelected, setShowConfirmDeleteSelected] = useState(false),
    [filter, setFilter] = useState({ page: 1, search: "" }),
    [pageCount, setPageCount] = useState(1),
    [showBulkModal, setShowBulkModal] = useState(false),
    searchInputRef = useRef(),
    searchTagMatch = useRouteMatch("/contacts/tags/:tag"),
    [loadingCsv, setLoadingCsv] = useState(false),
    [csvData, setCsvData] = useState(null);

  let loadList = async () => {
    setLoading(true);

    let res = await api.contacts.list(filter.page, filter.search);

    setLoading(false);

    if(!res || !res.success)
      return toastMessage.error("Unable to connect to the server.");

    setList(res.data);
    setPageCount(res.pages);
  };

  let selectedOnChange = ev => {
    let items = selected.filter(i => i != ev.target.value);
    if(ev.target.checked)
      items.push(ev.target.value);
    setSelected(items);
  };

  let updateUser = async () => {
    let res = await api.auth.getUserByToken(true);
    if(res)
      dispatch(fulfillUser(res.data));
  };

  let deleteSelectedOnClick = () => {
    setShowConfirmDeleteSelected(true);
  };

  let deleteItemOnClick = (ev, itemId) => {
    ev.preventDefault();
    setShowConfirmDelete(itemId);
  };

  let confirmDeleteSelected = async () => {
    setLoading("delete");
    setShowConfirmDeleteSelected(false);

    for(let id of selected)
      await api.contacts.delete(id);

    loadList();
    updateUser();
  };

  let confirmDelete = async () => {
    let itemId = showConfirmDelete;

    setShowConfirmDelete(false);
    setLoading("item_" + itemId);

    await api.contacts.delete(itemId);

    loadList();
    updateUser();
  };

  let handleSearch = async ev => {
    let search = ev.target.value;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setFilter({
        page: 1,
        search
      });
    }, 500);
  };

  let closeBulkModal = () => {
    window.location.hash = "";
  };

  let cancelForm = () =>
    history.push("/contacts", { ignorePrompt: true });

  let successForm = () => {
    toastMessage.success(editIdMatch
      ? "Contact updated!"
      : "Contact created!");
    history.push("/contacts", { ignorePrompt: true });
    loadList();
  };

  function searchTag(tag) {
    if(!searchInputRef.current)
      return;
    if(tag) {
      searchInputRef.current.value = "tag:" + tag;
      setFilter({
        page: 1,
        search: "tag:" + tag
      });
    } else {
      searchInputRef.current.value = "";
      setFilter({
        page: 1
      });
    }
  }

  function tagOnClick(ev, tag) {
    ev.preventDefault();
    searchTag(tag);
  }

  async function exportCsv() {
    let data;

    if(selected.length) {
      data = list.filter(o => selected.includes(o._id)).map(o => ({ ...o }));
    } else {
      setLoadingCsv(true);
      let res = await api.contacts.list(-1, filter.search);
      setLoadingCsv(false);

      if(!res || !res.success)
        return toastMessage.error((res && res.error) || "Unable to connect to the server.");

      data = res.data;
    }

    for(let item of data) {
      item.name = item.name || (item.firstName + " " + item.lastName);
      item.createdAt = moment(item.createdAt).locale("en").format("MMMM D, YYYY");
      item.products = item.products.map(p => products.find(o => o._id == p)?.name).filter(p => !!p).join(", ");
      item.tags = item.tags.join(", ");
      item.isSms = item.isSmsPhoneNumber ? "Yes" : "";
      item.isWhatsapp = item.isWhatsappPhoneNumber ? "Yes" : "";
    }

    setCsvData(data);
    setTimeout(() => setCsvData(null), 1000);
  }

  useEffect(() => {
    loadList();
  }, [filter]);

  useEffect(() => {
    searchTag(searchTagMatch
      ? searchTagMatch.params.tag
      : null);
  }, [location.pathname]);

  useEffect(() => {
    setShowBulkModal(location.hash == "#bulk-upload");
  }, [location.pathname, location.hash]);

  return (
    <>
      <h1>
        Contacts
        <NewContactDropdown className="inline" toggleClassName="btn-plus" />
      </h1>

      {!list.length && !editMatch && filter.page == 1 && !filter.search && !loading
        ? (
          <div className="manage-contacts-page text-center">
            <SVG src="/media/def-image/listener-empty-data-state.svg" className="mt-20 mb-8 empty-icon" />
            <h2>No Contacts Yet</h2>
            <div className="mb-20">
              <Link to={"/contacts/edit"} className="btn btn-gray mr-2 btn-primary">Add your first contact</Link>
              <Link to={"/contacts/#bulk-upload"} className="btn btn-primary ml-2">Upload CSV file</Link>
            </div>
          </div>
        )
        : (
          <div className={"card manage-contacts-page " + (editMatch || editIdMatch ? "has-edit-form " : "")}>
            {editMatch || editIdMatch ?
              (
                <div className="side-form">
                  <div className="card-header">
                    <h2 className="m-0">{editIdMatch ? "Edit details" : "New contact"}</h2>
                  </div>

                  <ContactEditForm user={user} onCancel={cancelForm} onSuccess={successForm} showMeta id={editIdMatch && editIdMatch.params.id} />
                </div>
              )
              : <></>}

            <div className="card-body">
              <div className="card-toolbar">
                <input type="text" className="form-control search" placeholder="Search Contacts" ref={searchInputRef} onChange={handleSearch} />
                <div className="d-flex fill justify-content-end">
                  <Link to="/contacts/edit" className="btn btn-primary font-weight-bolder font-size-sm mr-3">+ New Contact</Link>
                  <button type="button" onClick={exportCsv} className={"btn btn-primary-light mr-3 " + (loadingCsv ? "loading spinner" : "")}>Export</button>
                  <button type="button" className={"btn btn-danger font-weight-bolder font-size-sm " + (loading == "delete" ? "loading spinner " : "")} disabled={!selected.length} onClick={deleteSelectedOnClick}>Delete</button>
                </div>
              </div>

              {loading === true
                ? (
                  <div className="spinner spinner-full"></div>
                )
                : (
                  <div className="table-responsive">
                    <table className="table table-head-custom table-vertical-center">
                      <thead>
                        <tr>
                          <th width="10"></th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Shows</th>
                          <th>Tags</th>
                          <th width="1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {list
                          ? list.map((item, i) => (
                            <tr key={i}>
                              <td>
                                <label className="checkbox checkbox-lg checkbox-single">
                                  <input type="checkbox" onChange={selectedOnChange} value={item._id} checked={selected.includes(item._id)} />
                                  <span />
                                </label>
                              </td>
                              <td>
                                <Link to={"/contacts/" + item._id + "/edit"} className="text-inherit">
                                  {item.name && item.name != "Anonymous"
                                    ? item.name
                                    : <span className="text-muted">(No name)</span>}
                                </Link>

                                {item.onHold && <OnHoldBadge className="ml-3" user={user} />}
                              </td>
                              <td>
                                <Link to={"/contacts/" + item._id + "/edit"} className="text-inherit">
                                  {item.email || <span className="text-muted">(No email)</span>}
                                </Link>
                              </td>
                              <td>
                                <Link to={"/contacts/" + item._id + "/edit"} className="text-inherit">
                                  {item.phone || <span className="text-muted">(No phone)</span>}
                                </Link>

                                {item.phone && item.isSmsPhoneNumber && <SVG src="/media/def-image/icons/mobile.svg" className="align-top mx-2 w-15px" />}

                                {item.phone && item.isWhatsappPhoneNumber && <SVG src="/media/def-image/icons/whatsapp.svg" className="align-top mx-2 w-15px" />}
                              </td>
                              <td>
                                <div className="products-logos">
                                  <Link to={"/contacts/" + item._id + "/edit"} className="text-inherit">
                                    {item.products.map((product, i) => (
                                      <ProductLogo products={products} product={product} key={i} />
                                    ))}
                                  </Link>
                                </div>
                              </td>
                              <td>
                                {item.tags.map((tag, i) => (
                                  <a key={i} href="#" onClick={ev => tagOnClick(ev, tag)} className="badge gray-badge mr-1 my-1">{tag}</a>
                                ))}
                              </td>
                              <td>
                                <Dropdown className="dropdown table-options-dropdown fixed-dropdown dropdown-inline">
                                  <Dropdown.Toggle className={loading == "item_" + item._id ? "loading spinner spinner-dark" : ""}>
                                    <SVG src="/media/def-image/icons/menu-2.svg" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
                                    <Link to={"/contacts/" + item._id + "/edit"} className="dropdown-item">
                                      <div className="icon">
                                        <SVG src="/media/def-image/icons/edit-2.svg" />
                                      </div>
                                      Details
                                    </Link>
                                    <Link to={"/inbox/" + item._id + ""} className="dropdown-item">
                                      <div className="icon">
                                        <SVG src="/media/def-image/icons/inbox-menu.svg" />
                                      </div>
                                      Open Inbox
                                    </Link>
                                    <a href="#" className="dropdown-item" onClick={ev => deleteItemOnClick(ev, item._id)}>
                                      <div className="icon">
                                        <SVG src="/media/def-image/icons/delete.svg" />
                                      </div>
                                      Delete
                                    </a>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))
                          : (
                            <tr>
                              <td className="text-muted text-center" colspan="6">No contacts yet.</td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                )}

              <div className="card-table-footer">
                <label>Page:</label>
                <select className="custom-select ml-4" value={filter.page} onChange={ev => setFilter(v => ({ ...v, page: ev.target.value }))}>
                  {(() => {
                    let res = [];
                    for(let i = 1; i <= pageCount; i++)
                      res.push(<option key={i} value={i}>{i}</option>);
                    return res;
                  })()}
                </select>
              </div>
            </div>
          </div>
        )}

      <ConfirmModal
        show={showConfirmDeleteSelected}
        message="Are you sure you want to delete the selected contacts?"
        onConfirm={confirmDeleteSelected}
        onCancel={e => setShowConfirmDeleteSelected(false)} />

      <ConfirmModal
        show={showConfirmDelete}
        message="Are you sure you want to delete the contact?"
        onConfirm={confirmDelete}
        onCancel={e => setShowConfirmDelete(false)} />

      <BulkUploadListenersModal
        contacts={true}
        show={showBulkModal}
        onHide={() => closeBulkModal()}
        products={products}
        user={user}
        callbackBulkUploadListeners={() => {
          closeBulkModal();
          loadList();
        }} />

      {csvData !== null && <CSVDownload data={csvData} headers={csvHeaders} filename="contacts.csv" />}
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      dispatch
    })
  )(withRouter(Contacts))
);
