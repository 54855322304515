import React from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import SVG from "react-inlinesvg";
import { checkLimits } from "../../plans";
import ImportEvents from "../../pages/products/import/Import";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import actions from "../../../redux/actions";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

function NewProductDropdown({ className = "", toggleClassName = "", children, user, drop = "down", alignRight = false, fixed = false }) {
    let history = useHistory();

    async function openYoutubeImportOnClick() {
        if(await checkLimits.canAddProduct(user))
            ImportEvents.dispatchShowYoutubeImport();
    }

    async function openRssImportOnClick() {
        if(await checkLimits.canAddProduct(user))
            ImportEvents.dispatchShowRssImport();
    }

    async function fromScratchOnClick() {
        if(await checkLimits.canAddProduct(user))
            history.push("/create-product");
    }

    async function shareCodeOnClick() {
        if(await checkLimits.canAddProduct(user))
            ImportEvents.dispatchShareCodeImport();
    }

    return (
        <Dropdown className={"new-product-dropdown " + (fixed ? "fixed-dropdown " : "") + className} drop={drop} alignRight={alignRight}>
            <Dropdown.Toggle className={toggleClassName}>
                {children}
            </Dropdown.Toggle>
            <Dropdown.Menu popperConfig={fixed ? { strategy: "fixed" } : {}} renderOnMount>
                <div className="dropdown-title-row">
                    <label className="dropdown-title">New audio show</label>
                    <a href="https://hiro.fm/kb/how-to-create-a-show/" target="_blank" className="i-icon">
                        <SVG src="/media/def-image/icons/i.svg" />
                    </a>
                </div>

                <Dropdown.Item onClick={fromScratchOnClick} className="no-plan-cant-click">
                    <div className="icon">
                        <SVG src={toAbsoluteUrl("/media/def-image/icons/pencil.svg")} />
                    </div>
                    <div className="label-wrapper">
                        <div>Start from scratch</div>
                        <small>Set up a brand new show</small>
                    </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={openRssImportOnClick} className="no-plan-cant-click">
                    <div className="icon">
                        <SVG src={toAbsoluteUrl("/media/def-image/icons/feed.svg")} />
                    </div>
                    <div className="label-wrapper">
                        <div>Import an RSS Feed</div>
                        <small>Pull in a show from Apple or with RSS URL</small>
                    </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={openYoutubeImportOnClick} className="no-plan-cant-click">
                    <div className="icon">
                        <SVG src={toAbsoluteUrl("/media/def-image/icons/youtube-2.svg")} />
                    </div>
                    <div className="label-wrapper">
                        <div>Import a YouTube Channel</div>
                        <small>Create a show from a YouTube channel</small>
                    </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={shareCodeOnClick} className="no-plan-cant-click">
                    <div className="icon">
                        <SVG src={toAbsoluteUrl("/media/def-image/icons/copy-2.svg")} />
                    </div>
                    <div className="label-wrapper">
                        <div>Create using a Share Code</div>
                        <small>Copy an existing Hiro show and settings</small>
                    </div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default injectIntl(
    connect(
        (state) => ({
            user: state.auth.user,
        }),
        actions.product
    )(withRouter(NewProductDropdown))
);