import React, { useState } from "react";
import { Switch, Redirect, useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../../_metronic/layout";
import Login from "./Login";
import SignupStep1 from "./SignupStep1";
import SignupStep2 from "./SignupStep2";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./verifyEmail";
import "../../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import SVG from "react-inlinesvg";
import useTimeout from "../../../../helpers/useTimeout";
import _ from "lodash";

function Quotes() {
	let [lastReview, setLastReview] = useState(null),
		[currentReview, setCurrentReview] = useState(_.random(0, 5)),
		reviews = [{
			image: "angie",
			name: "Angie Norris",
			text: "My All Stars are killing it because Hiro makes it so easy."
		}, {
			image: "bryan",
			name: "Bryan Dulaney",
			text: "I love how seamless Hiro is. It makes it so easy for me and my customers."
		}, {
			image: "gabe",
			name: "Gabe Schillinger",
			text: "It’s all about standing out from all the cookie-cutter tactics out there. Instant results for your customers are the ticket."
		}, {
			image: "kiana",
			name: "Kiana Danial",
			text: "My refunds dropped more than half when I started using Hiro. Such a game changer."
		}, {
			image: "nellie",
			name: "Nellie Corriveau",
			text: "I spent months launching my course. And only a few minutes creating the audio version and it paid for itself instantly!"
		}, {
			image: "nina",
			name: "Nina Rocco",
			text: "My audio product on Hiro outsells all my other offers because my clients can listen to me as they work out."
		}];

	useTimeout(() => {
		setLastReview(currentReview);
		setCurrentReview(currentReview < reviews.length - 1 ? currentReview + 1 : 0);
	}, 6000, true);

	return (
		<div className="login-reviews">
			{reviews.map((review, index) => (
				<div key={index} className={"login-review" + (currentReview == index ? " custom-fade-in" : (lastReview == index ? " custom-fade-out" : ""))}>
					<img src={toAbsoluteUrl("/media/def-image/reviews/" + review.image + ".png")} />
					<div>
						<p>“{review.text}”</p>
						<h4>{review.name}</h4>
					</div>
				</div>
			))}
		</div>
	);
}

export function AuthPage() {
	let location = useLocation();

	return (
		<>
			<div className="login-page d-flex">
				<div className="flex-1 login-sidebar">
					<h1>Everything <SVG src="/media/def-image/icons/sun-emoji.svg" /> creators<br />
						need to <SVG src="/media/def-image/icons/piggy-emoji.svg" /> profit from<br />
						private audio <SVG src="/media/def-image/icons/popcorn-emoji.svg" /> shows</h1>

					<h2>You‘re in good company <SVG src="/media/def-image/icons/right-down-arrow.svg" /></h2>
					<Quotes />
				</div>

				<div className="d-flex flex-column login-content">
					<a href="https://hiro.fm/pricing/" className="return-link">
						<SVG src="/media/def-image/icons/arrow-left.svg" /> Return to pricing
					</a>
					<div className="d-flex flex-1 flex-column align-items-center justify-content-center">
						<div className="d-flex flex-column login-card">
							<SVG src="/media/def-image/hiro-blue-circle-2.svg" className="logo mx-auto" />
							<Switch>
								<ContentRoute path="/auth/login" component={Login} />
								<ContentRoute path="/auth/signup" component={SignupStep1} />
								<ContentRoute path="/auth/select-plan" component={SignupStep2} />
								<ContentRoute path="/auth/forgot-password" component={ForgotPassword} />
								<ContentRoute path="/auth/reset-password/:token" component={ResetPassword} />
								<ContentRoute path="/auth/verify-email" component={VerifyEmail} />
								<Redirect from="/auth" to={"/auth/login" + location.search} />
								<Redirect to={"/auth/login" + location.search} />
							</Switch>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
