import React,{useEffect,useState} from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import actions from "../../../../redux/actions";
import ImportEvents from "./Import";
import {Modal} from "react-bootstrap";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import api from "../../../../redux/api";
import GoogleLogin from "../../../GoogleLogin";
import { useLocation } from "react-router-dom";

require("dotenv").config();

let accessToken;

const YoutubeImport=props=>{
  let [show,setShow]=useState(false),
    [error,setError]=useState(null),
    [step,setStep]=useState(1),
    [loading,setLoading]=useState(false),
    [channels,setChannels]=useState([]),
    location = useLocation();

  useEffect(()=>{
    if(/google_at/.test(window.location.hash)) setLoading(true);

    ImportEvents.on("showYoutubeImport",()=>{
      setStep(1);
      setError(null);
      setLoading(false);
      setShow(true);
    });
  },[]);

  useEffect(() => {
    if(location.pathname == "/products/youtube-import")
      setShow(true);
  }, [ location ]);

  let close=()=>{
    setShow(false);
  };

	let responseGoogle=response=>{
		if(!response) return;

    setLoading(true);

    accessToken=response;
    api.product.connectYoutube(response).then(res=>{
      setLoading(false);
      if(!res.success||!res.channels||!res.channels.length)
        return setError("Google authentication failed.");
      if(res.success) {
        setChannels(res.channels);
        setStep(2);
      }
    });
	},
	failedGoogle = () => {
		setError(true);
	};

  let selectChannel=channel=>{
    if(loading) return;
    setLoading(true);

    api.product.importChannel(accessToken,channel.id).then(res=>{
      if(res.success) {
        api.product.getProducts().then(res=>{
          props.dispatch(props.setProducts(res));
        });
        setLoading(false);
        setStep(3);
      }
    });
  };

  return <>
    <Modal
      show={show}
      onHide={close}
      size="lg"
      centered
      className="new-modals youtube-import-modal"
    >
      <Modal.Body className="py-20 text-center">        
        <button type="button" className="close" onClick={close}><SVG src={toAbsoluteUrl("/media/def-image/close.svg")} className="svg-icon" /></button>

        {step==1?<>
          <h1>Let’s import a YouTube channel</h1>
          <p>Import your existing channel by allowing access.</p>
          <GoogleLogin
            onSuccess={responseGoogle}
            onFailure={failedGoogle}
            scope="profile https://www.googleapis.com/auth/youtube.readonly"
            state="youtube-import"
            label="Continue with Google"
            loading={loading}
          />
          {error?<div className="invalid-feedback-copy">{error}</div>:<></>}
        </>:<></>}

        {step==2?<>
          <h1>Select a channel to import</h1>
          <p>Pick the channel you would like to import. We will email when it’s complete.</p>
          <div className={"d-flex justify-content-center channels-list "+(loading?"loading spinner spinner-dark":"")}>
            {channels.map((channel,i)=><a key={i} href="#" className="youtube-channel p-5" onClick={ev=>{
              ev.preventDefault();
              selectChannel(channel);
            }}>
                <img src={channel.snippet.thumbnails.medium.url} className="channel-picture"/>
                <strong>{channel.snippet.title}</strong><br/>
                <span className="text-gray">{channel.statistics.videoCount} {channel.statistics.videoCount==1?"video":"videos"}</span>
              </a>)}
          </div>
        </>:<></>}

        {step==3?<>
          <h1>That’s all!</h1>
          <p>We're processing your content. We will email when it’s complete.</p>
          <button type="button" className="btn btn-secondary mt-10" onClick={close}>Close</button>
        </>:<></>}
      </Modal.Body>
    </Modal>
  </>;
};

export default injectIntl(connect(
  state=>({
    user:state.auth.user,
  }),
  dispatch => ({
    dispatch,
    ...actions.product,
  })
)(YoutubeImport));
