import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { UpgradeEvents } from "./UpgradeModal";
import { getPlans } from "../../plans";

export const NeedsUpgrade = (props) => {
  let [show, setShow] = useState(false),
    user = useSelector(state => state.auth.user, shallowEqual),
    [title, setTitle] = useState(null);

  useEffect(() => {
    let off = NeedsUpgradeEvents.on("showNeedsUpgrade", ev => {
      setShow(true);
      setTitle(ev.message);
    });

    return () => NeedsUpgradeEvents.off();
  }, [user]);

  return <Modal
    show={show}
    onHide={() => setShow(false)}
    size="md"
    centered
    className="new-modals needs-upgrade-modal text-center"
  >
    <Modal.Body className="pt-15 px-10">
      <button type="button" className="close" onClick={() => setShow(false)}><SVG src={toAbsoluteUrl("/media/def-image/close.svg")} className="svg-icon" /></button>
      <h2>Upgrade To Access This Feature</h2>
      <p>Unlock all premium features when you upgrade today!</p>
      <img src="/media/def-image/upgrade.png" className="image" />
    </Modal.Body>
    <Modal.Footer className="gray-bg">
      <button type="button" className="btn btn-primary" onClick={ev => {
        setShow(false);
        UpgradeEvents.dispatchShow();
      }}>Pick Your Plan</button>
    </Modal.Footer>
  </Modal >;
};

export const NeedsUpgradeEvents = {
  on(event, callback) {
    let c = e => callback(e.detail);
    document.addEventListener(event, c);
    return () => {
      NeedsUpgradeEvents.off(event, c);
    };
  },
  off(event, callback) {
    document.removeEventListener(event, callback);
  },
  dispatch(event, data = null) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  /**
   * Emits the signal to open the popup.
   */
  dispatchShow(message) {
    this.dispatch("showNeedsUpgrade", { message });
  },
  /**
   * 
   */
  dispatchShowForSms(message) {
    this.dispatch("showNeedsUpgradeForSMS", { message });
  }
};