import React,{useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import api from '../../../../../redux/api';

const VerifyEmail=() => {
	const [message,setMessage]=useState('Verifying your email....');
	const [showLogin,setShowLogin]=useState(true);
	useEffect(() => {
		const params=new URLSearchParams(window.location.search); // id=123
		let id=params.get('token');
		if(id) {
			api.auth.verifyUser(id).then((res) => {
				if(res.success) {
					setMessage('Your email has been verified');
					setShowLogin(false);
				} else {
					setMessage('Error verifying email address. Please try again');
				}
			}).catch((e) => {
				//console.log('error :: ', e)
			});
		}
	},[]);

	return (
		<div className='login-form login-signin' id='kt_login_signin_form'>
			<div>
				<h1 className="mb-5">Verify Email</h1>
				<p className='text-muted font-weight-bold mb-10'>{message}</p>
				<Link to='/auth/login' disabled={showLogin} className={`btn d-block btn-primary font-weight-bold`}>Sign in</Link>
			</div>
		</div>
	);
};
export default VerifyEmail;