import React, { useCallback, useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { connect } from "react-redux";
import actions from "../../../redux/actions";
import { fulfillUser } from "../../../redux/actions/auth-actions";
import api from "../../../redux/api";
import WelcomeMessage from "./chat/WelcomeMessage";
import Layout from "./Layout";
import { useCustomEventListener } from "../../helpers/use-event";
import List from "./List";
import Details from "./Details";
import { useLocation, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import useTimeout from "../../helpers/useTimeout";

let key = 0;

function AIPodcastCreator({ user, dispatch }) {
    const scrollRef = useRef(),
        [messageComponents, setMessageComponents] = useState([]),
        [state, setState] = useState({}),
        [projects, setProjects] = useState(null),
        [projectDetailsId, setProjectDetailsId] = useState(null),
        [showChat, setShowChat] = useState(true),
        location = useLocation(),
        history = useHistory();

    useCustomEventListener("chatScroll", () => scroll());

    let scroll = () => {
        if(scrollRef.current)
            scrollRef.current.view.scrollTo({ top: scrollRef.current.view.scrollHeight, behavior: "smooth" });
    };

    let update = async (updateUser = true) => {
        if(updateUser) {
            let user = await api.auth.getUserByToken(true);
            if(user) {
                dispatch(fulfillUser(user.data));
                if(!user.data.aiEnabled)
                    window.location.href = "/";
            }
        }

        let res = await api.ai.getProjects();
        if(res && res.success && res.projects) {
            setProjects(res.projects);
            if(!res.projects.length)
                setShowChat(true);
        }
    };

    let next = (component = null, state = null) => {
        if(state)
            setState(s => ({ ...s, ...state }));

        if(component) {
            key++;
            setMessageComponents(arr => [...arr, [component, key]]);
        }
    };

    let reset = () => {
        setState({ restarted: true });
        key++;
        setMessageComponents([[WelcomeMessage, key]]);
    };

    let newChat = () => {
        setState({});
        key++;
        setMessageComponents([[WelcomeMessage, key]]);
        setShowChat(true);
    };

    useTimeout(() => update(), 30000, true);

    useEffect(() => {
        update();

        setMessageComponents([[WelcomeMessage, key]]);
    }, []);

    useEffect(() => {
        let match = location.pathname.match(/^\/ai-podcast-creator\/([a-f0-9]+)/);
        setProjectDetailsId(match
            ? match[1]
            : null);
        if(match)
            setShowChat(false);
    }, [location]);

    if(!user)
        return <></>;

    // if(!user.planFeatures.ai || !user.aiEnabled) {
    //     history.push("/home");
    //     return <></>;
    // }

    return (
        <Layout>
            <Modal centered show={showChat} size="lg" className="ai-chat-modal" backdropClassName="ai-modal-backdrop" onHide={() => projects && projects.length && setShowChat(false)}>
                <Modal.Body>
                    {projects && !!projects.length && <button type="button" className="close" onClick={() => setShowChat(false)}><SVG src="/media/def-image/close.svg" /></button>}
                    <Scrollbars
                        ref={scrollRef}
                        hideTracksWhenNotNeeded
                        className="scrollbar-view"
                        renderTrackVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="scrollbar-track" />}
                        renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="scrollbar-thumb" />}>
                        <div className="messages">
                            {messageComponents.map(([Comp, key], i) => {
                                return <Comp user={user} next={next} key={key} last={i == messageComponents.length - 1} state={state} update={update} reset={reset} onCloseChat={() => setShowChat(false)} />
                            })}
                        </div>
                    </Scrollbars>
                </Modal.Body>
            </Modal>

            {projects && !!projects.length
                ? <List projects={projects} onNewProject={() => newChat()} onUpdateRequest={() => update(false)} onDetails={project => history.push("/ai-podcast-creator/" + project._id)} />
                : <></>}

            <Details projectId={projectDetailsId} onHide={() => history.push("/ai-podcast-creator")} />
        </Layout>
    );
}

export default connect(
    state => ({
        user: state.auth.user,
    }),
    (dispatch) => ({
        ...actions.auth,
        dispatch,
    })
)(AIPodcastCreator);