import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import common from "./common";

function MediaToolbox({ onHide, onData, show = false, className = "", autoClose = true, fixedPosition = false, html = true }) {
    let [mediaError, setMediaError] = useState(null),
        mediaRef = useRef();

    let insertMedia = () => {
        setMediaError(null);

        let url = mediaRef.current.value;

        if(!url || !url.trim() || !url.match(/^https:\/\/.+?(\..+)/))
            return setMediaError("Enter a valid URL (must start with https://).");

        onData(html
            ? "<img src=\"" + url.replace(/"/g, "") + "\" style=\"display: block; max-width: 100%; margin: 20px auto\">"
            : "\n\n[media " + url + "]\n\n");
        if(autoClose)
            onHide();
    };

    let mediaOnKeyDown = ev => {
        if(ev.which == 13) {
            ev.preventDefault();
            insertMedia();
        }
    };

    useEffect(common.useEffect(fixedPosition, onHide), [fixedPosition]);

    return (
        <>
            {show && (
                <div className={"dropdown toolbox-dropdown media-dropdown " + (!!fixedPosition ? "fixed" : "") + className} style={common.getStyle(fixedPosition)}>
                    <label>Embed Image, Video or Giphy</label>
                    <div className="d-flex">
                        <input ref={mediaRef} autoFocus type="text" className="form-control fill" onKeyDown={mediaOnKeyDown} />
                        <button type="button" className="btn btn-primary-light ml-2" onClick={insertMedia}>Insert</button>
                    </div>
                    {mediaError && <div className="field-error">{mediaError}</div>}
                </div>
            )}
        </>
    );
}

export default MediaToolbox;