import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import actions from "../actions";
import actionTypes from "../action-types";
import api from "../api";

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  adminAuthToken: undefined,
  admin: undefined
};

export const reducer = persistReducer(
  { storage, key: "v713-demo1-auth", whitelist: ["user", "authToken", "adminAuthToken", "admin"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.auth.Login: {
        const { authToken, user } = action.payload;
        return { ...state, authToken, user };
      }
      
      case actionTypes.auth.AdminLogin: {
        const { adminAuthToken, admin } = action.payload;
        return { ...state, adminAuthToken, admin };
      }

      case actionTypes.auth.Register: {
        const { authToken,user } = action.payload;
        return { authToken,user };
      }

      case actionTypes.auth.Logout: {
        return { user:null,authToken:null };
      }

      case actionTypes.auth.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.auth.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.auth.GetUserByToken: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export function* saga() {
  yield takeLatest(actionTypes.auth.Login, function* loginSaga() {
    yield put(actions.auth.requestUser());
  });

  yield takeLatest(actionTypes.auth.Register, function* registerSaga({ data }) {
    const result = yield api.auth.register(data.email, data.firstName, data.lastName, data.password)
    yield put(actions.auth.requestUser());
  });

  yield takeLatest(actionTypes.auth.UserRequested, function* userRequested() {
    const { data: user } = yield api.auth.getUserByToken(true);

    yield put(actions.auth.fulfillUser(user));
  });
}
