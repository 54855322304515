import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import schemas from "../../../schemas";
import { Formik, Form as FormFormik } from "formik";
import api from "../../../../redux/api";
import { toastMessage } from "../../../helpers";
import { injectIntl } from "react-intl";
import actions from "../../../../redux/actions";
import { connect } from "react-redux";

const DeleteProductConfirm = ({ match, history, dispatch, fulfillUser }) => {
  let [ loading, setLoading ] = useState(false);

  const getUser = async () => {
    let res = await api.auth.getUserByToken(true);
    if(res)
      dispatch(fulfillUser(res.data));
    
    res = await api.product.getProducts(true);
    if(res) {
      window.localStorage.setItem("productsCount", (res && Array.isArray(res) && res.length) || 0);
      dispatch(actions.product.setProducts(res));
    }
  };

  const sendForm = async data => {
    if(data.confirm == "DELETE SHOW") {
      setLoading(true);
      await api.product.deleteProduct(match.params.id);
      await getUser();
      toastMessage.success("Product deleted successfully");
      history.push("/products");
    } else {
      toastMessage.warn("Please type \"DELETE SHOW\" to confirm the deletion.");
    }
  };

  return (
    <>
      <h1>
        <Link to="/products" className="btn btn-back">
          <SVG src={toAbsoluteUrl("/media/def-image/icons/back.svg")} />
        </Link>
        Delete Show
      </h1>

      <Formik
        initialValues={{
          confirm: "",
        }}

        validationSchema={schemas.product.productDelete}
        onSubmit={sendForm}
      >
        {formik => <FormFormik>
          <div className="container-inner">
            <div className="card">
              <div className="card-body">
                <h2>Confirm Deletion</h2>

                <p>
                  Are you sure you want to delete your show? This is your last chance to change your mind! Remember,
                  you can always reach out to our support team to discuss any challenges you’re experiencing and we’ll be happy
                  to help out.
                </p>

                <Form.Group>
                  <Form.Label>CONFIRM IT’S YOU — FOR YOUR SAFETY, TYPE "DELETE SHOW"</Form.Label>

                  <Form.Control
                    type="text"
                    placeholder=""
                    {...formik.getFieldProps("confirm")}
                  />
                </Form.Group>
              </div>
              <div className="card-footer text-right">
                <a href="#" onClick={history.goBack} className="btn btn-secondary mr-2" disabled={loading}>Cancel</a>
                <button type="submit" className={"btn btn-danger " + (loading ? "loading spinner" : "")}>Delete</button>
              </div>
            </div>
          </div>
        </FormFormik>}
      </Formik>
    </>
  );
};

export default injectIntl(
  connect(
    (state) => ({
      user: state.auth.user
    }),
    (dispatch) => ({
      ...actions.auth,
      dispatch,
    })
  )(DeleteProductConfirm)
);