import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import actions from "../../../../redux/actions";
import api from "../../../../redux/api";
import { getInputClassName, toastMessage, updateCurrentUser } from "../../../helpers";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Switch } from "@material-ui/core";
import GoogleLogin from "../../../GoogleLogin";

function EmailAccountModal({ show, onDismiss, emailId, onCreate }) {
  const user = useSelector(state => state.auth.user, shallowEqual),
    [saving, setSaving] = useState(false),
    [testing, setTesting] = useState(false),
    [googleError, setGoogleError] = useState(),
    [googleLoading, setGoogleLoading] = useState(false),
    dispatch = useDispatch(),
    validationSchema = Yup.object().shape({
      email: Yup.string()
        .email("Enter a valid email address.")
        .required("Enter a valid email address.")
    }),
    formik = useFormik({
      initialValues: {
        email: "",
        type: "sendgrid",
        default: false,
        testedOk: false,
        connectionParams: {
          smtpHost: "",
          smtpPort: "465",
          smtpUser: "",
          smtpPassword: "",
          smtpSsl: true,
          sendgridKey: "",
          googleAccessToken: "",
          googleRefreshToken: ""
        }
      },
      validationSchema,
      onSubmit: values => save(values),
      enableReinitialize: true
    }),
    { handleSubmit, getFieldProps, resetForm, values, setFieldValue, touched, errors } = formik;

  async function save(values) {
    setSaving(true);

    let res = emailId
      ? await api.user.updateEmailAddress(emailId, values)
      : await api.user.addEmailAddress(values);

    if(!res || !res.success) {
      toastMessage.error((res && res.error) || "Unable to connect to the server.");
      setSaving(false);
      return;
    }

    await updateCurrentUser(dispatch);

    if(onCreate)
      onCreate(res.emailAddress);

    toastMessage.success(emailId
      ? "Email address updated!"
      : "Email address created!");

    onDismiss();
  }

  async function testOnClick() {
    setTesting(true);

    let res = await api.user.testEmailAddress({
      ...values,
      id: emailId
    });

    if(!res || !res.success) {
      toastMessage.error("Unable to send with these settings!" + (res.error ? " " + res.error : ""));
      formik.setFieldValue("testedOk", false);
    } else {
      toastMessage.success("Test message sent to " + user.email + "!");
      formik.setFieldValue("testedOk", true);
    }

    setTesting(false);
  }

  function reset() {
    if(emailId) {
      let values = user.emailAddresses.find(e => e._id == emailId);
      formik.setValues(values, true);
      formik.setTouched({});
    } else {
      resetForm({
        values: {
          email: "",
          type: "sendgrid",
          default: false,
          testedOk: false,
          connectionParams: {
            smtpHost: "",
            smtpPort: "465",
            smtpUser: "",
            smtpPassword: "",
            smtpSsl: true,
            sendgridKey: "",
            googleAccessToken: "",
            googleRefreshToken: ""
          }
        }
      });
    }
    setTesting(false);
    setSaving(false);
  }

  function googleOnFailure() {
    setGoogleError("Authorization failed!");
  }

  async function googleOnSuccess({ accessToken, refreshToken }) {
    setGoogleLoading(true);

    let res = await api.user.validateGmailConnection(accessToken);

    setGoogleLoading(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    formik.setFieldValue("email", res.emailAddress);
    formik.setFieldValue("connectionParams.googleAccessToken", accessToken);
    formik.setFieldValue("connectionParams.googleRefreshToken", refreshToken);
  }

  function disconnectGoogleOnClick() {
    formik.setFieldValue("email", "");
    formik.setFieldValue("connectionParams.googleAccessToken", "");
    formik.setFieldValue("connectionParams.googleRefreshToken", "");
  }

  useEffect(() => {
    if(show) {
      reset();

      if(window.location.hash.match(/google_at/))
        formik.setFieldValue("type", "gmail");
    }
  }, [show, emailId]);

  return (
    <Modal centered size="lg" show={show} onHide={onDismiss} className="new-modals edit-email-account-modal">
      <form onSubmit={handleSubmit}>
        <Modal.Header>
          <h1 className="m-0">
            {!!emailId
              ? "Edit Email Address"
              : "Add Email Address"}
          </h1>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row mb-12">
            <div className="col-md-auto mb-3 mb-md-0">
              <label className="form-label mb-0">Connection Type:</label>
            </div>
            <div className="col-md-auto mb-3 mb-md-0 text-nowrap">
              <label className="form-check mb-0">
                <input className="form-check-input" type="radio" checked={values.type == "sendgrid"} onChange={ev => setFieldValue("type", "sendgrid")} />
                Sendgrid
              </label>
            </div>
            <div className="col-md-auto mb-3 mb-md-0 text-nowrap">
              <label className="form-check mb-0">
                <input className="form-check-input" type="radio" checked={values.type == "gmail"} onChange={ev => setFieldValue("type", "gmail")} />
                Gmail
              </label>
            </div>
            <div className="col-md-auto mb-3 mb-md-0 text-nowrap">
              <label className="form-check mb-0">
                <input className="form-check-input" type="radio" checked={values.type == "smtp"} onChange={ev => setFieldValue("type", "smtp")} />
                SMTP
              </label>
            </div>
          </div>


          {(values.type == "sendgrid" || values.type == "smtp") && (
            <div className="form-group">
              <label className="form-label">Email:</label>
              <input type="text" {...getFieldProps("email")} className={getInputClassName(formik, "email", "autofocus")} />
              {touched.email && errors.email && <div className="field-error">{errors.email}</div>}
            </div>
          )}

          {values.type == "gmail"
            ? (
              formik.values.connectionParams.googleAccessToken
                ? (
                  <>

                    <div className="row mb-7 align-items-end">
                      <div className="col-md form-group mb-md-0">
                        <label className="form-label">Email:</label>
                        <input type="text" readOnly value={formik.values.email} className="form-control" />
                      </div>

                      <div className="col-md-auto form-group mb-md-0">
                        <button type="button" className="btn btn-secondary" onClick={disconnectGoogleOnClick}>Disconnect</button>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label">Access Token:</label>
                      <input type="password" readOnly value="**********************" className="form-control" />
                    </div>

                    <div className="form-group mb-12">
                      <label className="form-label">Refresh Token:</label>
                      <input type="password" readOnly value="**********************" className="form-control" />
                    </div>
                  </>
                )
                : (
                  <div className="form-group mb-12">
                    <GoogleLogin
                      scope="profile email https://www.googleapis.com/auth/gmail.send"
                      state={"connect-gmail" + (emailId ? "-email-id-" + emailId : "")}
                      icon="gmail"
                      label="Connect your Gmail account"
                      className="w-auto mx-auto"
                      loading={googleLoading}
                      promptConsent
                      refreshToken
                      onFailure={googleOnFailure}
                      onSuccess={googleOnSuccess} />
                    {googleError ? <div className="field-error text-center">{googleError}</div> : <></>}
                    <p className="fs-10 text-muted px-20 text-center mt-5">We will never share your data with AI or third party tools. Hiro‘s use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.</p>
                  </div>
                )
            )
            : <></>}

          {values.type == "sendgrid" && (
            <div className="form-group mb-12">
              <label className="form-label">Sendgrid API Key:</label>
              <input type="text" {...getFieldProps("connectionParams.sendgridKey")} className="form-control" />
            </div>
          )}

          {values.type == "smtp" && (
            <>
              <div className="row mb-7">
                <div className="col-md form-group mb-md-0">
                  <label className="form-label">SMTP Server Host:</label>
                  <input type="text" {...getFieldProps("connectionParams.smtpHost")} className="form-control" />
                </div>

                <div className="col-md-auto w-md-150px form-group mb-md-0">
                  <label className="form-label">Port:</label>
                  <input type="number" {...getFieldProps("connectionParams.smtpPort")} className="form-control hide-spinner" />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label">Username:</label>
                <input type="text" {...getFieldProps("connectionParams.smtpUser")} className="form-control" />
              </div>

              <div className="form-group">
                <label className="form-label">Password:</label>
                <input type="password" {...getFieldProps("connectionParams.smtpPassword")} className="form-control" />
              </div>

              <label className="form-group mb-12 d-flex align-items-center">
                <Switch value={true} className="blue-switch" checked={!!values.connectionParams.smtpSsl} onChange={ev => setFieldValue("connectionParams.smtpSsl", ev.target.checked)} />
                <span className="d-inline-block pl-6 mb-0">Use SSL</span>
              </label>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="d-lg-flex justify-content-center">
          <div>
            <label className="form-group mb-12 mb-lg-0 d-flex align-items-center">
              <Switch value={true} className="blue-switch" checked={!!values.default} onChange={ev => setFieldValue("default", ev.target.checked)} />
              <span className="d-inline-block pl-6 mb-0">Default email address</span>
            </label>
          </div>
          <div className="fill" />
          <div className="text-center">
            <button type="button" className="btn btn-secondary mr-2" onClick={onDismiss}>Cancel</button>
            <button type="button" className={"btn btn-secondary mr-2 " + (testing ? "loading spinner" : "")} onClick={testOnClick}>Test Connection</button>
            <button type="submit" className={"btn btn-primary " + (saving ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)}>Save</button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default connect(null, actions.auth)(EmailAccountModal);
