import axios from "axios";

export default function setupAxios(store) {
  let url;

  //if (process.env.NODE_ENV === 'development') {
  //  url = `${process.env.REACT_APP_LOCALHOST_URL}api/`;
  //} else {
    url = `${process.env.REACT_APP_SERVER_URL}api/`;
//    url = `${process.env.REACT_APP_SERVER_URL}api/public/`;
  //}

  axios.defaults.baseURL = url

  const apiInstance = axios.create()

  apiInstance.interceptors.request.use(
    config => {


      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
}
