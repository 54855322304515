import { useFetch } from "../../app/helpers";

export default {
  createEpisode: ({ id, data }) => useFetch.post(`product/${id}/episode`, data),
  createEpisodesBulk: ({ id, data }) => useFetch.post(`product/${id}/episodes`, data),
  editEpisode: data => useFetch.put(`episode`, data),
  editEpisodes: (episodeIds, data) => useFetch.put("episodes", { episodeIds, data }),
  getEpisode: ({ id, params }) => useFetch.get(`episode/${id}`, params),
  getEpisodes: ({ id, params }) => useFetch.get(`product/${id}/episodes`, params),
  deleteEpisode: episodeId => useFetch.delete(`episode/${episodeId}`, {}),
  deleteEpisodes: episodeIds => useFetch.deleteWithData("episodes", { episodeIds }),
  reorderEpisode: ({ productId, episodeId, params }) => useFetch.put(`product/${productId}/reorderEpisode/${episodeId}`, params),
  bulkUploadEpisodeDetails: data => useFetch.post(`bulk-upload-episode-details`, data),
};
