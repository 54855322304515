import _ from "lodash";
import React, { useEffect, useRef } from "react";

function RangeField({ className = "", showLabel, lg, renderLabel, min = 0, max = 100, name = null, formik = null, onChange = null, value = null }) {
    let ref = useRef(),
        labelRef = useRef();

    function getValue() {
        if(value)
            return value;
        if(formik && name)
            return _.get(formik.values, name);
        return 0;
    }

    function inputOnChange(ev) {
        if(formik && name)
            formik.setFieldValue(name, ev.target.value);
        if(onChange)
            onChange(ev.target.value);
        update();
    }

    function update() {
        if(!ref.current || !labelRef.current)
            return;

        let percent = (ref.current.value - min) * 100 / (max - min);
        ref.current.style.backgroundSize = percent + "% 100%";
        labelRef.current.style.left = "calc(" + percent + "% + " + ((lg ? 12 : 10) - percent * .2) + "px)";
    }

    useEffect(() => {
        update();
    }, [ref, min, max, value, showLabel]);

    return (
        <div className={"range-field " + (lg ? "lg " : "") + className}>
            {!!showLabel && (
                <span ref={labelRef} className="range-field-label">
                    {renderLabel
                        ? renderLabel(getValue())
                        : getValue()}
                </span>
            )}
            <input
                ref={ref}
                type="range"
                min={min}
                max={max}
                value={getValue()}
                onChange={inputOnChange} />
        </div>
    );
}

export default RangeField;