import React, { useState, useRef } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import actions from "../../../../redux/actions";
import { Form, Formik } from "formik";
import schemas from "../../../schemas";
import api from "../../../../redux/api";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { getAuthorUrl, toastMessage } from "../../../helpers";
import AwsS3Dropzone from "../../generic/AwsS3Dropzone";
import { Modal } from 'react-bootstrap';
import UpgradeLink from "../../generic/UpgradeLink";
import { ProfileCard } from "./components/ProfileCard";
import Editor from "../../../layout/components/Editor";
import UnsavedFormGuard from "../../../layout/components/UnsavedFormGuard";
import CopyButton from "../../../layout/components/CopyButton";
import { Switch } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { NeedsUpgradeEvents } from "../../modals/NeedsUpgrade";
import TrackingPixelModal from "./components/TrackingPixelModal";
import TrackingGAModal from "./components/TrackingGAModal";
import AIField from "../../../layout/ai/AIField";
import { languagesEn } from "../../../../locales";
import TagsInput from "react-tagsinput";
import { UpgradeEvents } from "../../modals/UpgradeModal";

function ProfilePageSettings() {
  const user = useSelector(state => state.auth.user, shallowEqual);

  let [avatarUploadError, setAvatarUploadError] = useState(null),
    [logoUploadError, setLogoUploadError] = useState(null),
    [loading, setLoading] = useState(0),
    [displayAboutExamplesPopup, setDisplayAboutExamplesPopup] = useState(false),
    [displayBioExamplesPopup, setDisplayBioExamplesPopup] = useState(false),
    [exampleNumber, setExampleNumber] = useState(0),
    [formikObj, setFormikObj] = useState(null),
    [showTrackingPixelModal, setShowTrackingPixelModal] = useState(false),
    [showTrackingGAModal, setShowTrackingGAModal] = useState(false),
    [activeSwitchLoading, setActiveSwitchLoading] = useState(false),
    dispatch = useDispatch(),
    history = useHistory(),
    formikRefs = {
      profileDetails: useRef(),
      changeUri: useRef(),
      social: useRef(),
      bio: useRef(),
      about: useRef(),
      language: useRef()
    };

  let getInputClasses = (formik, fieldname) => {
    if(formik.touched[fieldname] && formik.errors[fieldname]) return "is-invalid";
    if(formik.touched[fieldname] && !formik.errors[fieldname]) return "is-valid";
    return "";
  };

  let displayFeedback = (formik, fieldName) => formik.touched[fieldName] && formik.errors[fieldName] ? (
    <div className="invalid-feedback-copy">{formik.errors[fieldName]}</div>
  ) : null;

  let onAvatarUploaded = (uploadedFiles, setField) => {
    if((uploadedFiles || []).length < 1) {
      setAvatarUploadError("No uploaded file found.");
    } else if((uploadedFiles || []) > 1) {
      setAvatarUploadError("Please upload a single file");
    } else {
      setField("pictureUrl", uploadedFiles[0].amazonS3Url);
    }
  };

  let updateUser = async () => dispatch(actions.auth.fulfillUser((await api.auth.getUserByToken(true)).data));

  async function activeSwitchOnChange(ev) {
    setActiveSwitchLoading(true);

    let res = await api.user.updateSubaccountStatus(ev.target.checked);

    if(!res || !res.success) {
      setActiveSwitchLoading(false);
      toastMessage.error((res && res.error) || "Unable to connect to the server.");
      return;
    }

    await updateUser();

    setActiveSwitchLoading(false);
  }

  function deleteAccountOnClick() {
    if(user.owner.subAccountsIds.length == 1)
      return toastMessage.error("You can‘t delete all your accounts.");

    history.push("/delete-account");
  }

  let sendForm = async (buttonNumber, action, data, successCallback, update = true) => {
    setLoading(buttonNumber);

    let res = await api.user.editCurrentUser({
      action: action,
      user: data,
    });

    setLoading(0);

    let ok = res.status != 403 && res.status != 500;

    if(ok && successCallback)
      successCallback();

    if(update)
      await updateUser();

    return ok;
  };

  let sendChangeUriForm = data => {
    sendForm(1, "changeDomain", data, () => {
      toastMessage.success("Your public URL has been updated!");
    });
  };

  //let sendChangeLogoForm = data => {
  //  sendForm(2, "changeLogo", data, () => {
  //    toastMessage.success("Your logo has been updated!");
  //  });
  //};

  let sendSocialForm = data => {
    sendForm(3, "updateSocial", data, () => {
      toastMessage.success("Your profile has been updated!");
    });
  };

  let sendBioForm = data => {
    sendForm(4, "updateBio", data, () => {
      toastMessage.success("Your profile has been updated!");
    });
  };

  let sendAboutForm = data => {
    sendForm(5, "changeAbout", data, () => {
      toastMessage.success("Your profile has been updated!");
    });
  };

  let sendProfileForm = data => {
    sendForm(6, null, data, () => {
      toastMessage.success("Your profile has been updated!");
    });
  };

  let sendTrackingPixelForm = async ({ value }) => {
    if(await sendForm(7, "setTrackingPixel", { profileFacebookPixelId: value })) {
      setShowTrackingPixelModal(false);
      toastMessage.success("Your profile has been updated!");
    }
  };

  let sendTrackingGAForm = async ({ value }) => {
    if(await sendForm(8, "setTrackingGA", { profileAnalyticsId: value })) {
      setShowTrackingGAModal(false);
      toastMessage.success("Your profile has been updated!");
    }
  };

  function sendLanguageForm(data) {
    sendForm(9, "changeLanguage", data, () => {
      toastMessage.success("Your profile page has been updated!");
    });
  }

  let saveAll = async () => {
    if(formikRefs.profileDetails.current.dirty)
      await sendForm("modal", null, formikRefs.profileDetails.current.values, null, false);

    if(formikRefs.changeUri.current.dirty)
      await sendForm("modal", "changeDomain", formikRefs.changeUri.current.values, null, false);

    if(formikRefs.social.current.dirty)
      await sendForm("modal", "updateSocial", formikRefs.social.current.values, null, false);

    if(formikRefs.bio.current.dirty)
      await sendForm("modal", "updateBio", formikRefs.bio.current.values, null, false);

    if(formikRefs.about.current.dirty)
      await sendForm("modal", "changeAbout", formikRefs.about.current.values, null, false);

    if(formikRefs.language.current.dirty)
      await sendForm("modal", "changeLanguage", formikRefs.language.current.values, null, false);

    await updateUser();

    toastMessage.success("Your profile has been updated!");
  };

  let name = user.firstName
    ? user.firstName + " " + user.lastName
    : user.email.substring(0, user.email.indexOf("@"));

  let bioExamples = [
    `<p>I'm ${name}. I am the founder of ${user.companyName || name + " Inc."}, a company that helps
    entrepreneurs go from zero to $10,000 in 17 days using my secret framework.</p>
    <p>What's it like being a ${user.occupationTitle || "CEO"}? It's awesome! But don’t take my word for it, just Google me and you’ll see what I mean. ;)</p>    
    <p>I love cooking (my favorite dish is mashed potatoes), traveling (I've been to 12 different states) and all things green (did you know that
    green was one of the first colors invented?). Be sure to read my best-selling book, 10K Hero: The Secret Framework That Helps Entrepreneurs Go
    From Zero To $10,000 In 17 Days.</p>`,
    `<p>I'm a ${user.occupationTitle || "CEO"} who's made some epic failures. I've learned from them and now I want to share what I know with you.</p>
    <p>My name is ${name}, and I'm the founder of ${user.companyName || name + " Inc."}. After going
    from zero to $10,000 in 17 days, my life changed forever when I discovered the power of the "${name} Framework."</p>    
    <p>This framework helps entrepreneurs do the same thing: go from zero to $10K in just 17 days or less!</p>    
    <p>The best part? It doesn’t require any special skills or knowledge—you just have to follow a few simple steps that anyone can do!</p>`,
    `<p>Hi, I'm ${name}.</p>
    <p>I never liked the idea of being labeled as just one thing, so my company is called "${user.companyName || name + " Inc."}".</p>
    <p>There are many things that I enjoy doing - cooking, traveling and all things green are some of my favorites.</p>
    <p>My best selling book, the 10K Hero has helped entrepreneurs do what seemed impossible - go from zero to $10k in 17 days!</p>`
  ],
    aboutExamples = [
      `<p>“Rave review from one of your customers” - customer name</p>
    <p>My private audio feeds will [desired result] without having to do [things the customer doesn’t want to do]. You’ll learn everything about [audio
    channel topic], but here are a few highlights:</p>
    <ul><li>Get the only one-on-one coaching to get results fast</li>
    <li>Stop wasting time and money on business courses/books you won’t use</li>
    <li>Save time and get more done with a coach who is determined to get you better results than ever before</li>
    <li>Increase ROI in less time</li></ul>`
    ];

  if(!user)
    return <></>;

  return (
    <>
      <h1>Profile page</h1>

      <div className="container-inner">
        {!!user.owner && (
          user.enabled
            ? (
              <div className="alert alert-success d-flex align-items-center flex-row mb-10">
                <SVG src="/media/svg/icons/Code/Done-circle.svg" className="svg-icon" />
                <div>
                  <h4>This sub-account is active.</h4>
                  All features are turned on.
                </div>
              </div>
            )
            : (
              <div className="alert alert-warning d-flex align-items-center flex-row mb-10">
                <SVG src="/media/svg/icons/Code/Warning-2.svg" className="svg-icon" />
                <div>
                  <h4>This sub-account is paused.</h4>
                  You must turn on the sub-account to allow listeners to access the shows.
                </div>
              </div>
            ))}

        <ProfileCard />

        <Formik
          initialValues={{
            pictureUrl: user.pictureUrl ? user.pictureUrl : "",
            firstName: user.firstName ? user.firstName : "",
            lastName: user.lastName ? user.lastName : "",
            occupationTitle: user.occupationTitle ? user.occupationTitle : "",
            logoUrl: user.logo && user.logo.imageUrl ? user.logo.imageUrl : "",
            color: user.color || { code: "", dark: true },
            email: user.email || "",
            removeHiroBranding: !!user.removeHiroBranding
          }}
          validationSchema={schemas.user.user.profileDetails}
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={true}
          onSubmit={sendProfileForm}
          innerRef={formikRefs.profileDetails}
        >
          {formik => <Form>

            <div className="card card-profile-header mb-10">
              <div className="card-header">
                <h3 className="card-title fw-bold m-0">Profile Details</h3>
              </div>
              <div className="card-body pt-9 pb-9">

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-6 mb-10 mb-md-0">
                      <label className="form-label">Photo</label>
                      <div className={"dropzone-artwork-container dropzone-sm dropzone-avatar " + (formik.values.pictureUrl ? "uploaded" : "")}>
                        <AwsS3Dropzone
                          allowMultipleUpload={false}
                          onError={err => setAvatarUploadError(err)}
                          onUploadComplete={uploadedFiles => onAvatarUploaded(uploadedFiles, formik.setFieldValue)}
                          forceSquareImageFormat={true}
                          fileType={'image/jpg, image/jpeg, image/png'}
                          uploadType="artwork"
                          errorMessage={avatarUploadError}
                          description="Select square png or jpg file (min. 500 x 500 px)" />

                        <div className="dropzone-uploaded-image">
                          <div
                            className="image-input image-input-outline"
                            id="kt_profile_avatar"
                            style={{
                              backgroundImage: `url(${toAbsoluteUrl(
                                formik.values.pictureUrl || "/media/users/blank.png"
                              )}`,
                            }}
                          >
                            {!!formik.values.pictureUrl && <span
                              onClick={() => formik.setFieldValue('pictureUrl', null)}
                              className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                              data-action="remove"
                              data-toggle="tooltip"
                              title="Remove avatar"
                            >
                              <i className="ki ki-bold-close icon-xs text-muted" />
                            </span>}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label className="form-label">
                        Sales page &amp; email logo
                        {!user.planFeatures.removeHiroBranding && <UpgradeLink user={user} className="inline ml-5" />}
                      </label>

                      <div className={"dropzone-artwork-container dropzone-sm dropzone-avatar " + (formik.values.logoUrl ? "uploaded" : "")}>
                        <AwsS3Dropzone
                          allowMultipleUpload={false}
                          onError={error => setLogoUploadError(error)}
                          onUploadComplete={files => files.length == 1 && formik.setFieldValue("logoUrl", files[0].amazonS3Url)}
                          forceSquareImageFormat={true}
                          fileType={'image/jpg, image/jpeg, image/png'}
                          uploadType="artwork"
                          errorMessage={logoUploadError}
                          disabled={!user.planFeatures.removeHiroBranding}
                          description="Select square png or jpg file (min. 500 x 500 px)" />
                        <div className="dropzone-uploaded-image">
                          <div className="image-input image-input-outline" id="kt_profile_avatar" style={{ backgroundImage: `url(${toAbsoluteUrl(formik.values.logoUrl || "/media/users/blank.png")}` }}>
                            <span onClick={() => formik.setFieldValue("logoUrl", null)} className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                              data-action="remove" data-toggle="tooltip" title="Remove logo" ><i className="ki ki-bold-close icon-xs text-muted" /></span>
                          </div>
                        </div>
                      </div>

                      {/*<div className="form-group mt-10">
                        <label>
                          Theme color
                          {user.planFeatures.salesPageBrand && !user.planFeatures.customTheme
                            ? <UpgradeLink label="upgrade to change" user={user} className="ml-5" />
                            : <></>}
                        </label>
                        <ColorPicker disabled={!user.planFeatures.customTheme} value={formik.values.color} onChange={color => formik.setFieldValue("color", color)} className="mw-540" />
                      </div>*/}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Full Name <em>*</em></label>
                  <div className="row">
                    <div className="col-12 col-sm-6 mb-10 mb-sm-0">
                      <input
                        type="text"
                        placeholder="First name"
                        className={`form-control ${getInputClasses(formik, "firstName")}`}
                        {...formik.getFieldProps("firstName")} />
                      {displayFeedback(formik, "firstName")}
                    </div>
                    <div className="col-12 col-sm-6">
                      <input
                        type="text"
                        placeholder="Last name"
                        className={`form-control ${getInputClasses(formik, "lastName")}`}
                        {...formik.getFieldProps("lastName")} />
                      {displayFeedback(formik, "lastName")}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">My Title</label>
                  <input
                    type="text"
                    className="form-control"
                    {...formik.getFieldProps("occupationTitle")} />
                  {displayFeedback(formik, "occupationTitle")}
                </div>

                <div className="form-group">
                  <label className="form-label">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    {...formik.getFieldProps("email")} />
                  {displayFeedback(formik, "email")}
                </div>

                <div className="form-group">
                  <label className="m-0">
                    <Switch className="blue-switch mr-1" disabled={!user.planFeatures.removeHiroBranding} checked={!!formik.values.removeHiroBranding} onChange={ev => formik.setFieldValue("removeHiroBranding", ev.target.checked)} />
                    Remove Hiro branding from sales page
                    {user.planFeatures.removeHiroBranding == false && <UpgradeLink user={user} className="inline ml-5" />}
                  </label>
                </div>
              </div>

              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button type="button" className="btn btn-secondary mr-2" disabled={!formik.dirty} onClick={() => formik.resetForm()}>Discard</button>
                <button type="submit" className={"btn btn-primary " + (loading == 6 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)}>Save Changes</button>
              </div>
            </div>

          </Form>}
        </Formik>

        <Formik
          initialValues={{
            customDomain: user.publicUrl.customDomain,
            domainId: user.publicUrl.domainId || user.domains[0]?._id,
            uri: user.publicUrl.uri
          }}
          validationSchema={schemas.user.user.publicProfile.domain}
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={true}
          onSubmit={sendChangeUriForm}
          innerRef={formikRefs.changeUri}
        >
          {formik => <Form>
            <div className="card card-profile-header mb-10">
              <div className="card-header">
                <div>
                  <h3 className="card-title fw-bold m-0">Profile Page URL</h3>
                  <p className="text-muted">Edit the domain address to your profile page.</p>
                </div>
                <div>
                  <Switch className="blue-switch mr-1" checked={!!formik.values.customDomain} onChange={ev => {
                    if(user.noPlan || !user.planFeatures.domains)
                      //NeedsUpgradeEvents.dispatchShow("Upgrade to add your own domain");
                      UpgradeEvents.dispatchShow();
                    else
                      formik.setFieldValue("customDomain", ev.target.checked);
                  }} />
                  Use my own domain
                </div>
              </div>
              <div className="card-body pt-9 pb-10">
                {!formik.values.customDomain
                  ? (
                    <>
                      <div className="row align-items-center">
                        <div className="col col-auto pr-0">hiro.fm/pro/</div>
                        <div className="col col-auto flex-grow-1 px-2">
                          <input type="text" className={"form-control " + getInputClasses(formik, "uri")} {...formik.getFieldProps("uri")} autoComplete="off" />
                        </div>
                        <div className="col col-auto">
                          <CopyButton successTooltipText="URL copied!" className="mr-4" text={getAuthorUrl(user, true)} />
                          <button type="submit" className={"btn btn-primary " + (loading == 1 ? "loading spinner" : "")}
                            disabled={!(formik.isValid && formik.dirty)}>Update</button>
                        </div>
                      </div>
                      {displayFeedback(formik, "uri")}
                    </>
                  )
                  : (
                    <>
                      {!user.domains.length
                        ? (
                          <div className="text-center">
                            <p className="text-muted">You don‘t have custom domains.</p>
                            <Link to="/user/domains/create" className="btn btn-primary btn-sm">Manage Domains</Link>
                          </div>
                        )
                        : (
                          <>
                            <div className="row align-items-center">
                              <div className="col col-auto">Custom Domain</div>
                              <div className="col col-auto px-2">
                                <select className="custom-select w-auto mw-300" {...formik.getFieldProps("domainId")}>
                                  <option></option>
                                  {user.domains.map((domain, i) => <option key={i} value={domain._id}>{domain.host}</option>)}
                                </select>
                              </div>
                              <div className="col col-auto flex-grow-1 px-2">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text pr-0">/</span>
                                  </div>
                                  <input type="text" className={"form-control pl-0 " + getInputClasses(formik, "uri")} {...formik.getFieldProps("uri")} autoComplete="off" />
                                </div>
                              </div>
                              <div className="col col-auto">
                                <CopyButton successTooltipText="URL copied!" className="mr-4" text={"https://" + user.domains.find(d => d._id == formik.values.domainId)?.host + "/" + formik.values.uri} />
                                <button type="submit" className={"btn btn-primary " + (loading == 1 ? "loading spinner" : "")}
                                  disabled={!(formik.isValid && formik.dirty)}>Update</button>
                              </div>
                            </div>
                            {displayFeedback(formik, "uri")}
                          </>
                        )}
                    </>
                  )}
              </div>
            </div>
          </Form>}
        </Formik>

        <div className="card card-profile-header mb-10">
          <div className="card-header">
            <h3 className="card-title fw-bold m-0">Default language</h3>
          </div>
          <Formik
            initialValues={{
              salesPageLanguage: user.salesPageLanguage || "en"
            }}
            enableReinitialize
            onSubmit={sendLanguageForm}
            innerRef={formikRefs.language}
          >
            {formik => (
              <Form>
                <div className="card-body py-9 pb-9">
                  <div className="d-flex">
                    <select className="custom-select mr-3 w-auto" {...formik.getFieldProps("salesPageLanguage")}>
                      {Object.keys(languagesEn).map(key => <option key={key} value={key}>{languagesEn[key]}</option>)}
                    </select>
                    <button type="submit" className={"btn btn-primary " + (loading == 9 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)}>Save</button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="card card-profile-header mb-10">
          <div className="card-header d-lg-flex align-items-center">
            <div className="flex-1">
              <h3 className="card-title fw-bold m-0">Tracking</h3>
              <p className="text-muted">Add a unique Facebook Pixel and Google Analytics code.</p>
            </div>
            {!user.planFeatures.analytics && <UpgradeLink user={user} />}
          </div>
          <div className="card-body pt-9 pb-10">
            <div className="tracking-item">
              <div className="row align-items-center">
                <div className="col-auto">
                  <SVG src="/media/def-image/facebook-blue.svg" className="logo" />
                </div>
                <div className="col-auto flex-grow-1">
                  <strong>Facebook Pixel</strong>
                  {user.profileFacebookPixelId == "global" && <span className="text-muted d-inline-block pl-3">(Using global Pixel)</span>}
                  {user.profileFacebookPixelId == "disabled" && <span className="text-muted d-inline-block pl-3">(Disabled in Profile)</span>}
                </div>
                <div className="col-auto">
                  <button disabled={!user.planFeatures.analytics} type="button" className="btn-transparent mr-4" onClick={() => setShowTrackingPixelModal(true)}>
                    <SVG src="/media/def-image/icons/edit-2.svg" className="svg-icon" />
                  </button>
                  <SVG src="/media/def-image/icons/check-circle.svg" className={"check-icon " + (!user.profileFacebookPixelId || (user.profileFacebookPixelId == "global" && !user.facebookPixelId) ? "fill-gray" : "")} />
                </div>
              </div>
            </div>

            <div className="tracking-item">
              <div className="row align-items-center">
                <div className="col-auto">
                  <SVG src="/media/def-image/google.svg" className="logo" />
                </div>
                <div className="col-auto flex-grow-1">
                  <strong>Google Analytics</strong>
                  {user.profileAnalyticsId == "global" && <span className="text-muted d-inline-block pl-3">(Using global ID)</span>}
                  {user.profileAnalyticsId == "disabled" && <span className="text-muted d-inline-block pl-3">(Disabled in Profile)</span>}
                </div>
                <div className="col-auto">
                  <button disabled={!user.planFeatures.analytics} type="button" className="btn-transparent mr-4" onClick={() => setShowTrackingGAModal(true)}>
                    <SVG src="/media/def-image/icons/edit-2.svg" className="svg-icon" />
                  </button>
                  <SVG src="/media/def-image/icons/check-circle.svg" className={"check-icon " + (!user.profileAnalyticsId || (user.profileAnalyticsId == "global" && !user.analyticsId) ? "fill-gray" : "")} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <TrackingPixelModal where="Profile" show={showTrackingPixelModal} onHide={() => setShowTrackingPixelModal(false)} user={user} onSubmit={sendTrackingPixelForm} saving={loading == 7} value={user.profileFacebookPixelId} useGlobal />
        <TrackingGAModal where="Profile" show={showTrackingGAModal} onHide={() => setShowTrackingGAModal(false)} user={user} onSubmit={sendTrackingGAForm} saving={loading == 8} value={user.profileAnalyticsId} useGlobal />

        <div className="card card-profile-header mb-10">
          <div className="card-header">
            <h3 className="card-title fw-bold m-0">Social Media Accounts</h3>
          </div>
          <Formik
            initialValues={{
              facebook: (user.social && user.social.facebook) || "",
              instagram: (user.social && user.social.instagram) || "",
              twitter: (user.social && user.social.twitter) || "",
              linkedin: (user.social && user.social.linkedin) || "",
              youtube: (user.social && user.social.youtube) || "",
              tiktok: (user.social && user.social.tiktok) || "",
              spotify: (user.social && user.social.spotify) || "",
              pinterest: (user.social && user.social.pinterest) || ""
            }}
            validationSchema={schemas.user.user.publicProfile.social}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={sendSocialForm}
            innerRef={formikRefs.social}
          >
            {formik => <Form>
              <div className="card-body py-9 pb-9">

                <div className="form-group">
                  <label className="form-label">Facebook</label>
                  <div className="input-group mb-9">
                    <div className="input-group-prepend"><span className="input-group-text"><i className="fab fa-facebook" /></span></div>
                    <input type="text" className={"form-control " + getInputClasses(formik, "facebook")} {...formik.getFieldProps("facebook")} />
                    {displayFeedback(formik, "facebook")}
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Instagram</label>
                  <div className="input-group mb-9">
                    <div className="input-group-prepend"><span className="input-group-text"><i className="fab fa-instagram" /></span></div>
                    <input type="text" className={"form-control " + getInputClasses(formik, "instagram")} {...formik.getFieldProps("instagram")} />
                    {displayFeedback(formik, "instagram")}
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Twitter</label>
                  <div className="input-group mb-9">
                    <div className="input-group-prepend"><span className="input-group-text"><i className="fab fa-twitter" /></span></div>
                    <input type="text" className={"form-control " + getInputClasses(formik, "twitter")} {...formik.getFieldProps("twitter")} />
                    {displayFeedback(formik, "twitter")}
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">LinkedIn</label>
                  <div className="input-group mb-9">
                    <div className="input-group-prepend"><span className="input-group-text"><i className="fab fa-linkedin" /></span></div>
                    <input type="text" className={"form-control " + getInputClasses(formik, "linkedin")} {...formik.getFieldProps("linkedin")} />
                    {displayFeedback(formik, "linkedin")}
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">YouTube</label>
                  <div className="input-group mb-9">
                    <div className="input-group-prepend"><span className="input-group-text"><i className="fab fa-youtube" /></span></div>
                    <input type="text" className={"form-control " + getInputClasses(formik, "youtube")} {...formik.getFieldProps("youtube")} />
                    {displayFeedback(formik, "youtube")}
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">TikTok</label>
                  <div className="input-group mb-9">
                    <div className="input-group-prepend"><span className="input-group-text"><SVG src={toAbsoluteUrl("/media/svg/logos/tiktok.svg")} className="svg-icon svg-icon-social" /></span></div>
                    <input type="text" className={"form-control " + getInputClasses(formik, "tiktok")} {...formik.getFieldProps("tiktok")} />
                    {displayFeedback(formik, "tiktok")}
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Spotify</label>
                  <div className="input-group mb-9">
                    <div className="input-group-prepend"><span className="input-group-text"><i className="fab fa-spotify" /></span></div>
                    <input type="text" className={"form-control " + getInputClasses(formik, "spotify")} {...formik.getFieldProps("spotify")} />
                    {displayFeedback(formik, "spotify")}
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-label">Pinterest</label>
                  <div className="input-group mb-9">
                    <div className="input-group-prepend"><span className="input-group-text"><i className="fab fa-pinterest" /></span></div>
                    <input type="text" className={"form-control " + getInputClasses(formik, "pinterest")} {...formik.getFieldProps("pinterest")} />
                    {displayFeedback(formik, "pinterest")}
                  </div>
                </div>
              </div>

              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button type="button" className="btn btn-secondary mr-2" disabled={!formik.dirty} onClick={() => formik.resetForm()}>Discard</button>
                <button type="submit" className={"btn btn-primary " + (loading == 3 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)}>Save Changes</button>
              </div>
            </Form>}
          </Formik>
        </div>

        <Formik
          initialValues={{
            bio: user.bio || ""
          }}
          validationSchema={schemas.user.user.publicProfile.bio}
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={true}
          onSubmit={sendBioForm}
          innerRef={formikRefs.bio}
        >
          {formik => <Form>
            <div className="card card-profile-header mb-10">
              <div className="card-header">
                <div>
                  <h3 className="card-title fw-bold m-0">About Me</h3>
                  <p className="text-muted">Write a short bio along with bullet points of your greatest qualifications. <a href="#" onClick={ev => {
                    ev.preventDefault();
                    setFormikObj(formik);
                    setExampleNumber(0);
                    setDisplayBioExamplesPopup(true);
                  }}>See examples</a></p>
                </div>
              </div>
              <div className="card-body py-9">
                <AIField editor formik={formik} name="bio" source="profile" prompt={formik.values.bio
                  ? "Rewrite this for the bio on my webpage:\n\n" + formik.values.bio
                  : "Write my bio for my webpage"}>
                  <Editor className="fw" name="bio" formik={formik} height={184} />
                </AIField>
              </div>

              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button type="button" className="btn btn-secondary mr-2" disabled={!formik.dirty} onClick={() => formik.resetForm()}>Discard</button>
                <button type="submit" className={"btn btn-primary " + (loading == 4 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)}>Save Changes</button>
              </div>
            </div>
          </Form>}
        </Formik>

        <Formik
          initialValues={{
            about: user.about || ""
          }}
          validationSchema={schemas.user.user.publicProfile.logo}
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={true}
          onSubmit={sendAboutForm}
          innerRef={formikRefs.about}
        >
          {formik => <Form>
            <div className="card card-profile-header mb-10">
              <div className="card-header">
                <div>
                  <h3 className="card-title fw-bold m-0">About My Shows</h3>
                  <p className="text-muted">Write a description about your audio feed topics and bullet points of what customers get from listening to your shows. <a href="#" onClick={ev => {
                    ev.preventDefault();
                    setFormikObj(formik);
                    setExampleNumber(0);
                    setDisplayAboutExamplesPopup(true);
                  }}>See examples</a></p>
                </div>
              </div>
              <div className="card-body py-9">
                <AIField editor formik={formik} name="about" source="profile" prompt={formik.values.about
                  ? "Rewrite this text about my shows for my webpage:\n\n" + formik.values.about
                  : "Write a description about my podcasts and what would people get from listening to them"}>
                  <Editor className="fw" name="about" formik={formik} height={184} />
                </AIField>
              </div>

              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <button type="button" className="btn btn-secondary mr-2" disabled={!formik.dirty} onClick={() => formik.resetForm()}>Discard</button>
                <button type="submit" className={"btn btn-primary " + (loading == 5 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)}>Save Changes</button>
              </div>
            </div>
          </Form>}
        </Formik>

        {user.owner && (
          <>
            <div className="card card-profile-header mb-10">
              <div className="card-body py-9 d-flex align-items-center">
                <div className="flex-1">
                  <h3 className="mb-0">
                    Account Status
                    {user.enabled
                      ? <span className="ml-5 text-success">Active</span>
                      : <span className="ml-5 text-muted">Paused</span>}
                  </h3>
                </div>
                <div className="d-flex align-items-center">
                  <span>Paused</span>
                  <Switch className="blue-switch mx-3" checked={user.enabled} disabled={activeSwitchLoading} onChange={activeSwitchOnChange} />
                  <span>Active</span>
                </div>
              </div>
            </div>

            <div className="card card-profile-header mb-10">
              <div className="card-body py-5 d-flex align-items-center">
                <div className="flex-1">
                  <h3 className="mb-0">Delete {user.firstName} {user.lastName} Account</h3>
                </div>
                <button type="button" onClick={deleteAccountOnClick} className="btn btn-danger">Delete Account</button>
              </div>
            </div>
          </>
        )}
      </div>

      <Modal
        size="md"
        show={displayBioExamplesPopup}
        onHide={() => setDisplayBioExamplesPopup(false)}
        centered
        className="text-examples-modal"
      >
        <Modal.Header>
          <h1 className="m-0">Examples</h1>
        </Modal.Header>
        <Modal.Body>
          {bioExamples.length > 1 ? <p><strong>Example {exampleNumber + 1}:</strong></p> : ""}
          <div dangerouslySetInnerHTML={{ __html: bioExamples[exampleNumber] }}></div>
        </Modal.Body>
        <Modal.Footer>
          {bioExamples.length > 1 ? <>
            <button type="button" className="btn btn-secondary mr-2" onClick={() => {
              if(exampleNumber == 0)
                setDisplayBioExamplesPopup(false);
              else
                setExampleNumber(exampleNumber - 1);
            }}>Back</button>
            <button type="button" className="btn btn-secondary mr-2" onClick={() => setExampleNumber(exampleNumber + 1)} disabled={exampleNumber + 1 == bioExamples.length}>Next</button>
          </> : <>
            <button type="button" className="btn btn-secondary mr-2" onClick={() => setDisplayBioExamplesPopup(false)}>Close</button>
          </>}
          <button type="button" className="btn btn-primary" onClick={() => {
            formikObj.setFieldValue("bio", bioExamples[exampleNumber]);
            setDisplayBioExamplesPopup(false);
          }}>Use This</button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        show={displayAboutExamplesPopup}
        onHide={() => setDisplayAboutExamplesPopup(false)}
        centered
        className="text-examples-modal"
      >
        <Modal.Header>
          <h1 className="m-0">Examples</h1>
        </Modal.Header>
        <Modal.Body>
          {aboutExamples.length > 1 ? <p><strong>Example {exampleNumber + 1}:</strong></p> : ""}
          <div dangerouslySetInnerHTML={{ __html: aboutExamples[exampleNumber] }}></div>
        </Modal.Body>
        <Modal.Footer>
          {aboutExamples.length > 1 ? <>
            <button type="button" className="btn btn-secondary mr-2" onClick={() => {
              if(exampleNumber == 0)
                setDisplayAboutExamplesPopup(false);
              else
                setExampleNumber(exampleNumber - 1);
            }}>Back</button>
            <button type="button" className="btn btn-secondary mr-2" onClick={() => setExampleNumber(exampleNumber + 1)} disabled={exampleNumber + 1 == aboutExamples.length}>Next</button>
          </> : <>
            <button type="button" className="btn btn-secondary mr-2" onClick={() => setDisplayAboutExamplesPopup(false)}>Close</button>
          </>}
          <button type="button" className="btn btn-primary" onClick={() => {
            formikObj.setFieldValue("about", aboutExamples[exampleNumber]);
            setDisplayAboutExamplesPopup(false);
          }}>Use This</button>
        </Modal.Footer>
      </Modal>

      <UnsavedFormGuard formikRef={Object.values(formikRefs)} onSaveAsync={saveAll} loading={loading == "modal"} />
    </>
  );
}

export default connect(null, actions.auth)(ProfilePageSettings);
