import React, { useState, useEffect } from "react";
import api from "../../../../redux/api";
import { Message, sleep, Writting } from "./common";
import CreatorNameMessage from "./CreatorNameMessage";
import UploadPictureMessage from "./UploadPictureMessage";

function FirstAccountMessage({ next, scroll, state, user: _user, setLogin, dispatch, update }) {
    let [response, setResponse] = useState(null),
        [sent, setSent] = useState(false),
        [loading, setLoading] = useState(false),
        [error, setError] = useState(null),
        user = _user.owner
            ? _user.owner
            : _user;

    let submit = async creator => {
        setSent(true);
        setLoading(true);
        setResponse(creator == "someone-else"
            ? "Someone else"
            : user.firstName + " " + user.lastName);

        await sleep(2000);

        let data = { creator };

        let res = await api.user.onboardingStep("step-creator", data);

        //update token in case the user has just been upgraded to agency
        if(res && res.token && res.user) {
            setLogin(res.token, res.user);
            await update();
        }

        setLoading(false);

        if(!res || !res.success) {
            setError((res && res.error) || "I’m sorry, I wasn’t able to process that. Please, try again.");
            setTimeout(() => next(FirstAccountMessage), 1000);
            return;
        }

        update();

        if(creator == "someone-else")
            next(CreatorNameMessage, data);
        else
            next(UploadPictureMessage, data);
    };

    useEffect(() => {
        scroll();
    }, [sent, loading, error]);

    return (
        <>
            <Message>
                Let’s set up your first account. <strong>Is the show creator {user.firstName} {user.lastName} or someone else?</strong>
            </Message>
            {!sent && (
                <Message delay={500} incoming custom>
                    <div className="options small">
                        <a href="#" onClick={ev => submit("myself")}>{user.firstName} {user.lastName}</a>
                        <a href="#" onClick={ev => submit("someone-else")}>Someone else</a>
                    </div>
                </Message>
            )}
            {sent && (
                <Message incoming>
                    {response}
                </Message>
            )}
            {loading && <Writting delay={1000} />}
            {error && (
                <Message>{error}</Message>
            )}
        </>
    );
}

export default FirstAccountMessage;