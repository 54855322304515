import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import actionTypes from "../action-types";

const initialAuthState = {
  listeners: [],
  listener: {},
};

export const reducer = persistReducer(
  { storage, key: "listeners", whitelist: []},

  (state = initialAuthState, action) => {
    switch (action.type) {
    case actionTypes.listener.GetListeners: {
      return {
        ...state,
        listeners: action.payload.listeners,
      };
    }

    case actionTypes.listener.GetListener: {
      return {
        ...state,
        listener: action.payload.listener,
      };
    }

    default:
      return state;
    }
  }
);
