import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import actions from "../../../../redux/actions";
import { Form, Formik, useFormik } from "formik";
import schemas from "../../../schemas";
import api from "../../../../redux/api";
import { getInputClassName, toastMessage } from "../../../helpers";
import ProfileNavigation from "../../../layout/navigation/ProfileNavigation";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import CopyButton from "../../../layout/components/CopyButton";
import { Switch } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";

function Partnership() {
  const user = useSelector((state) => state.auth.user, shallowEqual);

  const formik = useFormik({
    initialValues: {
      email: user.trackdeskEmail || ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Enter a valid email address.")
        .required("Enter a valid email address.")
    }),
    onSubmit: values => changeTrackdeskEmail(values),
    enableReinitialize: true
  });

  const { handleSubmit, getFieldProps, touched, errors } = formik;

  let [trackdeskLoading, setTrackdeskLoading] = useState(true),
    [trackdesk, setTrackdesk] = useState(),
    [shortUrls, setShortUrls] = useState(false),
    [showUpdateTrackdeskEmailModal, setShowUpdateTrackdeskEmailModal] = useState(false),
    [changeTrackdeskEmailSaving, setChangeTrackdeskEmailSaving] = useState(false);

  async function loadData() {
    setTrackdeskLoading(true);

    let res = await api.user.getPartnership();

    setTrackdeskLoading(false);

    if(res && res.success)
      setTrackdesk(res.data);
    else
      setTrackdesk(null);
  }

  async function changeTrackdeskEmail(values) {
    setChangeTrackdeskEmailSaving(true);

    let res = await api.user.updatePartnership(values);

    setChangeTrackdeskEmailSaving(false);

    if(!res || !res.success)
      return;

    setShowUpdateTrackdeskEmailModal(false);

    loadData();
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <ProfileNavigation active="partnership" />

      <div className="container-inner">
        <div className="card partnership mb-10">
          <div className="card-body d-flex block-lg">
            <div className="image">
              <SVG src={toAbsoluteUrl("/media/def-image/partnership.svg")} />
            </div>
            <div className="fill">
              <h2>Receive up to  50% recurring commission for life. </h2>
              <p>We send you commission for every person who signs up to Hiro through your Hiro sales page, profile page or shows. But it’s not just that one payment when they sign up. We start with 30% hitting your bank account every month for as long as their Hiro account is active.</p>
              <div className="d-flex align-items-center mt-10">
                <a href="https://partner.hiro.fm/sign-up" target="_blank" className="btn btn-primary mr-10">Become a Partner</a>
                <div>
                  <strong>Already a partner?</strong> <a href="https://partner.hiro.fm" target="_blank" className="underline">Login here</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {trackdeskLoading || !trackdesk
          ? (
            <div className="card card-profile-header">
              <div className="card-header">
                <div>
                  <h3 className="card-title fw-bold m-0">Your affiliate links</h3>
                </div>
              </div>
              <div className="card-body py-9 text-center">
                {trackdeskLoading
                  ? (
                    <div className="loading loading-block spinner" />
                  )
                  : (
                    <div>
                      <p className="text-muted">
                        Register as a partner with the email you used to join Hiro, and your information will<br />
                        show up here automatically. <a href="https://partner.hiro.fm/sign-up" className="fb-bold underline" target="_blank">Become a partner affiliate</a>
                      </p>
                      
                      <div className="text-center d-lg-flex justify-content-center align-items-center mt-10">
                        <strong className="d-block mb-5 mb-lg-0 mr-lg-2">Registered with a different email?</strong>
                        <button type="button" className="btn btn-sm btn-primary ml-lg-2" onClick={() => setShowUpdateTrackdeskEmailModal(true)}>Update email address</button>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )
          : (
            <div className="card card-profile-header">
              <div className="card-header">
                <div>
                  <h3 className="card-title fw-bold m-0">Your partner details</h3>
                </div>
                <div>
                  <label>Long URLs</label>
                  <Switch className="mx-2 blue-switch" checked={shortUrls} onChange={ev => setShortUrls(ev.target.checked)} />
                  <label>Short URLs</label>
                </div>
              </div>
              <div className="card-body py-9">
                {trackdesk.offers.map((offer, i) => (
                  <div key={i} className={i == trackdesk.offers.length - 1 ? "" : "mb-12"}>
                    <p className="fw-bold mb-6">
                      {offer.name}
                    </p>
                    {offer.links.map((link, j) => (
                      <div className={"form-group " + (j == offer.links.length - 1 ? "" : "mb-6")} key={j}>
                        <label className="form-label fw-normal">
                          {link.name}
                        </label>
                        <div className="d-flex align-items-center">
                          <input type="text" readOnly className="form-control mr-4" value={shortUrls ? link.shortUrl : link.url} />
                          <CopyButton successTooltipText="URL copied!" text={shortUrls ? link.shortUrl : link.url} />
                        </div>
                      </div>
                    ))}
                  </div>
                ))}

                <div className="text-center d-lg-flex justify-content-center align-items-center mt-12">
                  <strong className="d-block mb-5 mb-lg-0 mr-lg-2">Registered with a different email?</strong>
                  <button type="button" className="btn btn-sm btn-primary ml-lg-2" onClick={() => setShowUpdateTrackdeskEmailModal(true)}>Update email address</button>
                </div>
              </div>
            </div>
          )}
      </div>

      <Modal show={showUpdateTrackdeskEmailModal} centered onHide={() => setShowUpdateTrackdeskEmailModal(false)} size="md">
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <h1>Change Trackdesk email</h1>

            <div className="form-group mb-10">
              <label className="form-label">Email:</label>
              <input type="text" {...getFieldProps("email")} className={getInputClassName(formik, "email", "autofocus")} />
              {touched.email && errors.email && <div className="field-error">{errors.email}</div>}
            </div>

            <div className="d-lg-flex justify-content-end">
              <button type="button" className="btn btn-transparent mr-6" onClick={() => setShowUpdateTrackdeskEmailModal(false)}>Cancel</button>
              <button type="submit" className={"btn btn-primary " + (changeTrackdeskEmailSaving ? "loading spinner" : "")}>Save</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default connect(null, actions.auth)(Partnership);
