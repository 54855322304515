import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect, useSelector, shallowEqual } from 'react-redux';
import { withRouter } from "react-router-dom";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import actions from "../../../redux/actions";

function PhoneNumberDropdown({ dispatch, withLabel, className, phoneNumber, setPhoneNumber }) {
    let user = useSelector(state => state.auth.user, shallowEqual);

    async function switchPhoneNumber(item) {
        dispatch(setPhoneNumber(item));
    }

    useEffect(() => {
        if(!user)
            return;

        if(!phoneNumber || !user.phoneNumbers.find(p => p.id == phoneNumber.id))
            switchPhoneNumber(user.phoneNumbers.length
                ? user.phoneNumbers[0]
                : null);
    }, [phoneNumber, user]);

    if(!user || !user.phoneNumbers || !user.phoneNumbers.length || !phoneNumber)
        return <></>;

    let iconName = phoneNumber.kind == "whatsapp"
        ? "whatsapp.svg"
        : "mobile.svg";

    let label = phoneNumber.kind == "whatsapp"
        ? "WhatsApp Number"
        : "SMS Number";

    return (
        <Dropdown className={"phone-number-dropdown " + (className || "")}>
            <Dropdown.Toggle as="div">
                {withLabel
                    ? (
                        <div className="flex-1">
                            <label>
                                <SVG src={"/media/def-image/icons/" + iconName} />
                                <div>
                                    {label}
                                    {phoneNumber.pending && <span className="badge yellow-badge">Pending Setup</span>}
                                </div>
                            </label>
                            <span className="current-value mt-2">
                                {phoneNumber.number}
                            </span>
                        </div>
                    )
                    : (
                        <span className="current-value">
                            <SVG src={"/media/def-image/icons/" + iconName} />
                            {phoneNumber.number}
                        </span>
                    )}
                <SVG src="/media/def-image/icons/angle-down.svg" className="angle-down-icon" />
            </Dropdown.Toggle>

            <Dropdown.Menu renderOnMount>
                {user.phoneNumbers.map(phoneNumber => {
                    let iconName = phoneNumber.kind == "whatsapp"
                        ? "whatsapp.svg"
                        : "mobile.svg";
                    return (
                        <Dropdown.Item onClick={() => switchPhoneNumber(phoneNumber)}>
                            <SVG src={"/media/def-image/icons/" + iconName} />
                            {phoneNumber.number}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default injectIntl(
    connect(
        (state) => ({
            user: state.auth.user,
            phoneNumber: state.phoneNumber.current
        }),
        dispatch => ({
            dispatch,
            ...actions.phoneNumber,
            ...actions.auth
        })
    )(withRouter(PhoneNumberDropdown))
);
