import React from 'react';
import { RollbarErrorTracking } from '../helpers';

export default function CustomErrorTracking(component, errorHandler) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: undefined,
      };
    }

    static getDerivedStateFromError(error) {
      return { error };
    }

    componentDidCatch(error, info) {
      if (errorHandler) {
        RollbarErrorTracking.logErrorInfo(info);
        RollbarErrorTracking.logErrorInRollbar(error);
        errorHandler(error, info);
      }
    }

    render() {
      const { error } = this.state;
      return component(this.props, error);
    }
  };
}
