import React, { useEffect, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import ImportEvents from "./Import";
import { Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import api from "../../../../redux/api";
import { toastMessage } from "../../../helpers";
import { useHistory } from "react-router-dom";

function ShareCodeImport({ dispatch }) {
  let [show, setShow] = useState(false),
    [product, setProduct] = useState(),
    [step, setStep] = useState(1),
    [loading, setLoading] = useState(false),
    [saving, setSaving] = useState(false),
    [error, setError] = useState(),
    codeRef = useRef(),
    codeInputRef = useRef(),
    history = useHistory();

  useEffect(() => {
    ImportEvents.on("showShareCodeImport", () => {
      setStep(1);
      setShow(true);
      setLoading(false);
      setSaving(false);
      setError(null);
    });
  }, []);

  async function validateCodeOnClick() {
    setLoading(true);

    let value = codeInputRef.current.value,
      res = await api.user.validateShareCode(value);

    setLoading(false);

    if(!res)
      return toastMessage.error("Unable to connect to the server.");

    if(!res.success)
      return setError(res.error || "The code is not valid.");

    codeRef.current = value;
    setProduct(res.product);
    setStep(2);
  }

  async function confirmImport() {
    setSaving(true);

    let res = await api.user.redeemShareCode(codeRef.current);

    if(!res || !res.success) {
      setSaving(false);
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");
    }

    await updateUser();

    toastMessage.success("Show imported successfully!");

    setSaving(false);
    setShow(false);
    history.push("/products/" + res.productId);
  }

  async function updateUser() {
    dispatch(actions.product.setProducts(await api.product.getProducts()));
    dispatch(actions.auth.fulfillUser((await api.auth.getUserByToken(true)).data));
  }

  return <>
    <Modal show={show} onHide={() => setShow(false)} size="md" centered className="new-modals share-code-import-modal">
      <Modal.Body className="py-20 text-center">
        <button type="button" className="close" onClick={() => setShow(false)}><SVG src={toAbsoluteUrl("/media/def-image/close.svg")} className="svg-icon" /></button>

        {step == 1 && (
          <>
            <h1>Let’s import a shared show</h1>
            <p>Import a show using the Share Code you‘ve received.</p>
            <div className="code-field-wrapper">
              <input type="text" className="form-control code-field" placeholder="ABC987DE" ref={codeInputRef} autoFocus />
              <button type="button" className={"btn btn-primary " + (loading ? "loading spinner" : "")} onClick={validateCodeOnClick}>Continue</button>
            </div>
            {!!error && <div className="field-error">{error}</div>}
          </>
        )}

        {step == 2 && !!product && (
          <>
            <h1 className="m-0">Let’s import a shared show</h1>
            <div className="product-card">
              {!!product.artwork && (
                <div className="product-image">
                  <img src={product.artwork.thumbnail || product.artwork.url} />
                </div>
              )}
              <div className="product-details">
                <strong>{product.name}</strong><br />
                {product.author}<br />
                <SVG src={toAbsoluteUrl("/media/def-image/episodes.svg")} className="svg-icon" /> {product.episodes.length} episodes
              </div>
            </div>
            <div className="options">
              <button type="button" className="btn btn-secondary me-3" onClick={() => setShow(false)}>Cancel</button>
              <button type="button" className={"btn btn-primary ms-3 " + (saving ? "loading spinner" : "")} onClick={() => confirmImport()}>Confirm</button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  </>;
};

export default injectIntl(connect(
  state => ({
    user: state.auth.user,
    products: state.product.products
  }),
  dispatch => ({
    dispatch,
    ...actions.product,
  })
)(ShareCodeImport));
