import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import api from "../../../redux/api";
import { toastMessage } from "../../helpers";
import SVG from "react-inlinesvg";
import RenameForm from "./RenameForm";

function Text({ contents }) {
    if(Array.isArray(contents))
        return contents.map((elem, index) => <Text key={index} contents={elem} />);

    if(typeof contents == "string" && contents.replace(/\n/g, "").match(/^1\./))
        return (
            <ol>
                {[...contents.matchAll(/(\d\.(.+))/g)].map((item, index) => (
                    <>
                        {item[2] && item[2].trim() && (
                            <li key={index}>
                                {item[2].trim()}
                            </li>
                        )}
                    </>
                ))}
            </ol>
        );

    if(typeof contents == "string")
        return <p>{contents.split("\n").map((part, index) => (
            <>
                {part.trim() && <React.Fragment key={index}>
                    {part}
                    <br />
                </React.Fragment>}
            </>
        ))}</p>;

    return <></>;
}

function PodcastBody({ project }) {
    const sections = [
        {
            type: "name",
            title: "Episode title suggestions",
            description: ""
        },
        {
            type: "intro",
            title: "Intro",
            description: "Tell them flat out what’s in it form them! You have to hook the listener in from the start. Explain the problem this episode solves and stress the importance of listening to the end. Share a shocking fact or big outcome for listening."
        },
        {
            type: "story",
            title: "Story",
            description: "Story is how we emotionally connect to others. This is the love chemical, oxytocin. Weave short stories throughout your podcast. The most important one is the backstory of where the topic came from or how you came to discover it."
        },
        {
            type: "why",
            title: "Why",
            description: "Why am I talking about that subject? Why is it important? Why should anybody care about it? Why does it manifest itself like that? Why can’t your listener move forward? Why the methods they currently are using aren’t working?"
        },
        {
            type: "what",
            title: "What",
            description: "Clarify what they need to know in order to get the results. What was missing before? What is the answer? What is the block? \"What\" can be your offer, message, tip, mistake. It’s endless."
        },
        {
            type: "how-to",
            title: "How to",
            description: "Share the framework of the solution. This will be 3-5 steps they can take to get a result. Most podcasts only focus on this part."
        },
        {
            type: "what-if",
            title: "What if",
            description: "If the previous section is over-utilized, this section is underutilized. At this stage we “sell” them on the possibilities of a life without those problems. Or a life with the results they are searching for."
        },
        {
            type: "recap-cta",
            title: "Recap/CTA",
            description: "Quickly recap and give them a call to action to like, share or rate. Make the action easy for that quick endorphin reward."
        }
    ];

    return sections.map((section, index) => {
        let contents = project.output.find(item => item.type == section.type);

        if(!contents)
            return <></>;

        return (
            <div className={"output " + section.type + "-output " + (section.description ? "has-description" : "")} key={index}>
                <h2>{section.title}</h2>
                {section.description && (
                    <p className="description">{section.description}</p>
                )}
                <Text contents={contents.text} />
            </div>
        );
    });
}

export default function Details({ projectId, onHide }) {
    const [loading, setLoading] = useState(),
        [show, setShow] = useState(false),
        [project, setProject] = useState(),
        [rename, setRename] = useState(false);

    const load = async id => {
        setShow(true);
        setLoading(true);

        let res = await api.ai.getProject(id);

        setLoading(false);

        if(!res || !res.success) {
            toastMessage.error((res && res.error) || "Unable to connect to the server.");
            onHide();
            return;
        }

        setProject(res.data);
    };

    const renameOnClick = ev => {
        ev.preventDefault();
        setRename(true);
    };

    const renameOnComplete = name => {
        setProject(v => ({ ...v, name }));
        setRename(false);
    };

    const closeOnClick = ev => {
        ev.preventDefault();
        onHide();
    };

    useEffect(() => {
        if(!projectId)
            setShow(false);
        else
            load(projectId);
    }, [projectId]);

    return (
        <Modal show={show} onHide={onHide} size="lg" className="new-modals ai-details-modal" centered>
            {loading || !project
                ? (
                    <div className="loading spinner spinner-sm spinner-blue py-20" />
                )
                : (
                    <>
                        <Modal.Header>
                            <h1>
                                {rename
                                    ? <RenameForm project={project} onComplete={renameOnComplete} onCancel={() => setRename(false)} />
                                    : project.name}
                            </h1>
                            <div className="header-options">
                                <a href="#" onClick={renameOnClick} title="Rename">
                                    <SVG src="/media/def-image/icons/rename.svg" />
                                </a>
                                <a href="#" disabled title="Duplicate">
                                    <SVG src="/media/def-image/icons/duplicate.svg" />
                                </a>
                                <a disabled={1 || project.status != "done"} href={process.env.REACT_APP_SERVER_URL + "api/ai-experimental/" + project._id + "/pdf"} target="_blank" title="Download as PDF">
                                    <SVG src="/media/def-image/icons/download.svg" />
                                </a>
                                <a href="#" onClick={closeOnClick} title="Close">
                                    <SVG src="/media/def-image/icons/close-2.svg" />
                                </a>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            {project.status == "failed" || !Array.isArray(project.output)
                                ? <p className="empty">The content generation failed. Please, try again.</p>
                                : (
                                    <>
                                        {project.status != "done"
                                            ? <p className="empty">The content generation is in progress. We’ll let you know when it’s done.</p>
                                            : (() => {
                                                if(project.input.template == "podcast")
                                                    return <PodcastBody project={project} />;
                                            })()}
                                    </>
                                )}
                        </Modal.Body>
                        <Modal.Footer>
                            <small className="text-muted">{parseInt(project.cost).toLocaleString()} credits</small>
                        </Modal.Footer>
                    </>
                )
            }
        </Modal>
    );
}
