import React from "react";
import { Modal } from "react-bootstrap";

export default function UnsavedModal({ show, loading = false, onSave = null, onCancel = null, onHide, className = "", message = null, okOnly = false }) {
  return (
    <Modal show={show} onHide={onHide} size="md" className={"confirm-modal " + className}>
      <Modal.Body>
        {message || (!okOnly && onSave && onCancel ? "Do you want to save first?" : "You have unsaved changes!")}
      </Modal.Body>
      <Modal.Footer>
        {!okOnly && onSave && onCancel
          ? (
            <>
              <button type="button" className={"btn btn-primary mr-2 " + (loading ? "loading spinner" : "")} onClick={onSave}>Yes</button>
              <button type="button" className="btn btn-secondary mr-2" onClick={onCancel}>No</button>
              <button type="button" className="btn btn-secondary" onClick={onHide}>Stay in this page</button>
            </>
          )
          : (
            <button type="button" className="btn btn-primary" onClick={onHide}>Ok</button>
          )}
      </Modal.Footer>
    </Modal>
  );
}
