import React, { useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import "react-phone-number-input/style.css";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import api from "../../../redux/api";
import QRCode from "qrcode.react";

function AppInstall(props) {
  let [ product, setProduct ] = useState(null),
    [ loading, setLoading ] = useState(true),
    [ error, setError ] = useState(null),
    linkUrl = "hiro://library/" + encodeURIComponent(props.match.params.product) + "/" + encodeURIComponent(props.match.params.key);

  let loadProduct = async () => {
    let res = await api.product.getToInstall(props.match.params.product, props.match.params.key);

    setLoading(false);
    
    if(!res || !res.success)
      setError((res && res.error) || "The install link is invalid, expired or has already been used.");

    if(res && res.product)
      setProduct(res.product);
    
    if(res && res.success && isMobile)
      window.location.href = linkUrl;
  };

  useEffect(() => {
    loadProduct();
  }, [ props.match.params ]);

  if(!isMobile)
    return <div className="install-page desktop">
        <SVG src={toAbsoluteUrl("/media/logos/logo.svg")} className="logo" />
        <p>Point the camera on your phone at the QR code below.</p>
        <QRCode value={window.location.href}/>
        <p>Alternatively, you can open the email on your phone and tap the access button.</p>
        <p>Need Help? Check out <a href="https://hiro.fm/kb/hiro-mobile-app-overview/" className="text-decoration-underline">the step-by-step instructions</a>.</p>
      </div>;

  if(loading)
    return <div className="app-page loading spinner spinner-blue"></div>;
  
  return (
    <>
      <div className={"app-page " + (isMobile ? "" : "desktop")}>
        <div>
          <SVG src={toAbsoluteUrl("/media/logos/logo.svg")} className="logo mb-10" />
          {error
          ? (
            <>
              <h3>Something went wrong</h3>
              <p className="mb-5">{error}</p>
              {product
              ? <p>Need Help? Please email {product.name} team at <a href={"mailto:" + product.email} className="text-decoration-underline">{product.replyToEmail}</a>.</p>
              : <p>Need Help? Check out <a href="https://hiro.fm/kb/hiro-mobile-app-overview/" className="text-decoration-underline">the step-by-step instructions</a>.</p>}
            </>
          )
          : (
            <>
              <div className="container mt-lg-20">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <img src="/media/logos/app.png" className="app-logo mb-5"/>
                    <h3 className="mb-5">You need the Hiro player app first</h3>
                    <p className="mb-5">Let’s add the Hiro player app to your phone first. This takes a few seconds. Tap the button below to get the free app.</p>
                    {isIOS
                    ? (
                      <a href="https://apps.apple.com/app/hiro-fm/id1625187269" className="btn btn-dark-gray btn-install-player">
                        Get it on the App Store
                      </a>
                    )
                    : <></>}
                    {isAndroid
                    ? (
                      <a href="https://play.google.com/store/apps/details?id=fm.hiro.player" className="btn btn-dark-gray btn-install-player">
                        Get it on Play Store
                      </a>
                    )
                    : <></>}
                    <p className="mb-5 mt-13">If you have the Hiro app, go ahead and add this show to your library.</p>
                    <a href={linkUrl} className="btn btn-primary btn-install-player">Add show to library</a>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AppInstall;