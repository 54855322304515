import React, { useEffect, useState, Fragment, useRef } from "react";
import { isValidUrl, toAbsoluteUrl } from "../../../_metronic/_helpers";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import actions from "../../../redux/actions";
import SVG from "react-inlinesvg";
import api from "../../../redux/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { getInputClassName, toastMessage } from "../../helpers";
import _ from "lodash";
import ProductDropdown, { SelectedProduct } from "../../layout/components/ProductDropdown";
import Preview from "../../layout/components/Preview";
import Datetime from "react-datetime";
import UnsavedFormGuard from "../../layout/components/UnsavedFormGuard";
import { PhoneNumberModalEvents } from "../modals/PhoneNumberModal";
import { NeedsUpgradeEvents } from "../modals/NeedsUpgrade";
import MessageField from "../../layout/components/MessageField";
import { SegmentedMessage } from "sms-segments-calculator";
import TagDropdown from "../../layout/components/TagDropdown";
import { EditTagModal } from "../contacts/Tags";

const validationSchema = Yup.object().shape({
  text: Yup.string(),
  attachment: Yup.string(),
  //products: Yup.array().nullable(),
  //tags: Yup.array().nullable(),
  sendDate: Yup.string().nullable().test("sendDate", "The date is invalid.", function(value) {
    return !value || moment(value, true).isValid();
  }),
  //status: Yup.string().nullable()
}).test(
  "textOrAttachment",
  "Text or attachment is required.",
  value => _.has(value, "text") || _.has(value, "attachment")
);

const emptyObject = {
  text: "",
  attachment: "",
  products: [],
  tags: [],
  sendDate: null,
  status: ""
};

function inputClass(formik, name) {
  if(!_.get(formik.touched, name))
    return "";
  if(_.get(formik.errors, name))
    return "is-invalid";
  return "is-valid";
}

function error(formik, name, className = "") {
  if(_.get(formik.touched, name) && _.get(formik.errors, name))
    return (
      <div className={"field-error " + className}>{_.get(formik.errors, name)}</div>
    );
}

function getTagCount(name, user) {
  let tag = user.tags.find(t => t.name == name);
  return tag
    ? tag.contactsCount
    : 0;
}

let previewTimer,
  initialValues = emptyObject;

function EditCampaign({ user, products, match, dispatch, fulfillUser, phoneNumber }) {
  const [loading, setLoading] = useState(match.params.id),
    [saving, setSaving] = useState(false),
    [previewBody, setPreviewBody] = useState(),
    [cost, setCost] = useState(null),
    [totalContacts, setTotalContacts] = useState(null),
    [showPreviewCard, setShowPreviewCard] = useState(false),
    [showCreateTagModal, setShowCreateTagModal] = useState(false),
    [attachmentIsValid, setAttachmentIsValid] = useState(),
    [attachmentIsLoading, setAttachmentIsLoading] = useState(),
    [attachmentError, setAttachmentError] = useState(),
    attachmentValidationTimerRef = useRef(),
    attachmentInputRef = useRef(),
    history = useHistory(),
    duplicate = !!match.path.match(/\/duplicate/),
    formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: data => submitForm(data, "save"),
      validateOnChange: true,
      validateOnMount: true,
      enableReinitialize: true
    });

  async function submitForm(data, loading = true) {
    setSaving(loading);

    data.phoneNumberId = phoneNumber.id;
    data.isSms = phoneNumber.kind == "sms";
    data.isWhatsapp = phoneNumber.kind == "whatsapp";

    let res = !duplicate && match.params.id
      ? await api.sms.updateCampaign(match.params.id, data)
      : await api.sms.createCampaign(data);

    setSaving(false);

    if(res && res.success) {
      toastMessage.success("Campaign " + (match.params.id ? "updated!" : "created!"));

      //res = await api.auth.getUserByToken(true);
      //if(res)
      //  dispatch(fulfillUser(res.data));

      history.push("/campaigns", { ignorePrompt: true });
    } else {
      toastMessage.error(res.error || "Unable to save the campaign. Please, try again later.");
    }
  }

  async function save() {
    await submitForm(formik.values, "modal");
  }

  async function saveDraftOnClick() {
    await submitForm({
      ...formik.values,
      status: "draft"
    }, "draft");
  }

  async function sendOnClick() {
    await submitForm({
      ...formik.values,
      status: "pending"
    }, "send");
  }

  async function loadData() {
    if(match.params.id) {
      let res = await api.sms.getCampaign(match.params.id);

      if(!res || !res.success) {
        toastMessage.error(res.error || "Unable to retreive the campaign details.");
        history.push("/campaigns", { ignorePrompt: true });
        return;
      }

      initialValues = {
        text: res.data.text,
        attachment: res.data.attachment,
        products: res.data.products,
        sendDate: res.data.sendDate,
        status: res.data.status,
        tags: res.data.tags
      };
    } else {
      initialValues = emptyObject;
    }

    formik.resetForm({ values: initialValues });
    if(attachmentInputRef.current)
      attachmentInputRef.current.value = initialValues.attachment;

    setLoading(false);
  }

  function updatePreview() {
    let { text, attachment } = formik.values;

    if(text)
      text = text.replace(/\[name(\|.+?)?\]/g, "John Carter")
        .replace(/\[email\]/g, "john@test.com")
        .replace(/\[first-name(\|.+?)?\]/g, "John");

    setPreviewBody(
      <>
        <div className={"message-out" + (text || attachment ? "" : " empty")} key={_.random()}>
          {!!attachment && (
            <div className="attachment" style={{ backgroundImage: "url(" + attachment + ")" }} />
          )}
          {!!text && (
            <>
              <em>{(user && user.firstName) || "You"}</em>
              {text.split(/\n/g).map((line, i) => <Fragment key={i}>{line}<br /></Fragment>)}
            </>
          )}
          {!text && !!attachment && (
            <>
              <em className="mb-0">{(user && user.firstName) || "You"}</em>
            </>
          )}
          {!text && !attachment && (
            <>
              <span />
              <span />
            </>
          )}
        </div>
      </>
    );
  }

  async function updateCost() {
    console.log(phoneNumber);
    let message = formik.values.text || "",
      cost = message && message.trim() && phoneNumber?.kind != "whatsapp"
        ? new SegmentedMessage(message).segmentsCount
        : null,
      res = await api.sms.getContactsCount({
        phoneNumberId: phoneNumber?.id,
        products: formik.values.products,
        tags: formik.values.tags
      }),
      listeners = res && res.success
        ? res.data
        : null;

    setCost(cost);
    setTotalContacts(listeners);
  };

  function attachmentOnInput() {
    clearTimeout(attachmentValidationTimerRef.current);
    setAttachmentIsValid(null);
    setAttachmentError(null);
    formik.setFieldValue("attachment", "");

    let value = attachmentInputRef.current.value;
    if(value)
      attachmentValidationTimerRef.current = setTimeout(() => validateAttachment(value), 500);
  }

  async function validateAttachment(url) {
    setAttachmentIsLoading(true);

    let res = await api.generic.validateAttachment(url);

    setAttachmentIsLoading(false);

    if(!res)
      return toastMessage.error("Unable to connect to the server.");

    if(!res.success) {
      setAttachmentIsValid(false);
      setAttachmentError(res.error || "Unable to validate the image.");
      return;
    }

    if(res.size > 600) {
      setAttachmentIsValid(false);
      setAttachmentError("The image is too large. Please use an image smaller than 600KB.");
      return;
    }

    setAttachmentIsValid(true);
    formik.setFieldValue("attachment", url);
  }

  useEffect(() => {
    updateCost();
    clearTimeout(previewTimer);
    previewTimer = setTimeout(updatePreview, 800);
  }, [formik.values, attachmentIsValid]);

  useEffect(() => {
    if(!user)
      return;

    loadData();

    if(!user.planFeatures.campaigns)
      NeedsUpgradeEvents.dispatchShowForSms();
    else if(!user.phoneNumbers.length)
      PhoneNumberModalEvents.dispatchShowSmsOrWhatsapp();
  }, [user, match]);

  if(!user)
    return <></>;

  return (
    <>
      <EditTagModal autoReload onHide={() => setShowCreateTagModal(false)} show={showCreateTagModal} />

      <h1>
        <Link to="/campaigns" className="btn btn-back">
          <SVG src={toAbsoluteUrl("/media/def-image/icons/back.svg")} />
        </Link>
        {!duplicate && match.params.id ? "Edit" : "New"} {phoneNumber?.kind == "whatsapp" ? "WhatsApp" : ""} campaign
      </h1>

      {loading
        ? (
          <div className="spinner spinner-full"></div>
        )
        : (
          <div className="form-with-preview">
            <form onSubmit={formik.handleSubmit}>
              <div className="contents">
                {showPreviewCard
                  ? (
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title m-0">Campaign Details</h3>
                      </div>
                      <div className="card-body">
                        <div className="mb-9">
                          <label className="d-block fw-bold">Message</label>
                          {formik.values.text
                            ? (
                              <>
                                {formik.values.text.split(/\n/g).map((line, i) => <Fragment key={i}>{line}<br /></Fragment>)}
                              </>
                            )
                            : "-"}
                        </div>

                        {phoneNumber?.kind != "whatsapp" && (
                          <div className="mb-9">
                            <label className="d-block fw-bold">Attachment</label>
                            {formik.values.attachment
                              ? (
                                <div className="attachment-preview" style={{ backgroundImage: "url(" + formik.values.attachment + ")" }} />
                              )
                              : "-"}
                          </div>
                        )}

                        <div className="mb-9">
                          <label className="d-block fw-bold">Send to</label>
                          {!formik.values.products.length && !formik.values.tags.length ? "-" : ""}

                          {phoneNumber?.kind == "whatsapp" && (
                            <div className="alert whatsapp-alert">
                              <SVG src="/media/def-image/icons/info-base.svg" />
                              Messages will be sent only to contacts who have opted-in using WhatsApp.
                            </div>
                          )}

                          <div className="d-flex items-center flex-wrap">
                            {formik.values.products.map((id, i) => <SelectedProduct key={i} products={products} productId={id} />)}
                          </div>

                          <div className="d-flex items-center flex-wrap">
                            {formik.values.tags.map((tag, i) => <span className="badge lg mr-2" key={i}>{tag} <small>({getTagCount(tag, user)})</small></span>)}
                          </div>
                        </div>

                        <div className="mb-9">
                          <label className="d-block fw-bold">Send time</label>
                          {formik.values.sendDate
                            ? moment(formik.values.sendDate).locale("en").format("MMM Do, YYYY h:mm A")
                            : "Message will be sent immediately"}
                        </div>

                        <div className="mb-9">
                          <label className="d-block fw-bold">Estimated credits</label>
                          <div className="row">
                            <div className="col-auto">
                              <label>Total contacts</label>
                              <div>
                                {totalContacts !== null
                                  ? totalContacts
                                  : "-"}
                              </div>
                            </div>
                            <div className="col-auto">
                              <label>Total cost</label>
                              <div>
                                {cost !== null && totalContacts !== null
                                  ? cost * totalContacts
                                  : "-"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-footer d-flex">
                        <button type="button" className="btn btn-secondary" onClick={() => setShowPreviewCard(false)}>Edit</button>
                        <div className="fill" />
                        <button type="button" className={"btn btn-secondary mr-2 " + (saving == "draft" ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)} onClick={saveDraftOnClick}>Save As Draft</button>
                        <button type="button" className={"btn btn-primary " + (saving == "send" ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)} onClick={sendOnClick}>Send</button>
                      </div>
                    </div>
                  )
                  : (
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title m-0">Compose Campaign</h3>
                      </div>
                      <div className="card-body">
                        <div className="form-group">
                          <div className="row align-items-center mb-3">
                            <div className="col">
                              <label className="m-0 form-label">SMS Message</label>
                            </div>
                            {cost !== null && <div className="col text-right sms-cost">Message costs <strong>{cost}</strong> credit</div>}
                          </div>
                          <MessageField className="fw" formik={formik} name={"text"} emoji personalization={phoneNumber?.kind != "whatsapp"} disablePersonalizationField={["install-url"]} />
                          {!!formik.errors.textOrAttachment && <div className="field-error">{formik.errors.textOrAttachment}</div>}
                        </div>

                        {phoneNumber?.kind != "whatsapp" && (
                          <div className="form-group attachment-field-group">
                            <label className="form-label">
                              Embed Image or Giphy<br />
                              <span className="help-text">Paste the URL below.</span>
                            </label>
                            {attachmentIsLoading && <div className="spinner spinner-sm attachment-spinner" />}
                            <input type="text" className={"form-control " + (attachmentIsValid === true ? "is-valid" : "") + (attachmentIsValid === false ? "is-invalid" : "")} defaultValue={formik.values.attachment} ref={attachmentInputRef} onInput={attachmentOnInput} />
                            {attachmentError && <div className="field-error">{attachmentError}</div>}
                          </div>
                        )}

                        <div className="form-group">
                          <div className="row align-items-center mb-3">
                            <div className="col">
                              <label className="m-0 form-label">Send to</label>
                            </div>
                            <div className="col text-right">
                              <button type="button" disabled={formik.values.products?.length == user.products.length} className="btn h-auto btn-primary-transparent" onClick={() => formik.setFieldValue("products", user.products.map(p => typeof p == "string" ? p : p._id))}>
                                <SVG src="/media/def-image/icons/check-circle-2.svg" /> Select all
                              </button>
                            </div>
                          </div>
                          {phoneNumber?.kind == "whatsapp" && (
                            <div className="alert whatsapp-alert">
                              <SVG src="/media/def-image/icons/info-base.svg" />
                              Messages will be sent only to contacts who have opted-in using WhatsApp.
                            </div>
                          )}
                          <ProductDropdown multiple value={formik.values.products} onChange={selected => formik.setFieldValue("products", selected)} />
                          {error(formik, "products")}
                        </div>

                        <div className="form-group">
                          <label className="form-label">Tags</label>
                          <TagDropdown onCreateTagRequest={() => setShowCreateTagModal(true)} multiple value={formik.values.tags} onChange={value => formik.setFieldValue("tags", value)} />
                          {formik.touched.tags && formik.errors.tags && <div className="field-error">{formik.errors.tags}</div>}
                        </div>

                        <div className="form-group">
                          <label className="form-label">Send time</label>
                          <div className="row">
                            <div className="col-auto">
                              <div className="btn-group">
                                <button type="button" className={"btn " + (!formik.values.sendDate ? "btn-active" : "")} onClick={() => formik.setFieldValue("sendDate", null)}>Send Now</button>
                                <button type="button" className={"btn " + (!!formik.values.sendDate ? "btn-active" : "")} onClick={() => formik.setFieldValue("sendDate", new Date().toISOString())}>Send Later</button>
                              </div>
                            </div>
                            {!!formik.values.sendDate && (
                              <div className="col-auto">
                                <Datetime locale="en" value={formik.values.sendDate ? moment(formik.values.sendDate) : ""} className={"input-date m-0 open-up " + inputClass(formik, "sendDate")} onChange={val => val && formik.setFieldValue("sendDate", val.toISOString())} inputProps={{ readOnly: true, className: "form-control" }} />
                              </div>
                            )}
                          </div>
                          {error(formik, "sendDate")}
                        </div>
                      </div>

                      <div className="card-footer text-right d-block">
                        <button type="button" className={"btn btn-secondary mr-2 " + (saving == "draft" ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)} onClick={saveDraftOnClick}>Save As Draft</button>
                        <button type="button" className={"btn btn-primary"} onClick={() => setShowPreviewCard(true)}>Preview</button>
                      </div>
                    </div>
                  )}
              </div>
            </form>

            {!showPreviewCard && (
              <Preview title="Message Preview" className="chat" scroll>
                <div className="list">
                  {previewBody}
                </div>
              </Preview>
            )}
          </div>
        )}

      <UnsavedFormGuard formik={formik} onSaveAsync={save} loading={saving == "modal"} />
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
      phoneNumber: state.phoneNumber.current
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      ...actions.phoneNumber,
      dispatch
    })
  )(withRouter(EditCampaign))
);
