import { useFetch } from "../../app/helpers";

export default {
  login: (user, password) => useFetch.post("/admin-page/auth", { user, password }),
  getUsers: (page, filter) => useFetch.get("/admin-page/users", { page, ...filter }),
  sendNotification: data => useFetch.post("/admin-page/notifications", { ...data }, true, true),
  sendAppNotification: data => useFetch.post("/admin-page/app-notifications", data, true, true),
  getNotifications: (page) => useFetch.get("/admin-page/notifications", { page }),
  getEventsForm: () => useFetch.get("/admin-page/events-form"),
  setEventsForm: data => useFetch.post("/admin-page/events-form", { ...data }, true, true),
  getEventsLog: filter => useFetch.get("/admin-page/events-log", filter),
  getAIUsers: page => useFetch.get("/admin-page/users", { page, ai: true }),
};
