import React, { useEffect, useState } from "react";
import { Message, Writting } from "../../modals/onboarding/common";
import PodcastTemplate from "./podcast/PodcastTemplate";
import WebinarTemplate from "./webinar/WebinarTemplate";

export default function WelcomeMessage({ user, next, state }) {
    const [answer, setAnswer] = useState(null),
        [loading, setLoading] = useState(false);

    const answerText = {
        podcast: "A podcast episode",
        webinar: "A webinar"
    };

    const templates = {
        podcast: PodcastTemplate,
        webinar: WebinarTemplate
    };

    const submit = (ev, template) => {
        ev.preventDefault();

        setAnswer(template);
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
            next(templates[template], { template });
        }, 2000);
    };

    return (
        <>
            {state.restarted
                ? (
                    <>
                        <Message incoming>
                            Start a new project
                        </Message>

                        <Message delay={1500}>
                            Ok!
                        </Message>
                    </>
                )
                : (
                    <>
                        <Message delay={1500}>
                            Hi, {user.firstName}!
                        </Message>
                        <Message delay={2300}>
                            I’m Hiro Bot and I’m here to help you create content with AI!
                        </Message>
                    </>
                )}

            <Message delay={state.restarted ? 2300 : 3100}>
                What do you want to create?
            </Message>

            {!answer && <Message incoming delay={state.restarted ? 2800 : 3600} custom>
                <div className="options flex-nowrap">
                    <a href="#" onClick={ev => submit(ev, "podcast")}>A podcast episode</a>
                    <a href="#" disabled>A webinar (Coming soon)</a>
                    <a href="#" disabled>A book (Coming soon)</a>
                </div>
            </Message>}

            {answer && <Message incoming>{answerText[answer]}</Message>}

            {loading && <Writting delay={1000} />}
        </>
    );
}
