import React from "react";

const colors = {
    "A": "green",
    "B": "yellow",
    "C": "blue",
    "D": "teal",
    "E": "pink",
    "F": "orange",
    "G": "yellow",
    "H": "teal",
    "I": "pink",
    "J": "orange",
    "K": "green",
    "L": "blue",
    "M": "pink",
    "N": "yellow",
    "O": "orange",
    "P": "green",
    "Q": "teal",
    "R": "blue",
    "S": "pink",
    "T": "orange",
    "U": "green",
    "V": "teal",
    "W": "yellow",
    "X": "blue",
    "Y": "pink",
    "Z": "orange",
    "0": "green",
    "1": "teal",
    "2": "yellow",
    "3": "pink",
    "4": "orange",
    "5": "blue",
    "6": "green",
    "7": "pink",
    "8": "teal",
    "9": "yellow"
};

export default function UserPicture({ user, className = "" }) {
    let letter,
        color;

    if(user) {
        let name = user.superUser
            ? user.companyName
            : user.firstName;

        letter = (name || user.email || "").trim().toUpperCase()[0];
        color = colors[letter];
    }

    if(!user.pictureUrl && !letter)
        return (
            <div className={"profile-picture profile-placeholder " + className} />
        );

    return (
        user.pictureUrl
            ? <img src={user.pictureUrl} className={"profile-picture " + className} />
            : (
                <div className={"profile-picture profile-placeholder " + (color ? "color-" + color : "") + " " + className}>
                    {letter}
                </div>
            )
    );
}