import actionTypes from "../action-types";

export const setLogin = (authToken,user) => ({
  type: actionTypes.auth.Login,
  payload: { authToken,user },
});
export const setAdminLogin = (token, user) => ({
  type: actionTypes.auth.AdminLogin,
  payload: { adminAuthToken: token, admin: user }
});
// export const requestPassword = (email) => ({
//   type: actionTypes.auth.SetNewPassword,
//   payload: { email },
// });
export const register = (data) => ({
  type: actionTypes.auth.Register,
  payload: data,
});
export const logout = () => ({ type: actionTypes.auth.Logout });
export const requestUser = (user) => ({
  type: actionTypes.auth.UserRequested,
  payload: { user },
});
export const fulfillUser = (user) => ({
  type: actionTypes.auth.UserLoaded,
  payload: { user },
});
export const setUser = (user) => ({
  type: actionTypes.auth.SetUser,
  payload: { user },
});
export const getUserByToken = () => ({
  type: actionTypes.auth.GetUserByToken,
  payload: {},
});
