import React, { useState, useEffect, useRef } from 'react';
import StyledDropzone from './StyledDropzone';
import UploadProgress from './UploadProgress';

export default props => {
  const [filesToUpload, _setFilesToUpload] = useState([]);
  const filesToUploadRef = useRef(filesToUpload);
  const [areThereValidFiles, setAreThereValidFiles] = useState(false);

  //Pass the reset function to whatever is set on the attribute resetRef
  if(typeof props.resetRef == "function") props.resetRef(() => {
    setFilesToUpload([]);
  });

  const setFilesToUpload = data => {
    filesToUploadRef.current = data;

    var found = false;
    for(var i = 0; i < data.length; i++) {
      if(data[i].isValid) {
        found = true;
        break;
      }
    }
    setAreThereValidFiles(found);

    _setFilesToUpload(data);
  };

  useEffect(() => startNextUpload(), [filesToUpload]);

  /**
   *
   * @param files:
   *    files[i].id is a uuid to use as key on the map() in the render
   *    files[i].file is the file object
   * This method also adds a
   *    files[i].uploadComplete field
   *    files[i].canStartUpload field
   */
  const addFilesToUpload = async (files) => {
    //If multiple uploads aren't allowed, abort the current upload (if any) first
    if(props.allowMultipleUpload === false && filesToUploadRef.current.length) {
      //UploadProgress will abort automatically
      filesToUploadRef.current = [];
    }

    files = files.map(f => ({ ...f, uploadComplete: false, canStartUpload: false }));

    for(const audio of files) {
      try {
        audio.durationInSeconds = await computeDuration(audio.file);
      } catch(e) {
        audio.durationInSeconds = null;
      }
    }

    setFilesToUpload([...filesToUploadRef.current, ...files]);
  };

  const computeDuration = (file) => {
    return new Promise((resolve, reject) => {
      try {
        if(!URL.errors) {
          let
            objectURL = URL.createObjectURL(file),
            mySound = new Audio([objectURL]);

          setTimeout(() => {
            reject("could not compute duration");
          }, 2000);

          mySound.addEventListener(
            "canplaythrough",
            () => {
              URL.revokeObjectURL(objectURL);
              resolve(mySound.duration);
            },
            false,
          );
        }
      } catch(e) {
        console.error(e);
        resolve("N/A");
      }
    });
  };

  const onUploadComplete = (id, amazonS3Url) => {
    const finishedItem = filesToUploadRef.current.find(f => f.id === id);
    if(finishedItem) {
      finishedItem.uploadComplete = true;
      finishedItem.amazonS3Url = amazonS3Url;
      if(props.onUploadComplete) props.onUploadComplete([...filesToUploadRef.current.filter(f => f.uploadComplete === true)]);
    }

    startNextUpload();
  };

  const startNextUpload = () => {
    const files = filesToUploadRef.current;
    const uploadInProgress = files.filter(f => f.canStartUpload === true && f.uploadComplete === false);
    if(uploadInProgress && uploadInProgress.length > 0) {
      //nothing to do, file upload is in progress
    } else {
      const nextFileToUpload = files.find(f => f.isValid === true && f.uploadComplete === false);
      if(nextFileToUpload) {
        nextFileToUpload.canStartUpload = true;
        setFilesToUpload([...files]);
      }
    }
  };

  return <div className={(props.classname || "") + (props.disabled ? " dropzone-disabled" : "")} style={props.style}>
    <StyledDropzone
      validateStorage={props.validateStorage}
      user={props.user}
      addFilesToUpload={addFilesToUpload}
      allowMultipleUpload={props.allowMultipleUpload}
      forceSquareImageFormat={props.forceSquareImageFormat}
      onError={props.onError}
      fileType={props.fileType}
      maxSize={props.maxSize}
      errorMessage={props.errorMessage}
      description={props.description}
      childDom={props.children}
      disabled={props.disabled}
      uploadProgress={areThereValidFiles && filesToUpload.map(file => (file.isValid && !file.uploadComplete) && <UploadProgress
        isValid={true}
        key={file.id}
        id={file.id}
        file={file.file}
        data={file.data}
        canStartUpload={file.canStartUpload}
        onUploadComplete={onUploadComplete}
        uploadType={props.uploadType}
        isHideProgress={props.customContent || props.isHideProgress}
      />).filter(e => !!e)}
    >
      {props.children}
    </StyledDropzone>
  </div>;
};
