import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import api from "../../../redux/api";
import WelcomeMessage from "./onboarding/WelcomeMessage";
import SVG from "react-inlinesvg";
import actions from "../../../redux/actions";
import { useLocation } from "react-router-dom";
import { useCustomEvent } from "../../helpers/use-event";

function Onboarding({ user, dispatch, fulfillUser, setProducts, setLogin }) {
    let [show, setShow] = useState(false),
        [messageComponents, setMessageComponents] = useState([]),
        [state, setState] = useState({}),
        [complete, setComplete] = useState(false),
        location = useLocation(),
        scrollRef = useRef(),
        { trigger: triggerUpgradedToAgency } = useCustomEvent("upgradedToAgency");

    let next = useCallback((component = null, state = null) => {
        if(state)
            setState(s => ({ ...s, ...state }));

        if(component) {
            setMessageComponents(arr => [...arr, component]);
        } else {
            api.user.onboardingStep("complete");
            setComplete(true);
        }
    });

    let scroll = () => {
        if(scrollRef.current)
            scrollRef.current.view.scrollTo({ top: scrollRef.current.view.scrollHeight, behavior: "smooth" });
    };

    let update = async () => {
        let res = await api.auth.getUserByToken(true);
        if(res)
            dispatch(fulfillUser(res.data));

        res = await api.product.getProducts();
        if(res)
            dispatch(setProducts(res));
    };

    function done() {
        setShow(false);
        if(user.owner && user.owner.totalSubAccountsLimit == 5 && user.owner.subAccountsIds.length == 1)
            triggerUpgradedToAgency();
    }

    useEffect(() => {
        scroll();
    }, [messageComponents, complete]);

    useEffect(() => {
        if(!user || !window.location.hash.match(/onboarding/) || (user.owner && !user.owner.onboarding.active) || (!user.owner && !user.onboarding.active) || show)
            return;

        setShow(true);
        setMessageComponents([WelcomeMessage]);
        setState({});
        setComplete(false);
    }, [user, location.pathname]);

    return (
        <Modal centered show={show} size="lg" className="onboarding-modal" backdropClassName="onboarding-modal-backdrop">
            <Modal.Body>
                {complete && (
                    <button type="button" className="close" onClick={() => done()}><SVG src="/media/def-image/close.svg" /></button>
                )}
                <Scrollbars
                    ref={scrollRef}
                    hideTracksWhenNotNeeded
                    className="scrollbar-view"
                    renderTrackVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="scrollbar-track" />}
                    renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="scrollbar-thumb" />}>
                    <div className="messages">
                        {messageComponents.map((Comp, i) => (
                            <Comp dispatch={dispatch} setLogin={setLogin} user={user} next={next} key={i} last={i == messageComponents.length - 1} state={state} scroll={scroll} update={update} />
                        ))}
                        {complete && (
                            <div className="text-center mt-9">
                                <button type="button" onClick={() => done()} className="btn btn-primary">Let’s Do This!</button>
                            </div>
                        )}
                    </div>
                </Scrollbars>
            </Modal.Body>
        </Modal>
    );
}

export default injectIntl(connect(
    state => ({
        user: state.auth.user,
    }),
    (dispatch) => ({
        ...actions.product,
        ...actions.auth,
        dispatch,
    })
)(Onboarding));
