import { useFetch } from "../../app/helpers";

export default {
  list: () => useFetch.get("/automations"),
  get: id => useFetch.get("/automations/" + id, null, true, true),
  getDetails: (id, offset = 0) => useFetch.get("/automations/" + id + "/details?offset=" + offset, null, true, true),
  create: data => useFetch.post("/automations", { data }, true, true),
  update: (id, data) => useFetch.put("/automations/" + id, { data }, true, true),
  delete: id => useFetch.delete("/automations/" + id, null, true, true),
  testWebhook: (url, data) => useFetch.post("/automations/test-webhook/", { url, data }, true, true),
  duplicate: id => useFetch.post("/automations/" + id + "/duplicate", null, true, true),
  toggleActive: (id, active) => useFetch.put("/automations/" + id + "/toggle-active", { active }, true, true)
};
