import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../redux/actions";
import api from "../../../redux/api";
import { toastMessage } from "../../helpers";
import { NeedsUpgradeEvents } from "../../pages/modals/NeedsUpgrade";
import { AIEvents } from "./AIChat";
import { UpgradeEvents } from "../../pages/modals/UpgradeModal";
import { OpenAiModalEvents } from "../../pages/modals/OpenAiModal";

function AIButton({ user, className, onClick, prompt, include, source, partialPrompt, chat, image, data, onCopy, onResponse, loading: loadingProp, dispatch, fulfillUser }) {
    const [loading, setLoading] = useState(false);

    async function updateUser() {
        dispatch(fulfillUser((await api.auth.getUserByToken(true)).data));
    }

    async function buttonOnClick() {
        if(!user.planFeatures.canUseHiroOpenAiKey && !user.openAiKey)
            return OpenAiModalEvents.dispatchShowMissingKey();

        if(onClick)
            return onClick();

        if(loading)
            return;

        if(image) {
            // if(!user.planFeatures.aiCover)
            //     //return NeedsUpgradeEvents.dispatchShow();
            //     return UpgradeEvents.dispatchShow();

            setLoading(true);

            const res = await api.ai.generateArtwork(data || {});

            updateUser();

            setLoading(false);

            if(!res || !res.success) {
                if(res.needsUpgrade)
                    return;

                if(res.insufficientCredits)
                    return;

                return toastMessage.error((res && res.error) || "Unable to connect to the server.");
            }

            if(onResponse)
                onResponse(res.data.url);

            return;
        }

        if(chat) {
            // if(!user.planFeatures.aiAssistant)
            //     //return NeedsUpgradeEvents.dispatchShow();
            //     return UpgradeEvents.dispatchShow();

            AIEvents.dispatchShowChat({
                prompt,
                include,
                data,
                source,
                partialPrompt,
                onCopy,
                onResponse
            });
        }
    }

    return (
        <button type="button" className={"btn btn-ai " + (loading || loadingProp ? "loading spinner " : "") + (className || "")} onClick={buttonOnClick}>
            <SVG src="/media/def-image/icons/ai.svg" />
            AI
        </button>
    );
}

export default injectIntl(
    connect(
        store => ({
            user: store.auth.user
        }),
        dispatch => ({
            ...actions.auth,
            dispatch
        })
    )(AIButton));