import _ from "lodash";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import api from "../../../../redux/api";
import { toastMessage } from "../../../helpers";
import utils from "../utils";

function Webhook({ automationId, submit, name, formik, saving, trigger, triggerIndex, actionIndex, remove, setPreviewBody, setPreviewSettings }) {
  let { set, get, error, props, touched } = utils.useFormikUtils(formik, name),
    [testLoading, setTestLoading] = useState(false);

  let testOnClick = async ev => {
    let url = get("url");

    if(!url || !url.trim() || !url.match(/^https?:\/\/[^\?\/]+(\..+)+/))
      return toastMessage.error("Enter a valid URL.");

    setTestLoading(true);

    let res = await api.automations.testWebhook(url, { trigger, automation: formik.values });

    setTestLoading(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success("Test data sent!");
  };

  useEffect(() => {
    set("changed", true);
  }, []);

  return (
    <>
      <div className="form-group">
        <label className="form-label">URL</label>
        <div className="d-flex">
          <input type="text" className="form-control fill" {...props("url")} onKeyDown={utils.disableIntro} />
          <button type="button" className={"btn btn-primary-light ml-5 " + (testLoading ? "loading spinner" : "")} onClick={testOnClick}>Test URL</button>
        </div>
        {error("url") && touched("url") && <div className="field-error">{error("url")}</div>}
      </div>

      <div className="form-group">
        <label className="form-label">Send Notification Email</label>
        <input type="text" className="form-control" {...props("email")} onKeyDown={utils.disableIntro} />
        {error("email") && touched("email") && <div className="field-error">{error("email")}</div>}
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      dispatch
    })
  )(Webhook)
);