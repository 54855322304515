import * as auth from "./auth-actions";
import * as product from "./product-actions";
import * as phoneNumber from "./phone-number-actions";
import * as user from "./user-actions";
import * as listener from "./listener-actions";
import * as episode from "./episode-actions";

export default {
  auth,
  product,
  user,
  listener,
  episode,
  phoneNumber
};