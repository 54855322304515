import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import Cropper from "react-easy-crop";

let currentCroppedArea = null;

function ImageCropper({ show, onHide, onSuccess, image }) {
  let [ crop, setCrop ] = useState({ x: 0, y: 0 }),
    [ zoom, setZoom ] = useState(1),
    [ hideLabel, setHideLabel ] = useState(false);

  useEffect(() => {
    //reset
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setHideLabel(false);
  }, [ show ])

  let onCropComplete = (croppedArea, croppedAreaPixels) => {
    currentCroppedArea = croppedAreaPixels;
  }, 
  useCroppedImage = async () => {
    if(!currentCroppedArea) return;

    let res = await getCroppedImg(image, currentCroppedArea);
    if(!res) return;

    onSuccess(res);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      centered
      className="new-modals image-cropper-modal text-center">
        <Modal.Body>
          <div className="cropper-container" onMouseDown={ev => setHideLabel(true)}>
            <label className={hideLabel ? "hidden" : ""}>Drag to crop</label>
            <Cropper
              image={image}
              aspect={1}
              crop={crop}
              zoom={zoom}
              onCropChange={v => setCrop(v)}
              onZoomChange={v => setZoom(v)}
              onCropComplete={onCropComplete}
            />
          </div>
          <div className="zoom-container d-flex justify-content-center align-items-center mt-5">
            <span>-</span>
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={ev => setZoom(ev.target.value)}
              className="custom-range flex-1"
            />
            <span>+</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={onHide} className="btn btn-secondary mr-2">Cancel</button>
          <button onClick={useCroppedImage} className="btn btn-primary">Upload</button>
        </Modal.Footer>
    </Modal>
  );
}

let createImage = url =>
  new Promise((resolve, reject) => {
    let image = new Image()
    image.addEventListener("load", () => resolve(image))
    image.addEventListener("error", error => resolve(null))
    image.src = url;
  });

let getCroppedImg = async (imageSrc, pixelCrop) => {
  let image = await createImage(imageSrc),
    canvas = document.createElement("canvas"),
    ctx = canvas.getContext("2d");

  if(!image || !ctx) return null;

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  ctx.drawImage(image, -pixelCrop.x, -pixelCrop.y);

  return canvas.toDataURL("image/jpeg");  
};

export default ImageCropper;