import React, { useEffect,useState,useRef } from 'react';
import SVG from "react-inlinesvg";
import { ProductLogo } from "../../../_metronic/layout/components/productLogo";
import { formatLength } from "../../helpers";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

let callback,
    fadingOut=false;

export function Player(props) {
    let [product,setProduct]=useState(null),
        [episode,setEpisode]=useState(null),
        [currentTime,setCurrentTime]=useState(0),
        [progress,setProgress]=useState(0),
        [playing,setPlaying]=useState(true),
        [knobDrag,setKnobDrag]=useState(false),
        player=useRef(null),
        timeBar=useRef(null),
        [stopAt,setStopAt]=useState(null);

    let fadeOut=()=>{
        if(fadingOut) return;
        fadingOut=true;
        let fn=()=>{
            if(!player.current) return;
            if(player.current.volume>=.1) {
                player.current.volume-=.1;
                return setTimeout(fn,200);
            }
            player.current.currentTime=0;
            player.current.volume=1;
            setPlaying(false);
            fadingOut=false;
        };
        return setTimeout(fn,200);
    };
    
    useEffect(()=>{
        PlayerEvents.on("playEpisode",({ product,episode,options })=>{
            callback=typeof options=="object"?options.callback:null;
            setStopAt(typeof options=="object"?options.stopAt:null);
            setProduct(product);
            setEpisode(episode);
        });
        PlayerEvents.on("pausePlayer",()=>{
            setPlaying(false);
        });
        document.addEventListener("mouseup",ev=>{
            setKnobDrag(false);
        });
    },[]);

    useEffect(()=>{
        if(!player.current) return;
        if(playing) {
            player.current.play();
            if(typeof callback=="function") callback("playing",{ product,episode });
        } else {
            player.current.pause();
            if(typeof callback=="function") callback("paused",{ product,episode });
        }
    },[playing]);

    useEffect(()=>{
        if(!episode) return;

        setProgress(Math.min(100,currentTime*100/episode.publicAudio.durationInSeconds));

        if(stopAt&&stopAt<=currentTime)
            fadeOut();
    },[currentTime]);

    useEffect(()=>{
        if(!episode||!player.current) return;
        player.current.load();
        player.current.play();
        if(typeof callback=="function") callback("playing",{ product,episode });
    },[episode]);

    if(!product||!episode) {
        if(typeof callback=="function") callback("closed");
        return <></>;
    }

    let seekByClick=ev=>{
        if(!timeBar.current||!player.current||!episode) return;
        let relativeX=ev.clientX-timeBar.current.getBoundingClientRect().left;
        player.current.currentTime=episode.publicAudio.durationInSeconds*(relativeX/timeBar.current.clientWidth);
        setCurrentTime(player.current.currentTime);
    };

    return <div className="fixed-audio-player">
            <div className="episode-summary">
                <ProductLogo product={product}/>
                <div>
                    <span><strong>{episode.title}</strong></span>
                    <span>{product.name}</span>
                </div>
            </div>
            <div className="time-bar">
                <div className="time text-right">{formatLength(currentTime,true)}</div>
                <div className="bar" ref={timeBar}
                    onMouseDown={ev=>{
                        setPlaying(false);
                        setKnobDrag(true);
                    }}
                    onMouseUp={ev=>{
                        setPlaying(true);
                        seekByClick(ev);
                        setKnobDrag(false);
                    }}
                    onMouseMove={ev=>{
                        if(!knobDrag) return;
                        seekByClick(ev);
                    }}>
                        <div className="fill" style={{ width:progress+"%" }}></div>
                        <div className="knob" style={{ left:progress+"%" }}></div>
                </div>
                <div className="time">{formatLength(episode.publicAudio.durationInSeconds,true)}</div>
            </div>
            <div className="options">
                <button type="button" className="btn btn-close" onClick={()=>{
                    setProduct(null);
                    setEpisode(null);
                }}>
                    <SVG src={toAbsoluteUrl("/media/def-image/close.svg")} className="svg-icon"/>
                </button>
                <button type="button" className="btn btn-play-pause" onClick={()=>{
                    setPlaying(!playing);
                }}>
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Media/"+(playing?"Pause":"Play")+".svg")} className="svg-icon"/>
                </button>
            </div>
            <audio ref={player} autoPlay
                onEnded={()=>{
                    setPlaying(false);
                }}
                onTimeUpdate={(ev)=>{
                    setCurrentTime(ev.target.currentTime);
                }}>
                    <source src={episode.publicAudio.audioUrl} type="audio/mpeg" />
            </audio>
        </div>;
};

export const PlayerEvents={
    on(event,callback) {
        document.addEventListener(event,e=>callback(e.detail));
    },
    dispatch(event,data) {
        document.dispatchEvent(new CustomEvent(event,{ detail:data }));
    },
    /**
     * Emits the signal to start playing an episode on the background.
     * @param {Object} product 
     * @param {Object} episode 
     * @param {Object} [options]
     * @param {function} [options.callback]
     * @param {number} [options.stopAt] - In seconds.
     */
    dispatchPlayEpisode(product,episode,options=null) {
        this.dispatch("playEpisode",{ product,episode,options });
    },
    /**
     * Emits the signal to pause the player.
     */
    dispatchPause() {
        this.dispatch("pausePlayer",{});
    }
};
