import React from "react";
import SVG from "react-inlinesvg";

export default function StepNotifications({ formik }) {
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title m-0">New episode email notifications</h3>
                    <p className="text-muted">Automatically notify your listeners that a new episode is available. You can turn this off to all your listeners.</p>
                </div>

                <div className="card-body listener-access-email">
                    <div className="d-flex episode-access-options">
                        <label className={"option " + (!formik.values.disableNewEpisodeNotifications ? "active" : "")}>
                            <input type="radio" name="disableNewEpisodeNotifications" value={false} onChange={ev => formik.setFieldValue("disableNewEpisodeNotifications", !ev.target.checked)} />
                            <SVG src="/media/def-image/icons/email-check.svg" />
                            Send new episode emails
                        </label>

                        <label className={"option " + (formik.values.disableNewEpisodeNotifications ? "active " : "")}>
                            <input type="radio" name="disableNewEpisodeNotifications" value={true} onChange={ev => formik.setFieldValue("disableNewEpisodeNotifications", ev.target.checked)} />
                            <SVG src="/media/def-image/icons/email-times.svg" />
                            Don’t send new episode emails
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};