import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom/cjs/react-router-dom";

function OpenAiModal() {
  let [show, setShow] = useState(false);

  useEffect(() => {
    let handler = ev => {
      setShow(true);
    };

    OpenAiModalEvents.on("showOpenAiMissingKeyModal", handler);
    return () => OpenAiModalEvents.off("showOpenAiMissingKeyModal", handler);
  }, []);

  return <>
    <Modal show={show} onHide={() => setShow(false)} size="md" centered className="new-modals missing-openai-key-modal">
      <Modal.Body>
        <button type="button" className="close" onClick={() => setShow(false)}><SVG src="/media/def-image/close-sm.svg" className="svg-icon" /></button>
        <SVG src="/media/def-image/openai.svg" />

        <h1 className="mb-6">Add your OpenAI API Key</h1>

        <p>Connect your OpenAI account to access AI features.</p>
        
        <Link to="/user/integrations" className="btn btn-primary" onClick={() => setShow(false)}>Go to Settings</Link>
      </Modal.Body>
    </Modal>
  </>;
}

export default OpenAiModal;

export const OpenAiModalEvents = {
  on(event, callback) {
    document.addEventListener(event, e => callback(e.detail));
  },
  off(event, callback) {
    document.removeEventListener(event, callback);
  },
  dispatch(event, data = null) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  /**
   * Emits the signal to open the popup.
   */
  dispatchShowMissingKey(message) {
    this.dispatch("showOpenAiMissingKeyModal", { message });
  }
};