import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Link } from "react-router-dom";

export function Error() {
  return <div className="container episodes error">
    <div className="container-small">
      <div className="row">
        <div className="col-lg-12 col-xxl-12 no-episodes-item">
          <img alt="" src={toAbsoluteUrl("/media/def-image/404.png")} />
          <h3>Oops!</h3>
          <p className="mb-10">The page you are looking for is missing.</p>

          <Link to="/products" className="btn btn-primary">View All Products</Link>
        </div>
      </div>
    </div>
  </div>;
}
