import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import actionTypes from "../action-types";

const initialAuthState = {
  episodes: [],
  episode: {},
};

export const reducer = persistReducer(
  { storage, key: "episodes", whitelist: []},

  (state = initialAuthState, action) => {
    switch (action.type) {
    case actionTypes.episode.GetEpisodes: {
      return {
        ...state,
        episodes: action.payload.episodes,
      };
    }

    case actionTypes.episode.GetEpisode: {
      return {
        ...state,
        episode: action.payload.episode,
      };
    }

    default:
      return state;
    }
  }
);
