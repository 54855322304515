import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { notificationIcons } from "../../helpers";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import api from "../../../redux/api";
import AwsS3Dropzone from "../generic/AwsS3Dropzone";

export default function SendNotification() {
	let [ showConfirm, setShowConfirm ] = useState(false),
		[ loading, setLoading ] = useState(false),
		[ showSuccess, setShowSuccess ] = useState(false),
		[ error, setError ] = useState(null),
		[ imageUploadError, setImageUploadError ] = useState(null),
		formik = useFormik({
			initialValues: {
				sendTo: "",
				title: "",
				subtitle: "",
				icon: notificationIcons[0].key,
				actionType: "none",
				action_url: "",
				image: ""
			},
			onSubmit: async () => {
				setLoading(true);
				setShowSuccess(false);
				setError(null);
				
				let res = await api.admin.sendNotification(formik.values);
				
				setLoading(false);

				if(res.success) {
					setShowSuccess(true);
					formik.resetForm();
				} else {
					setError(res.error);
				}
			}
		});

	useEffect(() => {
		let to = new URLSearchParams(window.location.search).get("to");
		if(to) formik.setFieldValue("sendTo", to);
	}, []);

	let onImageUploadComplete = files => {
		if(files && files.length == 1)
			formik.setFieldValue("image", files[0].amazonS3Url);
	},
	onImageUploadError = () => {
		setImageUploadError("Invalid file");
	};

	return <div>
		<h1>Send notification (Web)</h1>

		{ showSuccess && <div className="alert alert-success">Notification sent.</div> }
		{ error && <div className="alert alert-warning">{error}</div> }

		<div className="card p-5">
			<form onSubmit={ev => {
				ev.preventDefault();
				setShowConfirm(true);
			}}>
				<label>Send to<br/><small>One ID per line, leave blank to send to everybody.</small></label>
				<textarea className="form-control form-control-sm h-150 mb-3" {...formik.getFieldProps("sendTo")}/>

				<label>Title</label>
				<input type="text" className="form-control form-control-sm mb-3" {...formik.getFieldProps("title")}/>

				<label>Subtitle</label>
				<input type="text" className="form-control form-control-sm mb-3" {...formik.getFieldProps("subtitle")}/>

				<label>Icon<br/><small>Only shown in the Notifications popup (not in the push notification).</small></label>
				<div className="d-flex notification-icons mb-3">
					{notificationIcons.map(item =>
						<div className="form-check mr-3" key={item.key}>
							<label for={"icon-"+item.key} className={"form-check-label notification-icon icon-"+item.key}>
								<SVG src={toAbsoluteUrl(item.icon)} className="svg-icon"/>
							</label>
							<input className="form-check-input" type="radio" name="icon" value={item.key} id={"icon-"+item.key} checked={formik.values.icon==item.key} onChange={ev => formik.setFieldValue("icon", ev.target.value)}/>
						</div>)}
				</div>

				<label>Image<br/><small>For the push notification. Optional.</small></label>
				<div className={"dropzone-artwork-container dropzone-field mb-3 " + (formik.values.image ? "uploaded" : "")}>	
					<AwsS3Dropzone
						allowMultipleUpload={false}
						onError={onImageUploadError}
						onUploadComplete={onImageUploadComplete}
						uploadType="images"
						fileType="image/png"
						errorMessage={imageUploadError}
						description="Select PNG file" />
					<div className="dropzone-uploaded-image">
						<div className="image-input image-input-outline" style={{ backgroundImage: formik.values.image ? "url(" + formik.values.image + ")" : "" }}>
							<span onClick={() => formik.setFieldValue("image", null)} className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove"><i className="ki ki-bold-close icon-xs text-muted" /></span>
						</div>
					</div>
				</div>

				<label>On click</label>
				<select className="custom-select form-control-sm mb-3" {...formik.getFieldProps("actionType")}>
					<option value="none">Do nothing</option>
					<option value="events">Go to Events tab</option>
					<option value="url">Go to URL</option>
				</select>

				{ formik.values.actionType=="url" && <>
					<label>URL</label>
					<input type="text" className="form-control form-control-sm mb-3" {...formik.getFieldProps("action_url")}/>
				</>}

				<button type="submit" className="btn btn-sm btn-primary mr-2" disabled={loading}>Send</button>
				<button type="button" className="btn btn-sm btn-secondary" onClick={ev => formik.resetForm()}>Reset</button>
			</form>
		</div>

		<Modal size="md" centered show={showConfirm} onHide={() => setShowConfirm(false)} className="admin-confirm-modal">
			<Modal.Body>Are you sure you want to continue? <strong>This action can't be stopped or undone.</strong></Modal.Body>
			<Modal.Footer>
				<button type="button" className="btn btn-sm btn-primary mr-2" onClick={ev => {
					setShowConfirm(false);
					formik.handleSubmit();
				}}>Yes</button>
				<button type="button" className="btn btn-sm btn-secondary" onClick={ev => setShowConfirm(false)}>No</button>
			</Modal.Footer>
		</Modal>
	</div>;
}
