import React, { useEffect, useState } from "react";
import api from "../../../../redux/api";
import { isValidEmail } from "../../../../_metronic/_helpers";
import { Message, Send, sleep, Writting } from "./common";
import ConfirmCodeMessage from "./ConfirmCodeMessage";

function ChangeEmailMessage({ next, state, scroll, update }) {
    let [email, setEmail] = useState(""),
        [invalid, setInvalid] = useState(false),
        [sent, setSent] = useState(false),
        [loading, setLoading] = useState(false),
        [error, setError] = useState(null);

    let emailOnChange = ev => {
        const email = ev.target.value || "";
        setEmail(email);
        setInvalid(!isValidEmail(email));
    };

    let emailOnKeyDown = ev => {
        if(ev.which == 13) {
            ev.preventDefault();
            submit();
        }
    };

    let submit = async () => {
        if(!email || !isValidEmail(email))
            return;

        setSent(true);
        setLoading(true);

        await sleep(1000);

        let res = await api.user.onboardingStep("step-email", {
            email
        });

        setLoading(false);

        if(!res || !res.success) {
            setError((res && res.error) || "I’m sorry, I wasn’t able to process that. Please, try again.");
            setTimeout(() => next(ChangeEmailMessage, { email }), 1000);
            return;
        }

        next(ConfirmCodeMessage, { email, codeSent: true });

        update();
    };

    useEffect(() => {
        scroll();
    }, [sent, loading, error]);

    return (
        <>
            <Message>
                What’s your email?
                {!sent && !loading && (
                    <>
                        <div className={"input wide " + (invalid ? "invalid " : "") + (email ? "" : " empty")}>
                            <input type="text" className="form-control" onChange={emailOnChange} onKeyDown={emailOnKeyDown} />
                            <Send onClick={submit} />
                        </div>
                        {!state.email && <small>Press enter to send</small>}
                    </>
                )}
            </Message>
            {sent && (
                <Message incoming>{email}</Message>
            )}
            {loading && <Writting delay={500} />}
            {error && (
                <Message>{error}</Message>
            )}
        </>
    )
}

export default ChangeEmailMessage;