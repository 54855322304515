import _ from "lodash";
import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import utils from "../utils";

function Abandoned({ automationId, name, formik, product }) {
  let { set, get, error, props, touched } = utils.useFormikUtils(formik, name);

  let required = product.episodes.filter(ep => ep.required);

  return (
    <>
      <div className="form-group">
        <label className="d-block form-label">When to trigger</label>
        <div className="d-flex align-items-center">
          <input type="number" className="form-control hide-spinner w-90px mr-3" min="1" step="1" {...props("delay")} onKeyDown={utils.disableIntro} />
          <select className="custom-select w-auto mr-3" {...props("period")}>
            <option value="minutes">minutes</option>
            <option value="hours">hours</option>
            <option value="days">days</option>
          </select>
          <span>after listener stops before playing through {required.length == 0
            ? " all the episodes"
            : (
              required.length == 1
                ? " the required episode"
                : " the " + required.length + " required episodes" 
            )}</span>
        </div>
        {error("delay") && touched("delay") && <div className="field-error">{error("delay")}</div>}
      </div>
      {required.length > 0 && (
        <div className="form-group mb-10">
          <label className="d-block form-label">Required episodes</label>
          <ul className="bull-list">
            {required.map(ep => <li key={ep._id}>{ep.title}</li>)}
          </ul>
        </div>
      )}
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      dispatch
    })
  )(Abandoned)
);