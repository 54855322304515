import React from "react";
import { Modal } from "react-bootstrap";

export default function AlertModal({ show, onHide, message = null, children, className = "", closeButton = false, buttonLabel = "OK" }) {
  return (
    <Modal show={show} onHide={onHide} size="md" className={"confirmModal " + className}>
      {closeButton && (
        <button type="button" className="close" onClick={onHide}>
          <img src="/media/def-image/icons/close.svg" />
        </button>
      )}
      <Modal.Body>
        {message}
        {children}
      </Modal.Body>
      <Modal.Footer>
        <button ype="button" className="btn btn-primary" onClick={onHide}>
          {buttonLabel}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
