import React from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import actions from "../../../redux/actions";
import Dashboard from "./Dashboard";
import Users from "./Users";
import EventsSetup from "./EventsSetup";
import SendNotification from "./SendNotification";
import SendPushNotification from "./SendPushNotification";
import NotificationsStats from "./NotificationsStats";
import EventsStats from "./EventsStats";
import AIPodcastCreator from "./AIPodcastCreator";

function AdminArea(props) {
	let logout = ev => {
		ev.preventDefault();
		props.setAdminLogin(null, null);
	};

	return <div className="admin-page layout container-fluid">
		<div className="row">
			<div className="col-md-3 col-lg-2 sidebar">
				<nav>
					<NavLink activeClassName="active" to="/admin/dashboard">Dashboard</NavLink>
					<NavLink activeClassName="active" to="/admin/users">Users</NavLink>
					<NavLink activeClassName="active" to="/admin/notification">Send notification (Web)</NavLink>
					<NavLink activeClassName="active" to="/admin/push-notification">Send push notification (App)</NavLink>
					<NavLink activeClassName="active" to="/admin/event">Events form</NavLink>
					<NavLink activeClassName="active" to="/admin/notifications-stats">Notifications stats</NavLink>
					<NavLink activeClassName="active" to="/admin/events-stats">Events stats</NavLink>
					<NavLink activeClassName="active" to="/admin/aipc">AI Podcast Creator</NavLink>
					<a href="#" onClick={logout} className="nav-logout">Logout</a>
				</nav>
			</div>
			<div className="col-md-9 col-lg-10 main">
				<Switch>
					<Route path="/admin/users" component={Users} />
					<Route path="/admin/notification" component={SendNotification} />
					<Route path="/admin/push-notification" component={SendPushNotification} />
					<Route path="/admin/event" component={EventsSetup} />
					<Route path="/admin/dashboard" component={Dashboard} />
					<Route path="/admin/notifications-stats" component={NotificationsStats} />
					<Route path="/admin/events-stats" component={EventsStats} />
					<Route path="/admin/aipc" component={AIPodcastCreator} />
				</Switch>
			</div>
		</div>
	</div>;
}

export default injectIntl(connect(null, actions.auth)(AdminArea));