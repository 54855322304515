import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import actions from "../../../../redux/actions";
import api from "../../../../redux/api";
import { toastMessage, updateCurrentUser } from "../../../helpers";
import SVG from "react-inlinesvg";
import { Link, useHistory, useParams } from "react-router-dom";
import { parseDomain, ParseResultType } from "parse-domain";

function DomainSettings() {
  const user = useSelector((state) => state.auth.user, shallowEqual),
    [loading, setLoading] = useState(true),
    [details, setDetails] = useState(null),
    [subdomain, setSubdomain] = useState(null),
    dispatch = useDispatch(),
    { id } = useParams(),
    history = useHistory();

  async function loadData() {
    let res = await api.user.getDomainDetails(id);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    setLoading(false);
    setDetails(res.data);

    const parsed = parseDomain(res.data.host);
    if(parsed.subDomains.length)
      setSubdomain(parsed.subDomains);
  }

  async function verify() {
    setLoading("verify");

    let res = await api.user.verifyDomain(id);

    updateCurrentUser(dispatch);

    setLoading(false);

    if(!res || !res.success || !res.verified) {
      history.push("/user/domains#verification-in-progress");
    } else {
      toastMessage.success("The domain has been activated!");
      history.push("/user/domains");
    }
  }

  useEffect(() => {
    loadData();
  }, [id]);

  return (
    <>
      <h1>
        <Link to="/user/domains" className="btn btn-back">
          <SVG src="/media/def-image/icons/back.svg" />
        </Link>
        Connect Existing Domain
      </h1>

      <div className="container-inner">
        <div className="card domain-details">
          {loading === true || !details
            ? (
              <div className="loading-block spinner" />
            )
            : (
              <>
                <div className="card-header">
                  {!!subdomain
                    ? <h2 className="card-title m-0">Next Steps To Add Subdomain</h2>
                    : <h2 className="card-title m-0">Next Steps To Add Domain</h2>}
                </div>
                <div className="card-body">
                  {!subdomain && (
                    <div className="mb-10">
                      <h4 className="text">
                        OPTION 1<br />
                        Point Your Whole Website to Hiro
                      </h4>
                      <p>Choose this if: You want your entire website (every part of your website's address) to show Hiro pages, and not to an existing site. This is great for new websites without any current content or services like email.</p>
                      <p className="ol">
                        <strong className="number">1.</strong> Find Your Domain Registrar: This is where you registered your website's address (like GoDaddy or Namecheap).
                      </p>
                      <p className="ol">
                        <strong className="number">2.</strong> Log In and Look for DNS Settings: Once logged in, find a section called “DNS Settings” or “Manage Domains.”
                      </p>
                      <p className="ol">
                        <strong className="number">3.</strong> Enter Hiro's DNS Addresses: In the DNS section, you'll need to add these two addresses:
                      </p>
                      <div className="form-control ns ml-7 mb-4">
                        {details.ns.map((line, i) => <React.Fragment key={i}>{line}<br /></React.Fragment>)}
                      </div>
                      <p className="ol">
                        <strong className="number">4.</strong> Save Your Changes: Make sure to save these new settings.
                      </p>
                    </div>
                  )}

                  <div>
                    {subdomain
                      ? <></>
                      : (
                        <>
                          <h4>
                            OPTION 2<br />
                            Connect to Hiro Without Changing Your Whole Site
                          </h4>
                          <p>Choose this if: You have existing content or services (like email) with your current website.</p>
                        </>
                      )}

                    <p className="ol">
                      <strong className="number">1.</strong> Go to Your Website‘s Hosting Service: This could be the same as your domain registrar or a different service (like GoDaddy, Namecheap, or Cloudflare).
                    </p>
                    <p className="ol">
                      <strong className="number">2.</strong> Find the DNS Management Area: Once you're logged in, look for a section labeled “Manage DNS” or something similar.
                    </p>
                    <p className="ol">
                      <strong className="number">3.</strong> Add a CNAME Record:
                    </p>
                    <p className="ol pl-13">
                      <strong className="number">1.</strong> Choose <code>CNAME</code> as the type
                    </p>
                    <p className="ol pl-13">
                      <strong className="number">2.</strong> Name: <code>{subdomain ? subdomain.join(".") : "@"}</code>
                    </p>
                    <p className="ol pl-13">
                      <strong className="number">3.</strong> Value: <code>{details.cname}</code>
                    </p>
                    <p className="ol">
                      <strong className="number">4.</strong> Remember to Save: After entering these details, save the new record.
                    </p>
                    <p>Note: Changes can take a little time to activate. If anything seems confusing or doesn't work, reach out to our support team for help. We're here to ensure you get connected smoothly!</p>
                  </div>
                </div>

                {details.status == "verified"
                  ? (
                    <div className="card-footer text-right">
                      <Link to="/user/domains" className="btn btn-primary">Done</Link>
                    </div>
                  )
                  : (
                    <div className="card-footer text-right">
                      <Link to="/user/domains" className="btn btn-secondary mr-2">Cancel</Link>
                      <button type="button" onClick={() => verify()} className={"btn btn-primary " + (loading == "verify" ? "loading spinner" : "")}>I did this. Check status.</button>
                    </div>
                  )}
              </>
            )}
        </div>
      </div>
    </>
  );
}

export default connect(null, actions.auth)(DomainSettings);
