import { toastMessage, RollbarErrorTracking, ErrorBoundary } from "../helpers";
import axios from "axios";
import _ from "lodash";
import store from "../../redux/store";
import React from "react";
import actions from "../../redux/actions";

require("dotenv").config();

function loginAgain() {
  store.dispatch(actions.auth.logout());
  //window.history.pushState({},"","/auth/login");
}

function _checkError(error, hideError) {
  if(error.response && error.response.data && error.response.data.mustLoginAgain)
    return loginAgain();

  let e = typeof error == "object" && (error.response && error.response.data || error.message) || {},
    message = typeof e == "object" ? (e.message || e.error) || "Unexpected error, please try again." : "Connection to the server failed.";

  if(!hideError) {
    toastMessage.error(message);
  }

  if(error.toString().indexOf("401") !== -1) {
    //    storeRoot.commit('users/logout');
    //window.location.reload();
    // return rootRouter.push('/login').catch(() => {})
  }

  if(error.toString().indexOf("403") !== -1) {
    // return rootRouter.push('/dashboard').catch(() => {})
  }

  //RollbarErrorTracking.logErrorInRollbar(`Message: ${message} \n\rResponse: ${error.toString()}`);

  return Object.assign({}, error).request;
}

const runQuery = (url, method, data = null, params = null, hideError = false, returnNon200 = false, cancelToken = null) => {
  const { auth: { authToken, adminAuthToken } } = store.getState();

  let isAdmin = /^\/admin-page\//.test(url);

  let headers = {
    "X-Requested-With": "XMLHttpRequest"
  };

  if(isAdmin || adminAuthToken)
    headers.Authorization = "Bearer " + adminAuthToken;
  else if(authToken)
    headers.Authorization = "Bearer " + authToken;

  if((data || "").toString().indexOf("FormData") !== -1) {
    _.assign(headers, { "Content-Type": "multipart/form-data" });
  }

  return axios({
    url: url,
    method: method,
    data,
    params,
    headers,
    cancelToken
    //withCredentials:true
  })
    .then(res => {
      if(res.mustLoginAgain)
        return loginAgain();

      if(res && (res.success || !res.error))
        return res.data;

      if(!hideError)
        toastMessage.error(res.error || "Unknown error");

      return null;
    })
    .catch(res => {
      if(axios.isCancel(res))
        return;

      _checkError(res, hideError);

      if(returnNon200 && res.response)
        return res.response.data;
    });
};

const fetch = {
  get: (url, params, hideError, returnNon200, cancelToken) => runQuery(url, "GET", null, params, hideError, returnNon200, cancelToken),
  post: (url, data, hideError, returnNon200, cancelToken) => runQuery(url, "POST", data, {}, hideError, returnNon200, cancelToken),
  put: (url, data, hideError, returnNon200) => runQuery(url, "PUT", data, {}, hideError, returnNon200),
  delete: (url, params, hideError, returnNon200) => runQuery(url, "DELETE", null, params, hideError, returnNon200),
  deleteWithData: (url, data, hideError, returnNon200) => runQuery(url, "DELETE", data, null, hideError, returnNon200)
}

export const getCancelSource = () =>
  axios.CancelToken.source();

export default fetch;
