import React from "react";
import { Link } from "react-router-dom";

function SettingsNavigation({ active }) {
    return (
        <>
            <h1>Admin settings</h1>
            <div className="navigation-tabs">
                <Link to="/settings/organization" className={"tab " + (active == "organization" ? "active" : "")}>Organization</Link>
                <Link to="/settings/password" className={"tab " + (active == "password" ? "active" : "")}>Password</Link>
                <Link to="/settings/billing" className={"tab " + (active == "billing" ? "active" : "")}>Billing</Link>
            </div>
        </>
    );
}

export default SettingsNavigation;