import React, { useEffect, useState } from "react";
import api from "../../../../redux/api";
import { Message, Send, sleep, Writting } from "./common";
import ChangeEmailMessage from "./ChangeEmailMessage";
import FirstAccountMessage from "./FirstAccountMessage";

function ConfirmCodeMessage({ next, state, scroll, user }) {
    let [code, setCode] = useState(""),
        [invalid, setInvalid] = useState(false),
        [response, setResponse] = useState(null),
        [sent, setSent] = useState(false),
        [loading, setLoading] = useState(false),
        [error, setError] = useState(null);

    let isValidCode = v => v.match(/^[0-9]{4,4}-[0-9]{4,4}$/);

    let codeOnChange = ev => {
        if(ev.target.value.match(/^[0-9]{4,4}$/))
            ev.target.value += "-";

        setCode(ev.target.value);
        setInvalid(!isValidCode(ev.target.value));
    };

    let codeOnKeyDown = ev => {
        if(ev.which == 13) {
            ev.preventDefault();
            submit();
        }
    };

    let submit = async () => {
        if(!code || !isValidCode(code))
            return;

        setSent(true);
        setLoading(true);

        await sleep(1000);

        let res = await api.user.onboardingStep("step-email-code-validation", {
            code
        });

        setLoading(false);

        if(!res || !res.success) {
            setError((res && res.error) || "I’m sorry, I wasn’t able to process that. Please, try again.");
            setTimeout(() => next(ConfirmCodeMessage, { code }), 1000);
            return;
        }

        next(FirstAccountMessage, { code });
    };

    let resend = async () => {
        setSent(true);
        setCode("");
        setResponse("Resend code");
        setLoading(true);

        await sleep(1000);

        let res = await api.user.onboardingStep("step-email", {
            email: state.email || user.email
        });

        setLoading(false);

        if(!res || !res.success) {
            setError((res && res.error) || "I’m sorry, I wasn’t able to process that. Please, try again.");
            setTimeout(() => next(ChangeEmailMessage, { code: null }), 1000);
            return;
        }

        next(ConfirmCodeMessage, { code: null, codeSent: true });
    };

    let changeEmail = async () => {
        setSent(true);
        setCode("");
        setResponse("Use different email");
        setLoading(true);

        await sleep(1000);

        setLoading(false);

        next(ChangeEmailMessage, { code: null });
    };

    async function sendCode() {
        if(state.codeSent)
            return;

        await api.user.onboardingStep("step-email", {
            email: user.email
        });

        state.codeSent = true;
    }

    useEffect(() => {
        scroll();
    }, [sent, loading, error]);

    useEffect(() => {
        sendCode();
    }, []);

    return (
        <>
            {!state.code && (
                <Message>
                    {state.codeSent ? "" : "First, let’s confirm it’s you. "} An email with a verification code has been sent to <strong>{state.email || user.email}</strong>
                </Message>
            )}
            <Message delay={1000}>
                Enter the code to continue
                {!sent && (
                    <>
                        <div className={"input " + (invalid ? "invalid " : "") + (code ? "" : " empty")}>
                            <input type="text" onChange={codeOnChange} className="form-control" autoFocus placeholder="0000-0000" onKeyDown={codeOnKeyDown} />
                            <Send onClick={submit} />
                        </div>
                        <div className="d-flex">
                            <button type="button" className="btn btn-secondary mr-2" onClick={resend}>Resend code</button>
                            <button type="button" className="btn btn-secondary" onClick={changeEmail}>Use different email</button>
                        </div>
                    </>
                )}
            </Message>
            {sent && code && (
                <Message incoming>{code}</Message>
            )}
            {sent && response && (
                <Message incoming>{response}</Message>
            )}
            {loading && <Writting delay={500} />}
            {error && (
                <Message>{error}</Message>
            )}
        </>
    );
}

export default ConfirmCodeMessage;