import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomSelect from "./CustomSelect";
import { checkLimits } from "../../plans";

function CategoryDropdown({ user, className = "", onCreateCategoryRequest, ...props }) {
  if(!user)
    return <></>;

  let options = user.categories.sort((a, b) => a.name.localeCompare(b.name)).map(category => ({
    id: category._id,
    label: category.name
  }));

  async function createLinkOnClick(ev) {
    ev.preventDefault();

    if(await checkLimits.canAddCategory(user))
      onCreateCategoryRequest();
  }

  return (
    <CustomSelect
      multiple={false}
      showReset
      image={false}
      options={options}
      className={"category-dropdown " + className}
      dropdownFooter={<a href="#" onClick={createLinkOnClick} className="d-block">+ Create category</a>}
      emptyText={<div className="text-left">You don’t have any categories. <a href="#" onClick={createLinkOnClick}>Create category</a></div>}
      {...props} />
  );
}

export default injectIntl(
  connect(
    (state) => ({
      user: state.auth.user
    })
  )(withRouter(CategoryDropdown))
);
