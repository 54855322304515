import _ from "lodash";
import React, { useState } from "react";
import StyledDropzone from "../../../generic/StyledDropzone";
import UploadProgress from "../../../generic/UploadProgress";

export default props => {
  let [filesToUpload, setFilesToUpload] = useState([]),
    [uploadError, setUploadError] = useState(null);

  let addFilesToUpload = async (files) => {
    for(let audio of files) {
      if(audio.isValid) {
        audio.isComplete = false;
        audio.isUpload = false;
        try {
          audio.durationInSeconds = await computeDuration(audio.file);
        } catch(e) {
          audio.durationInSeconds = null;
        }
      }
    }

    // Concatenate the current list of files with the newly uploaded ones
    // so that the user can see all the files they've uploaded.
    let currentList = filesToUpload;
    let newFiles = files.filter(file => file.isValid);
    currentList = currentList.concat(newFiles);

    if(newFiles.length) {
      props.uploadStarted();
      setFilesToUpload(currentList);
    }
  };

  let computeDuration = file => {
    return new Promise((resolve, reject) => {
      try {
        let
          objectURL = URL.createObjectURL(file),
          mySound = new Audio([objectURL]);

        setTimeout(() => {
          reject("could not compute duration");
        }, 2000);

        mySound.addEventListener(
          "canplaythrough",
          () => {
            URL.revokeObjectURL(objectURL);
            resolve(mySound.duration);
          },
          false,
        );
      } catch(e) {
        console.error(e);
        resolve("N/A");
      }
    });
  };

  let onUploadError = message => setUploadError(message);

  let onUploadComplete = (id, amazonS3Url) => {
    let finishedItem = _.find(filesToUpload, { id: id });

    if(finishedItem) {
      finishedItem.isComplete = true;
      finishedItem.isUpload = true;
      finishedItem.amazonS3Url = amazonS3Url;
    }

    let isLoad = filesToUpload.filter(f => f.isValid === true && f.isComplete === false && f.isUpload === false);

    if(!isLoad.length) {
      props.setFileList(filesToUpload);
      props.uploadComplete();
    }
  };

  return <>
    <div className="mb-15 dropzone-bulk-container">
      <StyledDropzone
        validateStorage
        user={props.user}
        allowMultipleUpload
        addFilesToUpload={addFilesToUpload}
        onError={onUploadError}
        fileType="audio/*, video/*"
        description="Select videos and MP3’s to upload"
        errorMessage={uploadError}
      />

      {!!(filesToUpload && filesToUpload.length) && filesToUpload.map(file => <UploadProgress
        key={file.id}
        id={file.id}
        isValid={file.isValid}
        file={file.file}
        canStartUpload={true}
        hasBeenUploaded={file.isUpload}
        onUploadComplete={onUploadComplete}
        uploadType="raw"
        progressType="bulk"
        abortOnUnmount={false}
        canAbort
      />)}
    </div>
  </>;
};