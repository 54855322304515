import { useFetch } from "../../app/helpers";

export default {
  list: () => useFetch.get("/sms"),
  get: id => useFetch.get("/sms/" + id, null, true, true),
  getListeners: (id, offset = 0) => useFetch.get("/sms/" + id + "/listeners?offset=" + offset, null, true, true),
  create: data => useFetch.post("/sms", data, true, true),
  update: (id, data) => useFetch.put("/sms/" + id, data, true, true),
  delete: id => useFetch.delete("/sms/" + id, null, true, true),
  getAvailableNumbers: areaCode => useFetch.get("/available-phonenumbers?areaCode=" + areaCode, null, false, true),
  selectPhoneNumber: phoneNumber => useFetch.post("/select-phonenumber", { requestedNumber: phoneNumber }, false, true),
  getCampaign: id => useFetch.get("/campaigns/" + id, null, true, true),
  listCampaigns: phoneNumberKind => useFetch.get("/campaigns?phoneNumberKind=" + phoneNumberKind, null, true, true),
  createCampaign: data => useFetch.post("/campaigns", { data }, true, true),
  updateCampaign: (id, data) => useFetch.put("/campaigns/" + id, { data }, true, true),
  deleteCampaign: id => useFetch.delete("/campaigns/" + id, null, true, true),
  toggleCampaignActiveStatus: id => useFetch.put("/campaigns/" + id + "/toggle-active", null, true, true),
  getContactsCount: data => useFetch.post("/campaigns/contacts-count", { data }, true, true)
};
