import { useFetch } from "../../app/helpers";

export const LOGIN_URL = "auth";
export const REGISTER_URL = "auth/register";
export const REQUEST_PASSWORD_URL = '/public/resetpassword';
export const FORGOT_PASSWORD_URL = '/public/forgotpassword';
export const SIGNUP_URL = "/signup";
export const GET_USER_URL = "get-current-instructor";
export const VERIFY_EMAIL = '/verify-account';

export default {
  login: (email, password, agreesToTerms, hideError, socialLogin=null, recaptchaToken=null, activateLicense) =>
    useFetch.post(
      LOGIN_URL,
      { email, pwd: password, agreesToTerms: agreesToTerms, socialLogin, recaptchaToken, activateLicense },
      hideError
    ),
  register: (email, fisrtName, lastName, password) =>
    useFetch.post(REGISTER_URL, { email, fisrtName, lastName, password }),
  requestPassword: (token, password) => useFetch.post(REQUEST_PASSWORD_URL, { token, pwd: password }),
  forgotPassword: (email) => useFetch.post(FORGOT_PASSWORD_URL, { email }),
  getUserByToken: (hideError) => useFetch.post(GET_USER_URL, {}, hideError),
  signup: (email, phone, password, firstname, lastname, agreesToTerms, socialLogin=null, recaptchaToken=null, extra = {}, activateLicense) =>
    useFetch.post(SIGNUP_URL, {
      email,
      phone,
      pwd: password,
      firstname,
      lastname,
      agreesToTerms,
      socialLogin,
      recaptchaToken,
      activateLicense,
      ...extra
    }),
  verifyUser: (token) => useFetch.put(VERIFY_EMAIL, { token })
};
