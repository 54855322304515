import React, { useEffect, useRef, useState } from "react";
import { Message, Send, Writting } from "../../../modals/onboarding/common";
import FinalMessage from "../FinalMessage";

export default function TargetMessage({ user, next, state }) {
    const [answer, setAnswer] = useState(null),
        [sent, setSent] = useState(false),
        [loading, setLoading] = useState(false),
        [invalid, setInvalid] = useState(false);

    const submit = () => {
        if(!answer)
            return setInvalid(true);

        setInvalid(false);
        setSent(true);
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
            next(FinalMessage, { targetAudience: answer });
        }, 2000);
    };

    const inputOnChange = ev =>
        setAnswer(ev.target.value.trim());

    const inputOnKeyDown = ev => {
        if(ev.which == 13) {
            ev.preventDefault();
            submit();
        }
    };

    return (
        <>
            <Message fullWidth={!sent}>
                What’s your target audience?

                {!sent && (
                    <div className={"input " + (invalid ? "invalid " : "") + (answer ? "" : " empty")}>
                        <input type="text" className="form-control" autoFocus onChange={inputOnChange} onKeyDown={inputOnKeyDown} />
                        <Send onClick={submit} />
                    </div>
                )}
            </Message>

            {sent && <Message incoming>{answer}</Message>}

            {loading && <Writting delay={1000} />}
        </>
    );
}
