import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import CustomSelect from "../../../layout/components/CustomSelect";
import utils from "../utils";

function Episode({ automationId, name, formik, product }) {
  let { set, get, error, props, touched } = utils.useFormikUtils(formik, name),
    [episodes, setEpisodes] = useState([]),
    [episodeOptions, setEpisodeOptions] = useState([]);

  function loadEpisodes() {
    if(!product)
      return;

    let options = [];

    for(let episode of product.episodes)
      options.push({
        id: episode._id,
        label: (
          <>
            <span className="text-muted d-inline-block pr-3">{episode.episodeNumber}</span>
            {episode.title}
          </>
        )
      });

    setEpisodes(product.episodes);
    setEpisodeOptions(options);
  }

  useEffect(() => {
    loadEpisodes();
  }, [product]);

  return (
    <>
      <div className="form-group">
        <label className="form-label">Episodes</label>
        <CustomSelect dropdownFooter={(
          <button type="button" onClick={() => set("episodes", [])} className="dropdown-item">
            <label>All episodes</label>
          </button>
        )} noSelectionText={"All episodes (" + (product?.episodes.length || "0") + ")"} className="episodes-multi-select-sm" multiple options={episodeOptions} image={false} value={get("episodes")} onChange={values => set("episodes", values)} />
        {error("episodes") && <div className="field-error">{error("episodes")}</div>}
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      dispatch
    })
  )(Episode)
);