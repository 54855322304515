import React from "react";
import { Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import actions from "../../../redux/actions";
import { checkLimits } from "../../plans";

function NewContactDropdown({ className = "", toggleClassName = "", children, currentUser, drop = "down", alignRight = false, fixed = false }) {
    let history = useHistory();

    async function createContactOnClick(ev) {
        ev.preventDefault();

        if(await checkLimits.canAddContact(currentUser))
            history.push("/contacts/edit");
    }

    return (
        <Dropdown className={(fixed ? "fixed-dropdown " : "") + className} drop={drop} alignRight={alignRight}>
            <Dropdown.Toggle className={toggleClassName}>
                {children}
            </Dropdown.Toggle>
            <Dropdown.Menu popperConfig={fixed ? { strategy: "fixed" } : {}} renderOnMount>
                <Link to="#" onClick={createContactOnClick} className="dropdown-item no-plan-cant-click">
                    <div className="icon">
                        <SVG src={"/media/def-image/icons/user-plus2.svg"} />
                    </div>
                    Single Contact
                </Link>
                <Link to={"/contacts/#bulk-upload"} className="dropdown-item no-plan-cant-click">
                    <div className="icon">
                        <SVG src={"/media/def-image/icons/csv.svg"} />
                    </div>
                    Bulk Upload
                </Link>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default injectIntl(
    connect(
        (state) => ({
            currentUser: state.auth.user,
        }),
        actions.product
    )(withRouter(NewContactDropdown))
);