import * as Yup from "yup";

export const episodeSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "The title is too short.")
    .max(300, "The title is too long.")
    .required("Please enter a title."),

  summary: Yup.string()
    .max(1500, "The summary is too long."),

  audioUrl: Yup.string().nullable(),
  originalFileUrl: Yup.string().nullable()
}).test(
  "audio",
  "Please upload an audio or video file.",
  values => !!(values.originalFileUrl || values.audioUrl)
);

export const EpisodeBulkUploadSchema = Yup.object().shape({
  audioFileName: Yup.string().nullable(true)
    .required("Please upload an audio or video file.")
});
