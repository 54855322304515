import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import StyledDropzone from "../../../generic/StyledDropzone";
import { toastMessage } from "../../../../helpers";
import api from "../../../../../redux/api";
import { checkLimits } from "../../../../plans";
import UnsavedFormGuard from "../../../../layout/components/UnsavedFormGuard";
import ProductDropdown from "../../../../layout/components/ProductDropdown";
import TagDropdown from "../../../../layout/components/TagDropdown";
import { EditTagModal } from "../../../contacts/Tags";

class BulkUploadListenersModal extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      checkedProductsIds: this.props.productId ? [this.props.productId] : [],
      tags: [],
      uploadError: null,
      uploadFeedback: { imported: [], ignored: [] },
      step: 1,
      csvFile: null,
      loading: false,
      showUnsavedDialog: false,
      showCreateTagModal: false
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.productId !== this.props.productId)
      this.setState({ ...this.state, checkedProductsIds: [this.props.productId] });
  }

  render() {
    const onSelectCsvFile = files => {
      if(files[0]) {
        if(files[0].isValid) {
          this.setState({ ...this.state, csvFile: files[0].file });
        } else {
          onUploadError("Please select a CSV file");
          this.setState({ ...this.state, csvFile: null });
        }
      } else {
        this.setState({ ...this.state, csvFile: null });
      }
    };

    const startUpload = () => {
      //if(!this.props.contacts && (!this.state.checkedProductsIds || this.state.checkedProductsIds.length === 0)) {
      //  toastMessage.error("Please select at least 1 product to grant to these listeners.");
      if(!this.state.csvFile) {
        toastMessage.error("Please select a CSV file to upload.");
      } else {
        this.setState({ ...this.state, loading: true });

        let formData = new FormData();
        formData.append("file", this.state.csvFile, this.state.csvFile.name);
        formData.append("doNotSendAccess", this.props.doNotSendAccess);
        formData.append("contacts", this.props.contacts ? "1" : "");

        for(let productId of this.state.checkedProductsIds)
          formData.append("products", productId);

        for(let tag of this.state.tags)
          formData.append("tags", tag);

        api.listener.bulkUploadListener(formData)
          .then(res => {
            if(!res || !res.success)
              return;

            if(res.overLimit) {
              this.props.onHide();
              checkLimits.upgradeOrActivate(this.props.user);
              return;
            }

            this.props.callbackBulkUploadListeners && this.props.callbackBulkUploadListeners();
            this.setState({ ...this.state, uploadFeedback: res, step: 2, csvFile: null });
            toastMessage.success("File imported successfully!");
          }).finally(() => {
            this.setState({ ...this.state, loading: false });
          });
      }
    };

    const onUploadError = message => this.setState({ ...this.state, uploadError: message });

    /*let onHide = () => {
      if(this.state.csvFile)
        return this.setState({ ...this.state, showUnsavedDialog: true });
      this.props.onHide();
    };

    let unsavedOnSave = async () => {
      unsavedOnHide();
      startUpload();
    };

    let unsavedOnCancel = async () => {
      unsavedOnHide();
      this.props.onHide();
    };

    let unsavedOnHide = () => {
      this.setState({ ...this.state, showUnsavedDialog: false });
    };*/

    return (
      <>
        <EditTagModal autoReload onHide={() => this.setState({ ...this.state, showCreateTagModal: false })} show={this.state.showCreateTagModal} />

        <Modal
          size="md"
          onHide={this.props.onHide}
          show={this.props.show}
          centered
          className="new-modals upload-contacts-modal">
          <Modal.Header>
            {this.props.contacts
              ? (
                <div>
                  <h1 className="m-0">Upload your contacts</h1>
                  <p className="text-muted">
                    Upload a CSV file with your contacts. If you select a show, they will receive an access email.
                  </p>
                </div>
              )
              : (
                <div>
                  <h1 className="m-0">Upload your listeners</h1>
                  <p className="text-muted">
                    Upload a CSV file with your product listeners. They will receive an access email as soon as you publish the product.
                  </p>
                </div>
              )}
          </Modal.Header>

          <Modal.Body>
            <div className="dropzone-csv-container text-center">
              {this.state.csvFile
                ? (
                  <div className="selected-file">
                    <label>{this.state.csvFile.name}</label> <button type="button" className="btn btn-secondary btn-sm ml-3" onClick={() => this.setState({ ...this.state, csvFile: null })}>Replace CSV file</button>
                  </div>
                )
                : (
                  <>
                    <StyledDropzone
                      allowMultipleUpload={false}
                      addFilesToUpload={onSelectCsvFile}
                      onError={onUploadError}
                      fileType=".csv,text/csv"
                      description="Select CSV file to upload"
                      errorMessage={this.props.uploadError} />

                    <p className="dropzone-subtitle">
                      Grab our <a href="#" onClick={ev => window.open(this.props.contacts ? "/media/contacts.csv" : "/media/listeners.csv")} target="_blank">template</a>.
                    </p>
                  </>
                )}
            </div>

            {(this.props.contacts || (this.props.productId && !this.props.isOnlyOne)) && (
              <div className="select-show-row">
                <label className="form-label">
                  Select Shows
                  <small>Optional</small>
                </label>
                <ProductDropdown listenersCount={false} multiple value={this.state.checkedProductsIds} onChange={checkedProductsIds => this.setState({ ...this.state, checkedProductsIds })} />
              </div>
            )}

            <div className="tags-row">
              <label className="form-label">
                Tags
                <small>Optional</small>
              </label>
              <TagDropdown onCreateTagRequest={() => this.setState({ ...this.state, showCreateTagModal: true })} contactsCount={false} multiple value={this.state.tags} onChange={value => this.setState({ ...this.state, tags: value })} />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button type="button" className="btn btn-secondary mr-2" onClick={() => this.props.onHide()}>Cancel</button>
            <button className={"btn btn-primary " + (this.state.loading ? "loading spinner " : "")} disabled={!this.state.csvFile} onClick={startUpload}>Save</button>
          </Modal.Footer>
        </Modal>

        {/*<UnsavedFormGuard dirty={this.props.show && !!this.state.csvFile} onSaveAsync={unsavedOnSave} onCancelAsync={unsavedOnCancel} onHide={unsavedOnHide} showDialog={this.props.show && this.state.showUnsavedDialog} />*/}
      </>
    );
  }
}

export default BulkUploadListenersModal;