export const colors = [
    {
        id: 'color-1',
        color: '#3F4254'
    },
    {
        id: 'color-2',
        color: '#F64E60'
    },
    {
        id: 'color-3',
        color: '#EF7C35'
    },
    {
        id: 'color-4',
        color: '#FFA800'
    },
    {
        id: 'color-5',
        color: '#F9D247'
    },
    {
        id: 'color-6',
        color: '#B8E24A'
    },
    {
        id: 'color-7',
        color: '#08C98F'
    },
    {
        id: 'color-8',
        color: '#99DAFF'
    },
    {
        id: 'color-9',
        color: '#3699FF'
    },
    {
        id: 'color-10',
        color: '#605DF0'
    },
    {
        id: 'color-11',
        color: '#8950FC'
    },
    {
        id: 'color-12',
        color: '#EE83F8'
    },
    {
        id: 'color-13',
        color: '#F178B6'
    },
    {
        id: 'color-14',
        color: '#607893'
    },
]