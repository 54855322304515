import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import OneSignal from "react-onesignal";
import api from "../../../redux/api";
import { osName, browserName } from "react-device-detect";
import actions from "../../../redux/actions";
import { isModalOpen } from "../../helpers";

export default function NotificationsModal(props) {
  let [initialized, setInitialized] = useState(false),
    [show, setShow] = useState(false),
    [showBackdrop, setShowBackdrop] = useState(false),
    user = useSelector(state => state.auth.user, shallowEqual),
    dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      await OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_ID,
        safari_web_id: process.env.REACT_APP_ONESIGNAL_SAFARI,
        //For local testing:
        subdomainName: "hirofm",
        allowLocalhostAsSecureOrigin: true
      });
      OneSignal.on("subscriptionChange", async subscribed => {
        setShowBackdrop(false);

        if(!subscribed) return;
        let id = await OneSignal.getUserId();
        await api.user.enableNotifications({ oneSignalPushId: id, device: browserName + " on " + osName });
        //update user
        let res = await api.auth.getUserByToken(true);
        dispatch(actions.auth.fulfillUser(res.data));
      });
      setInitialized(true);
    })();
  }, []);

  useEffect(() => {
    //If noPlan, activation popup should be open; if onboarding.active, the onboarding popup should be visible
    if(!initialized || !user || user.noPlan || user.owner?.onboarding.active || user.onboarding.active || isModalOpen()) return;
    //If never subscribed, nor dismissed it, show the popup
    if(!user.dismissedPushPopup && (!user.oneSignalIds || !user.oneSignalIds.length)) {
      setShow(true);
      return;
    }

    //Else, silently try show the native popup on this browser (maybe the user subscribed from another device)
    OneSignal.showNativePrompt();
  }, [initialized, user]);

  let dismiss = ev => {
    setShow(false);
    api.user.dismissPushNotificationsPopup();
  };

  let confirm = ev => {
    api.user.dismissPushNotificationsPopup();
    setShow(false);
    setShowBackdrop(true);
    OneSignal.showNativePrompt();
    OneSignal.sendTag("users", "true");
  };

  return <>
    <Modal
      show={show}
      onHide={dismiss}
      size="lg"
      centered
      className="new-modals subscribe-modal text-center"
    >
      <Modal.Body className="pt-15 px-10">
        <button type="button" className="close" onClick={dismiss}><SVG src={toAbsoluteUrl("/media/def-image/close.svg")} className="svg-icon" /></button>

        <h2 className="fs-2 fw-normal mb-5 text-dark">Try our new notifications</h2>
        <p className="mb-15">Keep up to date on your sales and more!</p>
        <div className="d-flex align-items-center pb-15">
          <div className="col-sm-6">
            <img src={toAbsoluteUrl("/media/def-image/notif-popup.png")} />
          </div>
          <div className="col-sm-6 text-left fs-7 pl-5">
            <ul>
              <li>Hear about new feautues</li>
              <li className="text-dark">Celebrate your sales & new listeners</li>
              <li>Failed subsciption payments</li>
              <li>Turn off anytime in Hiro settings</li>
              <li>And more!</li>
            </ul>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="p-10 gray-bg text-dark align-items-center d-block d-lg-flex">
        <button type="button" className="btn btn-secondary mr-2" onClick={dismiss}>Not right now</button>
        <button type="button" className="btn btn-primary" onClick={confirm}>Sounds great</button>
      </Modal.Footer>
    </Modal>
    <div id="push-backdrop" className={showBackdrop ? "show" : ""} onClick={ev => setShowBackdrop(false)}></div>
  </>;
};
