import { useFetch } from "../../app/helpers";

export default {
  //Experimental/Podcast creator
  createProject: project => useFetch.post("/ai-experimental/projects", { project }, true, true),
  updateProject: (id, project) => useFetch.put("/ai-experimental/projects/" + id, { project }, true, true),
  getProjects: () => useFetch.get("/ai-experimental/projects", null, true, true),
  update: (id, data) => useFetch.put("/ai-experimental/" + id, { data }, true, true),
  getProject: id => useFetch.get("/ai-experimental/" + id, null, true, true),
  nameSuggestions: project => useFetch.post("/ai-experimental/name-suggestions", { project }, true, true),

  //Assistant
  pushMessage: data => useFetch.post("/ai/message", { data }, true, true),
  clearChat: () => useFetch.post("/ai/clear", null, true, true),
  getResponse: cancelSource => useFetch.get("/ai/response", null, true, true, cancelSource?.token),
  generateArtwork: (data, cancelSource) => useFetch.post("/ai/generate-artwork", { data }, true, true, cancelSource?.token),
  requestTranscription: data => useFetch.post("/ai/transcribe", { data }, true, true)
};
