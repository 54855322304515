import _ from "lodash";
import React from "react";
import Datetime from "react-datetime";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import moment from "moment";
import utils from "../utils";

function Schedule({ automationId, name, formik }) {
  let { set, get, error, props, touched } = utils.useFormikUtils(formik, name);

  let date = get("date");

  return (
    <>
      <div className="form-group mw-300">
        <label className="form-label">Trigger Date</label>
        <Datetime locale="en" value={date ? moment(date) : ""} className="input-date" onChange={v => {
            if(v)
              set("date", v.toISOString());
          }} inputProps={{
            readOnly: true,
            className: "form-control"
          }} />
        {error("date") && touched("date") && <div className="field-error">{error("date")}</div>}
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      dispatch
    })
  )(Schedule)
);