import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import SVG from "react-inlinesvg";

export default function CopyButton({ text, successTooltipText, className = "" }) {
  const [copyUriTooltip, setCopyUriTooltip] = useState(false);

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      onClose={() => setCopyUriTooltip(false)}
      open={copyUriTooltip}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      className="text-center"
      arrow
      placement="top"
      title={successTooltipText}>
      <button disabled={!text} type="button" className={"btn btn-transparent " + className} onClick={() => {
        try {
          navigator.clipboard.writeText(text);
          setCopyUriTooltip(true);
          setTimeout(() => setCopyUriTooltip(false), 2000);
        } catch(x) {}
      }}>
        <SVG src="/media/def-image/icons/clipboard.svg" className="svg-icon svg-icon-3 m-0" />
      </button>
    </Tooltip>
  );
}
