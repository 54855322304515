import React from 'react';


class PodcastPlayer extends React.Component {
  constructor (props) {
    super(props)
  }


  render () {
    return (
      <div>
        {(() => {
          if(this.props.product.pendingOwnership)
            return "Waiting verification...";

          if(!this.props.audioUrl && !this.props.originalFileUrl)
            return "No audio file.";

          if(!this.props.finishedProcessing)
            return "Processing file...";

          if(!this.props.failedProcessing)
            return <audio controls preload="none">
              <source src={this.props.audioUrl} type="audio/mpeg" />
              Your browser does not support the HTML5 audio element.
            </audio>;

          if(this.props.processingFriendlyError)
            return "Unable to process file: " + this.props.processingFriendlyError;

          return "Unable to process file.";
        })()}
      </div>
    )
  }
}

export default PodcastPlayer
