import React, { useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import api from "../../../redux/api";
import { toastMessage } from "../../helpers";
import SVG from "react-inlinesvg";
import RenameForm from "./RenameForm";

export default function List({ projects, onUpdateRequest, onDetails, onNewProject }) {
    const [actionLoading, setActionLoading] = useState(null),
        [renameId, setRenameId] = useState(null);

    const status = {
        pending: <span className="gray-badge status">Pending</span>,
        working: <span className="blue-badge status">Working</span>,
        failed: <span className="red-badge status">Failed</span>,
        done: <span className="green-badge status">Completed</span>
    };

    let renameOnComplete = () => {
        setRenameId(null);
        onUpdateRequest();
    };

    const detailsOnClick = (ev, project) => {
        ev.preventDefault();
        onDetails(project);
    };

    return (
        <>
            <div className="row align-items-center projects-title-row">
                <div className="col-md-10">
                    <h2 className="mb-2">My projects</h2>
                    <p className="text-muted">Project status will be updated automatically once the contents are avaibale.</p>
                </div>
                <div className="col-md-2 text-right">
                    <button type="button" onClick={onNewProject} className="btn btn-primary">New Project</button>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-head-custom table-vertical-center">
                            <thead>
                                <tr>
                                    <th>NAME</th>
                                    <th>STATUS</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {projects.map((project, index) => (
                                    <tr key={index}>
                                        <td>
                                            {renameId == project._id
                                                ? <RenameForm project={project} onComplete={renameOnComplete} onCancel={() => setRenameId(null)} />
                                                : <a href="#" onClick={ev => detailsOnClick(ev, project)} className={"text-inherit " + (project.unseen ? "fw-bold" : "")}>{project.name}</a>}
                                        </td>
                                        <td>{status[project.status]}</td>
                                        <td width="1">
                                            <Dropdown className="dropdown table-options-dropdown fixed-dropdown dropdown-inline">
                                                <Dropdown.Toggle>
                                                    <SVG src="/media/def-image/icons/menu-2.svg" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
                                                    <a href="#" onClick={ev => detailsOnClick(ev, project)} className="dropdown-item">
                                                        <div className="icon">
                                                            <SVG src="/media/def-image/icons/eye-2.svg" />
                                                        </div>
                                                        Details
                                                    </a>
                                                    <a disabled={1 || project.status != "done"} href={process.env.REACT_APP_SERVER_URL + "api/ai-experimental/" + project._id + "/pdf"} target="_blank" className="dropdown-item">
                                                        <div className="icon">
                                                            <SVG src="/media/def-image/icons/download.svg" />
                                                        </div>
                                                        Download as PDF
                                                    </a>
                                                    <button type="button" disabled className="dropdown-item">
                                                        <div className="icon">
                                                            <SVG src="/media/def-image/icons/duplicate.svg" />
                                                        </div>
                                                        Clone
                                                    </button>
                                                    <button type="button" className="dropdown-item" onClick={() => setRenameId(project._id)}>
                                                        <div className="icon">
                                                            <SVG src="/media/def-image/icons/rename.svg" />
                                                        </div>
                                                        Rename
                                                    </button>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
