import React, { useEffect,useState,useRef } from 'react';
import SVG from "react-inlinesvg";
import { ProductLogo } from "../../../_metronic/layout/components/productLogo";
import { formatLength, notificationIcons } from "../../helpers";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import api from '../../../redux/api';
import moment from "moment";
import useTimeout from '../../helpers/useTimeout';

let updateInterval;

/**
 * 
 * @param {Object} props 
 * @param {Object} props.user
 * @returns Object
 */
export function NotificationsPopup(props) {
    let [ data, setData ] = useState({}),
        [ loading, setLoading ] = useState(true),
        [ unread, setUnread ] = useState(false),
        [ showPopup, setShowPopup ] = useState(false),
        [ tab, setTab ] = useState(0),
        [ enableEvents, setEnableEvents ] = useState(false),
        [ formLoading, setFormLoading ] = useState(false),
        [ showFormThankYou, setShowFormThankYou ] = useState(false);

    let updateCount = async (autoUpdate = false) => {
        let res = await api.user.getNotificationsCount();
        if(!res) return;
        let eventsUnseen = res.data.hasEvent && !res.data.eventsFormSeen;
        setUnread(res.data.count || eventsUnseen);
        setEnableEvents(res.data.hasEvent);
        if(!autoUpdate) setTab(eventsUnseen ? 1 : 0); 
        return res.data;
    },
    loadData = async () => {
        setLoading(true);
        let res = await api.user.getNotifications();
        if(res) setData(res.data);
        setLoading(false);
    },
    sendSeen = async () => {        
        await api.user.seenNotifications({
            "0": "update",
            "1": enableEvents ? "event" : "new-listener",
            "2": "new-listener"
        }[tab]);
    };

    useEffect(()=>{
        (async () => {
            let { hasEvent } = await updateCount();

            if(window.location.hash == "#events") {
                openPopup();
                if(hasEvent) setTab(1);
            }
        })();
    },[]);

    useTimeout(() => updateCount(true), 300000, true);

    let openPopup = async (ev) => {
        if(showPopup) {
            setShowPopup(false);            
        } else {
            setShowPopup(true);
            setUnread(false);
            loadData();
            sendSeen();
        }
    },
    closePopupClick = (ev) => {
        ev.preventDefault();
        setShowPopup(false);
    },
    tabClick = (ev, i) => {
        ev.preventDefault();
        setTab(i);
        sendSeen(i);
    };

    let onFormSubmit = async ev => {
        if(formLoading) return;

        //Always submit, even if the action is taken on the frontend, to update the stats
        setFormLoading(true);
        let res = await api.user.submitEventsForm();
        setFormLoading(false);

        if(data.eventsForm.actionType == "url") {
            window.open(data.eventsForm.action);
            setShowFormThankYou(true);
            return;
        }

        if(data.eventsForm.actionType == "activecampaign") {
            setShowFormThankYou(true);
            if(res && res.openUrl) window.open(res.openUrl);
            return;
        }
    };

    return <>
        <button type="button" onClick={openPopup} className={"topbar-item btn notifications-item "+(unread ? "has-notifications":"")}>
            <SVG src={toAbsoluteUrl("/media/def-image/icons/notifications.svg")}/>
        </button>
        <div id="notifications-popup" className={(showPopup ? "show" : "") + (loading ? " loading spinner" : "")}>
            <div className="popup-header">
                <h1>Notifications</h1>
                <a href="#" onClick={closePopupClick} className="btn-close">
                    <SVG src={toAbsoluteUrl("/media/def-image/icons/close.svg")}/>
                </a>
                <div className="tabs">
                    <a href="#" className={tab == 0 ? "active" : ""} onClick={ev => tabClick(ev, 0)}>Updates</a>
                    {enableEvents && <a href="#" className={tab == 1 ? "active" : ""} onClick={ev => tabClick(ev, 1)}>Events</a>}
                    <a href="#" className={tab == (enableEvents ? 2 : 1) ? "active" : ""} onClick={ev => tabClick(ev, enableEvents ? 2 : 1)}>New Listeners</a>
                </div>
            </div>
            <div className={"popup-body tab-"+tab}>
                <div className="tabs">
                    <div className="tab">
                        <div>
                            {!data.updates || !data.updates.length
                                ? <p className="empty">You don’t have notifications yet.</p>
                                : data.updates.map((item, i) => <NotificationItem item={item} key={i} enableEvents={enableEvents} setTab={setTab}/>)}
                        </div>
                    </div>
                    {enableEvents && <div className="tab">
                        {data.eventsForm && <div className="form-tab">
                            {data.eventsForm.image && <img src={data.eventsForm.image} className="image"/> }
                            <h1>{data.eventsForm.title}</h1>
                            {data.eventsForm.subtitle && <h2>{data.eventsForm.subtitle}</h2>}
                            <div className={"form-card " + (showFormThankYou ? "d-none" : "")}>
                                {data.eventsForm.description && <p dangerouslySetInnerHTML={{__html: data.eventsForm.description.replace(/\n/g, "<br/>")}}></p>}
                                <button type="button" className={"btn btn-primary " + (formLoading ? "loading spinner" : "")} onClick={onFormSubmit}>{data.eventsForm.button}</button>
                            </div>
                            <div className={"thank-you-card " + (showFormThankYou ? "d-block" : "")}>
                                {data.eventsForm.thankYou && <p dangerouslySetInnerHTML={{__html: data.eventsForm.thankYou.replace(/\n/g, "<br/>")}}></p>}
                            </div>
                        </div>}
                    </div>}
                    <div className="tab">
                        <div>
                            {!data.listeners || !data.listeners.length
                                ? <p className="empty">You don’t have new recent listeners<br/>or sales.</p>
                                : data.listeners.map((item, i) => <NotificationItem item={item} key={i} enableEvents={enableEvents} setTab={setTab} targetSelf/>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

function NotificationItem({ item, enableEvents, setTab, targetSelf }) {
    let icon = item.icon
        ? notificationIcons.filter(x => x.key == item.icon)[0]
        : null;

    let onClick = ev => {
        ev.preventDefault();
        api.user.notificationClick(item._id);
        if(item.actionType == "events" && enableEvents) {
            setTab(1);
        } else if(item.actionType == "url") {
            targetSelf ? window.location.href = item.action : window.open(item.action);
        }
    };

    return (
        <div className="notification-item d-flex" onClick={onClick}>
            {icon && <div className={"notification-icon icon-"+icon.key}>
                <SVG src={toAbsoluteUrl(icon.icon)} className="svg-icon"/>
            </div>}
            {(!icon && item.image) && <img src={item.image} className="notification-image"/>}
            <div className="notification-text">
                <strong>{item.title}</strong><br/>
                {item.subtitle}
            </div>
            <div className="notification-date gray-badge">{moment(item.createdAt).locale("en").fromNow(true)}</div>
        </div>
    );
}
