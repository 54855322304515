import React, { useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";

function SocialLoginResults(props) {
  let { source, status, token } = props.match.params;

  let url = "hiro://login/" + source + "/" + status + "/" + token;

  if(isMobile)
    window.location.href = url;
  
  return (
    <>
      <div className={"app-page " + (isMobile ? "" : "desktop")}>
        <div>
          <SVG src={toAbsoluteUrl("/media/logos/logo.svg")} className="logo" />
          {status == "success"
          ? (
            <>
              <h3>Success!</h3>
              <p>Go back to the app to continue.</p>
              {isMobile && <a href={url} className="btn mt-10 btn-primary">Open App</a>}
            </>
          )
          : (
            <>
              <h3>Something went wrong</h3>
              <p>The authorization failed or was denied. Go back to the app and try again.</p>
              {isMobile && <a href={url} className="btn mt-10 btn-primary">Back to the App</a>}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SocialLoginResults;