import React from "react";
import AdvanceTablesWidgetEpisodes from "./generic/AdvanceTablesWidgetEpisodes";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import "react-block-ui/style.css";
import "loaders.css/loaders.min.css";
import NewEpisodeDropdown from "../../../layout/dropdowns/NewEpisodeDropdown";

function Episodes({ match }) {
  return (
    <>
      <h1>
        Episodes
        <NewEpisodeDropdown productId={match.params.id} className="inline" toggleClassName="btn-plus" />
      </h1>

      <div className="episodes episodes-table">
        <AdvanceTablesWidgetEpisodes
          productId={match.params.id} />
      </div>
    </>
  );
}

export default injectIntl(connect(
  state => ({
    episodes: (state.episode || {}).episodes || [],
    productObj: (state.product || []).product || {},
  }),

  dispatch => ({
    ...actions.product,
    ...actions.episode,
    dispatch,
  }),
)(Episodes));
