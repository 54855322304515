import React from "react";
import api from "../redux/api";
import { UpgradeEvents } from "./pages/modals/UpgradeModal";

/**
 * 
 * @returns {Object}
 */
export let getPlans = async () => {
    return await api.user.getPlans();
};

/**
 * 
 */
export let isActive = user =>
    !user.noPlan;

/**
 * 
 * @returns {boolean}
 */
let upgradeOrActivate = (user, type = null, message) => {
    //if(user.noPlan) {
    //    ActivatePlanEvents.dispatchShow();
    //} else {
    //    if(type && type.sms)
    //        NeedsUpgradeEvents.dispatchShowForSms();
    //    else
    //        NeedsUpgradeEvents.dispatchShow(message);
    //}
    UpgradeEvents.dispatchShow({
        upgradeToAccess: true,
        title: message
    });
    return false;
};

export let checkLimits = {
    /**
     * 
     * @param {Object} user 
     */
    upgradeOrActivate: (user, type = null, message) => {
        upgradeOrActivate(user, type, message);
    },

    /**
     * 
     * @param {Object} user 
     * @param {Object[]} products 
     * @returns {boolean}
     */
    canAddProduct: async (user) => {
        if(user && user.planFeatures.products > -1 && user.planFeatures.products <= user.stats.productsCount) return upgradeOrActivate(user);
        return true;
    },

    /**
     * 
     */
    canAddKeyword: async (user) => {
        console.log(user.plan);
        if(user && (!user.planFeatures.smsAccess || (user.planFeatures.smsAccess > -1 && user.planFeatures.smsAccess <= user.stats.keywordsCount)))
            return upgradeOrActivate(user, { sms: true });
        return true;
    },

    /**
     * 
     */
    canAddAutomation: async (user) => {
        if(user && (!user.planFeatures.automations || (user.planFeatures.automations > -1 && user.planFeatures.automations <= user.stats.automationsCount)))
            return upgradeOrActivate(user);
        return true;
    },

    /**
     * 
     */
    canAddCampaign: async (user) => {
        return user && (!user.planFeatures.campaigns || (user.planFeatures.campaigns > -1 && user.planFeatures.campaigns <= user.stats.campaignsCount))
            ? upgradeOrActivate(user, { sms: true })
            : true;
    },

    /**
     * 
     */
    canAddEmailAddress: async (user) => {
        return user && (!user.planFeatures.emailAccounts || (user.planFeatures.emailAccounts > -1 && user.planFeatures.emailAccounts <= user.emailAddresses.length))
            ? upgradeOrActivate(user)
            : true;
    },

    /**
     * 
     */
    canAddDomain: async (user) => {
        return user && (!user.planFeatures.domains || (user.planFeatures.domains > -1 && user.planFeatures.domains <= user.domains.length))
            ? upgradeOrActivate(user)
            : true;
    },

    /**
     * 
     */
    canAddTag: async (user) => {
        return user && (!user.planFeatures.tags || (user.planFeatures.tags > -1 && user.planFeatures.tags <= user.tags.length))
            ? upgradeOrActivate(user)
            : true;
    },

    /**
     * 
     */
    canAddCategory: async (user) => {
        return user && (!user.planFeatures.categories || (user.planFeatures.categories > -1 && user.planFeatures.categories <= user.categories.length))
            ? upgradeOrActivate(user)
            : true;
    },

    /**
     * 
     */
    canAddContact: async user => {
        return user && (!user.planFeatures.contacts || (user.planFeatures.contacts > -1 && user.planFeatures.contacts <= user.stats.contactsCount))
            ? upgradeOrActivate(user)
            : true;
    },

    /**
     * 
     */
    canRequestTranscription: async user => {
        return user && (!user.planFeatures.transcriptions || (user.planFeatures.transcriptions > -1 && user.planFeatures.transcriptions <= user.stats.transcriptionsCount))
            ? upgradeOrActivate(user)
            : true;
    }
};