import React from "react";
import { Modal } from "react-bootstrap";

export default class ConfirmModal extends React.Component {
  render() {
    return <Modal
      show={this.props.show}
      onHide={this.props.onCancel}
      size="md"
      className={"confirmModal " + (this.props.childrenInFooter ? "children-in-footer " : "") + this.props.className}
    >
      {this.props.closeButton &&
        <button type="button" className="close" onClick={this.props.onCancel}>
          <img src="/media/def-image/icons/close.svg" alt="×" />
        </button>}
      <Modal.Body>
        {this.props.message ? this.props.message : <></>}
        {this.props.childrenInFooter ? <></> : this.props.children}
      </Modal.Body>
      <Modal.Footer>
        {this.props.childrenInFooter ? this.props.children : <></>}
        <button
          type="button"
          className={"btn mr-2 " + (this.props.confirmButtonClassName || (!this.props.default || this.props.default == "confirm" ? "btn-primary" : "btn-secondary")) + " " + (this.props.loading ? "loading spinner" : "")}
          onClick={this.props.onConfirm}>
          {this.props.confirmLabel ? this.props.confirmLabel : "Yes"}
        </button>
        <button
          type="button"
          className={"btn " + (this.props.cancelButtonClassName || (this.props.default == "cancel" ? "btn-primary" : "btn-secondary"))}
          onClick={this.props.onCancel}>
          {this.props.cancelLabel ? this.props.cancelLabel : "No"}
        </button>
      </Modal.Footer>
    </Modal>
  }
}
