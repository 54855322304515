import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Form } from "react-bootstrap";
import actions from "../../../../../redux/actions";
import api from "../../../../../redux/api";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import PasswordStrengthBar from "react-password-strength-bar";
import { ReCaptcha } from "react-recaptcha-v3";
import GoogleLogin from "../../../../GoogleLogin";
import { getInputClassName, toastMessage } from "../../../../helpers";

//TODO fix this awful markup

const initialValues = {
  email: "",
  password: "",
  confirmPassword: ""
};

let recaptchaShouldSubmit = false;

function SignupStep1(props) {
  const [loading, setLoading] = useState(false);
  let [revealPassword, setRevealPassword] = useState(false),
    [revealConfirmPassword, setRevealConfirmPassword] = useState(false);
  let recaptchaRef = useRef(null);
  let [googleError, setGoogleError] = useState(null),
    history = useHistory();

  function getLicenseKey() {
    let match = window.location.hash.match(/activate-appsumo-([a-f0-9-]+)/);
    if(match)
      return match[1];
  }

  useEffect(() => {
    props.logout();

    if(window.location.hash == "#invalid-code-appsumo-1" || window.location.hash == "#invalid-code-appsumo-2")
      toastMessage.error("Invalid license.");

    if(window.location.hash == "#invalid-code-appsumo-3")
      toastMessage.error("The license has already been activated.");

    if(window.location.hash == "#invalid-code-appsumo-4")
      toastMessage.error("Unable to validate the license, please try again later.");

    recaptchaShouldSubmit = false;
    if(/google_at/.test(window.location.hash)) setLoading(true);
  }, []);

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format.")
      .required("Required field."),
    password: Yup.string()
      .min(8, "Must be at least 8 characters long.")
      .required("Required field."),
    confirmPassword: Yup.string()
      .required("Passwords don’t match.")
      .oneOf([Yup.ref('password'), null], "Passwords don’t match.")
  });

  const formik = useFormik({
    initialValues,
    validationSchema: RegisterSchema,

    onSubmit: () => {
      setLoading(true);
      recaptchaShouldSubmit = true;
      recaptchaRef.current.execute();
    },
  });

  let signupSuccess = res => {
    props.setLogin(res.token, res.user);
    api.pub.event("CompleteRegistration");

    if(res.startSubscription)
      history.push("/auth/select-plan" + window.location.hash);
    else
      history.push("/home#onboarding");
  };

  let responseGoogle = response => {
    if(!response) return;
    setLoading(true);
    api.auth
      .signup(
        null,
        null,
        null,
        null,
        null,
        true,
        { google: response },
        { pendingSignup: window.location.hash.substr(1) },
        {},
        getLicenseKey()
      )
      .then(res => {
        if(res)
          signupSuccess(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  let failedGoogle = () => {
    setGoogleError(true);
  };

  let doSignup = token => {
    let values = formik.values;
    api.auth
      .signup(
        values.email,
        "", //values.phone
        values.password,
        values.firstName,
        values.lastName,
        true,
        null,
        token,
        {},
        getLicenseKey()
      )
      .then((res) => {
        window.trackdesk?.("hiro", "externalCid", {
          externalCid: values.email,
          revenueOriginId: "5be0139a-2fd9-4b43-b79e-0d0cdf53719b"
        });

        window.gtag("event", "signup");

        setLoading(false);
        if(res)
          signupSuccess(res);
      });
  };

  let recaptchaCallback = token => {
    if(!recaptchaShouldSubmit) return;
    doSignup(token);
  };

  return <>
    <h1>
      {getLicenseKey()
        ? "Activate your AppSumo deal"
        : "Create an account"}
    </h1>

    <Form onSubmit={formik.handleSubmit}>
      <GoogleLogin
        onSuccess={responseGoogle}
        onFailure={failedGoogle}
        scope="profile email"
        state={"signup-" + (window.location.hash && window.location.hash.substring(1))}
        label="Sign up with Google"
      />
      {googleError ? (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>Login with google failed.</div>
        </div>
      ) : ""}
      <div className="form-or">or</div>
      <div className="form-group fv-plugins-icon-container">
        <label className="form-label">Email</label>
        <input
          type="email"
          className={getInputClassName(formik, "email")}
          name="email"
          tabIndex="1"
          {...formik.getFieldProps("email")}
        />
        {formik.touched.email && formik.errors.email && <div className="field-error">{formik.errors.email}</div>}
      </div>

      <div className="form-group fv-plugins-icon-container">
        <div className="form-group-title">
          <label className="form-label">Password</label>
        </div>
        <div className="password-wrapper">
          <input
            type={revealPassword ? "text" : "password"}
            className={getInputClassName(formik, "password")}
            name="password"
            tabIndex="6"
            {...formik.getFieldProps("password")}
          />
          <button type="button" className="btn eye" onClick={() => setRevealPassword(v => !v)}><img src={toAbsoluteUrl("/media/def-image/icons/eye.svg")} /></button>
        </div>
        <PasswordStrengthBar className="password-strength" password={formik.values.password} barColors={["", "#16A9F5", "#16A9F5", "#16A9F5", "#16A9F5"]} />
        <p className="text-gray small m-0 text-center">Use 8 or more characters with a mix of letters, numbers &amp; symbols</p>
        {formik.touched.password && formik.errors.password && <div className="field-error">{formik.errors.password}</div>}
      </div>

      <div className="form-group fv-plugins-icon-container mb-10">
        <div className="form-group-title">
          <label className="form-label">Confirm password</label>
        </div>
        <div className="password-wrapper">
          <input
            type={revealConfirmPassword ? "text" : "password"}
            className={getInputClassName(formik, "confirmPassword")}
            name="confirmPassword"
            tabIndex="7"
            {...formik.getFieldProps("confirmPassword")}
          />
          <button type="button" className="btn eye" onClick={() => setRevealConfirmPassword(v => !v)}><img src={toAbsoluteUrl("/media/def-image/icons/eye.svg")} /></button>
        </div>
        {formik.touched.confirmPassword && formik.errors.confirmPassword && <div className="field-error">{formik.errors.confirmPassword}</div>}
      </div>

      <div className="form-group d-flex flex-wrap justify-content-between align-items-center mb-8">
        <button type="submit" className={"btn btn-primary " + (loading ? "loading spinner" : "")}>
          {getLicenseKey()
            ? "Activate your account"
            : "Create your account"}
        </button>
      </div>

      {getLicenseKey()
        ? (
          <div>
            Already purchased in AppSumo? <Link to={"/auth/login#activate-appsumo-" + formik.values.activateLicense} className="font-weight-semibold">Sign in</Link>
          </div>
        )
        : (
          <div>
            Already have an account? <Link to="/auth/login" className="font-weight-semibold">Sign in</Link>
          </div>
        )}
    </Form>

    <ReCaptcha
      ref={recaptchaRef}
      sitekey={process.env.REACT_APP_RECAPTCHA}
      verifyCallback={recaptchaCallback}
    />
  </>;
}

export default injectIntl(connect(null, actions.auth)(SignupStep1));
