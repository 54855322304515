import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { UpgradeEvents } from "./UpgradeModal";
import { getPlans } from "../../plans";
import { NeedsUpgradeEvents } from "./NeedsUpgrade";
import api from "../../../redux/api";
import { numberFormat, toastMessage } from "../../helpers";
import PurchaseSuccessModal from "./PurchaseSuccessModal";
import actions from "../../../redux/actions";

export function NeedsUpgradeForSMS({}) {
  let [loading, setLoading] = useState(true),
    [upgrading, setUpgrading] = useState(false),
    [show, setShow] = useState(false),
    [showSuccess, setShowSuccess] = useState(false),
    [price, setPrice] = useState(),
    [plan, setPlan] = useState(),
    [period, setPeriod] = useState(),
    [oneTapUpgrade, setOneTapUpgrade] = useState(),
    user = useSelector(state => state.auth.user, shallowEqual),
    dispatch = useDispatch(),
    [title, setTitle] = useState(null);

  let loadData = async () => {
    setLoading(true);
    setUpgrading(false);

    let plans = await getPlans(),
      period = user.newPlan.period || "monthly",
      currentPrice = user.planFeatures.price && user.planFeatures.price[period],
      newPlan = user.nextPlan,
      newPrice = plans[newPlan].price[period];

    let res = await api.checkout.getCustomer(true);
    if(res && res.data && res.data.paymentMethod)
      setOneTapUpgrade(true);

    if(period == "annual") {
      if(currentPrice)
        currentPrice /= 12;
      newPrice /= 12;
    }

    setPlan(plans[newPlan]);
    setPeriod(period);
    setPrice(currentPrice && currentPrice < newPrice
      ? <><strong>${numberFormat(newPrice - currentPrice)}</strong> a month more</>
      : <><strong>${numberFormat(newPrice)}</strong> a month</>);

    setLoading(false);
  };

  let goToCheckout = () => {
    setShow(false);
    UpgradeEvents.dispatchShow();
  };

  let confirmOnClick = async ev => {
    if(upgrading)
      return;

    if(!oneTapUpgrade)
      return goToCheckout();

    setUpgrading(true);

    let res = await api.checkout.createSubscription(null, plan.name, period, "25OFF");

    if(!res || !res.success) {
      toastMessage.error("Unable to process the request. Please, check your payment method and try again.");
      return goToCheckout();
    }

    api.pub.event("Purchase", { value: plan.price[period], currency: "USD" });

    checkUser();
  };

  let checkUser = async () => {
    let user = await api.auth.getUserByToken(true);
    if(user.data.newPlan.name == plan.name) {
      dispatch(actions.auth.fulfillUser(user.data));

      setShow(false);
      setShowSuccess(true);
    } else {
      setTimeout(checkUser, 500);
    }
  };

  useEffect(() => {
    NeedsUpgradeEvents.on("showNeedsUpgradeForSMS", ev => {
      setShow(true);
      setTitle(ev.message);
    });

    if(user && show)
      loadData();
  }, [user, show]);

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} size="lg" centered className={"new-modals needs-upgrade-for-sms-modal " + (loading ? "loading" : "")}>
        {loading
          ? (
            <Modal.Body className="p-30 spinner spoinner-sm spinner-full" />
          )
          : (
            <>
              <Modal.Body className="pt-15 pb-10 px-10">
                <button type="button" className="close" onClick={() => setShow(false)}><SVG src="/media/def-image/close.svg" className="svg-icon" /></button>

                {title
                  ? <h2>{title}</h2>
                  : <h2>Upgrade to {plan.title}</h2>}

                <p>Increase business with these additional features</p>

                <div className="d-flex align-items-center pt-10 features">
                  <div className="col-sm-6 pr-10">
                    <SVG src="/media/def-image/upgrade.svg" />
                  </div>
                  <div className="col-sm-6 text-left">
                    <ul>
                      <li>Send automations to increase engagement</li>
                      <li className="text-dark">Keyword opt-in to grow your list</li>
                      <li>Create conversations with listeners</li>
                      <li>Send  SMS, emails, notifications and more</li>
                      <li>And lots more!</li>
                    </ul>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="py-10 px-30 gray-bg text-dark align-items-center d-block d-lg-flex">
                <div className="flex-grow-1 mb-5 mb-lg-0 text-left">
                  <p className="fs-2">For only {price},</p>
                  <p className="fs-5">you can supercharge your impact & income</p>
                  {oneTapUpgrade && <p className="fs-1 mt-3">Coupon code <strong>25OFF</strong> automatically applied</p>}
                </div>
                <div className="d-flex flex-column align-items-center text-nowrap">
                  <button type="button" className={"btn btn-primary " + (upgrading ? "loading spinner" : "")} onClick={confirmOnClick}>
                    {oneTapUpgrade ? "Confirm Upgrade" : "Pick Your Plan"}
                  </button>
                  {oneTapUpgrade && <small className="mt-3 text-muted">Your card will automatically be charged</small>}
                </div>
              </Modal.Footer>
            </>
          )}
      </Modal>

      <PurchaseSuccessModal show={showSuccess} close={() => setShowSuccess(false)} hasProducts={1} />
    </>
  );
}