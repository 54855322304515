import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import AIButton from "./AIButton";

function processResponse(text) {
    //let match = text.match(/^[\w\s]+?: (.+)/);
    //if(match)
    //    text = match[1];
    text = text.trim();
    if(text.substr(0, 1) == "\"" && text.substr(-1) == "\"") {
        text = text.substr(1);
        text = text.substr(0, text.length - 1);
    }
    return text;
}

function AIField({ user, source, formik, name, include, editor, onClick, prompt, onResponse: userOnResponse, partialPrompt, className, children, data, loading, onRender, autoReplace }) {
    function onResponse(text) {
        if(userOnResponse)
            userOnResponse(text);

        if(formik && name && autoReplace)
            formik.setFieldValue(name, processResponse(text));
    }

    function onCopy(text) {
        if(formik && name && !autoReplace)
            formik.setFieldValue(name, processResponse(text));
    }

    return (
        <div className={"ai-field " + (editor ? "editor " : "") + (className || "")}>
            <AIButton chat loading={loading} onClick={onClick} prompt={prompt} include={include} source={source} data={data} partialPrompt={partialPrompt} onResponse={onResponse} onCopy={onCopy} />
            {onRender
                ? onRender()
                : children}
        </div>
    );
}

export default injectIntl(connect(
    store => ({
        user: store.auth.user
    })
)(AIField));