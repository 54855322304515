import React from "react";
import { CardElement, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import SVG from "react-inlinesvg";

export default function CreditCard({ edit, onEdit, customer, paymentRequest = null, cardFeedback }) {
    return (
        <>
            {!edit && customer && customer.paymentMethod
                ? (
                    <div className="d-flex border rounded current-payment-method align-items-center mb-10 p-6">
                        <SVG src={"/media/svg/logos/" + customer.paymentMethod.brand + ".svg"} className="svg-icon" />
                        <div className="flex-grow-1">
                            <strong>{{
                                amex: "AMEX ",
                                diners: "Diners ",
                                discover: "Discover ",
                                jcb: "JCB ",
                                mastercard: "Master ",
                                unionpay: "Union ",
                                visa: "Visa ",
                                unknown: "Unkown "
                            }[customer.paymentMethod.brand]}
                                &middot;&middot;&middot;&middot; &middot;&middot;&middot;&middot; &middot;&middot;&middot;&middot; {customer.paymentMethod.last4}
                            </strong><br />
                            <span className="fs-7 text-gray">Expires {customer.paymentMethod.exp_month.toString().padStart(2, "0")}/{customer.paymentMethod.exp_year.toString().substr(2)}</span>
                        </div>
                        <button type="button" onClick={ev => onEdit(true)} className="btn btn-secondary">Edit</button>
                    </div>
                )
                : (
                    <div className="edit-payment-method mb-10">
                        {paymentRequest
                            ? <PaymentRequestButtonElement className="pay-button" options={{
                                paymentRequest,
                                style: {
                                    paymentRequestButton: {
                                        theme: "dark",
                                        height: "45px"
                                    }
                                }
                            }} />
                            : <></>}
                        <div className="form-group mb-0">
                            <div className="form-control pt-4">
                                <CardElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: "14px",
                                                fontFamily: "Poppins, Helvetica, 'sans-serif'",
                                                color: "#181c32",
                                                "::placeholder": {
                                                    color: "#181c32",
                                                },
                                            },
                                            invalid: {
                                                color: "#f1416c",
                                            },
                                        },
                                    }}
                                />
                            </div>
                            {cardFeedback && <div className="invalid-feedback-copy">{cardFeedback}</div>}
                        </div>
                    </div>
                )}
        </>
    );
}