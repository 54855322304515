import React, { useEffect, useRef, useState } from "react";
import api from "../../../redux/api";
import { Link } from "react-router-dom";
import { Pagination, Table } from "react-bootstrap";
import { getPlans } from "../../plans";
import moment from "moment";

export default function Users() {
	let [page, setPage] = useState(1),
		[pages, setPages] = useState(0),
		[count, setCount] = useState(0),
		[list, setList] = useState([]),
		[plans, setPlans] = useState({}),
		filterRef = useRef(),
		planRef = useRef(),
		billingRef = useRef();

	let init = async () => {
		setPlans(await getPlans());
	};

	let load = async (pg = null) => {
		if(pg) {
			setPage(pg);
		} else {
			pg = page;
		}
		let res = null;
		try {
			res = await api.admin.getUsers(pg, {
				search: filterRef.current.value,
				plan: planRef.current.value,
				billing: billingRef.current.value
			});
			if(res) {
				setList(res.data);
				setPages(res.pages);
				setCount(res.count);
			}
		} catch(x) { }
	};

	useEffect(() => {
		init();
		load();
	}, []);

	if(!plans) return <></>;

	return <div>
		<h1>Users</h1>

		<div className="card p-5">
			<form onSubmit={ev => {
				ev.preventDefault();
				load(1);
			}}>
				<div className="row g-2 justify-content-end mb-5">
					<div className="col-12 col-md-4 pt-2">{count} users found.</div>
					<div className="col-12 col-md-3 mb-3 mb-md-0">
						<input type="text" ref={filterRef} className="form-control form-control-sm" placeholder="Search..." />
					</div>
					<div className="col-5 col-md-2">
						<select className="custom-select form-control-sm" ref={planRef} onChange={ev => load(1)}>
							<option value="">Any plan</option>
							{Object.entries(plans).map(([name, item]) => <option key={name} value={name}>{item.title}</option>)}
						</select>
					</div>
					<div className="col-5 col-md-2">
						<select className="custom-select form-control-sm" ref={billingRef} onChange={ev => load(1)}>
							<option value="">Any billing period</option>
							<option value="monthly">Monthly</option>
							<option value="annual">Annual</option>
						</select>
					</div>
					<div className="col-2 col-md-1">
						<button type="submit" className="btn btn-sm btn-secondary d-block">Go</button>
					</div>
				</div>
			</form>

			<Table striped bordered={false} hover responsive size="sm">
				<thead>
					<tr>
						<th>ID</th>
						<th style={{ minWidth: 150 }}>Full Name</th>
						<th style={{ minWidth: 250 }}>Email</th>
						<th style={{ minWidth: 150 }}>Phone</th>
						<th style={{ minWidth: 120 }}>Plan</th>
						<th style={{ minWidth: 120 }}>Billing period<br />Expiration</th>
						<th>Stripe customer<br />Subscription</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{list.map((item, i) =>
						<tr key={i}>
							<td>{item._id}</td>
							<td>{item.firstName ? item.firstName + " " + item.lastName : "(no name)"}</td>
							<td>{item.email}</td>
							<td>{item.phone}</td>
							<td className={item.noPlan ? "text-decoration-line-through" : ""}>
								{item.newPlan.name}
							</td>
							<td>
								{item.newPlan && item.newPlan.period
									? [
										{ monthly: "Monthly", annual: "Annual" }[item.newPlan.period],
										<br />
									]
									: ""}
								{item.newPlan && item.newPlan.expiration && item.newPlan.expiration > new Date
									? "Exp. " + moment(item.newPlan.expiration).locale("en").format("MMM D, YYYY")
									: "Expiration not set"}
							</td>
							<td>
								{item.stripeCustomerId
									? <><a href={"https://dashboard.stripe.com/customers/" + item.stripeCustomerId} target="_blank">{item.stripeCustomerId}</a><br /></>
									: ""}
								{item.stripeSubscriptionId
									? <a href={"https://dashboard.stripe.com/subscriptions/" + item.stripeSubscriptionId} target="_blank">{item.stripeSubscriptionId}</a>
									: ""}
							</td>
							<td>
								<Link to={"/admin/notification?to=" + item._id} className="btn btn-primary btn-sm">Send notification</Link>
							</td>
						</tr>
					)}
				</tbody>
			</Table>

			<Pagination size="sm" className="justify-content-center mt-5">
				{(() => {
					let items = [];
					for(let i = 1; i <= pages; i++)
						items.push(<Pagination.Item key={i} active={i == page} onClick={ev => load(i)}>{i}</Pagination.Item>);
					return items;
				})()}
			</Pagination>
		</div>
	</div>;
}
