import Rollbar from 'rollbar';

export const RollbarErrorTracking = (() => {
  let RollbarObj;

  if (process.env.NODE_ENV === 'production') {
    RollbarObj = new Rollbar({
      accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
    });
  }

  const logErrorInfo = info => {
    if (RollbarObj && process.env.NODE_ENV === 'production') {
      RollbarObj.info(info);
    }
  };

  const logErrorInRollbar = error => {
    //console.log(error)
  };

  return { logErrorInfo, logErrorInRollbar };
})();

export default RollbarErrorTracking;
