import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { notificationIcons } from "../../helpers";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import api from "../../../redux/api";
import AwsS3Dropzone from "../generic/AwsS3Dropzone";

export default function SendPushNotification() {
	let [showConfirm, setShowConfirm] = useState(false),
		[loading, setLoading] = useState(false),
		[showSuccess, setShowSuccess] = useState(false),
		[error, setError] = useState(null),
		[imageUploadError, setImageUploadError] = useState(null),
		[iconUploadError, setIconUploadError] = useState(null),
		formik = useFormik({
			initialValues: {
				sendTo: "",
				title: "",
				text: "",
				icon: "",
				image: "",
				actionType: "none",
				actionLabel: "",
				action: "",
			},
			onSubmit: async () => {
				setLoading(true);
				setShowSuccess(false);
				setError(null);

				let res = await api.admin.sendAppNotification(formik.values);

				setLoading(false);

				if(res.success) {
					setShowSuccess(true);
					formik.resetForm();
				} else {
					setError(res.error);
				}
			}
		});

	useEffect(() => {
		let to = new URLSearchParams(window.location.search).get("to");
		if(to) formik.setFieldValue("sendTo", to);
	}, []);

	let onImageUploadComplete = files => {
		if(files && files.length == 1)
			formik.setFieldValue("image", files[0].amazonS3Url);
	};

	let onImageUploadError = () => {
		setImageUploadError("Invalid file");
	};

	let onIconUploadComplete = files => {
		if(files && files.length == 1)
			formik.setFieldValue("icon", files[0].amazonS3Url);
	};

	let onIconUploadError = () => {
		setIconUploadError("Invalid file");
	};

	return <div>
		<h1>Send push notification (App)</h1>

		{showSuccess && <div className="alert alert-success">Notification sent.</div>}
		{error && <div className="alert alert-warning">{error}</div>}

		<div className="card p-5">
			<form onSubmit={ev => {
				ev.preventDefault();
				setShowConfirm(true);
			}}>
				<label>Send to<br /><small>One <strong>email</strong> per line, leave blank to send to everybody.</small></label>
				<textarea className="form-control form-control-sm h-150 mb-3" {...formik.getFieldProps("sendTo")} />

				<label>Title</label>
				<input type="text" className="form-control form-control-sm mb-3" {...formik.getFieldProps("title")} />

				<label>Text</label>
				<input type="text" className="form-control form-control-sm mb-3" {...formik.getFieldProps("text")} />

				<label>Icon<br /><small>Optional.</small></label>
				<div className={"dropzone-artwork-container dropzone-field mb-3 " + (formik.values.icon ? "uploaded" : "")}>
					<AwsS3Dropzone
						allowMultipleUpload={false}
						onError={onIconUploadError}
						onUploadComplete={onIconUploadComplete}
						uploadType="images"
						fileType="image/*"
						errorMessage={iconUploadError}
						description="Select image file" />
					<div className="dropzone-uploaded-image">
						<div className="image-input image-input-outline" style={{ backgroundImage: formik.values.icon ? "url(" + formik.values.icon + ")" : "" }}>
							<span onClick={() => formik.setFieldValue("icon", null)} className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove"><i className="ki ki-bold-close icon-xs text-muted" /></span>
						</div>
					</div>
				</div>

				<label>Image<br /><small>Optional.</small></label>
				<div className={"dropzone-artwork-container dropzone-field mb-3 " + (formik.values.image ? "uploaded" : "")}>
					<AwsS3Dropzone
						allowMultipleUpload={false}
						onError={onImageUploadError}
						onUploadComplete={onImageUploadComplete}
						uploadType="images"
						fileType="image/*"
						errorMessage={imageUploadError}
						description="Select image file" />
					<div className="dropzone-uploaded-image">
						<div className="image-input image-input-outline" style={{ backgroundImage: formik.values.image ? "url(" + formik.values.image + ")" : "" }}>
							<span onClick={() => formik.setFieldValue("image", null)} className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove"><i className="ki ki-bold-close icon-xs text-muted" /></span>
						</div>
					</div>
				</div>

				<label>Action</label>
				<select className="custom-select form-control-sm mb-3" {...formik.getFieldProps("actionType")}>
					<option value="none">None</option>
					<option value="url">Open URL</option>
					<option value="app-product">Product</option>
				</select>

				{formik.values.actionType != "none" && <>
					<label>Button label</label>
					<input type="text" className="form-control form-control-sm mb-3" {...formik.getFieldProps("actionLabel")} />
				</>}

				{formik.values.actionType == "url" && <>
					<label>URL</label>
					<input type="text" className="form-control form-control-sm mb-3" {...formik.getFieldProps("action")} />
				</>}

				{formik.values.actionType == "app-product" && <>
					<label>Product ID</label>
					<input type="text" className="form-control form-control-sm mb-3" {...formik.getFieldProps("action")} />
				</>}

				<button type="submit" className="btn btn-sm btn-primary mr-2" disabled={loading}>Send</button>
				<button type="button" className="btn btn-sm btn-secondary" onClick={ev => formik.resetForm()}>Reset</button>
			</form>
		</div>

		<Modal size="md" centered show={showConfirm} onHide={() => setShowConfirm(false)} className="admin-confirm-modal">
			<Modal.Body>Are you sure you want to continue? <strong>This action can't be stopped or undone.</strong></Modal.Body>
			<Modal.Footer>
				<button type="button" className="btn btn-sm btn-primary mr-2" onClick={ev => {
					setShowConfirm(false);
					formik.handleSubmit();
				}}>Yes</button>
				<button type="button" className="btn btn-sm btn-secondary" onClick={ev => setShowConfirm(false)}>No</button>
			</Modal.Footer>
		</Modal>
	</div>;
}
