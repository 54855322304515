import React from "react";
import { checkLimits } from "../../plans";

export default function OnHoldBadge({ user, className }) {
    return (
        <a href="#" className={"badge on-hold-badge " + (className || "")} onClick={ev => {
            ev.preventDefault();
            checkLimits.upgradeOrActivate(user, null, "Upgrade to get more contacts");
        }}>
            <em className="dot" />
            On Hold
        </a>
    );
}