export const icons = [
    {
        id: 'icon-1',
        resource: 'no-icon'
    },
    {
        id: 'icon-2',
        resource: 'icon-2'
    },
    {
        id: 'icon-3',
        resource: 'icon-3'
    },
    {
        id: 'icon-4',
        resource: 'icon-4'
    },
    {
        id: 'icon-5',
        resource: 'icon-5'
    },
    {
        id: 'icon-6',
        resource: 'icon-6'
    },
    {
        id: 'icon-7',
        resource: 'icon-7'
    },
    {
        id: 'icon-8',
        resource: 'icon-8'
    },
    {
        id: 'icon-9',
        resource: 'icon-9'
    },
    {
        id: 'icon-10',
        resource: 'icon-10'
    },
    {
        id: 'icon-11',
        resource: 'icon-11'
    },
    {
        id: 'icon-12',
        resource: 'icon-12'
    },
];
