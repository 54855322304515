export {default as useFetch} from './use-fetch';
export {default as toastMessage} from './toast-message';
export {default as appContext} from './app-context';
export { endings } from './endings';
export { RollbarErrorTracking } from './rollbar-error-tracking';
export { default as CustomErrorTracking } from './custom-error-tracking';
export { default as ErrorBoundary } from './error-boundary';
export * from "./form-utils.js";
export * from "./utils.js";

export let notificationIcons = [
  { key: "failed-sale", icon: "/media/def-image/icons/failed-sale-notification.svg" },
  { key: "listener", icon: "/media/def-image/icons/listener-notification.svg" },
  { key: "rss", icon: "/media/def-image/icons/rss-notification.svg"},
  { key: "sale", icon: "/media/def-image/icons/sale-notification.svg"},
  { key: "update", icon: "/media/def-image/icons/update-notification.svg"},
  { key: "youtube", icon: "/media/def-image/icons/youtube-notification.svg"},
  { key: "zapier", icon: "/media/def-image/icons/zapier-notification.svg"},
  { key: "sms", icon: "/media/def-image/icons/sms-notification.svg"}
];