import React, { useEffect, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import ImportEvents from "./Import";
import { Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import api from "../../../../redux/api";
import { Scrollbars } from "react-custom-scrollbars";
import { toastMessage, updateCurrentUser, updateProducts } from "../../../helpers";
import { useHistory } from "react-router-dom";

require("dotenv").config();

let abortSearch = null,
  working = false,
  currentUrl = null;

const RssImport = props => {
  let [show, setShow] = useState(false),
    [step, setStep] = useState(1),
    [loading, setLoading] = useState(false),
    [loadingFeedBtn, setLoadingFeedBtn] = useState(null),
    [loadingImportBtn, setLoadingImportBtn] = useState(false),
    [loadingConfirmBtn, setLoadingConfirmBtn] = useState(false),
    [showResults, setShowResults] = useState(false),
    [showImportButton, setShowImportButton] = useState(false),
    [results, setResults] = useState(false),
    [feedData, setFeedData] = useState(null),
    searchRef = useRef(),
    history = useHistory();

  useEffect(() => {
    ImportEvents.on("showRssImport", () => {
      setStep(1);
      setShow(true);
      reset();
    });
  }, []);

  let close = () => {
    setShow(false);
  };

  let searchApplePodcasts = async val => {
    try {
      abortSearch = new AbortController();
      //Not using the api object so we can abort it easier
      let res = await fetch(process.env.REACT_APP_SERVER_URL + "api/itunes-search?term=" + encodeURIComponent(val), { signal: abortSearch.signal });
      return (await res.json()).results;
    } catch(e) {
      return null;
    }
  };

  let reset = () => {
    setLoading(false);
    setShowResults(false);
    setShowImportButton(false);
    setLoadingFeedBtn(null);
    setLoadingImportBtn(false);
    setLoadingConfirmBtn(false);
    working = false;
  };

  let search = async ev => {
    var val = ev.target.value.trim();

    if(abortSearch) abortSearch.abort();

    if(!val) {
      setLoading(false);
      setShowResults(false);
      setShowImportButton(false);
      return;
    }

    if(/^https?:\/\/.+/i.test(val)) {
      setLoading(false);
      setShowResults(false);
      setShowImportButton(true);
      return;
    }

    setLoading(true);
    setShowResults(true);
    setShowImportButton(false);

    let res = await searchApplePodcasts(val);

    setLoading(false);
    setShowResults(true);
    setResults(res);
  };

  let beginImport = async (url = null, buttonIdx = null) => {
    if(working) return;
    working = true;

    if(buttonIdx) {
      setLoadingFeedBtn(buttonIdx);
    } else {
      setLoadingImportBtn(true);
    }

    if(!url)
      url = searchRef.current.value;

    currentUrl = url;

    let res = await api.product.tryImportRss(url);

    reset();

    if(!res || !res.success)
      return;

    setFeedData(res.feed);

    setStep(2);
  };

  let confirmImport = async () => {
    if(working) return;
    working = true;

    setLoadingConfirmBtn(true);

    let res = await api.product.importRss(currentUrl);
    if(!res || !res.success) return reset();

    await updateCurrentUser(props.dispatch);

    await updateProducts(props.dispatch);

    toastMessage.success("Email sent. Go to your inbox and confirm ownership.");

    reset();
    setShow(false);
    history.push("/products");
  };

  return <>
    <Modal
      show={show}
      onHide={close}
      size="lg"
      centered
      className="new-modals rss-import-modal"
    >
      <Modal.Body className="py-20 text-center">
        <button type="button" className="close" onClick={close}><SVG src={toAbsoluteUrl("/media/def-image/close.svg")} className="svg-icon" /></button>

        {step == 1 ? <>
          <h1>Let’s import an existing podcast</h1>
          <p>Import your existing podcast by searching Apple Podcasts or by adding your RSS Feed.</p>
          <input type="text" className="form-control search-field" placeholder="Search Apple Podcasts" disabled={loadingFeedBtn || loadingImportBtn} onInput={search} ref={searchRef} />
          <p className="fs-7">You can always paste in your RSS feed url.</p>

          {showResults
            ? <div className={"search-results " + (loading ? "loading spinner spinner-center" : "")}>
              {results && !loading && (results.length
                ? <Scrollbars
                  autoHeight
                  hideTracksWhenNotNeeded
                  className="scrollbar-view"
                  renderTrackVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="scrollbar-track" />}
                  renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="scrollbar-thumb" />}>
                  {results.map((item, i) => (
                    <div className="search-results-podcast" key={i}>
                      <img src={item.artworkUrl60} className="podcast-cover" />
                      <div className="podcast-name">
                        <strong>{item.collectionName}</strong>
                        {item.trackCount} episodes
                      </div>
                      <button type="button" className={"btn btn-primary " + (loadingFeedBtn ? (loadingFeedBtn == i ? "loading spinner" : "disabled") : "")} onClick={ev => beginImport(item.feedUrl, i)}>Import</button>
                    </div>
                  ))}
                </Scrollbars>
                : <p className="empty-search">We can’t find any podcasts with that name.</p>)}
            </div>
            : <></>}

        </> : <></>}

        {step == 2 ? <>
          <h1>It’s almost ready! Confirm ownership</h1>
          <p>We’ll send and email to <strong>{feedData.email}</strong> to confirm your ownership. After that we’ll import your podcast.</p>
          <div className="product-card">
            <div className="product-image">
              <img src={feedData.image} />
            </div>
            <div className="product-details">
              <strong>{feedData.title}</strong><br />
              {feedData.author}<br />
              <SVG src={toAbsoluteUrl("/media/def-image/episodes.svg")} className="svg-icon" /> {feedData.episodes} episodes
            </div>
          </div>
          <div className="options">
            <button type="button" className="btn btn-secondary" disabled={loadingConfirmBtn} onClick={ev => setStep(1)}>Choose different podcast</button>
            <button type="button" className={"btn btn-primary " + (loadingConfirmBtn ? "loading spinner" : "")} onClick={ev => confirmImport()}>Send verification email</button>
          </div>

        </> : <></>}
      </Modal.Body>

      {showImportButton
        ? <Modal.Footer className="gray-bg justify-content-center">
          <button type="button" className={"btn btn-primary " + (loadingImportBtn ? "loading spinner" : "")} onClick={ev => beginImport()}>Import</button>
        </Modal.Footer>
        : <></>}
    </Modal>
  </>;
};

export default injectIntl(connect(
  state => ({
    user: state.auth.user,
  }),
  dispatch => ({
    dispatch,
    ...actions.product,
  })
)(RssImport));
