import React, { useEffect } from "react";
import { Message } from "./common";

function LastMessage({ next }) {
    useEffect(() => {
        setTimeout(next, 500);
    }, []);

    return (
        <>
            <Message>
                You’re all set. Any questions, just hit the question mark at the bottom of the screen for help.
            </Message>
        </>
    );
}

export default LastMessage;