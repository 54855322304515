import React from 'react';
import { CustomErrorTracking } from '../helpers';
export const ErrorBoundary = CustomErrorTracking((props, error) => {
  return error
    ? <div className="error-screen">
        <h2>Something went wrong</h2>
        <h4>{error.message}</h4>
      </div>
    : <>{props.children}</>;
});

ErrorBoundary.propsTypes = {
  children: React.ReactNode
};

export default ErrorBoundary;
