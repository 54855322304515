import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import _ from "lodash";
import MessageField from "../../../layout/components/MessageField";
import utils from "../utils";

let previewTimer;

function SendPush({ automationId, products, submit, name, formik, saving, trigger, triggerIndex, actionIndex, remove, setPreviewBody, setPreviewSettings, isNew }) {
  let { set, get, error, props, touched } = utils.useFormikUtils(formik, name);

  let updatePreview = () => {
    let title = get("title"),
      message = get("message"),
      attachment = get("attachment");

    let replace = v => v.replace(/\[name(\|.+?)?\\]/g, "John Carter")
      .replace(/\[email\]/g, "john@test.com")
      .replace(/\[install-url\]/g, "https://go.hiro.fm/abc")
      .replace(/\[first-name(\|.+?)?\\]/g, "John");

    let body = (
      <div className="push-preview">
        <div className="body">
          <img src="/media/def-image/icons/preview-hiro.png" className="icon" />
          <div className="fill d-flex align-items-start">
            <div className="fill">
              <p className="title">{title || "My Awesome Title"}</p>
              {message && message.trim() && <p className="message">{replace(message)}</p>}
            </div>
          </div>
        </div>
        {attachment && attachment.match(/^https?:\/\/.+?(\..+)/)
          ? <img className="attachment" src={attachment} onLoad={ev => ev.target.classList.add("show")} onError={ev => ev.target.classList.remove("show")} />
          : <img className="attachment show" src="/media/sample-image.png" />}
      </div>
    );

    setPreviewBody(body);
  };

  let actionTypeOnChange = ev => {
    set("actionType", ev.target.value);
    set("action", ev.target.value == "app-product"
      ? formik.values.products[0]
      : "");
  };

  useEffect(() => {
    setPreviewSettings({
      show: true,
      className: "push",
      title: "Push Notification Preview"
    });

    updatePreview();

    return () => setPreviewSettings({ show: false });
  }, []);

  useEffect(() => {
    if(isNew) {
      set("actionType", "url");
      set("attachment",
        //"http://localhost:8080/media/sample-image.png"
        process.env.REACT_APP_SERVER_URL + "media/sample-image.png");
    }
  }, [isNew]);

  useEffect(() => {
    clearTimeout(previewTimer);
    previewTimer = setTimeout(updatePreview, 500);

    //if(!get("_id"))
    //  set("attachment"
    //  );
  }, [formik.values]);

  useEffect(() => {
    set("changed", true);
  }, []);

  return (
    <>
      <div className="form-group">
        <div className="d-flex">
          <label className="fill form-label">Title</label>
          <span className="text-muted">
            {(get("title") || "").length} / 32
          </span>
        </div>
        <input type="text" className="form-control" {...props("title")} onKeyDown={utils.disableIntro} />
        {error("title") && touched("title") && <div className="field-error">{error("title")}</div>}
      </div>

      <div className="form-group">
        <label className="form-label">Message</label>
        <MessageField formik={formik} name={name + ".message"} maxLength={150} />
        {error("message") && <div className="field-error">{error("message")}</div>}
      </div>

      <div className="form-group">
        <label className="form-label">
          Attached Image or Giphy<br />
          <span className="help-text">Paste the URL below.</span>
        </label>
        <input type="text" className="form-control" {...props("attachment")} onKeyDown={utils.disableIntro} />
        {error("attachment") && touched("attachment") && <div className="field-error">{error("attachment")}</div>}
      </div>

      <div className="form-group">
        <div className="d-flex">
          <div className="flex-1">
            <label className="form-label">Action Button Text</label>
            <input type="text" className="form-control" {...props("actionLabel")} onKeyDown={utils.disableIntro} placeholder="Learn more" />
          </div>
          <div className="flex-1 ml-3">
            <label className="form-label">Go to</label>
            <select className="custom-select" {...props("actionType")} onChange={actionTypeOnChange}>
              <option value="url">Custom URL</option>
              <option value="app-product">Audio show</option>
            </select>
          </div>
          {(get("actionType") == "url" || !get("actionType")) && (
            <div className="fill ml-3">
              <label className="form-label">Custom URL</label>
              <input type="text" className="form-control" {...props("action")} onKeyDown={utils.disableIntro} />
            </div>
          )}
          {/*get("actionType") == "app-product" && (
            <div className="fill ml-3">
              <label>Show</label>
              <select className="custom-select" {...props("action")}>
                <option value=""></option>
                {products.map((product, i) => <option key={i} value={product._id}>{product.name}</option>)}
              </select>
            </div>
          )*/}
        </div>
        {error("action") && (touched("actionLabel") || touched("actionType") || touched("action")) && <div className="field-error">{error("action")}</div>}
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      dispatch
    })
  )(SendPush)
);