import React, { useEffect, useRef, useState } from "react";
import { Message, Send, Writting } from "../../../modals/onboarding/common";
import HasNameMessage from "./HasNameMessage";

export default function ToneMessage({ user, next, state }) {
    const [answer, setAnswer] = useState(null),
        [sent, setSent] = useState(false),
        [loading, setLoading] = useState(false),
        [invalid, setInvalid] = useState(false),
        inputRef = useRef();

    const submit = () => {
        if(!answer)
            return setInvalid(true);

        setInvalid(false);
        setSent(true);
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
            next(HasNameMessage, { style: answer });
        }, 2000);
    };

    const inputOnChange = ev =>
        setAnswer(ev.target.value.trim());

    const inputOnKeyDown = ev => {
        if(ev.which == 13) {
            ev.preventDefault();
            submit();
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if(inputRef.current)
                inputRef.current.focus();
        }, 500);
    }, [inputRef]);

    return (
        <>
            <Message fullWidth={!sent}>
                What tone of voice do you want?

                {!sent && (
                    <div className={"input " + (invalid ? "invalid " : "") + (answer ? "" : " empty")}>
                        <input type="text" className="form-control" ref={inputRef} onChange={inputOnChange} onKeyDown={inputOnKeyDown} />
                        <Send onClick={submit} />
                    </div>
                )}
            </Message>

            {sent && <Message incoming>{answer}</Message>}

            {loading && <Writting delay={1000} />}
        </>
    );
}
