import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../redux/actions";
import api from "../../../redux/api";
import { numberFormat, toastMessage } from "../../helpers";

/**
 * 
 */
function AddCreditsModal({ dispatch, user }) {
    let [show, setShow] = useState(false),
        [loading, setLoading] = useState(false),
        [error, setError] = useState(null),
        min = user && user.planFeatures.credits && user.planFeatures.credits.additional[0].amountFrom,
        max = user && user.planFeatures.credits && user.planFeatures.credits.additional.slice(-1).amountTo,
        [amount, setAmount] = useState(min),
        [isValid, setIsValid] = useState(true),
        amountRef = useRef(),
        [title, setTitle] = useState(null);

    //toastMessage.success("Your purchase has been processed. Thank you!");
    //setShow(false);
    //toastMessage.error(reason || "Unable to process the payment. Please, try again later.");

    let getTotal = v => {
        for(let item of user.planFeatures.credits.additional)
            if(v >= item.amountFrom && v <= item.amountTo)
                return v * item.unitPriceCents / 100;

        return null;
    };

    let amountOnChange = ev => {
        let v = ev.target.value;

        setAmount(v);
        setIsValid(false);
        setError(null);

        if(!v.match(/^[0-9]+$/))
            return setError("Enter a valid number.");

        if(v < min || v > max)
            return setError("Enter a value between " + numberFormat(min) + " and " + numberFormat(max) + ".");

        setIsValid(true);
    };

    let rowOnClick = item => {
        let v = item.amountFrom;
        setAmount(v);
        setIsValid(true);
    };

    let updateUser = async () =>
        dispatch(actions.auth.fulfillUser((await api.auth.getUserByToken(true)).data));

    let confirm = async () => {
        if(loading || !isValid)
            return;

        setLoading(true);

        let res = await api.user.buyCredits(amount);

        setLoading(false);

        if(!res || !res.success)
            return toastMessage.error((res && res.error) || "Unable to complete the transaction.");

        setLoading(true);

        api.pub.event("Purchase", { value: getTotal(amount), currency: "USD" });

        await updateUser();

        toastMessage.success("Thank you!");

        setShow(false);
        setLoading(false);
    };

    useEffect(() => {
        let handler = ev => {
            setShow(true);
            setTitle(ev.message);
        };

        AddCreditsModalEvents.on("showAddCreditsModal", handler);
        return () => AddCreditsModalEvents.off("showAddCreditsModal", handler);
    }, []);

    useEffect(() => {
        if(show) {
            setAmount(min);
            setError(null);
            setIsValid(true);
        }
    }, [show]);

    if(!user || !user.planFeatures.credits)
        return <></>;

    return <>
        <Modal show={show} onHide={() => setShow(false)} size="lg" centered className="new-modals add-credits-modal">
            <Modal.Body>
                <div className="form">
                    {title
                        ? (
                            <>
                                <h1>{title}</h1>
                                <p className="text-gray">Enter the amount of credits you’d like to purchase below</p>
                            </>
                        )
                        : (
                            <>
                                <h1>How many credits would you like to purchase?</h1>
                                <p className="text-gray">Enter the amount below</p>
                            </>
                        )}
                    <div className="amount-field">
                        <input type="number" className="form-control" step="1" min={min} max={max} value={amount} onChange={amountOnChange} ref={amountRef} />
                        <label>Credits</label>
                    </div>
                    {error && <div className="field-error">{error}</div>}
                    <label className="total">
                        ${numberFormat(getTotal(amount))}<br />
                        <small>one time</small>
                    </label>
                    <button type="button" onClick={confirm} disabled={!isValid} className={"btn btn-primary " + (loading ? "loading spinner" : "")}>Buy Credits</button>
                </div>
                <div className="prices">
                    <div className="header">
                        <div>Credits</div>
                        <div>Cost per credit</div>
                    </div>
                    {user.planFeatures.credits.additional.map((item, index) => (
                        <div className={"item " + (amount >= item.amountFrom && amount <= item.amountTo ? "active" : "")} key={index} onClick={ev => rowOnClick(item)}>
                            <div>{numberFormat(item.amountFrom)} - {numberFormat(item.amountTo)}</div>
                            <div>{numberFormat(item.unitPriceCents)}¢</div>
                        </div>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    </>;
}

export default injectIntl(connect(
    state => ({
        user: state.auth.user
    }),
    dispatch => ({
        dispatch
    })
)(AddCreditsModal));

export const AddCreditsModalEvents = {
    on(event, callback) {
        document.addEventListener(event, e => callback(e.detail));
    },
    off(event, callback) {
        document.removeEventListener(event, callback);
    },
    dispatch(event, data = null) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    /**
     * Emits the signal to open the popup.
     */
    dispatchShow(message) {
        this.dispatch("showAddCreditsModal", { message });
    }
};