import React, { useEffect } from "react";  

export function useEvent(node, eventName, callback, capture = false) {
    const unsuscribe = () =>
        node.removeEventListener(eventName, callback, capture);
    
    useEffect(() => {
        let handler = ev => callback(ev);

        node.addEventListener(eventName, handler, capture);

        return () => unsuscribe();
    }, [node, eventName, capture, callback]);

    return {
        unsuscribe
    };
}

export function useCustomEventListener(eventName, callback) {
    const { unsuscribe } = useEvent(window, eventName, ev => callback(ev.detail));

    const trigger = detail =>
        window.dispatchEvent(new CustomEvent(eventName, { detail }));

    return {
        trigger,
        unsuscribe
    };
}

export function useCustomEvent(eventName) {
    const trigger = detail =>
        window.dispatchEvent(new CustomEvent(eventName, { detail }));

    return {
        trigger
    };
}