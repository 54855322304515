import React, { useEffect } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import api from "../../../../redux/api";
import { ProductLogo } from "../../../../_metronic/layout/components/productLogo";
import SVG from "react-inlinesvg";

const DeleteProduct = ({ match, history, episodes, listeners, setListeners, setEpisodes, dispatch, setProduct, product }) => {
  useEffect(() => {
    //dispatch(setProduct({}));
    //dispatch(setListeners([]));
    //dispatch(setEpisodes([]));

    api.listener.getListeners(match.params.id).then(res => {
      dispatch(setListeners(res));
    });

    api.episode.getEpisodes({ id: match.params.id }).then(res => {
      dispatch(setEpisodes(res.data));
    });

    api.product.getProduct(match.params.id).then(res => {
      dispatch(setProduct(res));
    });
  }, [])

  return (
    <>
      <h1>
        <Link to="/products" className="btn btn-back">
          <SVG src={toAbsoluteUrl("/media/def-image/icons/back.svg")} />
        </Link>
        Delete Show
      </h1>

      <div className="container-inner">
        <div className="card">
          <div className="card-body">
            <div className="delete-product-item">
              <div className="delete-product-item-img">
                <ProductLogo product={product} />
              </div>
              <div className="delete-product-item-name">
                <h5>{product.name}</h5>
                <ul>
                  <li>{episodes.length} episodes</li>
                  <li>{listeners.length} listeners</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="card-footer text-right">
            <a href="#" onClick={history.goBack} className="btn btn-secondary mr-2">Cancel</a>
            <Link to={`/delete-product/${match.params.id}/confirm`} className="btn btn-primary">Next</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(connect(
  state => ({
    product: (state.product || {}).product || [],
    episodes: (state.episode || {}).episodes || [],
    listeners: (state.listener || {}).listeners || [],
  }),

  dispatch => ({
    ...actions.listener,
    ...actions.episode,
    ...actions.product,
    dispatch,
  }),
)(DeleteProduct));
