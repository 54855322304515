import React from 'react';
import SVG from "react-inlinesvg";
import { jsonSchema } from 'uuidv4';
import { toAbsoluteUrl, isValidUrl } from "../../../../../_metronic/_helpers";
import { AuthorSocialIcons } from "../../../../helpers";
import { useTranslation } from 'react-i18next';

export const AsideComponent = (props) => {
    const {
        author,
        name,
        avatar,
        website,
        social,
        productsCount,
        episodesCount,
        listenersCount,
        downloadsCount,
    } = props;

    const { t } = useTranslation();

    const nameJSX = name && (
        <span>{name}</span>
    );

    let websiteJSX = website && isValidUrl(website) && (
        <a href={website} target="_blank">{website.replace(/^https?:\/\//i, "")}</a>
    );

    const productsCountJSX = typeof productsCount !== 'undefined' && (
        <div className="statistics-items-item">
            <div className="statistics-items-item-icon">
                <SVG
                    src={toAbsoluteUrl(
                        "/media/def-image/author-page/statistics/productsIcon.svg"
                    )}
                />
            </div>
            <div className="statistics-items-item-content">
                <div className="statistics-items-item-content-title">{productsCount}</div>
                <div className="statistics-items-item-content-sub-title">{t("Products")}</div>
            </div>
        </div>
    );

    const episodesCountJSX = typeof episodesCount !== 'undefined' && (
        <div className="statistics-items-item">
            <div className="statistics-items-item-icon">
                <SVG
                    src={toAbsoluteUrl(
                        "/media/def-image/author-page/statistics/episodesIcon.svg"
                    )}
                />
            </div>
            <div className="statistics-items-item-content">
                <div className="statistics-items-item-content-title">{episodesCount}</div>
                <div className="statistics-items-item-content-sub-title">{t("Episodes")}</div>
            </div>
        </div>
    );

    const listenersCountJSX = typeof listenersCount !== 'undefined' && (
        <div className="statistics-items-item">
            <div className="statistics-items-item-icon">
                <SVG
                    src={toAbsoluteUrl(
                        "/media/def-image/author-page/statistics/listenersIcon.svg"
                    )}
                />
            </div>
            <div className="statistics-items-item-content">
                <div className="statistics-items-item-content-title">{listenersCount}</div>
                <div className="statistics-items-item-content-sub-title">{t("Listeners")}</div>
            </div>
        </div>
    );

    const downloadsCountJSX = typeof downloadsCount !== 'undefined' && (
        <div className="statistics-items-item downloads">
            <div className="statistics-items-item-icon">
                <SVG
                    src={toAbsoluteUrl(
                        "/media/def-image/author-page/statistics/downloadsIcon.svg"
                    )}
                />
            </div>
            <div className="statistics-items-item-content">
                <div className="statistics-items-item-content-title">{downloadsCount}</div>
                <div className="statistics-items-item-content-sub-title">{t("Downloads")}</div>
            </div>
        </div>
    );

    return (
        <aside className="aside-component">
            <div className="contacts">
                <div className="contacts-avatar">
                    <img src={author.pictureUrl || toAbsoluteUrl("/media/users/blank.png")} />
                </div>
                <div className="contacts-main">
                    {nameJSX}
                    {websiteJSX}
                </div>
                <AuthorSocialIcons author={author} />
            </div>
            <div className="statistics">
                <span>{t("My activity")}</span>
                <div className="statistics-items">
                    {productsCountJSX}
                    {episodesCountJSX}
                    {listenersCountJSX}
                    {downloadsCountJSX}
                </div>
            </div>
        </aside>
    );
}
