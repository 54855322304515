import React, { useState, useEffect, useRef, Fragment } from "react";
import { createPortal } from "react-dom";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import SVG from "react-inlinesvg";
import actions from "../../../redux/actions";
import Avatar from "../components/Avatar";
import Scrollbars from "react-custom-scrollbars";
import api from "../../../redux/api";
import { getCancelSource } from "../../helpers/use-fetch";
import { toastMessage } from "../../helpers";
import { OpenAiModalEvents } from "../../pages/modals/OpenAiModal";
import { Anchorme } from "react-anchorme";

function Copy({ text, callback }) {
    const [ok, setOk] = useState(false),
        timer = useRef();

    function onClick() {
        if(callback)
            callback(text);

        try {
            navigator.clipboard.writeText(text);
        } catch(err) {
        }

        setOk(true);
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            setOk(false);
        }, 1500);
    }

    return (
        <button type="button" className={"btn btn-copy " + (ok ? "ok " : "")} onClick={onClick}>
            <SVG src="/media/def-image/icons/copy.svg" className="copy-icon" />
            <SVG src="/media/def-image/icons/check-gray.svg" className="ok-icon" />
        </button>
    );
}

function nl2br(text, anchorme) {
    let res = [];

    for(let line of text.split("\n"))
        res.push(
            <Fragment key={res.length + 1}>
                {anchorme ?
                    <Anchorme target="_blank">{line}</Anchorme>
                    : line}
                <br />
            </Fragment>
        );

    return res;
}

function Message({ data = {}, user, loading, onCopy, hideAvatar }) {
    return (
        <div className={"message " + (data.pending ? "pending " : "") + (loading ? "message-loading message-hiro " : "") + (data.from ? "message-" + data.from + " " : "") + (data.delay ? "delay " : "") + (hideAvatar ? "hide-avatar " : "")}>
            {(data.from == "hiro" || loading) && <Avatar scale="009" />}

            {data.from == "user" && user && (
                <div className="user-avatar">
                    <div>
                        {user.pictureUrl
                            ? <img src={user.pictureUrl} />
                            : (user.firstName || user.email)[0]}
                    </div>
                </div>
            )}

            {data.text && (
                <div className="text">
                    {nl2br(data.text, data.from == "hiro")}
                </div>
            )}

            {loading && (
                <div className="loading">
                    <div />
                    <div />
                    <div />
                </div>
            )}

            {data.from == "hiro" && !data.disableCopy && !loading && (
                <div className="copy">
                    <Copy text={data.text} callback={onCopy} />
                </div>
            )}
        </div>
    );
}

function AIChat({ user, dispatch, fulfillUser }) {
    const [show, setShow] = useState(false),
        [messages, setMessages] = useState([]),
        scrollRef = useRef(),
        textareaRef = useRef(),
        onCopyRef = useRef(),
        onResponseRef = useRef(),
        cancelSourceRef = useRef(),
        includeRef = useRef(),
        sourceRef = useRef(),
        dataRef = useRef(),
        expectRef = useRef(),
        [sending, setSending] = useState(false),
        [loading, setLoading] = useState(false);

    async function updateUser() {
        dispatch(fulfillUser((await api.auth.getUserByToken(true)).data));
    }

    async function send(value) {
        if(!user.planFeatures.canUseHiroOpenAiKey && !user.openAiKey)
            return OpenAiModalEvents.dispatchShowMissingKey();

        if(sending)
            return;

        const text = value || textareaRef.current.value.trim();

        textareaRef.current.value = "";
        textareaRef.current.focus();
        textareaOnInput();

        if(!text)
            return;

        setSending(true);

        if(cancelSourceRef.current)
            cancelSourceRef.current.cancel();

        const res = await api.ai.pushMessage({
            text,
            data: dataRef.current,
            include: includeRef.current,
            source: sourceRef.current || "chat",
            expect: expectRef.current
        });

        setSending(false);

        if(!res || !res.success)
            return toastMessage.error((res && res.error) || "Unable to connect to the server.");

        setMessages(arr => [
            ...arr,
            {
                from: "user",
                text
            }
        ]);

        setLoading(true);

        cancelSourceRef.current = getCancelSource();

        const res2 = await api.ai.getResponse(cancelSourceRef.current);

        updateUser();

        setLoading(false);

        if(!res2 || !res2.success)
            return toastMessage.error((res2 && res2.error) || "Unable to connect to the server.");

        setMessages(arr => [
            ...arr,
            res2.data
        ]);

        if(onResponseRef.current)
            onResponseRef.current(res2.data.text);

        clearRefs();

        if(res2.data.expect)
            expectRef.current = res2.data.expect;
    }

    function clearRefs() {
        dataRef.current = null;
        includeRef.current = null;
        sourceRef.current = null;
        expectRef.current = null;
        onResponseRef.current = null;
    }

    async function clear() {
        setMessages([]);
        setLoading(false);

        if(cancelSourceRef.current)
            cancelSourceRef.current.cancel();

        clearRefs();
        onCopyRef.current = null;

        textareaRef.current.value = "";
        textareaOnInput();

        await api.ai.clearChat();
    }

    function textareaOnKeyDown(ev) {
        if(ev.which == 13 && !ev.shiftKey) {
            ev.preventDefault();
            send();
        }
    }

    function textareaOnInput() {
        if(textareaRef.current) {
            textareaRef.current.style.height = 0;
            textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
        }
    }

    useEffect(() => {
        function fn(ev) {
            setShow(true);

            const { prompt, partialPrompt, include, source, onCopy, onResponse, data } = ev.detail || {};

            onCopyRef.current = onCopy;
            includeRef.current = include;
            dataRef.current = data;
            sourceRef.current = source;
            onResponseRef.current = onResponse;

            if(prompt)
                if(partialPrompt) {
                    textareaRef.current.value = prompt;
                    textareaRef.current.focus();
                    textareaOnInput();
                } else {
                    setTimeout(() => send(prompt), messages.length || !show ? 1500 : 0);
                }
        }

        AIEvents.on("showChat", fn);

        return () =>
            AIEvents.off("showChat", fn);
    }, []);

    useEffect(() => {
        if(scrollRef.current)
            scrollRef.current.scrollToBottom();
    }, [messages, loading]);

    useEffect(() => {
        if(show) {
            textareaRef.current.focus();

            if(!messages.length)
                setMessages([
                    {
                        from: "hiro",
                        text: "Hi" + (user.firstName ? ", " + user.firstName : "") + "!",
                        delay: true,
                        disableCopy: true
                    }
                ]);
        }
    }, [show]);

    return createPortal(
        <>
            <button type="button" id="ai-chat-button" className={"btn " + (show ? "open" : "")} onClick={() => setShow(true)}>
                <Avatar animateOnHover scale="009" />
            </button>
            <div id="ai-chat" className={show ? "open" : ""}>
                <div className="header">
                    <Avatar scale="009" />
                    <div className="fill">
                        <strong>AI Bot Writer</strong>
                    </div>
                    <button type="button" className="btn" onClick={() => clear()}>
                        <SVG src="/media/def-image/icons/reload.svg" />
                    </button>
                    <button type="button" className="btn" onClick={() => setShow(false)}>
                        <SVG src="/media/def-image/icons/close-white.svg" />
                    </button>
                </div>
                <Scrollbars
                    ref={scrollRef}
                    hideTracksWhenNotNeeded
                    className="scrollbar-view"
                    renderView={props => <div {...props} className="view" />}
                    renderTrackVertical={props => <div {...props} className="scrollbar-track" />}
                    renderThumbVertical={props => <div {...props} className="scrollbar-thumb" />}>
                    <div className="messages">
                        {messages.map((message, i) => (
                            <Message key={i} hideAvatar={i < messages.length - 1 && (messages[i + 1].from == message.from || (i > 0 && messages[i - 1].from == message.from))} data={message} user={user} onCopy={onCopyRef.current} />
                        ))}
                        {loading && <Message loading />}
                    </div>
                </Scrollbars>
                <div className="footer">
                    <textarea className="form-control" ref={textareaRef} onKeyDown={textareaOnKeyDown} onInput={textareaOnInput} />
                    <button type="button" className={"btn btn-primary btn-submit " + (sending ? "loading spinner " : "")} onClick={() => send()}>
                        Send <SVG src="/media/def-image/icons/send.svg" />
                    </button>
                </div>
            </div>
        </>
        , document.body);
}

export default injectIntl(
    connect(
        store => ({
            products: store.product.products,
            user: store.auth.user
        }),
        dispatch => ({
            ...actions.auth,
            ...actions.product,
            dispatch
        })
    )(AIChat));

export const AIEvents = {
    on(event, callback) {
        document.addEventListener(event, callback);
    },
    off(event, callback) {
        document.removeEventListener(event, callback);
    },
    dispatch(event, data = null) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    /**
     * 
     */
    dispatchShowChat({ prompt, onCopy, onResponse, partialPrompt, include, source, data }) {
        this.dispatch("showChat", { prompt, onCopy, onResponse, partialPrompt, include, source, data });
    }
};