import React, { useEffect, useRef, useState } from "react";
import api from "../../../redux/api";
import { Link } from "react-router-dom";
import { Pagination, Table } from "react-bootstrap";
import moment from "moment";

export default function NotificationsStats() {
	let [ page, setPage ] = useState(1),
		[ pages, setPages ] = useState(0),
		[ list, setList ] = useState([]);

	let load = async (pg=null) => {
		if(pg) {
			setPage(pg);
		} else {
			pg = page;
		}
		let res = null;
		try {
			res = await api.admin.getNotifications(pg);
			if(res) {
				setList(res.data);
				setPages(res.pages);
			}
		} catch(x) {}
	};

	useEffect(() => {
		load();
	}, []);

	return <div>
		<h1>Notifications</h1>

		<div className="card p-5">
			<Table striped bordered={false} hover responsive size="sm">
				<thead>
					<tr>
						<th>ID</th>
						<th>Title</th>
						<th>Date</th>
						<th>Sent</th>
						<th>Seen</th>
						<th>Interactions</th>
						<th>Push sent</th>
					</tr>
				</thead>
				<tbody>
					{list.map((item, i) => 
						<tr key={i}>
							<td>{item._id}</td>
							<td>{item.title + (item.type=="form" ? " (Events form update)" : "")}</td>
							<td>{moment(item.createdAt).locale("en").format("MMM D, YYYY H:m")}</td>
							<td>{item.webRecipients||"0"}</td>
							<td>{item.webSeen||"0"}</td>
							<td>{item.webClicks||"0"}</td>
							<td>{item.pushSent||"0"}</td>
						</tr>
					)}
				</tbody>
			</Table>

			<Pagination size="sm" className="justify-content-center mt-5">
				{(() => {
					let items = [];
					for(let i=1; i<=pages; i++)
						items.push(<Pagination.Item key={i} active={i == page} onClick={ev => load(i)}>{i}</Pagination.Item>);
					return items;
				})()}
			</Pagination>
		</div>
	</div>;
}
