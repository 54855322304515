import React from "react";
import { Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import actions from "../../../redux/actions";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

function NewEpisodeDropdown({ productId, className = "", toggleClassName = "", children, currentUser, drop = "down", alignRight = false, fixed = false }) {
    return (
        <Dropdown className={(fixed ? "fixed-dropdown " : "") + className} drop={drop} alignRight={alignRight}>
            <Dropdown.Toggle className={toggleClassName}>
                {children}
            </Dropdown.Toggle>
            <Dropdown.Menu popperConfig={fixed ? { strategy: "fixed" } : {}} renderOnMount>                
                <Link className="dropdown-item no-plan-cant-click" to={"/products/" + productId + "/episodes/create-single"}>
                    <div className="icon">
                        <SVG src={toAbsoluteUrl("/media/def-image/file.svg")} />
                    </div>
                    Single Episode
                </Link>
                <Link className="dropdown-item no-plan-cant-click" to={"/products/" + productId + "/episodes/bulk-upload"}>
                    <div className="icon">
                        <SVG src={toAbsoluteUrl("/media/def-image/document.svg")} />
                    </div>
                    Bulk Upload
                </Link>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default injectIntl(
    connect(
        (state) => ({
            currentUser: state.auth.user,
        }),
        actions.product
    )(withRouter(NewEpisodeDropdown))
);