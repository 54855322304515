import React, { createRef, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";

function Preview({ className = "", title = null, children, scrollOnUpdate = true, scroll = false, scrollRef = createRef() }) {
    useEffect(() => {
        if(scrollRef.current && scrollOnUpdate)
            scrollRef.current.view.scrollTo({ top: scrollRef.current.view.scrollHeight, behavior: "smooth" });
    }, [ children ]);
    
    return (
        <div id="preview" className={className}>
            <div className="frame">
                <div className="screen">
                    {scroll
                    ? (
                        <Scrollbars
                            ref={scrollRef}
                            hideTracksWhenNotNeeded
                            autoHide
                            className="scrollbar-view"
                            renderTrackVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="scrollbar-track" />}
                            renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="scrollbar-thumb" />}>
                                {children}
                        </Scrollbars>
                    )
                    : children}
                </div>
            </div>
            {title && <label>{title}</label>}
        </div>
    );
}

export default Preview;