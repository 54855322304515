import React, { useEffect, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import { connect, useSelector, shallowEqual } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import UserPicture from "../components/UserPicture";
import actions from "../../../redux/actions";
import api from "../../../redux/api";
import { toastMessage } from "../../helpers";
import { UpgradeEvents } from "../../pages/modals/UpgradeModal";
import { FivePackEvents } from "../../pages/modals/FivePackModal";
import { useCustomEventListener } from "../../helpers/use-event";
import { Tooltip } from "@material-ui/core";

function SubAccountsDropdown({ dispatch }) {
    let user = useSelector(state => state.auth.user, shallowEqual),
        [subaccountIdLoading, setSubaccountIdLoading] = useState(null),
        [showTooltip, setShowTooltip] = useState(false),
        tooltipTimerRef = useRef();

    useCustomEventListener("upgradedToAgency", () => setShowTooltip(true));

    async function updateUser() {
        let data = (await api.auth.getUserByToken(true)).data;
        dispatch(actions.auth.fulfillUser(data));
    }

    async function switchSubaccount(account) {
        setSubaccountIdLoading(account._id);

        let res = await api.user.setSubaccount(account._id);

        if(!res || !res.success) {
            setSubaccountIdLoading(null);
            toastMessage.error((res && res.error) || "Unable to connect to the server.");
            return;
        }

        dispatch(actions.auth.setLogin(res.token, res.user));

        await updateUser();

        setSubaccountIdLoading(null);
    }

    function addSubAccountOnClick(ev) {
        ev.preventDefault();

        if(!user.owner || user.owner.newPlan.name != "agency")
            return UpgradeEvents.dispatchShow({ upgradeToAgency: true });

        if(user.owner && user.owner.totalSubAccountsLimit > user.owner.subAccountsIds.length)
            return (window.location.hash = "#create-subaccount");

        FivePackEvents.dispatchShow();
    }

    useEffect(() => {
        if(showTooltip)
            tooltipTimerRef.current = setTimeout(() => {
                setShowTooltip(false);
            }, 10000);

        return () => {
            clearTimeout(tooltipTimerRef.current);
        };
    }, [showTooltip]);

    return (
        <Tooltip
            PopperProps={{
                disablePortal: true,
            }}
            onClose={() => setShowTooltip(false)}
            open={showTooltip}
            onClick={() => setShowTooltip(false)}
            arrow
            placement="bottom"
            title={<span className="d-flex align-items-center p-2">
                <span className="fs-2">💡</span>
                <span className="text-left ml-2">Click here to add<br />your first subaccount.</span>
            </span>}>
            <Dropdown className="subaccounts-dropdown">
                <Dropdown.Toggle as="div">
                    <UserPicture user={user} />
                    <label>
                        {user.firstName
                            ? user.firstName + " " + user.lastName
                            : user.email}
                    </label>
                    <SVG src="/media/def-image/icons/angle-down.svg" className="angle-down-icon" />
                </Dropdown.Toggle>

                <Dropdown.Menu renderOnMount>
                    <div className="current-subaccount">
                        <div className="picture">
                            <UserPicture user={user} />
                            <Link to="/profile-page" className="edit-button">
                                <SVG src="/media/def-image/icons/pencil-white.svg" />
                            </Link>
                        </div>
                        <label className="name">
                            {user.firstName + " " + user.lastName}
                        </label>
                        <label className="email">
                            {user.email}
                        </label>
                    </div>

                    {!!user.owner && (
                        <Dropdown.Item as="div">
                            <Link to="/settings/organization">
                                <SVG className="icon" src="/media/def-image/icons/settings-white.svg" />
                                Admin settings
                            </Link>
                        </Dropdown.Item>
                    )}

                    {user.owner?.subAccounts?.map(account => (
                        <Dropdown.Item as="div" key={account._id}>
                            <a href="#" className="subaccount" onClick={ev => (ev.preventDefault(), ev.stopPropagation(), switchSubaccount(account))}>
                                <UserPicture user={account} />
                                <div className="item-label">
                                    <label className="name">
                                        {account.firstName + " " + account.lastName}
                                    </label>
                                    <label className="email">
                                        {account.email}
                                    </label>
                                </div>
                                {subaccountIdLoading == account._id
                                    ? <div className="loading spinner spinner-white spinner-sm" />
                                    : (
                                        account._id == user._id
                                            ? <SVG className="check-icon" src="/media/def-image/icons/check-white.svg" />
                                            : <></>
                                    )}
                            </a>
                        </Dropdown.Item>
                    ))}

                    <Dropdown.Item as="div">
                        <a href="#" onClick={addSubAccountOnClick}>
                            <SVG className="icon" src="/media/def-image/icons/plus-circle-white.svg" />
                            <span className="flex-1">Add account</span>
                            {!!user.owner?.totalSubAccountsLimit && <span className="text-muted">{user.owner?.subAccountsIds.length || "0"} of {user.owner?.totalSubAccountsLimit || "0"} used</span>}
                        </a>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Tooltip>
    );
}

export default injectIntl(
    connect(
        (state) => ({
            user: state.auth.user,
        }),
        dispatch => ({
            dispatch,
            ...actions.product,
            ...actions.auth
        })
    )(withRouter(SubAccountsDropdown))
);
