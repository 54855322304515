import React from "react";

export default function GHLSuccess() {
  return (
    <div className="ghl-success-page">
      <div className="card">
        <div className="text">
          <h1>Success</h1>
          <p>You successfully connected your Hiro.fm account</p>
          <a href="https://app.notonlyfunnels.io" className="btn">Return to Automations</a>
        </div>
        <img src="/media/def-image/ghl-success.png" />
      </div>
    </div>
  );
}