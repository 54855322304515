import React, { useState, useEffect, Fragment } from "react";
import { injectIntl } from "react-intl";
import { connect, useSelector, shallowEqual } from 'react-redux';
import { Link, useHistory, withRouter } from "react-router-dom";
import actions from "../../redux/actions";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { NotificationsPopup } from "../pages/generic/NotificationsPopup";
import { UserProfileDropdown } from "../../_metronic/layout/components/extras/dropdowns/UserProfileDropdown";
import NewEpisodeDropdown from "./dropdowns/NewEpisodeDropdown";
import NewListenerDropdown from "./dropdowns/NewListenerDropdown";
import NewProductDropdown from "./dropdowns/NewProductDropdown";
import moment from "moment";
import { checkLimits } from "../plans";
import { UpgradeEvents } from "../pages/modals/UpgradeModal";
import { AddCreditsModalEvents } from "../pages/modals/AddCreditsModal";
import { NeedsUpgradeEvents } from "../pages/modals/NeedsUpgrade";
import NewContactDropdown from "./dropdowns/NewContactDropdown";
import SubAccountsDropdown from "./dropdowns/SubAccountsDropdown";
import api from "../../redux/api";
import { toastMessage } from "../helpers";
import PhoneNumberDropdown from "./dropdowns/PhoneNumberDropdown";

let lastScroll = 0,
    currentNavTranslate = 0;

function HeaderBadges({ user, stripe }) {
    let challengeEndsAt = null,
        upgradeDays = -1,
        trialDays = -1;

    let addCreditsOnClick = ev => {
        ev.preventDefault();
        if(user.noPlan || !user.planFeatures.credits)
            NeedsUpgradeEvents.dispatchShowForSms();
        else
            AddCreditsModalEvents.dispatchShow();
    };

    //if(user) {
    //    if(user.freeTrial)
    //        trialDays = moment(user.newPlan.trialEnds).diff(moment(), "days");
    //    else if(user.currentChallengeEnds)
    //        challengeEndsAt = new Date(user.currentChallengeEnds);
    //    else if(user.upgradedFromWebhooks && user.newPlan && user.newPlan.expiration)
    //        challengeEndsAt = new Date(user.newPlan.expiration);
    //
    //    if(challengeEndsAt)
    //        upgradeDays = moment(challengeEndsAt).diff(moment(), "days");
    //}

    return (
        <>
            {/*trialDays >= 0 && (
                <>
                    <label className={"trial-expires-badge mr-5 " + (trialDays <= 1 ? "red-badge" : "yellow-badge")}>
                        <SVG src={toAbsoluteUrl("/media/def-image/icons/hourglass.svg")} className="mr-3" />
                        Free trial expires in {trialDays} {trialDays == 1 ? "day" : "days"}
                    </label>
                </>
            )}
            {upgradeDays >= 0 && (
                <label className={"challenge-expires-badge mr-5 " + (upgradeDays <= 1 ? "red-badge" : "yellow-badge")}>
                    <SVG src={toAbsoluteUrl("/media/def-image/icons/hourglass.svg")} className="mr-3" />
                    {upgradeDays} {upgradeDays == 1 ? "day" : "days"} left on your free upgrade
                </label>
            )*/}
            {user && user.phoneNumberOwner == "hiro" && (
                <label className="balance-badge">
                    <strong>{Math.max(0, user.credits)} </strong>
                    <span className="d-inline-block d-sm-none">Credits</span>
                    <span className="d-none d-sm-inline-block">Credits Available</span>
                    <button type="button" className="btn btn-plus outline btn-primary no-plan-cant-click" onClick={addCreditsOnClick} />
                </label>
            )}

            {user && user.planFeatures.contacts > -1 && (
                <label className="balance-badge ml-6">
                    <strong className="text-dark">{Math.max(0, user.planFeatures.contacts - (user.stats.contactsCount || 0))}</strong> Contacts Remaining
                </label>
            )}

            {user && !!user.stats.contactsOnHoldCount && (
                <label className="balance-badge ml-6">
                    <strong className="text-danger">{user.stats.contactsOnHoldCount}</strong> Contacts On Hold
                </label>
            )}
        </>
    );
}

function UpsellBar({ user }) {
    let [seconds, setSeconds] = useState(0),
        [show, setShow] = useState(),
        [loading, setLoading] = useState();

    function getRemainingSeconds() {
        let secondsSince = moment().diff(moment(user.upsellCouponCreatedAt), "seconds");
        return Math.max(0, user.upsellCouponExpirationDays * 24 * 60 * 60 - secondsSince);
    }

    async function onClick() {
        setLoading(true);

        let res = await api.user.getWordpressOtpLink();

        if(!res || !res.success) {
            setLoading(false);
            return toastMessage.error("Unable to sign you in, please contact support.");
        }

        window.location.href = res.url + "&redirect_to=upgrade&aero-coupons=" + user?.upsellCoupon;
    }

    useEffect(() => {
        if(!user || !user.upsellCoupon || !user.upsellCouponCreatedAt || !user.upsellCouponExpirationDays)
            return;

        let remaining = getRemainingSeconds();

        if(remaining == 0)
            return;

        let interval = setInterval(() => {
            setSeconds(getRemainingSeconds());
        }, 1000);

        setSeconds(remaining);
        setShow(true);
        document.body.classList.add("has-upsell-bar");

        return () => clearInterval(interval);
    }, [user]);

    useEffect(() => {
        if(show)
            document.body.classList.add("has-upsell-bar");
        else
            document.body.classList.remove("has-upsell-bar");
    }, [show]);

    if(!show)
        return <></>;

    let timer = [],
        s = seconds;
    timer[0] = Math.floor(s / (24 * 3600));
    s %= (24 * 3600);
    timer[1] = Math.floor(s / 3600);
    s %= 3600;
    timer[2] = Math.floor(s / 60);
    timer[3] = s % 60;

    return (
        <div id="upsell-bar">
            <div className="timer">
                <div>
                    <big>{timer[0] > 9 ? timer[0] : "0" + timer[0]}</big>
                    <span>day</span>
                </div>
                <div className="dot">:</div>
                <div>
                    <big>{timer[1] > 9 ? timer[1] : "0" + timer[1]}</big>
                    <span>hour</span>
                </div>
                <div className="dot">:</div>
                <div>
                    <big>{timer[2] > 9 ? timer[2] : "0" + timer[2]}</big>
                    <span>min</span>
                </div>
                <div className="dot">:</div>
                <div>
                    <big>{timer[3] > 9 ? timer[3] : "0" + timer[3]}</big>
                    <span>sec</span>
                </div>
            </div>

            <h1>🚀 Upgrade and save <strong className="text-green">{user?.upsellCouponPercentage}%</strong> on Business membership. Use coupon code <strong>{user?.upsellCoupon}</strong></h1>

            <button type="button" className={"btn btn-primary " + (loading ? "loading spinner" : "")} onClick={onClick}>Yes, I want to save!</button>

            <button type="button" className="close-btn" onClick={() => setShow(false)}>
                <SVG src="/media/def-image/icons/x-w.svg" />
            </button>
        </div>
    );
}

function Layout({ children, location }) {
    let products = useSelector(state => state.product.products, shallowEqual),
        user = useSelector(state => state.auth.user, shallowEqual),
        stripe = useSelector(state => state.user, shallowEqual),
        history = useHistory(),
        [showNav, setShowNav] = useState(false);

    let isActive = routes => {
        if(typeof routes == "string")
            routes = [routes];
        for(let route of routes)
            if(location.pathname.match(new RegExp("^" + route)))
                return true;
        return false;
    };

    let getNavItemClass = (routes = null, def = null, extra = null) => (def || "nav-item") + (routes && isActive(routes) ? " active" : "") + (extra ? " " + extra : "");

    let mainOnClick = ev => {
        if(!ev.target.classList.contains("nav-toggle") && !document.querySelector(".nav-toggle").contains(ev.target))
            setShowNav(false);
    };

    let newKeywordOnClick = async ev => {
        ev.preventDefault();

        if(await checkLimits.canAddKeyword(user))
            history.push("/sms/edit");
    };

    let newAutomationOnClick = async ev => {
        ev.preventDefault();

        if(await checkLimits.canAddAutomation(user))
            history.push("/automations/edit");
    };

    let newCampaignOnClick = async ev => {
        ev.preventDefault();

        if(await checkLimits.canAddCampaign(user))
            history.push("/campaigns/edit");
    };

    async function newCategoryOnClick(ev) {
        ev.preventDefault();

        if(await checkLimits.canAddCategory(user))
            history.push("/categories/edit");
    }

    async function newTagOnClick(ev) {
        ev.preventDefault();

        if(await checkLimits.canAddTag(user))
            history.push("/tags/edit");
    }

    useEffect(() => {
        document.querySelectorAll(".dropdown-menu.show").forEach(elem => elem.classList.remove("show"));
        setShowNav(false);
    }, [location]);

    useEffect(() => {
        let handleResize = () => {
            let navElem = document.querySelector("#main-nav"),
                rootElem = document.querySelector("#root-container"),
                contentElem = document.querySelector("#content"),
                navHeight = navElem.offsetHeight,
                contentHeight = contentElem.offsetHeight,
                padding = 0;

            if(contentHeight < navHeight)
                padding = navHeight - contentHeight;

            rootElem.style.paddingBottom = padding + "px";
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [location, products]);

    useEffect(() => {
        // let handleScroll = ev => {
        //     let scroll = document.querySelector("html").scrollTop,
        //         delta = lastScroll - scroll,
        //         elem = document.querySelector("#main-nav"),
        //         windowWidth = window.innerWidth,
        //         windowHeight = window.innerHeight,
        //         elemHeight = elem.clientHeight;

        //     currentNavTranslate += delta;
        //     lastScroll = scroll;

        //     if(currentNavTranslate > 0 || windowWidth < 768 || elemHeight <= windowHeight)
        //         currentNavTranslate = 0;
        //     else if(currentNavTranslate < windowHeight - elemHeight)
        //         currentNavTranslate = windowHeight - elemHeight;

        //     elem.style.transform = "translateY(" + currentNavTranslate + "px)";
        // };

        let handleClick = ev => {
            if((ev.target.classList.contains("no-plan-cant-click") || ev.target.closest(".no-plan-cant-click")) && user && ((user.owner && user.owner.noPlan) || user.noPlan)) {
                ev.preventDefault();
                ev.stopPropagation();
                UpgradeEvents.dispatchShow();
            }
        };

        // document.addEventListener("scroll", handleScroll);
        // document.addEventListener("mousewheel", handleScroll);
        // document.addEventListener("touchmove", handleScroll);
        document.addEventListener("click", handleClick, { capture: true });

        return () => {
            // document.removeEventListener("scroll", handleScroll);
            // document.removeEventListener("mousewheel", handleScroll);
            // document.removeEventListener("touchmove", handleScroll);
            document.removeEventListener("click", handleClick, { capture: true });
        };
    }, []);

    return (
        <div className={"app container-fluid" + (showNav ? " show-nav" : "")} id="root-container">
            <UpsellBar user={user} />

            <nav className="main-nav" id="main-nav">
                <button type="button" className="nav-close" onClick={ev => setShowNav(false)}>
                    <SVG src={toAbsoluteUrl("/media/def-image/close.svg")} />
                </button>

                <SubAccountsDropdown />

                <PhoneNumberDropdown withLabel />

                <div className={getNavItemClass("/home")}>
                    <Link to="/home" className="nav-link">
                        <SVG src={toAbsoluteUrl("/media/def-image/icons/home.svg")} />
                        <label>Dashboard</label>
                    </Link>

                    <NewProductDropdown fixed className="new-product-icon" drop="right">
                        <SVG src="/media/def-image/icons/new-show.svg" />
                        <label className="tooltip">New Show</label>
                    </NewProductDropdown>
                </div>

                <div className="hr" />

                <div className="label">
                    Chat
                </div>

                <div className={getNavItemClass("/inbox")}>
                    <Link to="/inbox" className="nav-link no-plan-cant-click">
                        <SVG className="icon" src={toAbsoluteUrl("/media/def-image/icons/inbox.svg")} />
                        <label>Inbox</label>
                    </Link>
                    <Link to="/inbox/new" className="plus no-plan-cant-click">
                        <label className="tooltip">New Conversation</label>
                    </Link>
                </div>

                <div className={getNavItemClass("/campaigns")}>
                    <Link to="/campaigns" className="nav-link no-plan-cant-click">
                        <SVG className="icon" src={toAbsoluteUrl("/media/def-image/icons/campaigns.svg")} />
                        <label>Campaigns</label>
                    </Link>
                    <Link to="#" onClick={newCampaignOnClick} className="plus no-plan-cant-click">
                        <label className="tooltip">New Campaign</label>
                    </Link>
                </div>

                <div className={getNavItemClass("/sms")}>
                    <Link to="/sms" className="nav-link no-plan-cant-click">
                        <SVG className="icon" src={toAbsoluteUrl("/media/def-image/icons/keywords.svg")} />
                        <label>Keywords</label>
                    </Link>
                    <Link to="#" onClick={newKeywordOnClick} className="plus no-plan-cant-click">
                        <label className="tooltip">New Keyword</label>
                    </Link>
                </div>

                <div className={getNavItemClass("/automations")}>
                    <Link to="/automations" className="nav-link no-plan-cant-click">
                        <SVG className="icon" src={toAbsoluteUrl("/media/def-image/automations.svg")} />
                        <label>Automations</label>
                    </Link>
                    <Link to="#" onClick={newAutomationOnClick} className="plus no-plan-cant-click">
                        <label className="tooltip">New Automation</label>
                    </Link>
                </div>

                <div className="hr" />

                <div className="label">
                    Contacts
                </div>

                <div className={getNavItemClass("/contacts")}>
                    <Link to="/contacts" className="nav-link no-plan-cant-click">
                        <SVG className="icon" src={toAbsoluteUrl("/media/def-image/icons/contacts.svg")} />
                        <label className="fw-bolder">Contacts</label>
                    </Link>
                    <NewContactDropdown fixed className="plus-dropdown no-plan-cant-click" toggleClassName="plus" drop="right">
                        <label className="tooltip">New Contact</label>
                    </NewContactDropdown>
                </div>

                <div className={getNavItemClass("/tags")}>
                    <Link to="/tags" className="nav-link no-plan-cant-click">
                        <SVG className="icon" src={"/media/def-image/icons/tag-sidebar.svg"} />
                        <label>Tags</label>
                    </Link>
                    <Link to="#" onClick={newTagOnClick} className="plus no-plan-cant-click">
                        <label className="tooltip">New Tag</label>
                    </Link>
                </div>

                <div className="hr" />

                <div className="label">
                    Shows
                </div>

                <div className={getNavItemClass("/categories")}>
                    <Link to="/categories" className="nav-link no-plan-cant-click">
                        <SVG className="icon" src={"/media/def-image/icons/categories.svg"} />
                        <label>Categories</label>
                    </Link>
                    <Link to="#" onClick={newCategoryOnClick} className="plus no-plan-cant-click">
                        <label className="tooltip">New Category</label>
                    </Link>
                </div>

                <div className={getNavItemClass(["/products/?$", "/create-product"])}>
                    <Link to="/products" className="nav-link no-plan-cant-click">
                        <SVG className="icon" src={toAbsoluteUrl("/media/def-image/icons/products-3.svg")} />
                        <label className="fw-bolder">Shows</label>
                    </Link>
                    <NewProductDropdown fixed className="plus-dropdown" toggleClassName="plus" drop="right">
                        <label className="tooltip">New Show</label>
                    </NewProductDropdown>
                </div>

                {products && products.map((product, i) => (
                    <Fragment key={i}>
                        <div className={getNavItemClass(["/products/" + product._id + "/?$", "/products/" + product._id + "/(settings|sales-page)/?$"]) + (isActive("/products/" + product._id) ? " active-within" : "")} key={i}>
                            <Link to={"/products/" + product._id} className="nav-link no-plan-cant-click">
                                <img src={product.artwork.thumbnail} />
                                <label>{product.name}</label>
                            </Link>
                        </div>
                        {isActive("/products/" + product._id) && (
                            <div className="submenu">
                                <div className={getNavItemClass("/products/" + product._id + "/episodes")}>
                                    <Link to={"/products/" + product._id + "/episodes"} className="nav-link no-plan-cant-click">
                                        <label>Episodes</label>
                                    </Link>
                                    <NewEpisodeDropdown fixed productId={product._id} className="plus-dropdown" toggleClassName="plus" drop="right">
                                        <label className="tooltip">New Episode</label>
                                    </NewEpisodeDropdown>
                                </div>
                                <div className={getNavItemClass("/products/" + product._id + "/listeners")}>
                                    <Link to={"/products/" + product._id + "/listeners"} className="nav-link no-plan-cant-click">
                                        <label>Listeners</label>
                                    </Link>
                                    <NewListenerDropdown fixed productId={product._id} className="plus-dropdown" toggleClassName="plus" drop="right">
                                        <label className="tooltip">New Listener</label>
                                    </NewListenerDropdown>
                                </div>
                                <div className={getNavItemClass("/products/" + product._id + "/sales-page")}>
                                    <Link to={"/products/" + product._id + "/sales-page"} className="nav-link no-plan-cant-click">
                                        <label>Sales page</label>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </Fragment>
                ))}

                <div className="hr" />

                <div className="label">
                    User
                </div>

                <div className={getNavItemClass("/profile-page")}>
                    <Link to="/profile-page" className="nav-link no-plan-cant-click">
                        <SVG className="icon" src={toAbsoluteUrl("/media/def-image/icons/user.svg")} />
                        <label>Profile page</label>
                    </Link>
                </div>

                <div className={getNavItemClass("/user")}>
                    <Link to="/user/integrations" className="nav-link no-plan-cant-click">
                        <SVG className="icon" src={toAbsoluteUrl("/media/def-image/icons/settings.svg")} />
                        <label>Creator settings</label>
                    </Link>
                </div>

                {/*isActive("/settings") && (
                    <div className="submenu">
                        <div className={getNavItemClass("/settings/organization")}>
                            <Link to={"/settings/organization"} className="nav-link no-plan-cant-click">
                                <label>Organization</label>
                            </Link>
                        </div>
                        <div className={getNavItemClass("/settings/domains")}>
                            <Link to={"/settings/domains"} className="nav-link no-plan-cant-click">
                                <label>Domains</label>
                            </Link>
                        </div>
                        <div className={getNavItemClass("/settings/email")}>
                            <Link to={"/settings/email-accounts"} className="nav-link no-plan-cant-click">
                                <label>Email</label>
                            </Link>
                        </div>
                        <div className={getNavItemClass("/settings/billing")}>
                            <Link to={"/settings/billing"} className="nav-link">
                                <label>Billing</label>
                            </Link>
                        </div>
                        <div className={getNavItemClass("/settings/team", null, "disabled")}>
                            <Link to="#" className="nav-link no-plan-cant-click">
                                <label>Team (coming soon)</label>
                            </Link>
                        </div>
                    </div>
                )*/}
            </nav>

            <div id="content">
                <main onClick={mainOnClick}>
                    <header className="profile">
                        <Link to="/home" className="nav-home">
                            <SVG src={toAbsoluteUrl("/media/def-image/hiro-blue-circle.svg")} />
                        </Link>
                        <button type="button" className="nav-toggle" onClick={ev => setShowNav(true)}>
                            <SVG src={toAbsoluteUrl("/media/def-image/icons/menu.svg")} />
                        </button>
                        <div className="fill" />
                        <HeaderBadges user={user} stripe={stripe} />
                        <NotificationsPopup user={user} />
                        <UserProfileDropdown user={user} />
                    </header>
                    {children}
                </main>
            </div>
        </div>
    );
}

export default injectIntl(
    connect(
        (state) => ({
            products: state.product.products,
            user: state.auth.user,
        }),
        dispatch => ({
            dispatch,
            ...actions.product,
            ...actions.auth
        })
    )(withRouter(Layout))
);
