/**
 * Entry application component used to compose providers and render Routes.
 */

import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { hot } from "react-hot-loader";
import { Player } from "./pages/generic/Player";
import { NeedsUpgrade } from "./pages/modals/NeedsUpgrade";
import UpgradeModal from "./pages/modals/UpgradeModal";
import YoutubeImport from "./pages/products/import/YoutubeImport";
import { loadReCaptcha } from "react-recaptcha-v3";
import NotificationsModal from "./pages/modals/NotificationsModal";
import RssImport from "./pages/products/import/RssImport";
import Onboarding from "./pages/modals/Onboarding";
import PhoneNumberModal from "./pages/modals/PhoneNumberModal";
import AddCreditsModal from "./pages/modals/AddCreditsModal";
import { NeedsUpgradeForSMS } from "./pages/modals/NeedsUpgradeForSMS";
import AddSubaccountModal from "./pages/modals/AddSubaccountModal";
import FivePackModal from "./pages/modals/FivePackModal";
import ShareCodeImport from "./pages/products/import/ShareCodeImport";
import OpenAiModal from "./pages/modals/OpenAiModal";

require("dotenv").config();

const App = ({ store, persistor, basename }) => {
  useEffect(() => {
    loadReCaptcha(process.env.REACT_APP_RECAPTCHA, () => { });
  }, []);

  return (
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                <Routes />
                {/* Modals and other global stuff that can be invoked from any component */}
                <Player />
                <Onboarding/>
                <NeedsUpgrade />
                <NeedsUpgradeForSMS />
                <UpgradeModal />
                <YoutubeImport />
                <RssImport />
                <ShareCodeImport />
                <NotificationsModal />
                <PhoneNumberModal />
                <OpenAiModal />
                <AddCreditsModal />
                <AddSubaccountModal />
                <FivePackModal />
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
};

export default process.env.NODE_ENV === "development" ? hot(module)(App) : App;
