import React from "react";
import Lottie from "react-lottie";
import successAnimation from "../modals/successAnimation";

function RssImportResultPage(props) {
  return <div className="min-vh-100 p-15 text-center d-flex align-items-center justify-content-center">
    <div>
      {props.match.params.status == "success"
        ? (
          <>
            <Lottie options={{
              animationData: successAnimation,
              loop: false,
              autoplay: true
            }} height={190} width={190} className="mb-16" />
            <h2 className="mb-6">Success!</h2>
            <p>You’ve verified the ownership of your podcast. The import process will start soon.</p>
          </>
        )
        : (
          <>
            <h2 className="mb-6">Something went wrong</h2>
            <p>We were unable to verify the ownership of the podcast.</p>
          </>
        )}
      <a href="/products" className="btn btn-primary mt-10">Back to my Products</a>
    </div>
  </div>;
}

export default RssImportResultPage;
