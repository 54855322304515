import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import actionTypes from "../action-types";

const initialAuthState = {
  phoneNumber: null
};

export const reducer = persistReducer(
  {
    storage,
    key: "phoneNumber",
    whitelist: []
  },
  (state = initialAuthState, action) => {
    switch(action.type) {
      case actionTypes.phoneNumber.GetPhoneNumber: {
        return {
          ...state,
          current: action.payload,
        };
      }

      default:
        return state;
    }
  }
);
