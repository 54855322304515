import React, { Fragment, useEffect, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import _ from "lodash";
import MessageField from "../../../layout/components/MessageField";
import utils from "../utils";
import CustomSelect from "../../../layout/components/CustomSelect";
import { SegmentedMessage } from "sms-segments-calculator";
import { getSmsBlockLength, isValidSmsText } from "../../../helpers/utils";
import { PhoneNumberModalEvents } from "../../modals/PhoneNumberModal";
import { NeedsUpgradeEvents } from "../../modals/NeedsUpgrade";
import { getInputClassName, toastMessage } from "../../../helpers";
import api from "../../../../redux/api";

let debounceTimer;

function SendWhatsapp({ automationId, submit, user, name, formik, saving, trigger, triggerIndex, actionIndex, remove, setPreviewBody, setPreviewSettings }) {
  let { set, get, error, props, touched } = utils.useFormikUtils(formik, name),
    [cost, setCost] = useState(null);

  let updatePreview = () => {
    let message = get("message");

    let replace = v => v.replace(/\[name(\|.+?)?\]/g, "John Carter")
      .replace(/\[email\]/g, "john@test.com")
      .replace(/\[install-url\]/g, "https://go.hiro.fm/abc")
      .replace(/\[first-name(\|.+?)?\]/g, "John");

    let body = (
      <>
        <div className={"message-out animated " + (message ? "" : " empty")} key={Math.random()}>
          {!!message
            ? (
              <>
                <em>{(user && user.firstName) || "You"}</em>
                {replace(message).split(/\n/g).map((line, i) => <Fragment key={i}>{line}<br /></Fragment>)}
              </>
            )
            : (
              <>
                <em className="mb-0">{(user && user.firstName) || "You"}</em>
                <span />
              </>
            )}
        </div>
      </>
    );

    setPreviewBody(body);
  };

  let updateCost = () => {
    // let message = get("message");

    // setCost(message && message.trim()
    //   ? new SegmentedMessage(message).segmentsCount
    //   : null);
  };

  useEffect(() => {
    setPreviewSettings({
      show: true,
      className: "chat",
      title: "SMS Message Preview"
    });

    updatePreview();

    return () => setPreviewSettings({ show: false });
  }, []);

  useEffect(() => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(updatePreview, 500);

    updateCost();
  }, [formik.values]);

  useEffect(() => {
    if(!user)
      return;

    if(!user.whatsappIsConnected)
      PhoneNumberModalEvents.dispatchShowWhatsapp();
  }, [user]);

  useEffect(() => {
    set("changed", true);
  }, []);

  return (
    <>
      <div className="form-group">
        <label className="d-block form-label">When to send message</label>
        <select className="custom-select w-auto" {...props("when")}>
          <option value="immediately">Immediately</option>
          <option value="delay">Delay send until</option>
        </select>
      </div>

      {get("when") == "delay" && (
        <div className="form-group -mt">
          <div className="d-flex align-items-center">
            <input type="number" className="form-control hide-spinner w-90px mr-3" min="1" step="1" {...props("delay")} onKeyDown={utils.disableIntro} />
            <select className="custom-select w-auto mr-3" {...props("period")}>
              <option value="minutes">minutes</option>
              <option value="hours">hours</option>
              <option value="days">days</option>
            </select>
            <span>after event is triggered</span>
          </div>
          {error("delay") && touched("delay") && <div className="field-error">{error("delay")}</div>}
        </div>
      )}

      {/*<div className="form-group">
        <label className="d-block">Recipients</label>
        <CustomSelect noSelectionText="Filter by tag" multiple />
      </div>*/}

      <div className="form-group">
        <div className="row align-items-center mb-2">
          <div className="col">
            <label className="m-0 form-label">Message text</label>
          </div>
          {cost !== null && <div className="col text-right sms-cost">Message costs <strong>{cost}</strong> credit</div>}
        </div>
        <MessageField className="fw" formik={formik} name={name + ".message"} emoji personalization={false} />
        {error("message") && <div className="field-error">{error("message")}</div>}
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      dispatch
    })
  )(SendWhatsapp)
);