import React, { useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import { useCustomEvent } from "../../../helpers/use-event";
import CommonAvatar from "../../../layout/components/Avatar";

let sleep = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

function Avatar() {
    return <CommonAvatar animateOnHover scale="015" />;
}

function Delayed({ delay = 0, children, className: cn = "" }) {
    const [className, setClassName] = useState("delayed-div "),
        { trigger } = useCustomEvent("chatScroll"),
        ref = useRef();

    useEffect(() => {
        let t = setTimeout(() => {
            setClassName(v => v + "show ");
            ref.current.querySelector("input, select, textearea")?.focus();
            trigger();
        }, delay);

        return () => clearTimeout(t);
    }, [delay]);

    return (
        <div className={className + " " + cn} ref={ref}>
            {children}
        </div>
    );
}

function Message({ children, delay = 0, fullWidth = false, incoming = false, writting = false, custom = false, className: cn = "" }) {
    //TODO use <Delay>

    const [className, setClassName] = useState("message " + (custom ? "custom " : "") + (incoming ? "incoming " : "sent ")),
        { trigger } = useCustomEvent("chatScroll"),
        ref = useRef();

    useEffect(() => {
        let t = setTimeout(() => {
            setClassName(v => v + "show ");
            ref.current.querySelector("input, select, textearea")?.focus();
            trigger();
        }, delay);

        return () => clearTimeout(t);
    }, [delay]);

    useEffect(() => {
        document.querySelectorAll(".message.show.last").forEach(elem => elem.classList.remove("last"));

        let elem = [...document.querySelectorAll(".message.show.sent")].pop();
        if(elem) elem.classList.add("last");

        elem = [...document.querySelectorAll(".message.show.incoming")].pop();
        if(elem) elem.classList.add("last");
    }, [className]);

    return (
        <div className={className + (writting ? "writting " : "") + (fullWidth ? "w-full " : "") + " " + cn} ref={ref}>
            <Avatar />
            <div className="text">
                {children}
            </div>
        </div>
    );
}

function Writting({ delay = 500, hideAfter = null }) {
    const [show, setShow] = useState(true),
        { trigger } = useCustomEvent("chatScroll");

    useEffect(() => {
        if(hideAfter === null)
            return;

        let t = setTimeout(() => {
            setShow(false);
            trigger();
        }, hideAfter);

        return () => clearTimeout(t);
    }, [hideAfter]);

    return show
        ? (
            <Message writting delay={delay}>
                <div className="dot-flashing" />
            </Message>
        )
        : <></>;
}

function Send({ onClick }) {
    return (
        <button type="button" className="btn send-btn" onClick={onClick}>
            <SVG src="/media/def-image/send.svg" />
        </button>
    );
}

export {
    sleep,
    Avatar,
    Message,
    Writting,
    Send,
    Delayed
};