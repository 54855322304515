export default {
    translation: {
        "All": "Toutes",
        "Audio Feeds": "Flux audio",
        "Category": "Catégorie",
        "There are no shows in this category.": "Il n'y a pas d'émissions dans cette catégorie.",
        "The creator doesn’t have any public audio feeds yet.": "Le créateur n'a pas encore de flux audio publics.",
        "About me": "À propos de moi",
        "My audio feeds": "Mes flux audio",
        "I agree to Hiro’s <a href=\"https://hiro.fm/terms\" target=\"_blank\" className=\"themed\">terms of service</a> & <a href=\"https://hiro.fm/privacy\" target=\"_blank\" className=\"themed\">privacy policy</a>.": "Je suis d'accord avec les conditions d'utilisation et la politique de confidentialité de Hiro.",
        "Powered by Hiro.fm": "Propulsé par Hiro.fm",
        "Start your own private audio product": "Lancez votre propre produit audio privé",
        "episodes": "épisodes",
        "Free": "Gratuit",
        "/ week": "/ semaine",
        "/ month": "/ mois",
        "/ year": "/ an",
        "one-time payment": "paiement unique",
        "per week": "par semaine",
        "per month": "par mois",
        "per year": "par an",
        "Products": "Produits",
        "Episodes": "Épisodes",
        "Listeners": "Auditeurs",
        "Downloads": "Téléchargements",
        "My activity": "Mon activité",
        "Created by:": "Créé par :",
        "Last update:": "Dernière mise à jour :",
        "This audio feed doesn’t have any eposides yet.": "Ce flux audio n'a pas encore d'épisodes.",
        "See more": "Voir plus",
        "Please verify the credit card details.": "Veuillez vérifier les détails de la carte de crédit.",
        "Payment": "Paiement",
        "Your details": "Vos détails",
        "First Name": "Prénom",
        "Last Name": "Nom",
        "Email": "E-mail",
        "Phone (optional)": "Téléphone (optionnel)",
        "Invalid phone number.": "Numéro de téléphone invalide.",
        "Payment details": "Détails du paiement",
        "Total payment": "Paiement total",
        "Get Access": "Obtenir l'accès",
        "Pay": "Payer",
        "Category:": "Catégorie :",
        "Get Free Access": "Obtenir un accès gratuit",
        "Show currently unavailable": "Spectacle actuellement indisponible",
        "Buy now for {{priceValue}}": "Acheter maintenant pour {{priceValue}}",
        "Length": "Durée",
        "reviews": "avis",
        "About": "À propos",
        "Testimonials": "Témoignages",
        "My other private audio feeds": "Mes autres flux audio privés",
        "Thank you!": "Merci !",
        "You’ve successfully purchased {{productName}} from {{author}}. We sent you an email to {{installEmail}} with access instructions.": "Vous avez réussi à acheter {{productName}} de {{author}}. Nous vous avons envoyé un e-mail à {{installEmail}} avec les instructions d'accès.",
        "Point your phone’s camera at the code below to access the private podcast.": "Pointez la caméra de votre téléphone sur le code ci-dessous pour accéder au podcast privé.",
        "Purchased item": "Article acheté",
        "Access Private Feed Now": "Accéder maintenant au flux privé",
        "Ohh no, something went wrong with your payment": "Ohh non, un problème est survenu avec votre paiement",
        "Check your credit card details and try one more time.": "Vérifiez les détails de votre carte de crédit et essayez à nouveau.",
        "Try one more time": "Essayer encore une fois",
        "Cancel": "Annuler",
        "Enter your first name.": "Entrez votre prénom.",
        "Enter your last name.": "Entrez votre nom.",
        "Enter your email address.": "Entrez votre adresse e-mail.",
        "Invalid email address.": "Adresse e-mail invalide.",
        "Product not found.": "Produit introuvable.",
        "Payment failed.": "Échec du paiement.",
        "Fill all the fields.": "Remplissez tous les champs.",
        "The email address is invalid.": "L'adresse e-mail est invalide.",
        "Invalud product ID.": "ID de produit invalide.",
        "Access denied to product.": "Accès refusé au produit.",
        "Show not available.": "Émission non disponible.",
        "Terms of service": "Conditions d'utilisation",
        "Privacy policy": "Politique de confidentialité"
    }
};