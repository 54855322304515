import React, { useEffect, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import actions from "../../../../redux/actions";
import api from "../../../../redux/api";
import { connect } from "react-redux";
import { toastMessage } from "../../../helpers";
import _ from "lodash";
import { ErrorMessage, Formik, Form } from "formik";
import schemas from "../../../schemas";
import { Button, Tooltip } from "@material-ui/core";
import AwsS3Dropzone from "../../generic/AwsS3Dropzone";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import StepAvailability from "../create-product/generic/generic/StepAvailability";
import StepEmail from "../create-product/generic/generic/StepEmail";
import { Link } from "react-router-dom";
import { CoverUploader } from "../create-product/generic/generic/coverUploader";
import SVG from "react-inlinesvg";
import UpgradeLink from "../../generic/UpgradeLink";
import ColorPicker from "../../generic/ColorPicker";
import ProductNavigation from "../../../layout/navigation/ProductNavigation";
import UnsavedFormGuard from "../../../layout/components/UnsavedFormGuard";
import AIField from "../../../layout/ai/AIField";
import { UpgradeEvents } from "../../modals/UpgradeModal";
import ConfirmModal from "../../modals/ConfirmModal";
import StepNotifications from "../create-product/generic/generic/StepNotifications";
import StepApp from "../create-product/generic/generic/StepApp";

function ProductSetting({ match, history, productObj, dispatch, currentUser }) {
  let [loading, setLoading] = useState(false),
    [showConfirmCancel, setShowConfirmCancel] = useState(false),
    formikRef = useRef();

  const productId = match.params.id;

  useEffect(() => {
    api.product.getProduct(productId).then(res => {
      dispatch(actions.product.setProduct(res));
    });
  }, []);

  const [uploadError, setUploadError] = React.useState(null);

  const onUploadError = message => setUploadError(message);

  const onArtworkUploaded = (uploadedFiles, setFieldValue) => {
    if((uploadedFiles || []).length < 1) {
      onUploadError("No uploaded file found.");
    } else if((uploadedFiles || []) > 1) {
      onUploadError("Please upload a single file");
    } else {
      setFieldValue("artwork", {
        url: uploadedFiles[0].amazonS3Url
      });
    }
  };

  let sendForm = async (n, values) => {
    setLoading(n);

    let res = await api.product.editProduct({
      product: {
        ...values,
        _id: productId
      }
    });

    setLoading(false);

    if(res) {
      toastMessage.success("Show updated!");

      let products = await api.product.getProducts();

      if(products) {
        dispatch(actions.product.setProducts(products));
        dispatch(actions.product.setProduct(products.find(p => p._id == productId)));
        window.localStorage.setItem("productsCount", (products && Array.isArray(products) && products.length) || 0);
      }
    }
  };

  let saveAll = async () => {
    await sendForm("modal", formikRef.current.values);
  };

  return (
    <>
      <ProductNavigation product={productObj} active="settings" />

      <div className="container-inner product-setting p-0">
        <div className="product-setting-content">

          {currentUser.planFeatures.products > -1 && currentUser.planFeatures.products <= currentUser.products.length
            ? (
              <div className="alert mb-10 alert-warning d-flex align-items-center m-0">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} className="svg-icon" />
                <div>
                  <h4>You reached the maximum number of audio feeds</h4>
                  Upgrade your plan to get more features and feeds. <a href="#" onClick={ev => {
                    ev.preventDefault();
                    //NeedsUpgradeEvents.dispatchShow();
                    UpgradeEvents.dispatchShow();
                  }}>Upgrade now</a>
                </div>
              </div>
            )
            : ""}

          <Formik
            initialValues={{
              name: productObj.name || "",
              description: productObj.description || "",
              artwork: productObj.artwork || {
                url: "",
                color: "color-9",
                icon: "icon-1",
              },
              color: productObj.color || {
                code: "",
                dark: true
              },
              instructorName: productObj.instructorName || "",
              releaseMode: productObj.releaseMode || 0,
              livePeriod: productObj.livePeriod || 0,
              productExpires: productObj.productExpires || 14,
              productExpiresMode: productObj.productExpiresMode || "days",
              displayCountdown: productObj.displayCountdown,
              fromName: productObj.fromName || "",
              replyToEmail: productObj.replyToEmail || "",
              appOnly: !!productObj.appOnly,
              emailContent: productObj.emailContent || "",
              emailSubject: productObj.emailSubject || "",
              sendEmailFromId: productObj.sendEmailFromId || "",
              disableNewEpisodeNotifications: productObj.disableNewEpisodeNotifications || false
            }}
            validationSchema={schemas.product.product.all}
            onSubmit={values => sendForm(1, values)}
            enableReinitialize
            innerRef={formikRef}
          >
            {formik => (
              <Form>
                <div className="card border-0">
                  <div className="card-body text-right p-0">
                    <button type="button" className="btn btn-secondary mr-3" onClick={() => setShowConfirmCancel(true)} disabled={!formik.dirty}>Cancel</button>
                    <button type="submit" className={"btn btn-primary " + (loading == 1 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)}>Save</button>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title m-0">Show Details</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label className="form-label">Title <em>*</em></label>

                      <AIField formik={formik} name="name" source="product" data={{ ...formik.values, productId: productObj._id }} prompt={formik.values.name
                        ? "Rewrite this title:\n\n" + formik.values.name
                        : "Write a title for this podcast"}>
                        <input
                          type="text"
                          name="name"
                          {...formik.getFieldProps("name")}
                          className={"form-control " + (formik.errors.name ? "is-invalid" : "")}
                        />
                      </AIField>
                      <ErrorMessage name="name">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
                    </div>

                    <div className="form-group">
                      <label className="form-label">Creator <em>*</em></label>
                      <input
                        type="text"
                        name="instructorName"
                        {...formik.getFieldProps("instructorName")}
                        className={"form-control " + (formik.errors.instructorName ? "is-invalid" : "")}
                      />
                      <ErrorMessage name="instructorName">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
                    </div>

                    <div className="form-group">
                      <label className="form-label">Cover art</label>
                      <CoverUploader
                        product={productObj._id}
                        formData={formik.values}
                        color={formik.values.artwork.color}
                        icon={formik.values.artwork.icon}
                        url={formik.values.artwork.url}
                        formHandler={formik.setFieldValue}
                        user={currentUser}
                        isRemoveEnabled
                        dropzone={(
                          <div className={"dropzone-artwork-container"}>
                            <AwsS3Dropzone
                              allowMultipleUpload={false}
                              onError={onUploadError}
                              onUploadComplete={uploadedFiles => onArtworkUploaded(uploadedFiles, formik.setFieldValue)}
                              forceSquareImageFormat={true}
                              fileType={"image/jpg, image/jpeg, image/png"}
                              uploadType="artwork"
                              errorMessage={uploadError}
                              maxSize={process.env.REACT_APP_MAX_IMAGE_SIZE * 1000000}
                              disabled={!currentUser.planFeatures.customCoverArt}
                              description="Select square png or jpg file (min. 500 x 500 px)" />
                          </div>
                        )}
                      />
                      {(!uploadError && formik.errors.artwork && formik.errors.artwork.url) && <div className="invalid-feedback-copy">Please upload a JPG or PNG File</div>}
                      {uploadError && <div className="invalid-feedback-copy">{uploadError}</div>}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Summary <em>*</em></label>
                      <AIField formik={formik} name="description" source="product" data={{ ...formik.values, productId: productObj._id }} prompt={formik.values.description
                        ? "Rewrite this description of my podcast:\n\n" + formik.values.description
                        : "Write a description for this podcast"}>
                        <textarea
                          rows="3"
                          name="description"
                          {...formik.getFieldProps("description")}
                          className={"form-control " + (formik.errors.description ? "is-invalid" : "")}
                        />
                      </AIField>
                      <ErrorMessage name="description">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
                    </div>

                    <div className="form-group">
                      <label className="form-label d-block">
                        Theme color
                        <Tooltip title="This will change the appeareance of your Sales Page and in our App." placement="top">
                          <Button><i className="fa fa-info-circle" aria-hidden="true" /></Button>
                        </Tooltip>
                        {!currentUser.planFeatures.customTheme ? <UpgradeLink label="Upgrade to change" user={currentUser} className="float-right" /> : <></>}
                      </label>
                      <ColorPicker disabled={!currentUser.planFeatures.customTheme} value={formik.values.color} onChange={color => formik.setFieldValue("color", color)} />
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title m-0">Episode Delivery & Availabilty</h3>
                  </div>

                  <div className="card-body episode-access">
                    <StepAvailability formik={formik} showButtons loading={loading == 2} />
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title m-0">Ownership</h3>
                  </div>

                  <div className="card-body ownership">
                    <div className="form-group owner-name-and-email row">
                      <div className="col-sm-6 mb-10 mb-sm-0">
                        <label className="form-label">Owner <em>*</em></label>
                        <input
                          type="text"
                          name="fromName"
                          {...formik.getFieldProps("fromName")}
                          className={"form-control " + (formik.errors.fromName ? "is-invalid" : "")}
                        />
                        <ErrorMessage name="fromName">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label">
                          Owner’s email
                          <em>*</em>
                          <Tooltip title="It’s best to use your support email address." placement="top">
                            <Button><i className="fa fa-info-circle" aria-hidden="true" /></Button>
                          </Tooltip>
                        </label>
                        <input
                          type="text"
                          name="replyToEmail"
                          {...formik.getFieldProps("replyToEmail")}
                          className={"form-control " + (formik.errors.replyToEmail ? "is-invalid" : "")}
                        />
                        <ErrorMessage name="replyToEmail">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
                      </div>
                    </div>
                  </div>
                </div>

                <StepApp formik={formik} user={currentUser} />

                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title m-0">Edit The Listener Access Email</h3>
                    <p className="text-muted">Edit the access email sent to you listeners. Or leave it blank and keep the default text.</p>
                  </div>

                  <div className="card-body listener-access-email">
                    <StepEmail product={productObj} formik={formik} showButtons loading={loading == 4} />
                  </div>
                </div>

                <StepNotifications formik={formik} />

                <div className="card border-0">
                  <div className="card-body p-0">
                    <div className="d-flex align-items-center">
                      <Link to={`/delete-product/${productId}`} className="btn btn-danger">
                        Delete Show
                      </Link>
                      <div className="fill" />
                      <button type="button" className="btn btn-secondary mr-3" onClick={() => setShowConfirmCancel(true)} disabled={!formik.dirty}>Cancel</button>
                      <button type="submit" className={"btn btn-primary " + (loading == 1 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)}>Save</button>
                    </div>
                  </div>
                </div>

                <ConfirmModal
                  show={showConfirmCancel}
                  message="Do you want to discard your changes?"
                  onConfirm={() => (setShowConfirmCancel(false), formik.resetForm())}
                  onCancel={() => setShowConfirmCancel(false)} />
              </Form>
            )}
          </Formik>
        </div>
      </div >

      <UnsavedFormGuard formikRef={formikRef} onSaveAsync={saveAll} loading={loading == "modal"} />
    </>
  );
}

export default injectIntl(connect(
  store => ({
    currentUser: store.auth.user,
    productObj: (store.product || []).product || {},
  }),

  dispatch => ({
    ...actions.product,
    dispatch,
  })
)(ProductSetting));;