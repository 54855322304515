import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import actions from "../../../redux/actions";
import Login from "./Login";
import AdminArea from "./AdminArea";

function AdminRouter(props) {
	const { isAuthorized } = useSelector(({ auth }) => ({
		isAuthorized: auth.adminAuthToken && auth.admin
	}), shallowEqual);

	return <Switch>		
		{isAuthorized
			? <Redirect exact from="/admin" to="/admin/dashboard"/>
			: <Route component={Login}/>}
		<Route component={AdminArea}/>
	</Switch>;
}

export default injectIntl(connect(null, actions.auth)(AdminRouter));