import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default (props) => {
    const { t } = useTranslation();

    let themeCss = "";
    if(props.color && props.color.code)
        themeCss = `
.themed-bg,
.btn-primary {
    background-color: ${props.color.code}!important;
    color: ${props.color.dark ? "#fff" : "#181c32"}!important;
}
.btn-primary {
    border-color: transparent !important;
}
.themed-bg a,
.themed-bg span {
    color: ${props.color.dark ? "#fff" : "#181c32"}!important;
}
.themed-bg .default-logo {
    filter: ${props.color.dark ? "none" : "invert(1)"};
}
.themed-text-color,
a.themed {
    color: ${props.color.code}!important;
}
.statistics-item path[fill],
.statistics-items-item path[fill] {
    fill: ${props.color.code};
}`;

    return <div className={"public-page " + (props.className || "") + (props.loading ? " loading" : "")}>
        <header className="themed-bg">
            <div className="container-regular">
                {props.breadcrumb && <div className="breadcrumb">{props.breadcrumb}</div>}
                <div className={"author-logo " + (props.logo ? "custom-logo" : "default-logo")}>
                    <img src={props.logo || toAbsoluteUrl("/media/def-image/author-page/authorPageMainLogo.svg")} />
                </div>
            </div>
        </header>
        <main>
            <div className="container-regular">
                <div className="row">
                    {props.children}
                </div>
            </div>
        </main>
        <footer>
            {props.author?.removeHiroBranding
                ? (
                    <div className="container-regular footer-documents">
                        <a href="https://hiro.fm/terms" target="_blank" className="themed">{i18n.t("Terms of service")}</a> &middot;&nbsp;
                        <a href="https://hiro.fm/privacy" target="_blank" className="themed">{i18n.t("Privacy policy")}</a>
                    </div>
                )
                : (
                    <div className="container-regular">
                        <div className="row">
                            <div className="col-md-4 footer-documents order-3 order-md-1 mt-6 mt-md-0">
                                <p dangerouslySetInnerHTML={{ __html: i18n.t("I agree to Hiro’s <a href=\"https://hiro.fm/terms\" target=\"_blank\" className=\"themed\">terms of service</a> & <a href=\"https://hiro.fm/privacy\" target=\"_blank\" className=\"themed\">privacy policy</a>.") }} />
                            </div>
                            <div className="col-md-4 text-center footer-copyright order-1 order-md-2">{t("Powered by Hiro.fm")}</div>
                            <div className="col-md-4 text-right footer-promo order-2 order-md-3">
                                <a href={props.author?.landingWithTracking} target="_blank" className="themed">{t("Start your own private audio product")}</a>
                            </div>
                        </div>
                    </div>
                )}
        </footer>
        <div className="loading">
            <svg className="splash-spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
        </div>
        <style>{themeCss}</style>
    </div>;
};
