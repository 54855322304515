import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, call, takeLatest } from "redux-saga/effects";
import actions from "../actions";
import actionTypes from "../action-types";
import api from "../api";

const initialAuthState = {
  products: null,
  product: {},
};

export const reducer = persistReducer(
  { storage, key: "products", whitelist: []},

  (state = initialAuthState, action) => {
    switch (action.type) {
    case actionTypes.product.GetProducts: {
      return {
        ...state,
        products: action.payload.products,
      };
    }

    case actionTypes.product.GetProduct: {
      return {
        ...state,
        product: action.payload.product,
      };
    }

    default:
      return state;
    }
  }
);

export function* saga() {
  yield takeLatest(actionTypes.auth.UserRequested, function* getProducts() {
    const data = yield call(() => {
        return api.product.getProducts(true)
      }
    );

    put(actions.product.setProducts(data))
  });
}
