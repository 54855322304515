import React, { useEffect, useRef, useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, useHistory, useRouteMatch, withRouter } from "react-router-dom";
import actions from "../../../redux/actions";
import SVG from "react-inlinesvg";
import api from "../../../redux/api";
import ConfirmModal from "../modals/ConfirmModal";
import { getSearchRegExp, toastMessage } from "../../helpers";
import { Dropdown, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import UnsavedFormGuard from "../../layout/components/UnsavedFormGuard";
import { checkLimits } from "../../plans";

function EditTagModalFn({ show, onHide, user, dispatch, fulfillUser, autoReload, tagId }) {
  const ref = useRef(),
    [saving, setSaving] = useState(),
    data = tagId && user.tags.find(t => t._id == tagId);

  async function submit(values) {
    await save(values, "form");
  }

  async function saveModal() {
    await save(ref.current.values, "modal");
  }

  async function reloadUser() {
    let res = await api.auth.getUserByToken(true);
    if(res)
      dispatch(fulfillUser(res.data));
  }

  async function save(values, from) {
    setSaving(from);

    let res = tagId
      ? await api.contacts.updateTag(tagId, values)
      : await api.contacts.createTag(values);

    setSaving(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success(tagId
      ? "Tag updated!"
      : "Tag created!");

    if(autoReload)
      reloadUser();

    onHide();
  }

  return (
    <>
      <Modal show={show} onHide={onHide} size="md" centered className="new-modals edit-tag-modal">
        <Formik
          initialValues={{
            name: data?.name || ""
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Enter the tag name.")
          })}
          enableReinitialize
          validateOnChange={true}
          onSubmit={submit}
          innerRef={ref}>
          {formik => (
            <Form>
              <Modal.Header>
                <h1 className="m0">
                  {tagId ? "Update Tag" : "Create Tag"}
                </h1>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label className="form-label">Tag name</label>
                  <input type="text" autoFocus className={"form-control " + (formik.touched.name && (formik.errors.name ? "is-invalid" : "is-valid"))} {...formik.getFieldProps("name")} />
                  {formik.touched.name && formik.errors.name && <div className="field-error">{formik.errors.name}</div>}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button type="button" className="btn btn-secondary mr-2" onClick={onHide}>Cancel</button>
                <button type="submit" className={"btn btn-primary " + (saving == "form" ? "loading spinner" : "")} disabled={!formik.isValid || !formik.dirty}>Save</button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <UnsavedFormGuard formikRef={ref} onSaveAsync={() => saveModal()} loading={saving == "modal"} />
    </>
  );
}

function Tags({ user, products, dispatch, fulfillUser }) {
  const [loading, setLoading] = useState(false),
    [actionLoading, setActionLoading] = useState(),
    [selected, setSelected] = useState([]),
    [showConfirmDelete, setShowConfirmDelete] = useState(false),
    [search, setSearch] = useState(null),
    [showEditModal, setShowEditModal] = useState(false),
    [editTagId, setEditTagId] = useState(null),
    createMatch = useRouteMatch("/tags/edit"),
    editMatch = useRouteMatch("/tags/:id/edit"),
    history = useHistory();

  async function reloadUser() {
    setLoading(true);

    let res = await api.auth.getUserByToken(true);
    if(res)
      dispatch(fulfillUser(res.data));

    setLoading(false);
  }

  function selectedOnChange(ev) {
    let items = selected.filter(i => i != ev.target.value);
    if(ev.target.checked)
      items.push(ev.target.value);
    setSelected(items);
  }

  function deleteOnClick(ev) {
    setShowConfirmDelete(true);
  }

  function deleteItemOnClick(ev, item) {
    ev.preventDefault();
    setShowConfirmDelete(item._id);
  }

  async function confirmDelete() {
    setShowConfirmDelete(false);

    if(showConfirmDelete === true) {
      setLoading(true);
      for(let item of selected)
        await api.contacts.deleteTag(item);
      setSelected([]);
    } else {
      setActionLoading("menu_" + confirmDelete);
      await api.contacts.deleteTag(showConfirmDelete);
      setActionLoading(false);
    }

    await reloadUser();
  }

  async function handleSearch(ev) {
    setSearch(ev.target.value);
  }

  function filter(list, search) {
    if(!search || !search.trim())
      return list;

    let r = getSearchRegExp(search);

    return list.filter(item => r.test(item.name));
  }

  async function closeEditModal() {
    history.push("/tags", { ignorePrompt: true });
    await reloadUser();
  }

  async function createOnClick(ev) {
    ev.preventDefault();

    if(await checkLimits.canAddTag(user))
      history.push("/tags/edit");
  }

  useEffect(() => {
    reloadUser();
  }, []);

  useEffect(() => {
    setShowEditModal(!!(createMatch || editMatch));
    setEditTagId(editMatch && editMatch.params.id);
  }, [createMatch, editMatch]);

  return (
    <>
      <h1>
        Tags
        <Link as="button" to="#" onClick={createOnClick} className="btn btn-plus inline" />
      </h1>

      {loading || (user && user.tags && user.tags.length)
        ? (
          <div className="card p-10 manage-tags-page">
            <div className="card-toolbar">
              <input type="text" className="form-control search" placeholder="Search Tags" onChange={handleSearch} />
              <div className="d-flex fill justify-content-end">
                <Link to="#" onClick={createOnClick} className="btn btn-primary font-weight-bolder font-size-sm mr-3">+ New Tag</Link>
                <button type="button" className="btn btn-danger font-weight-bolder font-size-sm" disabled={!selected.length} onClick={deleteOnClick}>Delete</button>
              </div>
            </div>

            {loading
              ? (
                <div className="spinner spinner-full"></div>
              )
              : (
                <div className="table-responsive">
                  <table className="table table-head-custom table-vertical-center">
                    <thead>
                      <tr>
                        <th width="10"></th>
                        <th>Tag</th>
                        <th>Contacts</th>
                        <th width="10"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {filter(user.tags, search).map((item, i) => (
                        <tr key={i}>
                          <td>
                            <label className="checkbox checkbox-lg checkbox-single">
                              <input type="checkbox" onChange={selectedOnChange} value={item._id} checked={selected.includes(item._id)} />
                              <span />
                            </label>
                          </td>
                          <td>
                            <Link to={"tags/" + item._id + "/edit"} className="text-inherit">{item.name}</Link>
                          </td>
                          <td>
                            {item.contactsCount || "0"}
                          </td>
                          <td>
                            <Dropdown className="dropdown table-options-dropdown fixed-dropdown dropdown-inline">
                              <Dropdown.Toggle className={actionLoading == "menu_" + item._id ? " loading spinner spinner-dark" : ""}>
                                <SVG src={toAbsoluteUrl("/media/def-image/icons/menu-2.svg")} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
                                <Dropdown.Item href={"/contacts/tags/" + item.name}>
                                  <div className="icon">
                                    <SVG src="/media/def-image/icons/contacts-menu.svg" />
                                  </div>
                                  Open Contacts
                                </Dropdown.Item>
                                <Dropdown.Item href={"/tags/" + item._id + "/edit"}>
                                  <div className="icon">
                                    <SVG src="/media/def-image/icons/edit-2.svg" />
                                  </div>
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item href="#" onClick={ev => deleteItemOnClick(ev, item)}>
                                  <div className="icon">
                                    <SVG src="/media/def-image/icons/delete.svg" />
                                  </div>
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
          </div>
        )
        : (
          <div className="manage-tags-page text-center">
            <SVG src="/media/def-image/tags-empty-state.svg" className="mt-20 mb-8 empty-icon" />
            <h2>No Tags Yet</h2>
            <Link to="#" onClick={createOnClick} className="btn btn-primary">Add your first tag</Link>
          </div>
        )}

      <ConfirmModal
        show={showConfirmDelete}
        message={showConfirmDelete === true ? "Are you sure you want to delete the selected tags?" : "Are you sure you want to delete the tag?"}
        onConfirm={confirmDelete}
        onCancel={e => setShowConfirmDelete(false)} />

      <EditTagModalFn show={showEditModal} onHide={() => closeEditModal()} user={user} tagId={editTagId} />
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      dispatch
    })
  )(withRouter(Tags))
);

export const EditTagModal = injectIntl(
  connect(
    (state) => ({
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.auth,
      dispatch
    })
  )(withRouter(EditTagModalFn))
);
