import { useFetch } from "../../app/helpers";

export default {
	getInstructor: filter => useFetch.get("instructor", filter),
	getProduct: filter => useFetch.get("public-product", filter),
	getPlayer: id => useFetch.get("public-player/" + id, null, true),
	createListener: data => useFetch.post("public-student/", data),
	beginConnectAccount: () => useFetch.post("stripe/begin-connect-account"),
	purchaseAccessTest: (productId, data) => useFetch.post("stripe/purchase-access-test/" + productId, data),
	getCustomerByEmail: (instructorId, email, recaptchaToken) => useFetch.post("stripe/customer/" + email, {
		instructorId,
		recaptchaToken
	}),
	createCustomer: (instructorId, customerData, recaptchaToken) => useFetch.post("stripe/customer", {
		instructorId,
		recaptchaToken,
		...customerData
	}),
	createSubscription: (customerId, instructorId, paymentMethodId, priceId, recaptchaToken) => useFetch.post("stripe/subscription", {
		customerId,
		instructorId,
		paymentMethodId,
		priceId,
		recaptchaToken
	}),
	attachPaymentMethod: (instructorId, paymentMethodId, customerId, recaptchaToken) => useFetch.post('stripe/paymentMethod/attach', {
		instructorId,
		paymentMethodId,
		customerId,
		recaptchaToken
	}),
	createPaymentIntentForDirectChange: (instructorId, productId, customerId, recaptchaToken) => useFetch.post("stripe/paymentIntent/direct", {
		instructorId,
		productId,
		customerId,
		recaptchaToken
	}),
	failedPayment: (product, listener) => useFetch.post("stripe/notify-failed-payment", { product, listener })
};