import React, { useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import "react-phone-number-input/style.css";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";

function Validation(props) {
  let [ message, setMessage ] = useState(""),
    [ linkUrl, setLinkUrl ] = useState(null);

  let isUser = props.match.params.type == "user",
    isProduct = props.match.params.type == "product",
    success = props.match.params.status == "success" && (isUser || isProduct);

  useEffect(() => {
    let url = null;

    if(success) {
      if(isUser) {
        setMessage("You can now go back to the app.");
        url = "hiro://library/user-validation-success";
      } else if(isProduct) {
        setMessage("You can now go back to the app and start listening to the podcast.");
        url = "hiro://library/ownership-validation-success";
      }
    } else {
      if(isUser) {
        setMessage("The link is invalid or expired.");
        url = "hiro://library/user-validation-failed";
      } else if(isProduct) {
        setMessage("The link is invalid or expired.");
        url = "hiro://library/ownership-validation-failed";
      } else {
        setMessage("Either the link is invalid or there was an unexpected problem.");
      }
    }

    if(url && isMobile)
      window.location.href = url;
    setLinkUrl(url);
  }, []);
  
  return (
    <>
      <div className={"app-page " + (isMobile ? "" : "desktop")}>
        <div>
          <SVG src={toAbsoluteUrl("/media/logos/logo.svg")} className="logo" />
          {success
          ? (
            <>
              <h3>Success!</h3>
              <p>{message}</p>
              {linkUrl && isMobile && <a href={linkUrl} className="btn mt-10 btn-primary">Open App</a>}
            </>
          )
          : (
            <>
              <h3>Something went wrong</h3>
              <p>{message}</p>
              {linkUrl && isMobile && <a href={linkUrl} className="btn mt-10 btn-secondary">Back to the App</a>}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Validation;