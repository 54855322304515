import React from "react";

export default function Avatar({ animateOnHover, scale = "1" }) {
    //source: https://codepen.io/akhilarjun/pen/oNbKxNO (modified)
    return (
        <div className={"avatar cute-robot-v1 " + (scale ? "scale-" + scale + " ": "") + (animateOnHover ? "animate " : "")}>
            <div className="circle-bg">
                <div className="robot-ear left" />
                <div className="robot-head">
                    <div className="robot-face">
                        <div className="eyes left" />
                        <div className="eyes right" />
                        <div className="mouth" />
                    </div>
                </div>
                <div className="robot-ear right" />
                <div className="robot-body" />
            </div>
        </div>
    );
}