import React from "react";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import actions from "../../../redux/actions";

function Layout({ user, children, loading = false }) {
    return (
        <div className="app ai container">
            <div className="header">
                <SVG src="/media/def-image/ai-logo.svg" className="logo" />
                <div className="fill" />
                <div className="credits">
                    {parseInt(user.aiCredits).toLocaleString()} credits
                </div>
            </div>
            {loading && <div className="loading spinner" />}
            {children}
        </div>
    );
}

export default connect(
    state => ({
        user: state.auth.user,
    }),
    (dispatch) => ({
        ...actions.auth,
        dispatch,
    })
)(Layout);