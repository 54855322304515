import React from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import actions from "../../../../../redux/actions";
import api from "../../../../../redux/api";
import { getInputClassName, toastMessage } from "../../../../helpers";

//TODO fix this awful markup

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Required field.")
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      let res = await api.auth.forgotPassword(values.email);
      
      if(res && res.success) {
        toastMessage.success("We just sent you an email to recover your account.");
        formik.resetForm({});
      }
      
      setSubmitting(false);
    }
  });

  return (
    <>
      <h1 className="mb-6">Forgot password?</h1>
      <p className="mb-12">Fill in your email and we send you a link to reset your password.</p>

      <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">
        <div className="form-group fv-plugins-icon-container">
          <label className="form-label">Email</label>
          <input
            type="email"
            className={getInputClassName(formik, "email")}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email && <div className="field-error">{formik.errors.email}</div>}
        </div>

        <div className="form-group form-group-btn d-flex flex-wrap mb-8">
          <button type="submit" className={"btn btn-primary " + (formik.isSubmitting ? "loading spinner" : "")}>
            Send link
          </button>
        </div>
      </form>

      <div>
        <Link to="/auth/login" className="font-weight-semibold">Return to sign in</Link>
      </div>
    </>
  );
}

export default injectIntl(connect(null, actions.auth)(ForgotPassword));
