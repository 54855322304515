import _ from "lodash";
import React, { useState } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import actions from "../../../../redux/actions";
import ConfirmModal from "../../modals/ConfirmModal";
import api from "../../../../redux/api";
import { Switch } from "@material-ui/core";
import { toastMessage } from "../../../helpers";
import ProfileNavigation from "../../../layout/navigation/ProfileNavigation";

function NotificationsSettings() {
  let user = useSelector((state) => state.auth.user, shallowEqual),
    [ confirmRemove, setConfirmRemove ] = useState(null),
    dispatch = useDispatch();

  let update = async () => {
    let res = await api.auth.getUserByToken(true);
    dispatch(actions.auth.fulfillUser(res.data));
  };

  let doRemove = async () => {
    let id = confirmRemove;
    setConfirmRemove(null);

    let res = await api.user.disableNotifications(id);
    if(res.success) {
      update();
      toastMessage.success("The device has been removed.");
    }
  };

  let handleSwitchChange = async ev => {
    let res = await api.user.configureNotifications({
      option: ev.target.name,
      value: ev.target.checked
    });
    if(res.success) {
      update();
      toastMessage.success("Your preferences have been updated.");
    }
  };

  if(!user) return <></>;

  return (
    <>
      <ProfileNavigation active="notifications" />

      <div className="container-inner">
        <div className="card card-profile-header mb-10">
          <div className="card-header">
            <div>
              <h3 className="card-title fw-bold m-0">My Devices</h3>
            </div>
          </div>
          <div className="card-body pt-9 pb-10">
            {!user.oneSignalIds || !user.oneSignalIds.length
              ? <div className="text-center text-gray">You don’t have push notifications enabled on any device.</div>
              : <></>}
            {user && user.oneSignalIds
              ? user.oneSignalIds.map((item, i) => <div key={i} className="d-flex align-items-center mb-3">
                <div className="flex-grow-1">
                  <strong>{item.device}</strong>
                </div>
                <div>
                  <button className="btn btn-secondary" onClick={e => setConfirmRemove(item._id)}>Remove</button>
                </div>
              </div>)
              : <></>}
          </div>
        </div>

        <div className="card card-profile-header mb-10">
          <div className="card-header">
            <div>
              <h3 className="card-title fw-bold m-0">Enabled Notifications</h3>
            </div>
          </div>
          <div className="card-body pt-9 pb-10">
            <label className="mb-3 d-flex align-items-center">
              <Switch name="news" checked={!!(user.notifications && user.notifications.includes("news"))} onChange={handleSwitchChange} className="blue-switch mr-1" />
              <span className="pl-2 flex-grow-1">News and events</span>
            </label>
            <label className="mb-3 d-flex align-items-center">
              <Switch name="updates" checked={!!(user.notifications && user.notifications.includes("updates"))} onChange={handleSwitchChange} className="blue-switch mr-1" />
              <span className="pl-2 flex-grow-1">Activity updates regarding your products (audio file conversion completed, import completed, etc.)</span>
            </label>
            <label className="mb-3 d-flex align-items-center">
              <Switch name="listeners" checked={!!(user.notifications && user.notifications.includes("listeners"))} onChange={handleSwitchChange} className="blue-switch mr-1" />
              <span className="pl-2 flex-grow-1">New listeners in your products (paid or free products)</span>
            </label>
            <label className="mb-3 d-flex align-items-center">
              <Switch name="sms" checked={!!(user.notifications && user.notifications.includes("sms"))} onChange={handleSwitchChange} className="blue-switch mr-1" />
              <span className="pl-2 flex-grow-1">New SMS message</span>
            </label>
            <label className="mb-3 d-flex align-items-center">
              <Switch name="sales" checked={!!(user.notifications && user.notifications.includes("sales"))} onChange={handleSwitchChange} className="blue-switch mr-1" />
              <span className="pl-2 flex-grow-1">New sales in your products (paid products only)</span>
            </label>
            <label className="mb-3 d-flex align-items-center">
              <Switch name="failed-payments" checked={!!(user.notifications && user.notifications.includes("failed-payments"))} onChange={handleSwitchChange} className="blue-switch mr-1" />
              <span className="pl-2 flex-grow-1">Failed subscription payments for your products</span>
            </label>
            { /*<label className="mb-3 d-flex align-items-center">
              <Switch name="weekly-email" checked={!!(user.notifications&&user.notifications.includes("weekly-email"))} onChange={handleSwitchChange} className="blue-switch mr-1"/>
              <span className="pl-2 flex-grow-1">Weekly news digest email</span>
            </label>*/ }
          </div>
        </div>
      </div>

      <ConfirmModal
        show={confirmRemove}
        message="Are you sure you want to disable notifications on the selected device?"
        onConfirm={doRemove}
        onCancel={ev => setConfirmRemove(null)} />
    </>
  );
}

export default connect(null, actions.auth)(NotificationsSettings);
