import _ from "lodash";
import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomSelect from "./CustomSelect";
import { useHistory } from "react-router-dom";
import { checkLimits } from "../../plans";

function getObject(tag, { contactsCount = true }) {
  return {
    id: tag.name,
    label: (
      <>
        {tag.name}&nbsp;
        {contactsCount && <small>({tag.contactsCount})</small>}
      </>
    )
  };
}

function TagDropdown({ user, onCreateTagRequest, contactsCount = true, className = "", ...props }) {
  const history = useHistory();

  if(!user)
    return <></>;

  async function createLinkOnClick(ev) {
    ev.preventDefault();
    if(!await checkLimits.canAddTag(user))
      return;
    if(onCreateTagRequest)
      onCreateTagRequest();
    else
      history.push("/tags");
  }

  let options = user.tags.map(t => getObject(t, { contactsCount }));

  return (
    <CustomSelect
      excludeSelected
      image={false}
      options={options}
      className={"tags-dropdown " + className}
      dropdownFooter={<a href="#" onClick={createLinkOnClick} className="d-block">+ Create tag</a>}
      emptyText={<>You don’t have any tags. <a href="#" onClick={createLinkOnClick}>Create tag</a></>}
      emptyDropdownText="There are no more tags to select." {...props} />
  );
}

export default injectIntl(
  connect(
    (state) => ({
      user: state.auth.user
    })
  )(withRouter(TagDropdown))
);
