import _ from "lodash";
import React, { useRef, useState } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import actions from "../../../../redux/actions";
import schemas from "../../../schemas";
import api from "../../../../redux/api";
import { toastMessage } from "../../../helpers";
import SettingsNavigation from "../../../layout/navigation/SettingsNavigation";
import UnsavedFormGuard from "../../../layout/components/UnsavedFormGuard";
import TagsInput from "react-tagsinput";
import TrackingGAModal from "./components/TrackingGAModal";
import TrackingPixelModal from "./components/TrackingPixelModal";
import SVG from "react-inlinesvg";
import AwsS3Dropzone from "../../generic/AwsS3Dropzone";

function OrganizationSettings() {
  let auth = useSelector((state) => state.auth.user, shallowEqual),
    user = auth?.owner || auth,
    [loading, setLoading] = useState(0),
    [profilePictureError, setProfilePictureError] = useState(),
    dispatch = useDispatch(),
    // [showTrackingPixelModal, setShowTrackingPixelModal] = useState(false),
    // [showTrackingGAModal, setShowTrackingGAModal] = useState(false),
    formikRefs = [useRef()];

  const getInputClasses = (formik, fieldname) => {
    if(formik.touched[fieldname] && formik.errors[fieldname])
      return "is-invalid";
    if(formik.touched[fieldname] && !formik.errors[fieldname])
      return "is-valid";
    return "";
  };

  let sendForm = async (values, loading = 1) => {
    setLoading(loading);

    await api.user.editCurrentUser({
      updateSuperUser: true,
      user: values,
    });

    toastMessage.success("Your settings have been updated!");

    dispatch(actions.auth.fulfillUser((await api.auth.getUserByToken(true)).data));

    setLoading(0);
  };

  let displayFeedback = (formik, fieldName) => formik.touched[fieldName] && formik.errors[fieldName] ? (
    <div className="invalid-feedback">{formik.errors[fieldName]}</div>
  ) : null;

  let saveOrganization = async () => {
    await sendForm(formikRefs[0].current.values, "modal");
  };

  function profilePictureOnError(error) {
    setProfilePictureError(error);
  }

  function profilePictureOnUpload(formik, files) {
    if((files || []).length != 1)
      return setProfilePictureError("Please upload a single file");

    formik.setFieldValue("pictureUrl", files[0].amazonS3Url);
  }

  // let savePixel = async ({ value }) => {
  //   await sendForm({ facebookPixelId: value }, "pixel");
  //   setShowTrackingPixelModal(false);
  // };

  // let saveGA = async ({ value }) => {
  //   await sendForm({ analyticsId: value }, "ga");
  //   setShowTrackingGAModal(false);
  // };

  return (
    <>
      <SettingsNavigation active="organization" />

      <div className="container-inner">
        <Formik
          initialValues={{
            companyName: user.companyName ? user.companyName : "",
            phone: user.phone ? "+" + user.phone.replace(/[^0-9]/g, "") : "",
            website: user.website ? user.website : "",
            pictureUrl: user.pictureUrl || ""
          }}
          validationSchema={schemas.user.user.organizationSettings}
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={true}
          onSubmit={values => sendForm(values, 1)}
          innerRef={formikRefs[0]}
        >
          {formik => (
            <Form>
              <div className="card card-profile-header mb-3">
                <div className="card-header">
                  <div>
                    <h3 className="card-title fw-bold m-0">Organization</h3>
                    <p className="text-muted">Your company and contact details.</p>
                  </div>
                  <button type="submit" className={"btn btn-primary " + (loading == 1 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)}>Update</button>
                </div>
                <div className="card-body pt-9 pb-9">
                  <div className="form-group">
                    <label className="form-label">Profile picture</label>
                    <div className={"dropzone-artwork-container dropzone-sm dropzone-avatar " + (formik.values.pictureUrl ? "uploaded" : "")}>
                      <AwsS3Dropzone
                        allowMultipleUpload={false}
                        onError={profilePictureOnError}
                        onUploadComplete={files => profilePictureOnUpload(formik, files)}
                        forceSquareImageFormat={true}
                        fileType="image/jpg, image/jpeg, image/png"
                        uploadType="artwork"
                        errorMessage={profilePictureError}
                        description="Select square png or jpg file (min. 500 x 500 px)" />
                      <div className="dropzone-uploaded-image">
                        <div className="image-input image-input-outline" style={{ backgroundImage: "url(" + (formik.values.pictureUrl || "/media/users/blank.png") + ")" }}>
                          {!!formik.values.pictureUrl && (
                            <span onClick={() => formik.setFieldValue("pictureUrl", null)} className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove" data-toggle="tooltip" title="Remove avatar">
                              <i className="ki ki-bold-close icon-xs text-muted" />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-label">Company Name</label>
                    <input
                      type="text"
                      className="form-control"
                      {...formik.getFieldProps("companyName")} />
                    {displayFeedback(formik, "companyName")}
                  </div>

                  <div className="form-group">
                    <label className="form-label">Contact Phone</label>
                    <div className="input-group input-group-lg input-group-solid">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-phone" />
                        </span>
                      </div>
                      <input
                        type="text"
                        placeholder="+1(123)112-11-11"
                        className={`form-control ${getInputClasses(formik, "phone")}`}
                        name="phone"
                        {...formik.getFieldProps("phone")}
                      />
                    </div>
                    {displayFeedback(formik, "phone")}
                    <small className="text-muted">We’ll never share your phone with anyone else.</small>
                  </div>

                  <div className="form-group">
                    <label className="form-label">Company Website</label>
                    <input
                      type="text"
                      placeholder="https://hiro.fm"
                      className="form-control"
                      {...formik.getFieldProps("website")} />
                    {displayFeedback(formik, "website")}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        {/*
        <div className="card card-profile-header mb-10">
          <div className="card-header">
            <div>
              <h3 className="card-title fw-bold m-0">Tracking</h3>
              <p className="text-muted">Add a unique Facebook Pixel and Google Analytics code.</p>
            </div>
          </div>
          <div className="card-body pt-9 pb-10">
            <div className="tracking-item">
              <div className="row align-items-center">
                <div className="col-auto">
                  <SVG src="/media/def-image/facebook-blue.svg" className="logo" />
                </div>
                <div className="col-auto flex-grow-1">
                  <strong>Facebook Pixel</strong>
                  {!user.facebookPixelId && <span className="text-muted d-inline-block pl-3">(Not set up)</span>}
                </div>
                <div className="col-auto">
                  <button type="button" className="btn-transparent mr-4" onClick={() => setShowTrackingPixelModal(true)}>
                    <SVG src="/media/def-image/icons/edit-2.svg" className="svg-icon" />
                  </button>
                  <SVG src="/media/def-image/icons/check-circle.svg" className={"check-icon " + (!user.facebookPixelId ? "fill-gray" : "")} />
                </div>
              </div>
            </div>

            <div className="tracking-item">
              <div className="row align-items-center">
                <div className="col-auto">
                  <SVG src="/media/def-image/google.svg" className="logo" />
                </div>
                <div className="col-auto flex-grow-1">
                  <strong>Google Analytics</strong>
                  {!user.analyticsId && <span className="text-muted d-inline-block pl-3">(Not set up)</span>}
                </div>
                <div className="col-auto">
                  <button type="button" className="btn-transparent mr-4" onClick={() => setShowTrackingGAModal(true)}>
                    <SVG src="/media/def-image/icons/edit-2.svg" className="svg-icon" />
                  </button>
                  <SVG src="/media/def-image/icons/check-circle.svg" className={"check-icon " + (!user.analyticsId ? "fill-gray" : "")} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <TrackingPixelModal where="Profile and Sales pages" show={showTrackingPixelModal} onHide={() => setShowTrackingPixelModal(false)} user={user} onSubmit={savePixel} saving={loading == "pixel"} value={user.facebookPixelId} />
        <TrackingGAModal where="Profile and Sales pages" show={showTrackingGAModal} onHide={() => setShowTrackingGAModal(false)} user={user} onSubmit={saveGA} saving={loading == "pixel"} value={user.analyticsId} />
        */}

      </div>

      <UnsavedFormGuard formikRef={formikRefs[0]} onSaveAsync={saveOrganization} loading={loading == "modal"} />
    </>
  );
}

export default connect(null, actions.auth)(OrganizationSettings);
