import React from "react";

/**
 * Converts the given string to JSX replacing all the linebreaks with `<br>`.
 * @param {string} str - Input string.
 * @returns {Object}
 */
export function nl2br(str) {
    if(typeof str!="string"||!str) return <></>;

    let result=[];
    for(let line of str.split("\n"))
        result.push(<>{line.trim()}<br/></>);
    
    return result;    
}

/**
 * Returns `true` if the given value is a string and *looks like* a valid email address.
 * @param {string} str - Input string.
 * @returns {boolean}
 */
export function isValidEmail(str) {
    return !!(typeof str=="string"&&str.trim()&&/^.+?@.+?$/.test(str.trim())); //TODO
}

/**
 * Returns `true` if the given value is a string and *looks like* a valid URL.
 * @param {string} str - Input string.
 * @returns {boolean}
 */
export function isValidUrl(str) {
    return !!(typeof str=="string"&&str.trim()&&/^https?:\/\/[^\s]+$/.test(str.trim()));
}