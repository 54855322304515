import _ from "lodash";

export default {
    /**
     * 
     */
    useFormikUtils: (formik, baseName = "") => {
        let get = (prop, name) => _.get(formik[prop], (baseName ? baseName + "." : "") + name);

        return {
            props: name => formik.getFieldProps((baseName ? baseName + "." : "") + name),
            set: (name, value) => formik.setFieldValue((baseName ? baseName + "." : "") + name, value),
            get: name => get("values", name),
            error: name => get("errors", name),
            touched: name => get("touched", name)
        };
    },

    /**
     * 
     */
    disableIntro: ev => ev.which == 13 && ev.preventDefault()
};