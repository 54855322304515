import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { isMobile } from "react-device-detect";

function LaunchApp() {
  if(isMobile)
    window.location.href = "hiro://library";
  
  return (
    <>
      <div className={"app-page " + (isMobile ? "" : "desktop")}>
        <div>
          <SVG src={toAbsoluteUrl("/media/logos/logo.svg")} className="logo" />
          {isMobile
          ? (
            <>
              <p>Go to the app to continue.</p>
              <a href="hiro://library" className="btn mt-10 btn-primary">Open App</a>
            </>
          )
          : (
            <p>Open the Hiro app in your phone to continue.</p>
          )}
        </div>
      </div>
    </>
  );
}

export default LaunchApp;