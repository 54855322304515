import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as authReducers from "./reducers/auth-reducers";
import * as productReducers from "./reducers/product-reducers";
import * as phoneNumberReducers from "./reducers/phone-number-reducers";
import * as userReducers from "./reducers/user-reducers";
import * as listenerReducers from "./reducers/listener-reducers";
import * as episodeReducers from "./reducers/episode-reducers";

export const rootReducer = combineReducers({
  auth: authReducers.reducer,
  product: productReducers.reducer,
  user: userReducers.reducer,
  listener: listenerReducers.reducer,
  episode: episodeReducers.reducer,
  phoneNumber: phoneNumberReducers.reducer
});

export function* rootSaga() {
  yield all([authReducers.saga(), productReducers.saga(), userReducers.saga()]);
}
