import * as auth from "./auth-types";
import * as product from "./product-types";
import * as phoneNumber from "./phone-number-types";
import * as user from "./user-types";
import * as listener from "./listener-types";
import * as episode from "./episode-types";

export default {
  auth,
  product,
  user,
  listener,
  episode,
  phoneNumber
};
