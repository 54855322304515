import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import actions from "../../../../../redux/actions";
import * as Yup from "yup";
import api from "../../../../../redux/api";
import { useHistory } from "react-router-dom";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import SVG from "react-inlinesvg";
import { getInputClassName } from "../../../../helpers";
import { Switch } from "@material-ui/core";

const validationSchema = Yup.object().shape({
  coupon: Yup.string(),
  plan: Yup.string(),
  period: Yup.string()
});

const prices = {
  solo_limited: {
    monthly: 57,
    annual: 570
  },
  business_97: {
    monthly: 97,
    annual: 970
  },
  agency: {
    monthly: 125,
    annual: 1197
  }
};

const coupons = {
  solo_limited: {
    monthly: "97OFF",
    annual: ""
  },
  business_97: {
    monthly: "97OFF",
    annual: ""
  },
  agency: {
    monthly: "97OFF",
    annual: ""
  }
};

function getDefaultPlan() {
  let hash = window.location.hash.substring(1),
    plans = {
      "solo": "solo_limited",
      "business": "business_97",
      "agency": "agency",
      "solo-annual": "solo_limited",
      "business-annual": "business_97",
      "agency-annual": "agency",
      "solo-monthly": "solo_limited",
      "business-monthly": "business_97",
      "agency-monthly": "agency"
    };
  return plans[hash] || "solo_limited";
}

function getDefaultPeriod() {
  let hash = window.location.hash.substring(1),
    plans = {
      "solo": "annual",
      "business": "annual",
      "agency": "annual",
      "solo-annual": "annual",
      "business-annual": "annual",
      "agency-annual": "annual",
      "solo-monthly": "monthly",
      "business-monthly": "monthly",
      "agency-monthly": "monthly"
    };
  return plans[hash] || "annual";
}

function PaymentForm({ elements, stripe, setLogin }) {
  let [loading, setLoading] = useState(false),
    [priceLoading, setPriceLoading] = useState(false),
    [price, setPrice] = useState(),
    [couponIsInvalid, setCouponIsInvalid] = useState(false),
    [cardFeedback, setCardFeedback] = useState(null),
    [agreesToTerms, setAgreesToTerms] = useState(false),
    dispatch = useDispatch(),
    history = useHistory(),
    couponTimerRef = useRef(),
    formik = useFormik({
      initialValues: {
        coupon: "",
        period: getDefaultPeriod(),
        plan: getDefaultPlan()
      },
      validationSchema,
      onSubmit: async values => {
        if(loading)
          return;

        if(!agreesToTerms)
          return toast.error("You must agree to our Terms of Service and our Privacy Policy to continue.");

        setCardFeedback(null);
        setLoading(true);

        await api.user.editCurrentUser({
          user: {
            ...values
          }
        });

        dispatch(actions.auth.fulfillUser((await api.auth.getUserByToken(true)).data));

        processCard();
      },
    });

  function fail(reason) {
    setLoading(false);
    setCardFeedback(reason || "Please enter a valid credit card.");
  }

  function success() {
    api.pub.event("StartTrial");
    history.push("/home#onboarding");
  }

  async function checkUser() {
    let user = await api.auth.getUserByToken(true);
    if((user.data.owner && user.data.owner.newPlan.name == formik.values.plan) || user.data.newPlan.name == formik.values.plan) {
      dispatch(actions.auth.fulfillUser(user.data));
      success();
    } else {
      setTimeout(checkUser, 500);
    }
  }

  async function processCard() {
    try {
      let customer = await api.checkout.getCustomer(true);

      if(!customer || !customer.data)
        return fail("Unable to process your payment details.");

      let paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement)
      });

      if(paymentMethod.error) {
        setLoading(false);

        return paymentMethod.error.type == "validation_error"
          ? fail("Please verify the credit card details.")
          : fail(paymentMethod.error.message);
      }

      let res = await api.checkout.attachPaymentMethod(paymentMethod.paymentMethod.id);
      if(!res.data) {
        setLoading(false);

        return fail(res.message);
      }

      let subscription = await api.checkout.createSubscription(paymentMethod.paymentMethod.id, formik.values.plan, formik.values.period, formik.values.coupon);

      if(subscription && subscription.token && subscription.user)
        setLogin(subscription.token, subscription.user);

      if(!subscription || !subscription.success) {
        setLoading(false);

        return toast.error(subscription.error);
      }

      let value = prices[formik.values.plan][formik.values.period],
        currency = "USD";

      api.pub.event("Purchase", { value, currency });

      window.gtag("event", "purchase", { value, currency });

      checkUser();
    } catch(err) {
      fail();
    }
  }

  async function validatePrice(plan, coupon) {
    let res = await api.checkout.validatePrice(plan, coupon);

    setPriceLoading(false);

    if(!res || !res.success)
      return toast.error((res && res.error) || "Unable to connect to the server.");

    setPrice(res.data);
  }

  // useEffect(() => {
  //   formik.setFieldValue("coupon", coupons[formik.values.plan][formik.values.period]);
  // }, [formik.values.plan, formik.values.period]);

  useEffect(() => {
    //setPriceLoading(true);

    //couponTimerRef.current = setTimeout(() => {
    //  validatePrice(formik.values.plan, formik.values.coupon);
    //}, 1500);

    //return () => clearTimeout(couponTimerRef.current);
  }, [formik.values.plan, formik.values.period, formik.values.coupon]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <h1>
        {formik.values.period == "monthly"
          ? "Pick your monthly plan"
          : "Get two months free"}
      </h1>

      <div className="d-flex align-items-center justify-content-center mb-8">
        <span>MONTHLY</span>
        <Switch className="always-blue-switch mx-3" checked={formik.values.period == "annual"} onChange={ev => formik.setFieldValue("period", ev.target.checked ? "annual" : "monthly")} />
        <span>ANNUALLY</span>
      </div>

      <div className="form-group">
        <label className="form-label">Plan</label>

        {formik.values.period == "monthly"
          ? (
            <>
              <div className={"plan " + (formik.values.plan == "solo_limited" ? "selected" : "")} onClick={() => formik.setFieldValue("plan", "solo_limited")}>
                <span>
                  <SVG src="/media/def-image/icons/check-white.svg" />
                </span>
                <div className="title">
                  <h2>Solo</h2>
                  One limited account, billed monthly
                </div>
                <div className="price">
                  $57/mo
                </div>
              </div>

              <div className={"plan " + (formik.values.plan == "business_97" ? "selected" : "")} onClick={() => formik.setFieldValue("plan", "business_97")}>
                <span>
                  <SVG src="/media/def-image/icons/check-white.svg" />
                </span>
                <div className="title">
                  <h2>Business</h2>
                  One unlimited account, billed monthly
                </div>
                <div className="price">
                  $97/mo
                </div>
              </div>

              <div className={"plan " + (formik.values.plan == "agency" ? "selected" : "")} onClick={() => formik.setFieldValue("plan", "agency")}>
                <span>
                  <SVG src="/media/def-image/icons/check-white.svg" />
                </span>
                <div className="title">
                  <h2>Agency</h2>
                  5 unlimited accounts, billed yearly
                </div>
                <div className="price">
                  $125/mo
                </div>
                <div className="discount discount-yellow">Most Popular</div>
              </div>
            </>
          )
          : (
            <>
              <div className={"plan " + (formik.values.plan == "solo_limited" ? "selected" : "")} onClick={() => formik.setFieldValue("plan", "solo_limited")}>
                <span>
                  <SVG src="/media/def-image/icons/check-white.svg" />
                </span>
                <div className="title">
                  <h2>Solo</h2>
                  One limited account, billed yearly
                </div>
                <div className="price">
                  $47/mo
                </div>
                <div className="discount">Two months free</div>
              </div>

              <div className={"plan " + (formik.values.plan == "business_97" ? "selected" : "")} onClick={() => formik.setFieldValue("plan", "business_97")}>
                <span>
                  <SVG src="/media/def-image/icons/check-white.svg" />
                </span>
                <div className="title">
                  <h2>Business</h2>
                  One unlimited account, billed yearly
                </div>
                <div className="price">
                  $80/mo
                </div>
                <div className="discount">Two months free</div>
              </div>

              <div className={"plan " + (formik.values.plan == "agency" ? "selected" : "")} onClick={() => formik.setFieldValue("plan", "agency")}>
                <span>
                  <SVG src="/media/def-image/icons/check-white.svg" />
                </span>
                <div className="title">
                  <h2>Agency</h2>
                  5 unlimited accounts, billed yearly
                </div>
                <div className="price">
                  $99/mo
                </div>
                <div className="discount discount-yellow">Most Popular</div>
              </div>
            </>
          )}

        {/*
        <div className="d-flex justify-content-between total-billed">
          <div className="d-flex align-items-center">
            <span className="d-inline-block mr-3">Total billed today:</span>
            {priceLoading
              ? <span className="loading d-inline-block spinner spinner-sm" />
              : (
                !!price && (
                  price.total && typeof price.today != "undefined"
                    ? (
                      <>
                        <s>${price.total.toLocaleString()}</s> <span className="ml-2">${price.today.toLocaleString()}</span>
                      </>
                    )
                    : (
                      <>${price.total.toLocaleString()}</>
                    )
                ))}
          </div>
          <div className="text-muted">* per account</div>
        </div>
        */}
      </div>

      <div className="form-group">
        <label className="form-label">Credit card</label>
        <div className="form-control pt-4">
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "14px",
                  fontFamily: "Poppins, Helvetica, 'sans-serif'",
                  color: "#181c32",
                  "::placeholder": {
                    color: "#181c32",
                  },
                },
                invalid: {
                  color: "#f1416c",
                },
              },
            }}
          />
        </div>
        {cardFeedback && <div className="field-error">{cardFeedback}</div>}
      </div>

      <div className="form-group">
        <label className="form-label">Coupon</label>
        <input type="text" className={getInputClassName(formik, "coupon " + (couponIsInvalid ? "is-invalid" : ""))} tabIndex="4" {...formik.getFieldProps("coupon")} />
        {formik.touched.coupon && formik.errors.coupon && <div className="field-error">{formik.errors.coupon}</div>}
      </div>

      <button type="submit" className="btn btn-primary mb-6">
        {loading
          ? (
            <>
              <span className="spinner spinner-sm spinner-white spinner-inline mr-2" /> Processing...
            </>
          )
          : "Sign up now!"}
      </button>

      <div className="form-group">
        <label className="checkbox checkbox-lg checkbox-outline">
          <input type="checkbox" checked={agreesToTerms} onChange={ev => setAgreesToTerms(ev.target.checked)} />
          <span />
          <div>
            Cancel anytime in your dashboard or by e-mail (hello@hiro.fm). I  agree to the <a href="https://hiro.fm/terms" target="_blank">Terms of Service</a> and <a href="https://hiro.fm/privacy" target="_blank">Privacy Policy</a>.
          </div>
        </label>
      </div>
    </form>
  );
}

function PaymentFormElements(props) {
  let elements = useElements(),
    stripe = useStripe();

  return <PaymentForm elements={elements} stripe={stripe} {...props} />;
}

function SignupStep2({ setLogin }) {
  const [stripeObject, setStripeObject] = useState(null);

  async function init() {
    setStripeObject(await loadStripe(process.env.REACT_APP_STRIPE_KEY));
  }

  useEffect(() => {
    if(!stripeObject)
      init();
  }, []);

  return (
    <Elements stripe={stripeObject}>
      <PaymentFormElements setLogin={setLogin} />
    </Elements>
  );
}

export default injectIntl(connect(null, actions.auth)(SignupStep2));
