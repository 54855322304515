import _ from "lodash";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import api from "../../../../redux/api";
import { formatLength } from "../../../helpers";
import CustomSelect from "../../../layout/components/CustomSelect";
import RangeField from "../../../layout/components/RangeField";
import utils from "../utils";

function Progress({ automationId, name, formik, product }) {
  let { set, get, error, props, touched } = utils.useFormikUtils(formik, name),
    [episodes, setEpisodes] = useState([]),
    [episodeOptions, setEpisodeOptions] = useState([]),
    [duration, setDuration] = useState(0);    

  let loadEpisodes = () => {
    if(!product)
      return;

    let options = [];

    for(let episode of product.episodes)
      options.push({
        id: episode._id,
        label: (
          <>
            <span className="text-muted d-inline-block pr-3">{episode.episodeNumber}</span>
            {episode.title}
          </>
        )
      });

    setEpisodes(product.episodes);
    setEpisodeOptions(options);
  };

  useEffect(() => {
    loadEpisodes();
  }, [product]);

  useEffect(() => {
    if(!episodes)
      return;

    let episodeId = get("episode"),
      episode = episodeId && episodes.find(e => e._id == episodeId);

    if(episode && episode.durationInSeconds != duration)
      setDuration(episode.durationInSeconds);

    let progress = get("progress");
    if(isNaN(parseInt(progress)) || (episode && progress > episode.durationInSeconds))
      set("progress", 0);
  }, [formik.values, episodes]);

  return (
    <>
      <div className="form-group">
        <label className="form-label">Episode</label>
        <CustomSelect options={episodeOptions} image={false} value={get("episode")} onChange={v => set("episode", v[0])} />
        {error("episode") && <div className="field-error">{error("episode")}</div>}
      </div>

      {!!duration && (
        <div className="form-group">
          <RangeField max={duration} name={name + ".progress"} formik={formik} className="mb-2" />
          <div className="d-flex text-muted">
            <div className="fill">
              {formatLength(_.get(formik.values, name + ".progress"))}
            </div>
            <div className="fill text-right">
              {formatLength(duration)}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      dispatch
    })
  )(Progress)
);