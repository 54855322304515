import _ from "lodash";
import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import ProductDropdown from "../../../layout/components/ProductDropdown";
import utils from "../utils";

function SendAccess({ automationId, submit, name, formik, saving, trigger, triggerIndex, actionIndex, remove, setPreviewBody, setPreviewSettings }) {
  let { set, get, error, props, touched } = utils.useFormikUtils(formik, name);

  useEffect(() => {
    set("changed", true);
  }, []);

  return (
    <>
      <div className="form-group">
        <label className="form-label">Show</label>
        <ProductDropdown multiple value={get("products")} onChange={v => set("products", v)} />
        {error("products") && <div className="field-error">{error("products")}</div>}
      </div>

      <div className="form-group">
        <label className="form-label">Send Notification Email</label>
        <input type="text" className="form-control fill" {...props("email")} onKeyDown={utils.disableIntro} />
        {error("email") && touched("email") && <div className="field-error">{error("email")}</div>}
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      dispatch
    })
  )(SendAccess)
);