import React from "react";

export default {
  on(event,callback) {
      document.addEventListener(event,e=>callback(e.detail));
  },
  dispatch(event,data=null) {
      document.dispatchEvent(new CustomEvent(event,{ detail:data }));
  },
  /**
   * Emits the signal to open the Youtube Import popup.
   */
  dispatchShowYoutubeImport() {
      this.dispatch("showYoutubeImport");
  },
  /**
   * Emits the signal to open the RSS Import popup.
   */
  dispatchShowRssImport() {
      this.dispatch("showRssImport");
  },
  /**
   * 
   */
  dispatchShareCodeImport() {
      this.dispatch("showShareCodeImport");
  }
};