import React from "react";
import { Link } from "react-router-dom";

function ProfileNavigation({ active }) {
    return (
        <>
            <h1>Creator settings</h1>
            <div className="navigation-tabs">
                <Link to="/user/integrations" className={"tab " + (active == "integrations" ? "active" : "")}>Integrations</Link>
                <Link to="/user/email-accounts" className={"tab no-plan-cant-click " + (active == "email-accounts" ? "active" : "")}>Email</Link>
                <Link to="/user/phone-numbers" className={"tab no-plan-cant-click " + (active == "phone" ? "active" : "")}>Phone</Link>
                <Link to="/user/whatsapp" className={"tab no-plan-cant-click " + (active == "whatsapp" ? "active" : "")}>WhatsApp <span className="beta">BETA</span></Link>
                <Link to="/user/domains" className={"tab no-plan-cant-click " + (active == "domains" ? "active" : "")}>Domains</Link>
                <Link to="/user/developer" className={"tab no-plan-cant-click " + (active == "developer" ? "active" : "")}>API Keys</Link>
                <Link to="/user/partnership" className={"tab " + (active == "partnership" ? "active" : "")}>Your Affiliate Links</Link>
                <Link to="/user/notifications" className={"tab " + (active == "notifications" ? "active" : "")}>Notifications</Link>
            </div>
        </>
    );
}

export default ProfileNavigation;