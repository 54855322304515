import React from "react";
import { ButtonToolbar } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../../redux/actions";
import BulkUploadListenersModal from "./BulkUploadListenersModal";

class BulkUploadListeners extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = { modalShow: false };
  }

  render() {
    let modalClose = () => this.setState({ modalShow: false });

    return <ButtonToolbar>
      <span onClick={() => this.setState({ modalShow: true })}>{this.props.children}</span>

      <BulkUploadListenersModal
        show={this.state.modalShow}
        onHide={modalClose}
        products={this.props.products}
        user={this.props.user}
        productId={this.props.productId}
        callbackBulkUploadListeners={this.props.callbackBulkUploadListeners}
        isOnlyOne={this.props.isOnlyOne}
        doNotSendAccess={this.props.doNotSendAccess}
      />
    </ButtonToolbar>
  }
}

export default injectIntl(connect(
  state => ({
    products: state.product.products,
    user: state.auth.user
  }),

  dispatch => ({
    ...actions.listener,
    dispatch,
  }),
)(BulkUploadListeners));
