import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export const ProductLogo = (props) => {
    let { product, products } = props,
        img = <img src="/media/def-image/product-img.png" />;

    if(typeof product == "string" && products)
        product = products.find(p => p._id == product);

    if(product && product.artwork) {
        if(product.artwork.thumbnail || product.artwork.url) {
            img = <img src={product.artwork.thumbnail || product.artwork.url} />;
        } else if(product.artwork.icon && product.artwork.color) {
            img = (
                <div className={"productLogoComponent " + product.artwork.color}>
                    <SVG src={toAbsoluteUrl("/media/def-image/create-product-icons/" + product.artwork.icon + ".svg")} />
                </div>
            );
        }
    }

    return (
        <div className={"cover-image " + (props.className || "")}>
            {img}
        </div>
    );
}
