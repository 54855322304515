import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../redux/actions";
import api from "../../../redux/api";
import SVG from "react-inlinesvg";
import { displayFeedback, getInputClassName, toastMessage } from "../../helpers";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import AwsS3Dropzone from "../generic/AwsS3Dropzone";

/**
 * 
 */
function AddSubaccountModal({ dispatch, user, setLogin }) {
    const initialValues = {
        firstName: "",
        lastName: "",
        email: ""
    };

    const validationSchema = Yup.object()
        .shape({
            firstName: Yup.string()
                .required(),
            lastName: Yup.string()
                .required(),
            email: Yup.string()
                .email("Wrong email format")
                .required()
        });

    let [show, setShow] = useState(false),
        [saving, setSaving] = useState(false),
        [avatarUploadError, setAvatarUploadError] = useState(null),
        location = useLocation(),
        history = useHistory(),
        formik = useFormik({
            initialValues,
            validationSchema,
            onSubmit: values => {
                onSubmit(values);
            },
            enableReinitialize: true
        });

    async function updateUser() {
        dispatch(actions.auth.fulfillUser((await api.auth.getUserByToken(true)).data));
    }

    async function onSubmit(values) {
        setSaving(true);

        let res = await api.user.createSubaccount(values);

        if(!res || !res.success) {
            setSaving(false);
            toastMessage.error((res && res.error) || "Unable to connect to the server.");
            return;
        }

        await api.user.setSubaccount(res.subAccountId);

        setLogin(res.token, res.user);

        await updateUser();

        toastMessage.success("Account created!");

        setSaving(false);
        setShow(false);
    }

    function onAvatarUploaded(uploadedFiles) {
        if((uploadedFiles || []).length < 1) {
            setAvatarUploadError("No uploaded file found.");
        } else if((uploadedFiles || []).length > 1) {
            setAvatarUploadError("Please upload a single file.");
        } else {
            formik.setFieldValue("pictureUrl", uploadedFiles[0].amazonS3Url);
        }
    }

    useEffect(() => {
        let handler = () => {
            setShow(true);
        };

        AddSubaccountModalEvents.on("showAddSubaccountModal", handler);
        return () => AddSubaccountModalEvents.off("showAddSubaccountModal", handler);
    }, []);

    useEffect(() => {
        if(show) {
            formik.resetForm({
                values: initialValues
            });
        } else if(location.hash == "#create-subaccount") {
            history.push("#");
        }
    }, [show]);

    useEffect(() => {
        if(location.hash == "#create-subaccount")
            setShow(true);
    }, [location]);

    if(!user)
        return <></>;

    return <>
        <Modal show={show} onHide={() => setShow(false)} size="lg" centered className="new-modals add-subaccount-modal">
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header>
                    <h1 className="mb-0">Add Account</h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label className="form-label">Photo <span className="text-muted">(this appears in the player app)</span></label>
                        <div className={"dropzone-artwork-container dropzone-sm dropzone-avatar " + (formik.values.pictureUrl ? "uploaded" : "")}>
                            <AwsS3Dropzone
                                allowMultipleUpload={false}
                                onError={setAvatarUploadError}
                                onUploadComplete={onAvatarUploaded}
                                forceSquareImageFormat={true}
                                fileType={"image/jpg, image/jpeg, image/png"}
                                uploadType="artwork"
                                errorMessage={avatarUploadError}
                                description="Select square png or jpg file (min. 500 x 500 px)" />

                            <div className="dropzone-uploaded-image">
                                <div className="image-input image-input-outline" style={{ backgroundImage: "url(" + (formik.values.pictureUrl || "") + ")" }}>
                                    {!!formik.values.pictureUrl && (
                                        <span onClick={() => formik.setFieldValue("pictureUrl", null)} className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove" data-toggle="tooltip" title="Remove avatar">
                                            <i className="ki ki-bold-close icon-xs text-muted" />
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-7">
                        <div className="col-md-6">
                            <div className="form-group mb-md-0">
                                <label className="form-label">First name</label>
                                <input type="text" {...formik.getFieldProps("firstName")} className={getInputClassName(formik, "firstName", "autofocus")} />
                            </div>
                            {displayFeedback(formik, "firstName")}
                        </div>

                        <div className="col-md-6">
                            <div className="form-group mb-0">
                                <label className="form-label">Last name</label>
                                <input type="text" {...formik.getFieldProps("lastName")} className={getInputClassName(formik, "lastName")} />
                            </div>
                            {displayFeedback(formik, "lastName")}
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="form-label">Email</label>
                        <input type="text" {...formik.getFieldProps("email")} className={getInputClassName(formik, "email")} />
                    </div>
                    {displayFeedback(formik, "email")}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary mr-2" onClick={() => setShow(false)}>Cancel</button>
                    <button type="submit" className={"btn btn-primary " + (saving ? "loading spinner" : "")} disabled={!formik.isValid || !formik.dirty}>
                        Save
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    </>;
}

export default injectIntl(connect(
    state => ({
        user: state.auth.user
    }),
    dispatch => ({
        dispatch,
        ...actions.auth
    })
)(AddSubaccountModal));

export const AddSubaccountModalEvents = {
    on(event, callback) {
        document.addEventListener(event, e => callback(e.detail));
    },
    off(event, callback) {
        document.removeEventListener(event, callback);
    },
    dispatch(event, data = null) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    /**
     * Emits the signal to open the popup.
     */
    dispatchShow(message) {
        this.dispatch("showAddSubaccountModal", { message });
    }
};