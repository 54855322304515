import React, { useEffect, useRef, useState } from "react";
import { Message, Send, Writting } from "../../../modals/onboarding/common";
import TargetMessage from "./TargetMessage";

export default function StoryMessage({ user, next, state }) {
    const [answer, setAnswer] = useState(null),
        [sent, setSent] = useState(false),
        [loading, setLoading] = useState(false),
        [invalid, setInvalid] = useState(false),
        isMac = window.navigator.platform.match(/mac/i),
        inputRef = useRef();

    const submit = () => {
        if(!answer)
            return setInvalid(true);

        setInvalid(false);
        setSent(true);
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
            next(TargetMessage, { story: answer });
        }, 2000);
    };

    const textareaOnChange = ev =>
        setAnswer(ev.target.value.trim());

    const textareaOnKeyDown = ev => {
        if(ev.which == 13 && (ev.ctrlKey || ev.metaKey)) {
            ev.preventDefault();
            submit();
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if(inputRef.current)
                inputRef.current.focus();
        }, 500);
    }, [inputRef]);

    return (
        <>
            <Message fullWidth={!sent}>
                In a few sentences, tell me the story about how you discovered it.

                {!sent && (
                    <div className={"input " + (invalid ? "invalid " : "") + (answer ? "" : " empty")}>
                        <textarea className="form-control" ref={inputRef} onChange={textareaOnChange} onKeyDown={textareaOnKeyDown} />
                        <Send onClick={submit} ctrlIntroLabel />
                        <small className="text-muted">{isMac ? "⌘" : "Ctrl"}+intro to send</small>
                    </div>
                )}
            </Message>

            {sent && <Message incoming>{answer}</Message>}

            {loading && <Writting delay={1000} />}
        </>
    );
}
