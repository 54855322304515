import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import actions from "../../../redux/actions";
import api from "../../../redux/api";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import CreditCard from "../../layout/components/CreditCard";
import PurchaseFailedModal from "./PurchaseFailedModal";
import PurchaseSuccessModal from "./PurchaseSuccessModal";
import { toastMessage } from "../../helpers";
import { Switch } from "@material-ui/core";
import { useCustomEvent } from "../../helpers/use-event";

const prices = {
  solo_limited: {
    monthly: 57,
    annual: 570
  },
  business_97: {
    monthly: 97,
    annual: 970
  },
  agency: {
    monthly: 125,
    annual: 1197
  }
};

const coupons = {
  solo_limited: {
    monthly: "97OFF",
    annual: ""
  },
  business_97: {
    monthly: "97OFF",
    annual: ""
  },
  agency: {
    monthly: "97OFF",
    annual: ""
  }
};

/**
 * Component `PaymentForm`.
 * @param {*} [props.show] - Modal's `show`.
 * @param {function} [props.onHide] - Modal's `onHide`.
 * @param {function} [props.onSuccess]
 * @param {function} [props.onFailure]
 * @param {Object} [props.user]
 * @param {Object} [props.elements]
 * @param {Object} [props.stripe]
 */
function PaymentForm({ show, onHide, onSuccess, onFailure, elements, stripe, user, setLogin, upgradeToAgency, title }) {
  let [loading, setLoading] = useState(false),
    [paymentRequest, setPaymentRequest] = useState(null),
    [paymentMethod, setPaymentMethod] = useState(null),
    [customer, setCustomer] = useState(null),
    [editPaymentMethod, setEditPaymentMethod] = useState(false),
    [plan, setPlan] = useState(),
    [period, setPeriod] = useState(),
    dispatch = useDispatch(),
    [cardFeedback, setCardFeedback] = useState(null),
    [coupon, setCoupon] = useState(""),
    [priceLoading, setPriceLoading] = useState(false),
    [price, setPrice] = useState(),
    couponTimerRef = useRef();

  async function loadData() {
    setCustomer((await api.checkout.getCustomer(true)).data);
  }

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if(!user)
      return;

    let p = "solo_limited";

    switch(user.owner?.newPlan.name || user.newPlan.name) {
      case "solo_57":
      case "solo_limited":
      case "solo":
        p = "business_97";
        break;
      case "business_97":
      case "new_business":
      case "business_pro":
      case "super":
        p = "agency";
        break;
      case "agency":
        p = "agency";
        break;
    }

    if(upgradeToAgency)
      p = "agency";

    setPlan(p);
    setPeriod(user.owner?.newPlan.period || "annual");
  }, [user]);

  let fail = reason => {
    setLoading(false);
    close();
    onFailure(typeof reason == "string" ? reason : null);
    return null;
  };

  let success = res => {
    setLoading(false);
    close();
    onSuccess(res);
  };

  let close = () => {
    setEditPaymentMethod(false);
    onHide();
  };

  let checkUser = async () => {
    let user = await api.auth.getUserByToken(true);
    if((user.data.owner && user.data.owner.newPlan.name == plan) || user.data.newPlan.name == plan) {
      dispatch(actions.auth.fulfillUser(user.data));
      success();
    } else {
      setTimeout(checkUser, 500);
    }
  };

  let processSubscription = async values => {
    let fn = async paymentMethodId => {
      if(paymentMethodId) {
        let attachedPaymentMethod = await api.checkout.attachPaymentMethod(paymentMethodId);
        if(!attachedPaymentMethod.data)
          return fail(attachedPaymentMethod.message);
      } else if(customer.paymentMethod) {
        paymentMethodId = customer.paymentMethod.id;
      } else {
        setEditPaymentMethod(true);
        setLoading(false);
        return setCardFeedback("Please fill the credit card details.");
      }

      let subscription = await api.checkout.createSubscription(paymentMethodId, plan, period, coupon);

      //update token in case the user has just been upgraded to agency
      if(subscription && subscription.token && subscription.user)
        setLogin(subscription.token, subscription.user);

      if(!subscription || !subscription.success) {
        setLoading(false);
        if(subscription && subscription.recoverable)
          toastMessage.error(subscription.error);
        else
          fail(subscription?.error);
        return;
      }

      let value = prices[plan][period],
        currency = "USD";

      //Facebook pixel
      api.pub.event("Purchase", { value, currency });

      window.gtag("event", "purchase", { value, currency });

      window.gtag("event", "upgrade", { value, currency });

      //Wait until the upgrade takes effect
      checkUser();
    };

    if(editPaymentMethod || !customer || !customer.paymentMethod) {
      if(paymentMethod) {
        //Google pay/Apple pay, use the button's payment method
        fn(paymentMethod.id);
      } else {
        //New card
        stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement)
        })
          .then(async paymentMethodData => {
            if(paymentMethodData.error) {
              setLoading(false);
              if(editPaymentMethod && paymentMethodData.error.type == "validation_error")
                return setCardFeedback("Please verify the credit card details.");
              return fail(paymentMethodData.error.message);
            }
            fn(paymentMethodData.paymentMethod.id);
          })
          .catch(fail);
      }
    } else {
      //Existing payment method
      fn(null);
    }
  };

  function submit() {
    if(loading)
      return;

    if((user.owner?.newPlan.name || user.newPlan.name) == plan)
      return onSuccess();

    setCardFeedback(null);
    setLoading(true);
    processSubscription();
  }

  async function validatePrice(plan, coupon) {
    let res = await api.checkout.validatePrice(plan, coupon);

    setPriceLoading(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    setPrice(res.data);
  }

  useEffect(() => {
    //setPriceLoading(true);

    //couponTimerRef.current = setTimeout(() => {
    //  validatePrice(plan, coupon);
    //}, 1500);

    //return () => clearTimeout(couponTimerRef.current);
  }, [coupon, plan, period]);

  useEffect(() => {
    // if(plan && period)
    //   setCoupon(coupons[plan][period]);
  }, [plan, period]);

  if(!user)
    return <></>;

  return (
    <>
      <Modal show={show} onHide={close} size="lg" centered className="new-modals subscription-update-modal">
        <Modal.Header>
          <h1 className="m-0">
            {title}
          </h1>
        </Modal.Header>
        <Modal.Body>
          <CreditCard edit={editPaymentMethod} customer={customer} paymentRequest={paymentRequest} cardFeedback={cardFeedback} onEdit={v => setEditPaymentMethod(v)} />

          <div className="d-flex align-items-center justify-content-center mb-8">
            <span>MONTHLY</span>
            <Switch className="always-blue-switch mx-3" checked={period == "annual"} onChange={ev => setPeriod(ev.target.checked ? "annual" : "monthly")} />
            <span>ANNUALLY</span>
          </div>

          <div className="d-md-flex justify-content-around">
            <div className="plans-list">
              {period == "monthly"
                ? (
                  <>
                    <div className={"plans-list-item rounded-lg mb-4 p-8 d-flex align-items-center " + (plan == "solo_limited" ? "selected" : "")} onClick={e => setPlan("solo_limited")}>
                      <span className="radio"></span>
                      <div className="flex-grow-1">
                        <strong className="fs-3">Solo</strong><br />
                        <span className="text-muted">1 account • Billed monthly</span>
                      </div>
                      <div className="price text-muted">
                        $ <strong className="fs-2 text-dark">57</strong> /mo
                      </div>
                    </div>

                    <div className={"plans-list-item rounded-lg mb-4 p-8 d-flex align-items-center " + (plan == "business_97" ? "selected" : "")} onClick={e => setPlan("business_97")}>
                      <span className="radio"></span>
                      <div className="flex-grow-1">
                        <strong className="fs-3">Business</strong><br />
                        <span className="text-muted">1 account • Billed monthly</span>
                      </div>
                      <div className="price text-muted">
                        $ <strong className="fs-2 text-dark">97</strong> /mo
                      </div>
                    </div>

                    <div className={"plans-list-item rounded-lg mb-4 p-8 d-flex align-items-center " + (plan == "agency" ? "selected" : "")} onClick={e => setPlan("agency")}>
                      <span className="radio"></span>
                      <div className="discount">Most Popular</div>
                      <div className="flex-grow-1">
                        <strong className="fs-3">Agency</strong><br />
                        <span className="text-muted">5 accounts • Billed annually</span>
                      </div>
                      <div className="price text-muted">
                        $ <strong className="fs-2 text-dark">125</strong> /mo
                      </div>
                    </div>
                  </>
                )
                : (
                  <>
                    <div className={"plans-list-item rounded-lg mb-4 p-8 d-flex align-items-center " + (plan == "solo_limited" ? "selected" : "")} onClick={e => setPlan("solo_limited")}>
                      <span className="radio"></span>
                      <div className="flex-grow-1">
                        <strong className="fs-3">Solo</strong><br />
                        <span className="text-muted">1 account • Billed yearly</span>
                      </div>
                      <div className="price text-muted">
                        $ <strong className="fs-2 text-dark">47</strong> /mo
                      </div>
                    </div>

                    <div className={"plans-list-item rounded-lg mb-4 p-8 d-flex align-items-center " + (plan == "business_97" ? "selected" : "")} onClick={e => setPlan("business_97")}>
                      <span className="radio"></span>
                      <div className="flex-grow-1">
                        <strong className="fs-3">Business</strong><br />
                        <span className="text-muted">1 account • Billed yearly</span>
                      </div>
                      <div className="price text-muted">
                        $ <strong className="fs-2 text-dark">80</strong> /mo
                      </div>
                    </div>

                    <div className={"plans-list-item rounded-lg mb-4 p-8 d-flex align-items-center " + (plan == "agency" ? "selected" : "")} onClick={e => setPlan("agency")}>
                      <span className="radio"></span>
                      <div className="discount">Most Popular</div>
                      <div className="flex-grow-1">
                        <strong className="fs-3">Agency</strong><br />
                        <span className="text-muted">5 accounts • Billed yearly</span>
                      </div>
                      <div className="price text-muted">
                        $ <strong className="fs-2 text-dark">99</strong> /mo
                      </div>
                    </div>
                  </>
                )}

              {/*
              <div className="d-flex justify-content-between total-billed">
                <div className="d-flex align-items-center">
                  <span className="d-inline-block mr-3">Total billed today:</span>
                  {priceLoading
                    ? <span className="loading d-inline-block spinner spinner-sm" />
                    : (
                      !!price && (
                        price.total && typeof price.today != "undefined"
                          ? (
                            <>
                              <s>${price.total.toLocaleString()}</s> <span className="ml-2">${price.today.toLocaleString()}</span>
                            </>
                          )
                          : (
                            <>${price.total.toLocaleString()}</>
                          )
                      ))}
                </div>
                <div className="text-muted">* per account per month</div>
              </div>
              */}
            </div>

            <div className="features-list">
              {plan == "solo_limited" && (
                <>
                  <h3>What is in the Solo plan</h3>

                  <div>1 account <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>3 shows <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>3 automation <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>3 keywords <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Unlimited episodes <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>500 contacts <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div className="text-muted">Pixel tracking <SVG src="/media/def-image/icons/x-circle.svg" /></div>
                  <div className="text-muted">Drip + date episode release <SVG src="/media/def-image/icons/x-circle.svg" /></div>
                  <div className="text-muted">Add resources to episodes <SVG src="/media/def-image/icons/x-circle.svg" /></div>
                  <div className="text-muted">Remove Hiro branding <SVG src="/media/def-image/icons/x-circle.svg" /></div>
                </>
              )}

              {plan == "business_97" && (
                <>
                  <h3>What is in the Business plan</h3>
                  <div>1 account <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Unlimited shows <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Unlimited automation <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Unlimited keywords <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Unlimited episodes <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Unlimited contacts <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Pixel tracking <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Drip + date episode release <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Add resources to episodes <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Remove Hiro branding <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                </>
              )}

              {plan == "agency" && (
                <>
                  <h3>What is in the Agency plan</h3>
                  <div>5 accounts <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Unlimited shows <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Unlimited automation <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Unlimited keywords <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Unlimited episodes <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Unlimited contacts <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Pixel tracking <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Drip + date episode release <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Add resources to episodes <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                  <div>Remove Hiro branding <SVG src="/media/def-image/icons/check-circle.svg" /></div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="align-items-center">
          <div className="flex-1">
            <div className="coupon-field">
              <label>Coupon</label>
              <input type="text" className="form-control" value={coupon} onChange={ev => setCoupon(ev.target.value)} />
            </div>
          </div>
          <button type="button" className="btn btn-secondary mr-2" onClick={close}>Cancel</button>
          <button type="button" className={"btn btn-primary " + (loading ? "loading spinner" : "")} onClick={submit}>
            {(user.owner?.newPlan.name || user.newPlan.name) == plan
              ? "Keep Current Plan"
              : "Start Plan"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

/**
 * 
 */
function PaymentFormElements(props) {
  let elements = useElements(),
    stripe = useStripe();

  return <PaymentForm
    elements={elements}
    stripe={stripe}
    {...props} />;
}

const Upgrade = (props) => {
  let [show, setShow] = useState(false),
    [successModal, setSuccessModal] = useState(false),
    [failedModal, setFailedModal] = useState(false),
    [failureReason, setFailureReason] = useState(null),
    [stripeObject, setStripeObject] = useState(null),
    [upgradeToAgency, setUpgradeToAgency] = useState(false),
    [title, setTitle] = useState(),
    { trigger: triggerUpgradedToAgency } = useCustomEvent("upgradedToAgency");

  async function init() {
    if(!stripeObject)
      setStripeObject(await loadStripe(process.env.REACT_APP_STRIPE_KEY));
  }

  function onSuccess(result) {
    setShow(false);
    setSuccessModal(true);
  }

  function onFailure(reason) {
    setShow(false);
    setFailureReason(reason);
    setFailedModal(true);
  }

  function onRetry() {
    setFailedModal(false);
    setShow(true);
  }

  useEffect(() => {
    let off = UpgradeEvents.on("showUpgrade", params => {
      setShow(true);

      let titleValue = "Manage My Account";
      if(params) {
        if(params.upgradeToAgency)
          titleValue = "Upgrade to Agency to add subaccounts";
        if(params.upgradeToAccess)
          titleValue = "Upgrade to access this feature";
        if(params.title)
          titleValue = params.title;
      }

      setUpgradeToAgency(params && params.upgradeToAgency);
      setTitle(titleValue);
    });

    init();

    return () => off();
  }, []);

  function successModalClose() {
    setSuccessModal(false);
    if(props.user.owner && props.user.owner.totalSubAccountsLimit == 5 && props.user.owner.subAccountsIds.length == 1)
      triggerUpgradedToAgency();
  }

  if(!show && !successModal && !failedModal)
    return <></>;

  return (
    <>
      <Elements stripe={stripeObject}>
        <PaymentFormElements
          show={show}
          onHide={() => setShow(false)}
          user={props.user}
          setLogin={props.setLogin}
          onSuccess={onSuccess}
          onFailure={onFailure}
          upgradeToAgency={upgradeToAgency}
          title={title} />
      </Elements>

      <PurchaseSuccessModal show={successModal} close={successModalClose} hideOptions />
      <PurchaseFailedModal show={failedModal} close={() => setFailedModal(false)} reason={failureReason} retry={onRetry} />
    </>
  );
};

export default injectIntl(connect(
  state => ({
    user: state.auth.user
  }),
  dispatch => ({
    dispatch,
    ...actions.product,
    ...actions.auth
  })
)(Upgrade));

export const UpgradeEvents = {
  on(event, callback) {
    let h = e => callback(e.detail);
    document.addEventListener(event, h);
    return () => document.removeEventListener(event, h);
  },
  dispatch(event, data = null) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  /**
   * Emits the signal to open the popup.
   */
  dispatchShow(params) {
    this.dispatch("showUpgrade", params);
  }
};