import _ from "lodash";
import React from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { NavLink } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../../redux/actions";
import api from "../../../../../redux/api";
import { toastMessage, endings } from "../../../../helpers";
import { ProductLogo } from "../../../../../_metronic/layout/components/productLogo";
import BulkUploadDropzone from "./BulkUploadDropzone";
import UnsavedFormGuard from "../../../../layout/components/UnsavedFormGuard";

let newEpisodeTemplate = {
  title: "",
  summary: "",
  audioFileName: null,
  originalFileUrl: null,
  audioUrl: "",
  durationInSeconds: null,
  audioFileSizeInBytes: null,
  delayAfterPreviousInDays: 1,
  releaseDate: new Date(),
  draft: 1,
  dripReleaseNumber: 1,
  dripReleasePeriod: "days",
  expireAfterNumber: 1,
  expireAfterPeriod: "days",
};

class BulkUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productId: _.get(this.props, 'match.params.id', this.props.productId),
      uploading: null,
      filesToUpload: [],
      showUnsavedDialog: false,
      loading: false
    };
  }

  setUploading = v => {
    this.setState({ ...this.state, uploading: v });
  };

  setUploadedFiles = list => {
    this.setState({ ...this.state, filesToUpload: list });
  };

  submit = async (loading = true) => {
    this.setState({ ...this.state, loading });

    if(!this.state.filesToUpload.length) return;

    let episodesData = [];
    this.state.filesToUpload.forEach((file, index) => {
      if(file.file && file.amazonS3Url) {
        let episode = _.clone(newEpisodeTemplate);

        episode.title = file.file.name;
        episode.audioFileName = file.file.name;
        episode.audioFileSizeInBytes = file.file.size;
        episode.originalFileUrl = file.amazonS3Url;
        episode.durationInSeconds = file.durationInSeconds;

        episodesData.push(episode);
      }
    });

    let res = await api.episode.createEpisodesBulk({
      id: this.state.productId,
      data: { episodes: episodesData },
    });

    this.setState({ ...this.state, loading: false });

    if(res.success) {
      if(this.props.uploadedCallback) {
        this.props.uploadedCallback();
      } else {
        this.props.history.push("/products/" + this.state.productId + "/episodes", { ignorePrompt: true });
        toastMessage.success("Episodes created successfully.");
      }
    }
  };

  componentDidMount() {
    if(!this.props.isModal) {
      //this.props.dispatch(this.props.setProduct({}));

      api.product.getProduct(this.state.productId).then(res => {
        this.props.dispatch(this.props.setProduct(res));
      });
    }
  }

  render() {
    let unsavedOnSave = async () =>
      await this.submit("modal");

    return (
      <>
        {this.props.isModal
          ? (
            <>
              <h4 className="text-center">Upload your episodes.</h4>
              <p className="mb-15">
                Upload MP3 or video files. Videos will be converted to MP3’s.
              </p>
              <BulkUploadDropzone user={this.props.user} product={this.props.productData} />
            </>
          )
          : (
            <>
              <h1>Bulk Upload Episodes</h1>

              <div className="container-inner">
                <div className="card">
                  <div className="card-body">
                    <p className="mb-15 text-center">
                      Upload MP3 or video files. Videos will be converted to MP3’s.
                    </p>
                    <BulkUploadDropzone user={this.props.user} uploadStarted={() => this.setUploading(true)} uploadComplete={() => this.setUploading(false)} setFileList={this.setUploadedFiles} />
                    <div className="dropzone-upload-file text-center" style={{ position: "relative" }}>
                      {this.state.uploading !== null && <button type="button" className={"btn btn-primary " + (this.state.loading === true ? "loading spinner" : "")} disabled={this.state.uploading} onClick={() => this.submit()}>{!this.state.uploading ? "Create Episodes" : "Uploading, please wait..."}</button>}
                    </div>
                  </div>
                </div>
              </div>

              <UnsavedFormGuard dirty={this.state.filesToUpload.length || this.state.uploading} valid={this.state.filesToUpload.length} onSaveAsync={unsavedOnSave} loading={this.state.loading == "modal"} />
            </>
          )}
      </>
    );
  }
}

export default injectIntl(connect(
  state => ({
    productData: (state.product || {}).product || {},
    user: (state.auth || {}).user || {},
  }),

  dispatch => ({
    ...actions.product,
    dispatch,
  }),
)(BulkUpload));
