import React, { useEffect, useState } from "react";
import UnsavedFormGuard from "../../layout/components/UnsavedFormGuard";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "../../../redux/api";
import { toastMessage, updateCurrentUser } from "../../helpers";
import SVG from "react-inlinesvg";
import ProductDropdown from "../../layout/components/ProductDropdown";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import _ from "lodash";
import TagDropdown from "../../layout/components/TagDropdown";
import { EditTagModal } from "./Tags";
import { useDispatch } from "react-redux";

const initialValues = {
    _id: null,
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    createdAt: new Date,
    updatedAt: new Date,
    products: [],
    tags: [],
    isSmsPhoneNumber: true,
    isWhatsappPhoneNumber: false
};

const validationSchema = Yup.object().shape({
    name: Yup.string().nullable(),
    firstName: Yup.string().nullable(),
    lastName: Yup.string().nullable(),
    email: Yup.string().nullable().email("The email is not valid."),
    phone: Yup.string().nullable(),
    products: Yup.array().of(Yup.string()).nullable(),
    tags: Yup.array().of(Yup.string()).nullable()
});

export default function ContactEditForm({ isModalBody, onCancel, onSuccess, showMeta = false, id = null, createLabel = null, updateLabel = null, productIds = null, type = "contacts", updateDuplicated = false, user }) {
    let [loading, setLoading] = useState(false),
        [showCreateTagModal, setShowCreateTagModal] = useState(false),
        dispatch = useDispatch();

    let loadItem = async () => {
        setLoading(true);

        let res = await api.contacts.get(id);

        setLoading(false);

        if(!res || !res.success)
            return toastMessage.error((res && res.error) || "Unable to connect to the server.");

        formik.resetForm({ values: res.data });
    };

    let reset = () =>
        formik.resetForm({ values: initialValues });

    let close = (cancel = false) => {
        reset();
        if(cancel)
            onCancel();
        else
            onSuccess();
    };

    let formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {console.log()
            setLoading("save");

            let res = values._id
                ? await api.contacts.update(values._id, values)
                : await api.contacts.create(values, { type, updateDuplicated });

            await updateCurrentUser(dispatch);

            setLoading(false);

            if(!res || !res.success)
                return toastMessage.error((res && res.error) || "Unable to connect to the server.");

            close();
        },
    });

    useEffect(() => {
        if(id)
            loadItem();
        else
            reset();
    }, [id]);

    useEffect(() => {
        let arr = [];
        if(formik.values.products)
            arr = [...arr, ...formik.values.products];
        if(productIds)
            arr = [...arr, ...productIds];
        arr = _.uniq(arr);
        formik.setFieldValue("products", arr);
    }, [productIds]);

    return (
        <>
            <EditTagModal autoReload onHide={() => setShowCreateTagModal(false)} show={showCreateTagModal} />

            <form onSubmit={formik.handleSubmit} className={loading === true ? "loading spinner" : ""}>
                <div className={isModalBody ? "modal-body" : "card-body"}>
                    <div className="form-group">
                        <label className="form-label">Full Name <em>*</em></label>
                        <input type="text" autoFocus className="form-control" {...formik.getFieldProps("name")} />
                        {formik.touched.name && formik.errors.name && <div className="field-error">{formik.errors.name}</div>}
                    </div>

                    <div className="row mb-5">
                        <div className="col-6">
                            <div className="form-group">
                                <label className="form-label">First Name</label>
                                <input type="text" className="form-control" {...formik.getFieldProps("firstName")} />
                                {formik.touched.firstName && formik.errors.firstName && <div className="field-error">{formik.errors.firstName}</div>}
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-group">
                                <label className="form-label">Last Name</label>
                                <input type="text" className="form-control" {...formik.getFieldProps("lastName")} />
                                {formik.touched.lastName && formik.errors.lastName && <div className="field-error">{formik.errors.lastName}</div>}
                            </div>
                        </div>
                    </div>

                    {showMeta && formik.values._id && (
                        <div className="mb-5">
                            <div className="meta">
                                <SVG src="/media/def-image/icons/user-add.svg" />
                                {(() => {
                                    if(formik.values.isManual)
                                        return "Added manually";
                                    if(formik.values.isSmsOptIn)
                                        return "SMS Opt-in";
                                    if(formik.values.isPublic)
                                        return "Sales page";
                                    if(formik.values.isFromApp)
                                        return "Hiro App";
                                    return "Show listener";
                                })()}
                            </div>
                            <div className="meta">
                                <SVG src="/media/def-image/icons/calendar-2.svg" />
                                {formik.values.createdAt == formik.values.updatedAt
                                    ? "Created on " + moment(formik.values.createdAt).locale("en").format("MMMM, D YYYY")
                                    : "Updated on " + moment(formik.values.updatedAt).locale("en").format("MMMM, D YYYY")}
                            </div>
                        </div>
                    )}

                    <div className="form-group">
                        <label className="form-label">Email</label>
                        <input type="text" className="form-control" {...formik.getFieldProps("email")} />
                        {formik.touched.email && formik.errors.email && <div className="field-error">{formik.errors.email}</div>}
                    </div>

                    <div className="form-group">
                        <label className="form-label">Phone</label>
                        <PhoneInput className="form-control" reset defaultCountry="US" value={formik.values.phone} onChange={value => formik.setFieldValue("phone", value || "")} />
                        {formik.touched.phone && formik.errors.phone && <div className="field-error">{formik.errors.phone}</div>}
                    </div>

                    <div className="form-group d-flex flex-column flex-md-row gap justify-content-between">
                        <label className="checkbox checkbox-lg checkbox-single">
                            <input type="checkbox" checked={formik.values.isSmsPhoneNumber} onChange={ev => formik.setFieldValue("isSmsPhoneNumber", ev.target.checked)} />
                            <span />
                            SMS Number
                        </label>

                        <label className="checkbox checkbox-lg checkbox-single">
                            <input type="checkbox" checked={formik.values.isWhatsappPhoneNumber} onChange={ev => formik.setFieldValue("isWhatsappPhoneNumber", ev.target.checked)} />
                            <span />
                            WhatsApp Number
                        </label>
                    </div>

                    <div className="form-group">
                        <label className="form-label">Shows</label>
                        <ProductDropdown listenersCount={false} multiple value={formik.values.products} onChange={value => formik.setFieldValue("products", value)} />
                        {formik.touched.products && formik.errors.products && <div className="field-error">{formik.errors.products}</div>}
                    </div>

                    <div className="form-group">
                        <label className="form-label">Tags</label>
                        <TagDropdown onCreateTagRequest={() => setShowCreateTagModal(true)} multiple contactsCount={false} value={formik.values.tags} onChange={value => formik.setFieldValue("tags", value)} />
                        {formik.touched.tags && formik.errors.tags && <div className="field-error">{formik.errors.tags}</div>}
                    </div>
                </div>

                <div className={isModalBody ? "modal-footer" : "card-footer text-right"}>
                    <button type="button" className="btn btn-secondary mr-2" onClick={() => close(true)}>Cancel</button>
                    <button type="submit" className={"btn btn-primary " + (loading == "save" ? "loading spinner " : "")}>{formik.values._id ? (updateLabel || "Update") : (createLabel || "Create")}</button>
                </div>
            </form>

            <UnsavedFormGuard formik={formik} onSaveAsync={() => formik.handleSubmit()} onCancelAsync={() => close(true)} />
        </>
    );
}