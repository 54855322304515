import React, { useEffect, useState } from "react";
import api from "../../../redux/api";
import { Pagination, Table } from "react-bootstrap";

export default function AIPodcastCreator() {
	let [ page, setPage ] = useState(1),
		[ pages, setPages ] = useState(0),
		[ count, setCount ] = useState(0),
		[ list, setList ] = useState([]);

	let init = async () => {
	},
	load = async (pg=null) => {
		if(pg) {
			setPage(pg);
		} else {
			pg = page;
		}
		let res = null;
		try {
			res = await api.admin.getAIUsers(pg);
			if(res) {
				setList(res.data);
				setPages(res.pages);
				setCount(res.count);
			}
		} catch(x) {}
	};

	useEffect(() => {
		init();
		load();
	}, []);

	return <div>
		<h1>AI usage</h1>

		<div className="card p-5">
			<Table striped bordered={false} hover responsive size="sm">
				<thead>
					<tr>
						<th>ID</th>
						<th style={{ minWidth: 250 }}>Email</th>
						<th>Projects</th>
						<th>AI Credits used</th>
					</tr>
				</thead>
				<tbody>
					{list.map((item, i) => 
						<tr key={i}>
							<td>{item._id}</td>
							<td>{item.email}</td>
							<td>{item.aiProjectsCount}</td>
							<td>{parseInt(item.aiCreditsUsed).toLocaleString()}</td>
						</tr>
					)}
				</tbody>
			</Table>

			<Pagination size="sm" className="justify-content-center mt-5">
				{(() => {
					let items = [];
					for(let i=1; i<=pages; i++)
						items.push(<Pagination.Item key={i} active={i == page} onClick={ev => load(i)}>{i}</Pagination.Item>);
					return items;
				})()}
			</Pagination>
		</div>
	</div>;
}
