import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import ModalEditCustomer from "./generic/generic/ModalEditCustomer";
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';

class SingleListenerCreator extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = { modalShow: false, newListeners: [], editListenerEmail: null, listenerDataIsLoading: false };
  }

  render() {
    const modalJSX = this.props.product.id && (
      <ModalEditCustomer
        show={this.props.isModalVisible}
        onHide={this.props.hideModal}
        productId={this.props.product.id}
        productName={this.props.product.name}
        listenerEmail={null}
        user={this.props.user}
      />
    );

    return (
      <>
        {modalJSX}
      </>
    );
  }
}

export default injectIntl(connect(
  state => ({
    product: state.product.product || {},
    episodes: (state.product.product || {}).episodes || [],
    listeners: (state.listener || {}).listeners || [],
  }),

  dispatch => ({
    ...actions.product,
    ...actions.listener,
    dispatch,
  }),
)(SingleListenerCreator));
