import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default {
  success: (message) => {
    toast.success(message,
      {
        position: "top-center",
        autoClose: 3000,
        bodyClassName: "px-3 wordbreak",
        pauseOnFocusLoss: false,
        transition: Slide,
        draggable: false
      }
    );
  },
  error: (message) => {
    toast.error(message,
      {
        position: "top-center",
        autoClose: 3000,
        bodyClassName: "px-3 wordbreak",
        pauseOnFocusLoss: false,
        transition: Slide,
        draggable: false
      }
    );
  },
  warn: (message) => {
    toast.error(message,
      {
        position: "top-center",
        autoClose: 3000,
        bodyClassName: "px-3 wordbreak",
        pauseOnFocusLoss: false,
        transition: Slide,
        draggable: false
      }
    );
  },
};
