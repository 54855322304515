import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect, shallowEqual, useSelector } from "react-redux";
import actions from "../../../../../../redux/actions";
import Editor from "../../../../../layout/components/Editor";
import EmailAccountModal from "../../../../account/UserProfile/EmailAccountModal";
import { getInputClassName } from "../../../../../helpers";

function StepEmail({ formik, product, showButtons = false, loading = false }) {
  const user = useSelector(state => state.auth.user, shallowEqual),
    [showAddEmailModal, setShowAddEmailModal] = useState(false);

  function addEmailOnClick(ev) {
    ev.preventDefault();
    setShowAddEmailModal(true);
  }

  useEffect(() => {
    if(!formik.values.sendEmailFromId)
      formik.resetForm({
        emailContent: formik.values.emailContent,
        sendEmailFromId: user.emailAddresses.find(item => item.default)?._id,
        emailSubject: formik.values.emailSubject
      });
  }, []);

  return (
    <>
      <div className="form-group">
        <label className="form-label">Sending Email Address</label>
        {!user.emailAddresses.length
          ? (
            <div className="py-3">
              You don’t have an email service connected.
              <a href="#" onClick={addEmailOnClick} className="fw-bold ml-4">Connect an email service</a>
            </div>
          )
          : (
            <select className="custom-select" {...formik.getFieldProps("sendEmailFromId")}>
              {user.emailAddresses.map(item => <option value={item._id} key={item._id}>{item.email}</option>)}
            </select>
          )}
      </div>

      <div className="form-group">
        <label className="form-label">Subject</label>
        <input type="text" className={getInputClassName(formik, "emailSubject")} placeholder={(formik.values.name || product?.name || "(show name)") + " access details inside!"} {...formik.getFieldProps("emailSubject")} />
        {formik.touched.emailSubject && formik.errors.emailSubject && <div className="field-error">{formik.errors.emailSubject}</div>}
      </div>

      <div className="form-group">
        <label className="form-label">Access Email</label>
        <Editor className="form-group" formik={formik} name="emailContent" placeholder="To access your private feed, open this email on your phone 📲, click the button below, and then choose the podcast player you'd like to listen in." emoji media personalization />
      </div>

      <EmailAccountModal show={showAddEmailModal} onDismiss={() => setShowAddEmailModal(false)} onCreate={email => formik.setFieldValue("sendEmailFromId", email._id)} />
    </>
  );
}

export default injectIntl(connect(null, [actions.auth, actions.product])(StepEmail));