import React, { useEffect, useState } from "react";
import api from "../../../../redux/api";
import { Delayed, Message, Writting } from "../../modals/onboarding/common";

export default function FinalMessage({ reset, update, state, onCloseChat }) {
    const [done, setDone] = useState(false),
        [showReset, setShowReset] = useState(false),
        [error, setError] = useState(null);

    const resetOnClick = ev => {
        setShowReset(false);
        reset();
    };

    const save = async () => {
        let res = state.projectId
            ? await api.ai.updateProject(state.projectId, state)
            : await api.ai.createProject(state);
        await update();

        if(res && res.success)
            setDone(true);
        else
            setError((res && res.error) || "I’m sorry, I wasn’t able to talk to our servers, please try again later.");

        setShowReset(true);
    };

    useEffect(() => {
        save();
    }, []);

    return (
        <>
            <Message>
                Thank you! One sec, I’m saving your answers...
                {!error && !done && <span className="spinner spinner-sm d-inline-block align-middle mx-5" />}
            </Message>

            {error && (
                <>
                    <Writting hideAfter={2000} />

                    <Message delay={2000}>
                        {error}
                    </Message>
                </>
            )}

            {done && (
                <>
                    <Writting hideAfter={2000} />

                    <Message delay={2000}>
                        All set! Your content will be ready in a little bit.
                    </Message>
                </>
            )}

            {showReset && (
                <Delayed delay={5000} className="reset-btn-container">
                    <button type="button" className="btn btn-primary mr-2" onClick={onCloseChat}>Close chat</button>
                    <button type="button" className="btn btn-secondary ml-2" onClick={resetOnClick}>Start a new project</button>
                </Delayed>
            )}
        </>
    );
}
