import React, { useEffect, useRef, useState } from "react";
import api from "../../../redux/api";
import { Link } from "react-router-dom";
import { Pagination, Table } from "react-bootstrap";
import moment from "moment";

export default function EventsStats() {
	let fromFilterRef = useRef(),
		toFilterRef = useRef(),
		[ list, setList ] = useState([]);

	let load = async () => {
		try {
			let res = await api.admin.getEventsLog({
				from: fromFilterRef.current.value,
				to: toFilterRef.current.value
			});
			if(res)
				setList(res.data);
		} catch(x) {}
	};

	useEffect(() => {
		load();
	}, []);

	return <div>
		<h1>Events</h1>

		<div className="card p-5">
			<form onSubmit={ev => {
				ev.preventDefault();
				load();
			}}>
				<div className="row g-2 justify-content-end mb-5">
					<div className="col-5 col-md-2 mb-3 mb-md-0">
						<input type="text" ref={fromFilterRef} className="form-control form-control-sm" placeholder="From (year-month-day)"/>
					</div>
					<div className="col-5 col-md-2 mb-3 mb-md-0">
						<input type="text" ref={toFilterRef} className="form-control form-control-sm" placeholder="To (year-month-day)"/>
					</div>
					<div className="col-1 col-md-1">
						<button type="submit" className="btn btn-sm btn-secondary d-block">Go</button>
					</div>
					{/*<div className="col-1 col-md-1">
						<button type="submit" className="btn btn-sm btn-secondary d-block">Download</button>
					</div>*/}
				</div>
			</form>

			<Table striped bordered={false} hover responsive size="sm">
				<thead>
					<tr>
						<th>Event</th>
						<th>Last Date</th>
						<th>Total</th>
					</tr>
				</thead>
				<tbody>
					{list.map((item, i) => 
						<tr key={i}>
							<td>{item.event}</td>
							<td>{moment(item.lastDate).locale("en").format("MMM D, YYYY H:m")}</td>
							<td>{item.count}</td>
						</tr>
					)}
				</tbody>
			</Table>
		</div>
	</div>;
}
