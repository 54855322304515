import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import api from "../../../redux/api";
import AwsS3Dropzone from "../generic/AwsS3Dropzone";

export default function EventsSetup() {
	let [ loading, setLoading ] = useState(false),
		[ showSuccess, setShowSuccess ] = useState(false),
		[ error, setError ] = useState(null),
		[ imageUploadError, setImageUploadError ] = useState(null),
		[ tags, setTags ] = useState([]),
		formik = useFormik({
			initialValues: {
				actionType: "activecampaign"
			},
			onSubmit: async () => {
				setLoading(true);
				setShowSuccess(false);
				setError(null);
				
				let res = await api.admin.setEventsForm(formik.values);
				
				setLoading(false);

				if(res.success) {
					setShowSuccess(true);
				} else {
					setError(res.error);
				}
			}
		});

	let loadData = async () => {
		let res = await api.admin.getEventsForm();
		if(!res) return;
		if(res.data) formik.setValues(res.data);
		setTags(res.activeCampaignTags);
	};

	useEffect(() => {
		loadData();
	}, []);

	let onImageUploadComplete = files => {
		if(files && files.length == 1)
			formik.setFieldValue("image", files[0].amazonS3Url);
	},
	onImageUploadError = () => {
		setImageUploadError("Invalid file");
	};

	let onTagChecked = ev => {
		let arr = formik.values.action_tags;
		if(ev.target.checked) {
			if(!arr) {
				arr = [ ev.target.value ];
			} else {
				arr.push(ev.target.value);				
			}
		} else {
			if(arr) {
				arr = arr.filter(i => i != ev.target.value);
			}
		}
		formik.setFieldValue("action_tags", arr);
	};

	return <div>
		<h1>Set up Events form</h1>

		{ showSuccess && <div className="alert alert-success">Events form updated.</div> }
		{ error && <div className="alert alert-warning">{error}</div> }

		<div className="card p-5">
			<form onSubmit={formik.handleSubmit}>
				<label>Enable</label>
				<div className="mb-3">
					<div className="form-check form-switch">
						<input className="form-check-input" type="checkbox" value="1" checked={formik.values.active} onChange={ev => formik.setFieldValue("active", ev.target.checked)}/>
						<label className="form-check-label">&nbsp;</label>
					</div>
				</div>

				<label>From - To<br/><small>Optional. Leave fields blank to keep it active as long as Enabled is on.</small></label>
				<div className="row">
					<div className="col-6">
						<input type="text" placeholder="year-month-day" className="form-control form-control-sm mb-3" {...formik.getFieldProps("activeFrom")}/>
					</div>
					<div className="col-6">
						<input type="text" placeholder="year-month-day" className="form-control form-control-sm mb-3" {...formik.getFieldProps("activeTo")}/>
					</div>
				</div>

				<label>Title</label>
				<input type="text" className="form-control form-control-sm mb-3" {...formik.getFieldProps("title")}/>

				<label>Subtitle<br/><small>Optional.</small></label>
				<input type="text" className="form-control form-control-sm mb-3" {...formik.getFieldProps("subtitle")}/>

				<label>Text</label>
				<textarea className="form-control form-control-sm h-150 mb-3" {...formik.getFieldProps("description")}/>

				<label>Thank you message</label>
				<textarea className="form-control form-control-sm h-150 mb-3" {...formik.getFieldProps("thankYou")}/>

				<label>Image</label>
				<div className={"dropzone-artwork-container dropzone-field mb-3 " + (formik.values.image ? "uploaded" : "")}>	
					<AwsS3Dropzone
						allowMultipleUpload={false}
						onError={onImageUploadError}
						onUploadComplete={onImageUploadComplete}
						uploadType="images"
						fileType="image/png"
						errorMessage={imageUploadError}
						description="Select PNG file" />
					<div className="dropzone-uploaded-image">
						<div className="image-input image-input-outline" style={{ backgroundImage: formik.values.image ? "url(" + formik.values.image + ")" : "" }}>
							<span onClick={() => formik.setFieldValue("image", null)} className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove"><i className="ki ki-bold-close icon-xs text-muted" /></span>
						</div>
					</div>
                </div>

				<label>Button label</label>
				<input type="text" className="form-control form-control-sm mb-3" {...formik.getFieldProps("button")}/>

				<label>On click</label>
				<select className="custom-select form-control-sm mb-3" {...formik.getFieldProps("actionType")}>
					<option value="activecampaign">Add current user to Active Campaign</option>
					<option value="url">Go to URL</option>
				</select>

				{formik.values.actionType=="url" && <>
					<label>URL</label>
					<input type="text" className="form-control form-control-sm mb-3" {...formik.getFieldProps("action_url")}/>
				</>}

				{formik.values.actionType=="activecampaign" && <>
					<label>List ID</label>
					<input type="text" className="form-control form-control-sm mb-3" {...formik.getFieldProps("action_list")}/>

					<label>Tags<br/><small>Optional.</small></label>
					{tags&&tags.length
						? tags.map((tag, i) => (
							<div className="form-check mb-2" key={i}>
								<label for={"tag-"+tag.id} className="form-check-label">{tag.tag}</label>
								<input className="form-check-input" type="checkbox" for={"tag-"+tag.id} value={tag.id} checked={formik.values.action_tags&&formik.values.action_tags.includes(tag.id)} onChange={onTagChecked}/>
							</div>
						))
						: <p className="mb-3 text-gray">No tags found.</p> }

					<label>Open external website after subscription (URL)<br/><small>Optional. The user will always see the "Thank you" message.</small></label>
					<input type="text" className="form-control form-control-sm mb-3" {...formik.getFieldProps("action_after_url")}/>
				</>}

				<button type="submit" className="btn btn-sm btn-primary mr-2" disabled={loading}>Update</button>
				<button type="button" className="btn btn-sm btn-secondary" onClick={ev => loadData()}>Reset</button>
			</form>
		</div>
	</div>;
}
