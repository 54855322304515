export default {
    translation: {
        "All": "",
        "Audio Feeds": "Audiofeeds",
        "Category": "Categorie",
        "There are no shows in this category.": "Er zijn geen shows in deze categorie.",
        "The creator doesn’t have any public audio feeds yet.": "De maker heeft nog geen openbare audiofeeds.",
        "About me": "Over mij",
        "My audio feeds": "Mijn audiofeeds",
        "I agree to Hiro’s <a href=\"https://hiro.fm/terms\" target=\"_blank\" className=\"themed\">terms of service</a> & <a href=\"https://hiro.fm/privacy\" target=\"_blank\" className=\"themed\">privacy policy</a>.": "Ik ga akkoord met de algemene voorwaarden & privacybeleid van Hiro.",
        "Powered by Hiro.fm": "Ondersteund door Hiro.fm",
        "Start your own private audio product": "Start je eigen privé audio product",
        "episodes": "afleveringen",
        "Free": "Gratis",
        "/ week": "/ week",
        "/ month": "/ maand",
        "/ year": "/ jaar",
        "one-time payment": "eenmalige betaling",
        "per week": "per week",
        "per month": "per maand",
        "per year": "per jaar",
        "Products": "Producten",
        "Episodes": "Afleveringen",
        "Listeners": "Luisteraars",
        "Downloads": "Downloads",
        "My activity": "Mijn activiteiten",
        "Created by:": "Gemaakt door:",
        "Last update:": "Laatste update:",
        "This audio feed doesn’t have any eposides yet.": "Deze audiofeed heeft nog geen afleveringen.",
        "See more": "Meer zien",
        "Please verify the credit card details.": "Controleer de creditcardgegevens alstublieft.",
        "Payment": "Betaling",
        "Your details": "Uw gegevens",
        "First Name": "Voornaam",
        "Last Name": "Achternaam",
        "Email": "E-mail",
        "Phone (optional)": "Telefoon (optioneel)",
        "Invalid phone number.": "Ongeldig telefoonnummer.",
        "Payment details": "Betalingsdetails",
        "Total payment": "Totale betaling",
        "Get Access": "Toegang verkrijgen",
        "Pay": "Betalen",
        "Category:": "Categorie:",
        "Get Free Access": "Gratis toegang verkrijgen",
        "Show currently unavailable": "Toon momenteel niet beschikbaar",
        "Buy now for {{priceValue}}": "Koop nu voor {{priceValue}}",
        "Length": "Lengte",
        "reviews": "recensies",
        "About": "Over",
        "Testimonials": "Getuigenissen",
        "My other private audio feeds": "Mijn andere privé audiofeeds",
        "Thank you!": "Dank je!",
        "You’ve successfully purchased {{productName}} from {{author}}. We sent you an email to {{installEmail}} with access instructions.": "Je hebt met succes {{productName}} gekocht van {{author}}. We hebben een e-mail gestuurd naar {{installEmail}} met toegangsinstructies.",
        "Point your phone’s camera at the code below to access the private podcast.": "Richt de camera van je telefoon op de onderstaande code om toegang te krijgen tot de privé-podcast.",
        "Purchased item": "Gekocht item",
        "Access Private Feed Now": "Nu toegang krijgen tot privéfeed",
        "Ohh no, something went wrong with your payment": "Oeps, er ging iets mis met je betaling",
        "Check your credit card details and try one more time.": "Controleer uw creditcardgegevens en probeer het opnieuw.",
        "Try one more time": "Probeer opnieuw",
        "Cancel": "Annuleren",
        "Enter your first name.": "Voer uw voornaam in.",
        "Enter your last name.": "Voer uw achternaam in.",
        "Enter your email address.": "Voer uw e-mailadres in.",
        "Invalid email address.": "Ongeldig e-mailadres.",
        "Product not found.": "Product niet gevonden.",
        "Payment failed.": "Betaling mislukt.",
        "Fill all the fields.": "Vul alle velden in.",
        "The email address is invalid.": "Het e-mailadres is ongeldig.",
        "Invalud product ID.": "Ongeldig product-ID.",
        "Access denied to product.": "Toegang tot het product geweigerd.",
        "Show not available.": "Show niet beschikbaar.",
        "Terms of service": "Servicevoorwaarden",
        "Privacy policy": "Privacybeleid"
    }
};