import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, connect } from "react-redux";
import actions from "../../../../redux/actions";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import ConfirmModal from "../../modals/ConfirmModal";
import api from "../../../../redux/api";
import { useDispatch } from 'react-redux';
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { getInputClassName, toastMessage } from "../../../helpers";
import ProfileNavigation from "../../../layout/navigation/ProfileNavigation";
import schemas from "../../../schemas";
import TagsInput from "react-tagsinput";
import UnsavedFormGuard from "../../../layout/components/UnsavedFormGuard";

function UserTelnyxParams({ user, update }) {
  const [loading, setLoading] = useState(false),
    formik = useFormik({
      initialValues: {
        telnyxKey: "",
        telnyxPublicKey: ""
      },
      validationSchema: Yup.object().shape({
        telnyxKey: Yup.string().required("Required field."),
        telnyxPublicKey: Yup.string().required("Required field."),
      }),
      onSubmit: async values => {
        setLoading(true);

        const res = await api.user.setUserTelnyx(values);

        if(!res || !res.success)
          toastMessage.error((res && res.error) || "Unable to connect to the server.");

        await update();

        formik.resetForm({ values });

        setLoading(false);
      }
    });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="card-body pt-9 pb-10">
        <div className="form-group">
          <label className="form-label">API Key</label>
          <input type="text" className={getInputClassName(formik, "telnyxKey")} {...formik.getFieldProps("telnyxKey")} />
          {formik.errors.telnyxKey && <div className="field-error">{formik.errors.telnyxKey}</div>}
        </div>

        <div className="form-group">
          <label className="form-label">Public Key</label>
          <input type="text" className={getInputClassName(formik, "telnyxPublicKey")} {...formik.getFieldProps("telnyxPublicKey")} />
          {formik.errors.telnyxPublicKey && <div className="field-error">{formik.errors.telnyxPublicKey}</div>}
        </div>
      </div>
      <div className="card-footer text-right">
        <button className={"btn btn-secondary " + (loading ? "loading spinner " : "")} disabled={!formik.dirty || !formik.isValid} type="submit">Connect</button>
      </div>
    </form>
  );
}

function UserTelnyxNumber({ user, update }) {
  const [disconnectLoading, setDisconnectLoading] = useState(false),
    [saving, setSaving] = useState(false),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(),
    [numbers, setNumbers] = useState([]),
    [changed, setChanged] = useState(false),
    [selectedNumber, setSelectedNumber] = useState();

  async function loadData() {
    setLoading(true);

    const res = await api.user.getUserTelnyxData();
    
    setLoading(false);

    if(!res || !res.success)
      setError((res && res.error) || "Unable to connect to the server.");

    setNumbers(res.data.existingNumbers || []);
  }
  
  async function disconnectOnClick() {
    if(loading || saving || disconnectLoading)
      return;

    setDisconnectLoading(true);

    const res = await api.user.disconnectUserTelnyx();

    if(!res || !res.success)
      toastMessage.error((res && res.error) || "Unable to connect to the server.");

    await update();

    setDisconnectLoading(false);
  }

  async function updateOnClick() {
    if(loading || saving || disconnectLoading)
      return;

    setSaving(true);

    const res = await api.user.setUserTelnyxPhoneNumber(selectedNumber);

    if(!res || !res.success)
      toastMessage.error((res && res.error) || "Unable to connect to the server.");
    else
      toastMessage.success("The number has been configured and added to your account!");

    await update();

    setSaving(false);
    setSelectedNumber(null);
  }

  useEffect(() => {
    loadData();
  }, [user]);

  useEffect(() => {
    setChanged(!!selectedNumber);
  }, [selectedNumber]);

  return (
    <>
      <div className="card-body pt-9 pb-10">
        {loading
          ? <div className="loading-block spinner" />
          : (
            <>
              {error
                ? (
                  <div className="error-block">
                    {error}
                  </div>
                )
                : (
                  <>
                    <div className="form-group">
                      <label className="fw-bold mb-5">Select the phone number you want to use with Hiro:</label>
                      <div class={numbers.length > 15 ? "two-columns-md" : ""}>
                        {numbers.length
                          ? numbers.map((number, i) => (
                            <label className="form-check mb-4" key={i}>
                              <input className="form-check-input" type="radio" name="number" value={number.id} onChange={ev => setSelectedNumber(number.id)} />
                              {number.formatted}
                              {number.currentForSms && <span className="badge gray-badge ml-2">Current for SMS</span>}
                              {number.currentForWhatsapp && <span className="badge gray-badge ml-2">Current for WhatsApp</span>}
                            </label>
                          ))
                          : (
                            <div className="text-muted">You don’t have any phone numbers in your Telnyx account.</div>
                          )}
                      </div>
                    </div>

                  </>
                )}
            </>
          )}
      </div>
      <div className="card-footer d-flex align-items-center">
        <div className="text-right">
          <button className={"btn btn-transparent mr-7 " + (disconnectLoading ? "loading spinner spinner-dark " : "")} type="button" onClick={disconnectOnClick}>Disconnect</button>
          <button className={"btn btn-primary " + (saving ? "loading spinner " : "")} type="button" disabled={!changed} onClick={updateOnClick}>Save</button>
        </div>
      </div>
    </>
  );
}

function UserTwilioParams({ user, update }) {
  const [loading, setLoading] = useState(false),
    formik = useFormik({
      initialValues: {
        twilioAccountSid: "",
        twilioToken: ""
      },
      validationSchema: Yup.object().shape({
        twilioAccountSid: Yup.string().required("Required field."),
        twilioToken: Yup.string().required("Required field.")
      }),
      onSubmit: async values => {
        setLoading(true);

        const res = await api.user.setUserTwilio(values);

        if(!res || !res.success)
          toastMessage.error((res && res.error) || "Unable to connect to the server.");

        await update();

        formik.resetForm({ values });

        setLoading(false);
      }
    });

  function getInputClass(name) {
    return formik.touched[name]
      ? formik.errors[name] && "is-invalid"
      : "";
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="card-body pt-9 pb-10">
        <div className="form-group">
          <label className="form-label">Account SID</label>
          <input type="text" className={"form-control " + getInputClass("twilioAccountSid")} {...formik.getFieldProps("twilioAccountSid")} />
          {formik.errors.twilioAccountSid && <div className="field-error">{formik.errors.twilioAccountSid}</div>}
        </div>
        <div className="form-group">
          <label className="form-label">Account Token</label>
          <input type="text" className={"form-control " + getInputClass("twilioToken")} {...formik.getFieldProps("twilioToken")} />
          {formik.errors.twilioToken && <div className="field-error">{formik.errors.twilioToken}</div>}
        </div>
      </div>
      <div className="card-footer text-right">
        <button className={"btn btn-secondary " + (loading ? "loading spinner " : "")} disabled={!formik.dirty || !formik.isValid} type="submit">Connect</button>
      </div>
    </form>
  );
}

function UserTwilioNumber({ user, update }) {
  const [disconnectLoading, setDisconnectLoading] = useState(false),
    [saving, setSaving] = useState(false),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(),
    [numbers, setNumbers] = useState([]),
    [changed, setChanged] = useState(false),
    [selectedNumber, setSelectedNumber] = useState();

  async function loadData() {
    setLoading(true);

    const res = await api.user.getUserTwilioData();

    if(!res || !res.success)
      setError((res && res.error) || "Unable to connect to the server.");

    setNumbers(res.data.existingNumbers || []);

    setLoading(false);
  }

  async function disconnectOnClick() {
    if(loading || saving || disconnectLoading)
      return;

    setDisconnectLoading(true);

    const res = await api.user.disconnectUserTwilio();

    if(!res || !res.success)
      toastMessage.error((res && res.error) || "Unable to connect to the server.");

    await update();

    setDisconnectLoading(false);
  }

  async function updateOnClick() {
    if(loading || saving || disconnectLoading)
      return;

    setSaving(true);

    const res = await api.user.setUserTwilioPhoneNumber(selectedNumber);

    if(!res || !res.success)
      toastMessage.error((res && res.error) || "Unable to connect to the server.");
    else
      toastMessage.success("The number has been configured and added to your account!");

    await update();

    setSaving(false);
    setSelectedNumber(null);
  }

  useEffect(() => {
    loadData();
  }, [user]);

  useEffect(() => {
    setChanged(!!selectedNumber);
  }, [selectedNumber]);

  return (
    <>
      <div className="card-body pt-9 pb-10">
        {loading
          ? <div className="loading-block spinner" />
          : (
            <>
              {error
                ? (
                  <div className="error-block">
                    {error}
                  </div>
                )
                : (
                  <>
                    <div className="form-group">
                      <label className="fw-bold mb-5">Select the phone number you want to use with Hiro:</label>
                      <div class={numbers.length > 15 ? "two-columns-md" : ""}>
                        {numbers.length
                          ? numbers.map((number, i) => (
                            <label className="form-check mb-4" key={i}>
                              <input className="form-check-input" type="radio" name="number" value={number.sid} onChange={ev => setSelectedNumber(number.sid)} />
                              {number.formatted} {number.current && <span className="text-muted">(Current)</span>}
                            </label>
                          ))
                          : (
                            <div className="text-muted">You don’t have any phone numbers in your Twilio account.</div>
                          )}
                      </div>
                    </div>

                  </>
                )}
            </>
          )}
      </div>
      <div className="card-footer d-flex align-items-center">
        <div className="text-right">
          <button className={"btn btn-transparent mr-7 " + (disconnectLoading ? "loading spinner spinner-dark " : "")} type="button" onClick={disconnectOnClick}>Disconnect</button>
          <button className={"btn btn-primary " + (saving ? "loading spinner " : "")} type="button" disabled={!changed} onClick={updateOnClick}>Save</button>
        </div>
      </div>
    </>
  );
}

function UserTelnyx(props) {
  return (
    <div className="card card-profile-header mb-10">
      <div className="card-header">
        <h3 className="card-title fw-bold m-0">
          Connect Telnyx Number
          <span className="badge yellow-badge ml-3">Preferred</span>
          <a href="https://hiro.fm/kb/adding-a-telnyx-number/" target="_blank" className="get-help-link">Get Help</a>
        </h3>
      </div>

      {!props.user.telnyxConnected
        ? <UserTelnyxParams {...props} />
        : <UserTelnyxNumber {...props} />}
    </div>
  );
}

function UserTwilio(props) {
  return (
    <div className="card card-profile-header mb-10">
      <div className="card-header">
        <h3 className="card-title fw-bold m-0">
          Connect Twilio Number
          <a href="https://hiro.fm/kb/adding-a-twilio-number/" target="_blank" className="get-help-link">Get Help</a>
        </h3>
      </div>

      {!props.user.twilioConnected
        ? <UserTwilioParams {...props} />
        : <UserTwilioNumber {...props} />}
    </div>
  );
}

function OptOut({ user, update }) {
  const formik = useFormik({
    initialValues: {
      optOutWords: user.optOutWords || [],
      optOutMessage: user.optOutMessage || "",
      resubscribeMessage: user.resubscribeMessage || "",
      resubscribeWords: user.resubscribeWords || []
    },
    validationSchema: schemas.user.user.optOutSettings,
    enableReinitialize: true,
    onSubmit: sendForm
  });

  const { handleSubmit, resetForm, setFieldValue, getFieldProps, dirty, values, touched, errors, isValid } = formik,
    [isSaving, setIsSaving] = useState(false);

  async function sendForm(values, loading = true) {
    setIsSaving(loading);

    let res = await api.user.editCurrentUser({
      action: "updateOptOut",
      user: values
    });

    setIsSaving(false);

    if(!res)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success("Settings updated!");

    await update();
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="card card-profile-header mb-10">
          <div className="card-header">
            <div>
              <h3 className="card-title fw-bold m-0">SMS Opt-out Management</h3>
              <p className="text-muted">Set additional opt-out words and reply messages.</p>
            </div>
          </div>
          <div className="card-body pt-9 pb-9">
            <div className="form-group">
              <label className="form-label">
                Additional Opt-out words
                <small>Predefined keywords: cancel, end, quit, unsubscribe, stop, stopall</small>
              </label>
              <TagsInput addOnBlur className={getInputClassName(formik, "optOutWords", "react-tagsinput")} value={values.optOutWords} onChange={v => setFieldValue("optOutWords", v)} inputProps={{ placeholder: "" }} />
              {touched.optOutWords && errors.optOutWords && <div className="field-error">{errors.optOutWords}</div>}
            </div>

            <div className="form-group">
              <label className="form-label">Opt-out Message
                <small>This will be sent immediately after the first SMS we send to the contact.</small></label>
              <textarea className={getInputClassName(formik, "optOutMessage")} {...getFieldProps("optOutMessage")} placeholder="Reply STOP to desuscribe from all our notifications." />
              {touched.optOutMessage && errors.optOutMessage && <div className="field-error">{errors.optOutMessage}</div>}
            </div>

            <div className="form-group">
              <label className="form-label">Resubscribe Message
                <small>This will be sent after the contact sends the opt-out word.</small></label>
              <textarea className={getInputClassName(formik, "resubscribeMessage")} {...getFieldProps("resubscribeMessage")} placeholder="Sorry to se you go. Reply START to resubscribe." />
              {touched.resubscribeMessage && errors.resubscribeMessage && <div className="field-error">{errors.resubscribeMessage}</div>}
            </div>

            <div className="form-group">
              <label className="form-label">
                Resubscribe words
                <small>Predefined keywords: resubscribe, start</small>
              </label>
              <TagsInput addOnBlur className={getInputClassName(formik, "resubscribeWords", "react-tagsinput")} value={values.resubscribeWords} onChange={v => setFieldValue("resubscribeWords", v)} inputProps={{ placeholder: "" }} />
              {touched.resubscribeWords && errors.resubscribeWords && <div className="field-error">{errors.resubscribeWords}</div>}
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="button" className="btn btn-secondary mr-2" disabled={!dirty} onClick={() => formik.resetForm()}>Discard</button>
            <button type="submit" className={"btn btn-primary " + (isSaving === true ? "loading spinner" : "")} disabled={!(isValid && dirty)}>Save Changes</button>
          </div>
        </div>
      </form>

      <UnsavedFormGuard formik={formik} onSaveAsync={async () => sendForm(formik.values, "modal")} loading={isSaving == "modal"} />
    </>
  );
}

function PhoneNumbers() {
  const user = useSelector((state) => state.auth.user, shallowEqual),
    dispatch = useDispatch();

  async function updateUser() {
    const res = await api.auth.getUserByToken(true);
    dispatch(actions.auth.fulfillUser(res.data));

    dispatch(actions.phoneNumber.setPhoneNumber(
      res.data.phoneNumbers.length
        ? res.data.phoneNumbers[0]
        : null
    ));
  }

  return (
    <>
      <ProfileNavigation active="phone" />

      <UserTelnyx user={user} update={() => updateUser()} />

      <UserTwilio user={user} update={() => updateUser()} />

      <OptOut user={user} update={() => updateUser()} />
    </>
  );
}

export default connect(null, actions.auth)(PhoneNumbers);