import React, { useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import UnsavedModal from "./UnsavedModal";

export default function UnsavedFormGuard({ formik = null, formikRef = null, onSaveAsync = null, loading = null, onCancelAsync = null, onHide = null, dirty = false, valid = null, showDialog = false, okOnly = false }) {
    let [showUnsavedModal, setShowUnsavedModal] = useState(false),
        history = useHistory();

    let isDirty = () => dirty || formikIs("dirty", true);

    let isValid = () => valid === null 
        ? formikIs("isValid", false)
        : valid;
    
    let formikIs = (prop, some) => {
        if(!formikRef && !formik)
            return;

        let arr = formik
            ? (
                Array.isArray(formik)
                    ? formik
                    : [ formik ]
            )
            : (
                Array.isArray(formikRef)
                    ? formikRef.map(e => e.current)
                    : [ formikRef.current ]
            );
            
        for(let f of arr) {
            if(some && f && f[prop])
                return true;
            if(!some && (!f || !f[prop]))
                return false;
        }

        return !some;
    };

    let onSave = async () => {
        //if(!isValid())
        //    return setShowUnsavedModal(false);

        await onSaveAsync();
        setShowUnsavedModal(false);
        history.push(showUnsavedModal, { ignorePrompt: true });
    };

    let handlePrompt = route => {
        if(isDirty() && (!route.state || !route.state.ignorePrompt)) {
            setShowUnsavedModal(route.pathname);
            //cancel navigation
            return false;
        }
    };

    let modalOnHide = () => {
        setShowUnsavedModal(false);

        if(onHide)
            onHide();
    };

    let overridePrompt = () => {
        if(typeof showUnsavedModal == "string")
            history.push(showUnsavedModal, { ignorePrompt: true });
    };

    let modalOnCancel = async () => {
        if(onCancelAsync)
            await onCancelAsync();
        setShowUnsavedModal(false);
        overridePrompt();
    };

    useEffect(() => {
        let handler = ev => {
            if(isDirty()) {
                ev.preventDefault();
                return ev.returnValue = "You have unsaved changes!";
            }
        };

        window.addEventListener("beforeunload", handler, { capture: true });
        return () => window.removeEventListener("beforeunload", handler, { capture: true });
    }, [ dirty, formik, formikRef ]);

    useEffect(() => {
        if(!showUnsavedModal)
            setShowUnsavedModal(!!showDialog);
    }, [ showUnsavedModal, showDialog ]);

    return (
        <>
            <Prompt message={handlePrompt} />
            <UnsavedModal show={!!showUnsavedModal} onHide={modalOnHide} onSave={onSaveAsync && onSave} onCancel={modalOnCancel} loading={loading} okOnly={okOnly} />
        </>
    )
}