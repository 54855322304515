export default {
    translation: {
        "All": "Tutto",
        "Audio Feeds": "Feed audio",
        "Category": "Categoria",
        "There are no shows in this category.": "Non ci sono programmi in questa categoria.",
        "The creator doesn’t have any public audio feeds yet.": "Il creatore non ha ancora feed audio pubblici.",
        "About me": "Su di me",
        "My audio feeds": "I miei feed audio",
        "I agree to Hiro’s <a href=\"https://hiro.fm/terms\" target=\"_blank\" className=\"themed\">terms of service</a> & <a href=\"https://hiro.fm/privacy\" target=\"_blank\" className=\"themed\">privacy policy</a>.": "Accetto i termini di servizio e la politica sulla privacy di Hiro.",
        "Powered by Hiro.fm": "Powered by Hiro.fm",
        "Start your own private audio product": "Avvia il tuo prodotto audio privato",
        "episodes": "episodi",
        "Free": "Gratis",
        "/ week": "/ settimana",
        "/ month": "/ mese",
        "/ year": "/ anno",
        "one-time payment": "pagamento unico",
        "per week": "a settimana",
        "per month": "al mese",
        "per year": "all'anno",
        "Products": "Prodotti",
        "Episodes": "Episodi",
        "Listeners": "Ascoltatori",
        "Downloads": "Download",
        "My activity": "La mia attività",
        "Created by:": "Creato da:",
        "Last update:": "Ultimo aggiornamento:",
        "This audio feed doesn’t have any eposides yet.": "Questo feed audio non ha ancora episodi.",
        "See more": "Vedi di più",
        "Please verify the credit card details.": "Verifica i dettagli della carta di credito.",
        "Payment": "Pagamento",
        "Your details": "I tuoi dettagli",
        "First Name": "Nome",
        "Last Name": "Cognome",
        "Email": "Email",
        "Phone (optional)": "Telefono (opzionale)",
        "Invalid phone number.": "Numero di telefono non valido.",
        "Payment details": "Dettagli del pagamento",
        "Total payment": "Pagamento totale",
        "Get Access": "Ottieni accesso",
        "Pay": "Paga",
        "Category:": "Categoria:",
        "Get Free Access": "Ottieni accesso gratuito",
        "Show currently unavailable": "Mostra attualmente non disponibile",
        "Buy now for {{priceValue}}": "Acquista ora per {{priceValue}}",
        "Length": "Durata",
        "reviews": "recensioni",
        "About": "Informazioni",
        "Testimonials": "Testimonianze",
        "My other private audio feeds": "I miei altri feed audio privati",
        "Thank you!": "Grazie!",
        "You’ve successfully purchased {{productName}} from {{author}}. We sent you an email to {{installEmail}} with access instructions.": "Hai acquistato con successo {{productName}} da {{author}}. Ti abbiamo inviato un'email a {{installEmail}} con le istruzioni per l'accesso.",
        "Point your phone’s camera at the code bPunta la fotocamera del tuo telefono sul codice qui sotto per accedere al podcast privato.elow to access the private podcast.": "",
        "Purchased item": "Articolo acquistato",
        "Access Private Feed Now": "Accedi subito al feed privato",
        "Ohh no, something went wrong with your payment": "Oh no, qualcosa è andato storto con il tuo pagamento",
        "Check your credit card details and try one more time.": "Controlla i dettagli della tua carta di credito e prova un'altra volta.",
        "Try one more time": "Prova un'altra volta",
        "Cancel": "Annulla",
        "Enter your first name.": "Inserisci il tuo nome.",
        "Enter your last name.": "Inserisci il tuo cognome.",
        "Enter your email address.": "Inserisci il tuo indirizzo email.",
        "Invalid email address.": "Indirizzo email non valido.",
        "Product not found.": "Prodotto non trovato.",
        "Payment failed.": "Pagamento fallito.",
        "Fill all the fields.": "Compila tutti i campi.",
        "The email address is invalid.": "L'indirizzo email non è valido.",
        "Invalud product ID.": "ID prodotto non valido.",
        "Access denied to product.": "Accesso al prodotto negato.",
        "Show not available.": "Spettacolo non disponibile.",
        "Terms of service": "Termini di servizio",
        "Privacy policy": "Politica sulla riservatezza"
    }
};