import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../redux/actions";
import api from "../../../redux/api";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import RangeField from "../../layout/components/RangeField";
import { toastMessage } from "../../helpers";
import PurchaseSuccessModal from "./PurchaseSuccessModal";
import PurchaseFailedModal from "./PurchaseFailedModal";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import CreditCard from "../../layout/components/CreditCard";
import { loadStripe } from "@stripe/stripe-js";
import { Switch } from "@material-ui/core";

//TODO allow each plan to have a different price
const prices = {
  monthly: 125,
  annual: 1197
};

function FivePackModal({ show, onHide, onSuccess, onFailure, elements, stripe, user, dispatch, setLogin }) {
  let [loading, setLoading] = useState(false),
    [value, setValue] = useState(1),
    [currentSubscriptionValue, setCurrentSubscriptionValue] = useState(0),
    [priceLoading, setPriceLoading] = useState(false),
    [price, setPrice] = useState(),
    [period, setPeriod] = useState("annual"),
    [coupon, setCoupon] = useState(""),
    [editPaymentMethod, setEditPaymentMethod] = useState(false),
    [cardFeedback, setCardFeedback] = useState(null),
    [customer, setCustomer] = useState(null),
    [paymentMethod, setPaymentMethod] = useState(null),
    [paymentRequest, setPaymentRequest] = useState(null),
    couponTimerRef = useRef();

  async function loadCustomer() {
    setCustomer((await api.checkout.getCustomer(true)).data);
  }

  async function updateUser() {
    let user = await api.auth.getUserByToken(true).data;
    dispatch(actions.auth.fulfillUser(user));
  }

  async function checkUser() {
    let user = (await api.auth.getUserByToken(true)).data;

    let expectedValue = (user.owner?.planFeatures.includedSubAccounts || 0) + value * 5;

    if(user.owner?.totalSubAccountsLimit == expectedValue) {
      dispatch(actions.auth.fulfillUser(user));
      success();
    } else {
      setTimeout(checkUser, 500);
    }
  }

  async function activateSubscription(paymentMethodId) {
    if(paymentMethodId) {
      let attachedPaymentMethod = await api.checkout.attachPaymentMethod(paymentMethodId);
      if(!attachedPaymentMethod.data)
        return fail(attachedPaymentMethod.message);
    } else if(customer.paymentMethod) {
      paymentMethodId = customer.paymentMethod.id;
    } else {
      setEditPaymentMethod(true);
      setLoading(false);
      return setCardFeedback("Please fill the credit card details.");
    }

    let res = await api.checkout.createFivePackSubscription(paymentMethodId, value, period, coupon);

    //update token in case the user has just been upgraded to agency
    if(res && res.token && res.user)
      setLogin(res.token, res.user);

    if(!res || !res.success) {
      setLoading(false);
      return fail(res.error);
    }

    let finalValue = prices[period] * (value / 5),
      currency = "USD";

    //Facebook pixel
    api.pub.event("Purchase", { value: finalValue, currency });

    window.gtag("event", "extras_purchase", { value: finalValue, currency });

    window.gtag("event", "purchase", { value: finalValue, currency });

    //Wait until the upgrade takes effect
    checkUser();
  }

  async function processSubscription() {
    if(editPaymentMethod || !customer || !customer.paymentMethod) {
      if(paymentMethod) {
        //Google pay/Apple pay, use the button's payment method
        activateSubscription(paymentMethod.id);
      } else {
        //New card
        try {
          let paymentMethodData = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
          });

          if(paymentMethodData.error) {
            setLoading(false);

            if(editPaymentMethod && paymentMethodData.error.type == "validation_error")
              return setCardFeedback("Please verify the credit card details.");

            return fail(paymentMethodData.error.message);
          }

          activateSubscription(paymentMethodData.paymentMethod.id);
        } catch(error) {
          fail();
        }
      }
    } else {
      //Existing payment method
      activateSubscription();
    }
  }

  async function submit() {
    if(loading)
      return;

    setCardFeedback(null);
    setLoading(true);
    processSubscription();
  }

  function fail(reason) {
    setLoading(false);
    onFailure(typeof reason == "string" && reason);
  }

  function success(res) {
    setLoading(false);
    onSuccess(res);
  }

  async function validatePrice(coupon) {
    let res = await api.checkout.validatePrice("fivePack", coupon);

    setPriceLoading(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    setPrice(res.data);
  }

  useEffect(() => {
    //setPriceLoading(true);

    //couponTimerRef.current = setTimeout(() => {
    //  validatePrice(coupon);
    //}, 1500);

    //return () => clearTimeout(couponTimerRef.current);
  }, [coupon, period]);

  useEffect(() => {
    if(!user || !show)
      return;

    let currentPacks = user.owner
      ? Math.ceil((user.owner.totalSubAccountsLimit - user.owner.planFeatures.includedSubAccounts) / 5)
      : 0;

    setValue(currentPacks || 1);
    setCurrentSubscriptionValue(currentPacks);
    setEditPaymentMethod(false);
    loadCustomer();
  }, [user, show]);

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered className="new-modals five-pack-modal">
        <Modal.Header>
          <h1 className="m-0">Additional accounts</h1>
          <button type="button" className="close" onClick={() => onHide()}><SVG src={toAbsoluteUrl("/media/def-image/close.svg")} className="svg-icon" /></button>
        </Modal.Header>
        <Modal.Body>
          <p className="description">First pick the number of sub-accounts you would like to add to your plan, then start profiting from your own audio business.</p>

          <CreditCard edit={editPaymentMethod} customer={customer} paymentRequest={paymentRequest} cardFeedback={cardFeedback} onEdit={v => setEditPaymentMethod(v)} />

          <div className="d-flex align-items-center justify-content-center mb-8">
            <span>MONTHLY</span>
            <Switch className="always-blue-switch mx-3" checked={period == "annual"} onChange={ev => setPeriod(ev.target.checked ? "annual" : "monthly")} />
            <span>ANNUALLY</span>
          </div>

          <RangeField min={0} max={10} value={value} onChange={v => setValue(v)} lg showLabel renderLabel={v => (user.owner?.planFeatures.includedSubAccounts || 0) + v * 5} />

          <div className="details">
            <div className="total-col">
              <p>Sub-accounts available in sets of five accounts.</p>

              <div className="total-price">
                ${(prices[period] * value).toLocaleString("en-US")}<small>/{period == "monthly" ? "mo" : "year"}</small>
              </div>

              <button type="button" className={"btn btn-primary " + (loading ? "loading spinner" : "")} onClick={submit} disabled={value == currentSubscriptionValue}>
                {value > currentSubscriptionValue
                  ? "Add Accounts"
                  : "Update Subscription"}
              </button>

              <div className="coupon-field">
                <label>Coupon</label>
                <input type="text" className="form-control" value={coupon} onChange={ev => setCoupon(ev.target.value)} />
              </div>

              {/*
              <div className="d-flex justify-content-center align-items-center mt-11 fs-1 text-muted">
                <span className="d-inline-block mr-3">Total billed today:</span>
                {priceLoading
                  ? <span className="loading d-inline-block spinner spinner-sm" />
                  : (
                    !!price && (
                      price.total && typeof price.today != "undefined"
                        ? (
                          <>
                            <s>${price.total.toLocaleString()}</s> <span className="ml-2">${price.today.toLocaleString()}</span>
                          </>
                        )
                        : (
                          <>${price.total.toLocaleString()}</>
                        )
                    ))}
              </div>
              */}
            </div>

            <div className="features-col">
              <h3>What’s included</h3>
              <div>
                <SVG src="/media/def-image/icons/check-circle-blue.svg" /> Unlimited shows
              </div>
              <div>
                <SVG src="/media/def-image/icons/check-circle-blue.svg" /> Unlimited listeners
              </div>
              <div>
                <SVG src="/media/def-image/icons/check-circle-blue.svg" /> Unlimited downloads
              </div>
              <div>
                <SVG src="/media/def-image/icons/check-circle-blue.svg" /> Free agency starter pack
              </div>
              <div>
                <SVG src="/media/def-image/icons/check-circle-blue.svg" /> Free CRM software
              </div>
              <div>
                <SVG src="/media/def-image/icons/check-circle-blue.svg" /> Unlimited keywords
              </div>
              <div>
                <SVG src="/media/def-image/icons/check-circle-blue.svg" /> Unlimited automations
              </div>
              <div>
                <SVG src="/media/def-image/icons/check-circle-blue.svg" /> Personalized emails & url
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
}

function PaymentFormWrapper(props) {
  let elements = useElements(),
    stripe = useStripe();

  return (
    <FivePackModal elements={elements} stripe={stripe} {...props} />
  );
}

function FivePackElementsWrapper({ user, dispatch, setLogin }) {
  let [show, setShow] = useState(false),
    [successModal, setSuccessModal] = useState(false),
    [failedModal, setFailedModal] = useState(false),
    [failureReason, setFailureReason] = useState(null),
    [stripeObject, setStripeObject] = useState(null);

  async function init() {
    if(!stripeObject)
      setStripeObject(await loadStripe(process.env.REACT_APP_STRIPE_KEY));
  }

  function onSuccess(result) {
    setShow(false);
    setSuccessModal(true);
  }

  function onFailure(reason) {
    setShow(false);
    setFailureReason(reason);
    setFailedModal(true);
  }

  function onRetry() {
    setFailedModal(false);
    setShow(true);
  }

  useEffect(() => {
    let off = FivePackEvents.on("showFivePack", () => {
      setShow(true);
    });

    init();

    return () => off();
  }, []);

  if(!show && !successModal && !failedModal)
    return <></>;

  return (
    <>
      <Elements stripe={stripeObject}>
        <PaymentFormWrapper
          show={show}
          onHide={() => setShow(false)}
          user={user}
          dispatch={dispatch}
          setLogin={setLogin}
          onSuccess={onSuccess}
          onFailure={onFailure} />
      </Elements>

      <PurchaseSuccessModal show={successModal} close={() => setSuccessModal(false)} hideOptions />
      <PurchaseFailedModal show={failedModal} close={() => setFailedModal(false)} reason={failureReason} retry={onRetry} />
    </>
  );
};

export default injectIntl(connect(
  state => ({
    user: state.auth.user
  }),
  dispatch => ({
    dispatch,
    ...actions.product,
    ...actions.auth
  })
)(FivePackElementsWrapper));

export const FivePackEvents = {
  on(event, callback) {
    let h = e => callback(e.detail);
    document.addEventListener(event, h);
    return () => document.removeEventListener(event, h);
  },
  dispatch(event, data = null) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  /**
   * Emits the signal to open the popup.
   */
  dispatchShow() {
    this.dispatch("showFivePack");
  }
};