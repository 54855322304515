import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import actions from "../../../redux/actions";
import { Modal } from "react-bootstrap";
import api from "../../../redux/api";
import { toastMessage } from "../../helpers";
import { CSVDownload, CSVLink } from "react-csv";

let csvHeaders = [
  { label: "Name", key: "name" },
  { label: "Number", key: "phoneNumber" },
  { label: "Email", key: "email" },
  { label: "Opt-in date", key: "date" },
  { label: "Status", key: "status" }
];

function OptInsModal({ show, onHide, code }) {
  let [ loading, setLoading ] = useState(true),
    [ loadingCsv, setLoadingCsv ] = useState(false),
    [ csvData, setCsvData ] = useState(null),
    [ list, setList ] = useState([]),
    [ offset, setOffset ] = useState(0),
    [ count, setCount ] = useState(null);

  let loadData = async (offset = 0) => {
    setLoading(true);
    
    let res = await api.sms.getListeners(code._id, offset);

    if(!res || !res.success)
      return toastMessage.error("Unable to connect to the server.");

    setLoading(false);
    setCount(res.totalItems);
    setList(v => v.concat(res.data));
  };

  let exportCsv = async () => {
    setLoadingCsv(true);

    let res = await api.sms.getListeners(code._id, -1);
    
    setLoadingCsv(false);

    if(!res || !res.success)
      return toastMessage.error("Unable to connect to the server.");

    for(let item of res.data) {
      item.date = moment(item.date).locale("en").format("MMMM D, YYYY");
      item.status = statusText[item.status];
    }
    
    setCsvData(res.data);
    setTimeout(() => setCsvData(null), 1000);
  };

  let loadMoreOnClick = async ev => {
    ev.preventDefault();

    let o = offset + 50;

    setOffset(o);
    loadData(o);
  };

  useEffect(() => {
    if(!code)
      return;

    loadData();
  }, [ code ]);

  useEffect(() => {
    setList([]);
    setCsvData(null);
    setOffset(0);
  }, [ show, code ]);

  let status = {
    "not-active": <span className="gray-badge">Not active</span>,
    "accessed": <span className="yellow-badge">Accessed</span>,
    "listened": <span className="green-badge">Listened</span>
  };

  let statusText = {
    "not-active": "Not active",
    "accessed": "Accessed",
    "listened": "Listened"
  };

  return (
    <Modal show={show} onHide={onHide} size="xl" centered className="new-modals optins-modal">
      <Modal.Header className="py-5" closeButton>
        <div className="d-flex align-items-center">
          <h1 className="fill m-0">
            Keyword Opt-in <span className="text-muted fw-normal">{count === null ? "" : "(" + count + ")"}</span>
          </h1>
          <button type="button" onClick={exportCsv} className={"btn btn-primary-light mr-6 " + (loadingCsv ? "loading spinner" : "")}>Export</button>
        </div>
      </Modal.Header>
      <Modal.Body>
        {loading === true && !offset
        ? <div className="loading spinner spinner-sm spinner-blue py-20"/>
        : (
          <div className="table-responsive">
            <table className="table table-head-custom">
              <thead>
                <tr>
                  <th className="pl-0">Name</th>
                  <th>Number</th>
                  <th>Email</th>
                  <th>Opt-in date</th>
                  <th width="1">Status</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, i) => (
                  <tr key={i}>
                    <td className="pl-0">
                      {item.name}
                    </td>
                    <td>
                      {item.phone}
                    </td>
                    <td>
                      {item.email}
                    </td>
                    <td>
                      {moment(item.date).locale("en").format("MMMM D, YYYY")}
                      {item.conversations > 1 && (
                        <small className="text-muted fs-inherit">&nbsp;(+{item.conversations} attempts)</small>
                      )}
                    </td>
                    <td>
                      {status[item.status]}
                    </td>
                  </tr>
                ))}
                {count > list.length && (
                  <tr>
                    <td colSpan="5">
                      <a href="#" className={"load-more d-block btn btn-transparent " + (loading ? "loading spinner spinner-dark" : "")} onClick={loadMoreOnClick}>Load more...</a>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Body>

      {csvData !== null && <CSVDownload data={csvData} headers={csvHeaders} filename="opt-ins.csv"/>}
    </Modal>
  );
}

export default injectIntl(connect(
  state => ({
  }),
  dispatch => ({
  }),
)(OptInsModal));
