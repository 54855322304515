import React, { useCallback, useRef } from "react";
import _ from "lodash";
import SVG from "react-inlinesvg";

export default function NumberField({ formik, name, min = "0", step = "0.01", max = null, addon = null, buttons = true }) {
    let ref = useRef();

    let changed = ev => {
        let val = ev.target.value;

        if(typeof val != "string")
            val = "";

        val = val.replace(",", ".").replace(/[^0-9\.-]/g, "");

        formik.setFieldValue(name, val);
    };

    let n = val => {
        if(typeof val != "number")
            val = parseFloat(val);
        return val.toFixed(parseInt(val) === parseFloat(val) ? 0 : 2);
    };

    let up = () => {
        let val = parseFloat(ref.current.value);

        if(isNaN(val))
            val = 0;

        if(max !== null && val >= parseFloat(max))
            val = max;
        else
            val += parseFloat(step);

        formik.setFieldValue(name, n(val));
    };

    let down = () => {
        let val = parseFloat(ref.current.value);

        if(isNaN(val))
            val = 0;

        if(min !== null && val <= parseFloat(min))
            val = min;
        else
            val -= parseFloat(step);

        formik.setFieldValue(name, n(val));
    };

    return (
        <div className={"form-control number-field " + (addon ? "has-addon " : "")}>
            {addon && <span>{addon}</span>}
            <input type="text" ref={ref} value={_.get(formik.values, name)} onChange={changed} onInput={changed} onPaste={changed} />
            {buttons && (
                <>
                    <button type="button" className="btn up" onClick={up}>
                        <SVG src="/media/def-image/icons/chevron-up.svg" />
                    </button>
                    <button type="button" className="btn down" onClick={down}>
                        <SVG src="/media/def-image/icons/chevron-down.svg" />
                    </button>
                </>
            )}
        </div>
    );
}