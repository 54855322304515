import React from "react";
import {Modal} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";

/**
 * 
 * @param {boolean} props.show
 * @param {function} props.retry
 * @param {function} props.close
 * @param {string} [props.reason]
 * @param {(string|Object)} [props.message]
 */
export default (props)=>
  <Modal
    show={props.show}
    onHide={props.close}
    size="md"
    centered
    className="user-success-modal user-failure-modal"
  >
    <Modal.Body className="text-center p-15">
      <img src={toAbsoluteUrl("/media/def-image/purchase-failed.png")} />
      <h2 className="my-10 fs-2">Something went wrong with your payment</h2>
      <p className="mb-10">{props.reason?props.reason:"Check your credit card details and try one more time."}</p>
      <button type="button" className="btn btn-primary mb-6" onClick={props.retry}>Try one more time</button><br />
      <button type="button" className="btn btn-transparent text-muted" onClick={props.close}>Cancel</button>
    </Modal.Body>
  </Modal>;