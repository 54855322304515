import React, { useEffect, useState } from "react";
import { Message, Writting } from "../../../modals/onboarding/common";
import NameMessage from "./NameMessage";
import NameSuggestionsMessage from "./NameSuggestionsMessage";

export default function HasNameMessage({ user, next, state }) {
    const [answer, setAnswer] = useState(null),
        [loading, setLoading] = useState(false);

    const submit = (ev, hasName) => {
        ev.preventDefault();

        setAnswer(hasName);
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
            next(hasName
                ? NameMessage
                : NameSuggestionsMessage,
                { hasName });
        }, 2000);
    };

    return (
        <>
            <Message>
                Do you already have a title for this episode?
            </Message>

            {answer === null
                ? (
                    <Message incoming custom delay={500}>
                        <div className="options">
                            <a href="#" onClick={ev => submit(ev, true)}>Yes</a>
                            <a href="#" onClick={ev => submit(ev, false)}>No</a>
                        </div>
                    </Message>
                )
                : (
                    <>
                        <Message incoming>{answer ? "Yes" : "No"}</Message>
                    </>
                )}

            {loading && <Writting delay={1000} />}
        </>
    );
}
