// Core
import React from "react";

export const AsideCustomProductLabel = ({title, count}) => {
    const countJSX = (count || count === 0) && (
        <div className="asideCustomProductLabel-count">
            <span>
                {count}
            </span>
        </div>
    );

    const titleJSX = title && (
        <span className="asideCustomProductLabel-title">
            {title}
        </span>
    );

    return (
        <div className="asideCustomProductLabel">
            {titleJSX}
            {countJSX}
        </div>
    );
}
