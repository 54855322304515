import React, { useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import store from "../../../redux/store";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import QRCode from "qrcode.react";
import SVG from "react-inlinesvg";
import "react-phone-number-input/style.css";
import { Link } from "react-router-dom";

require("dotenv").config();

export default (props) => {
  let { auth: { authToken } } = store.getState(),
    [product, setProduct] = useState(false),
    [error, setError] = useState(null);

  let platform = "";
  if(isIOS) platform = "ios";
  if(isAndroid) platform = "android";

  let fetchProductToInstall = async () => {
    try {
      let response = await fetch(
        process.env.REACT_APP_SERVER_URL + "feed/" + `installproduct/${props.match.params.productid}/${props.match.params.privatekey}`,
        { method: 'GET', headers: { 'Authorization': 'Bearer ' + authToken, 'Content-Type': 'application/json', }, body: null }
      );

      let responseJson = await response.json();
      if(responseJson && (responseJson.success === false || responseJson.error != null)) {
        setError(responseJson.error);
      } else {
        setProduct(responseJson);
      }
    } catch(e) {
      setError(e.message || e.error || e);
    }
  },
    onPlayerClick = async player => {
      await fetch(
        process.env.REACT_APP_SERVER_URL + "feed/registerInstall/" + props.match.params.productid + "/" + props.match.params.privatekey, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + authToken,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          platform: platform,
          player: player.key
        })
      }
      );
      window.location = isAndroid && player.urlAndroid
        ? player.urlAndroid :
        player.url;
    };

  useEffect(() => {
    fetchProductToInstall();
  }, [props.match.params.productid, props.match.params.privatekey]);

  if(!isMobile)
    return (
      <div className="install-page desktop">
        <div>
          <SVG src={toAbsoluteUrl("/media/logos/logo.svg")} className="logo" />
          <p>Point the camera on your phone at the QR code below.</p>
          <QRCode value={window.location.href} />
          <p>Alternatively, you can open the email on your phone and tap the access button.</p>
          <p>Need Help? Check out <a href="https://hiro.fm/kb/hiro-mobile-app-overview/" className="text-decoration-underline">the step-by-step instructions</a>.</p>
        </div>
      </div>
    );

  return <>
    <div className="install-page">
      <div>
        <SVG src={toAbsoluteUrl("/media/logos/logo.svg")} className="logo" />

        {error
          ? <p className="error">{error}</p>
          : (!product
            ? <></>
            : (
              <div className="container">
                <div className="d-flex flex-column align-items-center pb-8">
                  <div className="cover-image">
                    <img src={product.artworkUrl} />
                  </div>
                  <h3>One more step...</h3>
                  <p>Add <strong><a href={product.salesPageUrl}>{product.name}</a></strong> to the Hiro mobile app.</p>
                  <Link to={"/app-install/" + props.match.params.productid + "/" + props.match.params.privatekey} className="btn btn-primary btn-install-player my-8">Hiro App</Link>
                  {!product.appOnly && (
                    <><p className="mb-8">Or you can add the show to one of the podcast player listed below.</p>
                      {product.players.map(player => {
                        if((isIOS && !player.ios) || (isAndroid && !player.android)) return;
                        return <button key={player.name} type="button" className="btn btn-dark-gray btn-install-player" onClick={() => onPlayerClick(player)}>
                          {player.name}
                        </button>;
                      })}
                    </>
                  )}
                </div>
              </div>
            )
          )
        }
      </div>
    </div>
  </>;
};