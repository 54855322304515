import auth from './auth-api'
import checkout from './checkout-api.js'
import generic from './generic-api'
import product from './product-api'
import user from './user-api'
import listener from './listener-api'
import episode from './episode-api'
import salesPage from "./sales-page-api";
import admin from "./admin-api";
import pub from "./public-api";
import sms from "./sms-api";
import app from "./app-api";
import automations from "./automations-api";
import ai from "./ai";
import inbox from "./inbox";
import contacts from "./contacts";

export default {
  auth,
  checkout,
  generic,
  product,
  user,
  listener,
  episode,
  salesPage,
  admin,
  pub,
  sms,
  app,
  automations,
  ai,
  inbox,
  contacts
};