import _ from "lodash";
import Picker from "emoji-picker-react";
import React, { useEffect } from "react";
import common from "./common";

function EmojiToolbox({ onHide, onData, show = false, className = "", autoClose = true, fixedPosition = false }) {
    let onEmojiClick = (ev, selection) => {
        onData(selection.emoji);
        if(autoClose)
            onHide();
    };

    useEffect(common.useEffect(fixedPosition, onHide), [fixedPosition]);

    return (
        <>
            {show && (
                 <div className={"emoji-picker-react-container " + (!!fixedPosition ? "fixed" : "") + className} style={common.getStyle(fixedPosition)}>
                    <Picker onEmojiClick={onEmojiClick} disableSearchBar />
                </div>
            )}
        </>
    );
}

export default EmojiToolbox;