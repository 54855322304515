import { useFetch } from "../../app/helpers";

export default {
  list: (phoneNumberKind, page, search) => useFetch.get("/inbox?phoneNumberKind=" + phoneNumberKind + "&page=" + (page || "") + "&search=" + encodeURIComponent(search || ""), null, true, true),
  updateList: (phoneNumberKind, page, search) => useFetch.get("/inbox/update?phoneNumberKind=" + phoneNumberKind + "&page=" + (page || "") + "&search=" + encodeURIComponent(search || ""), null, true, true),
  getConversation: (phoneNumberKind, contactId) => useFetch.get("/inbox/" + contactId + "?phoneNumberKind=" + phoneNumberKind, null, true, true),
  getConversationUpdates: (phoneNumberKind, contactId, lastMessageId) => useFetch.get("/inbox/" + contactId + "/update?phoneNumberKind=" + phoneNumberKind + "&last=" + lastMessageId, null, true, true),
  post: (phoneNumberKind, contactId, message) => useFetch.post("/inbox/" + contactId, { phoneNumberKind, message }, true, true),
  delete: contactId => useFetch.delete("/inbox/" + contactId, null, true, true)
};
