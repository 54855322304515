import React, { useState } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import actions from "../../../../redux/actions";
import api from "../../../../redux/api";
import { toastMessage, updateCurrentUser } from "../../../helpers";
import UnsavedFormGuard from "../../../layout/components/UnsavedFormGuard";
import SVG from "react-inlinesvg";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { parseDomain, ParseResultType } from "parse-domain";

const validationSchema = Yup.object().shape({
  domain: Yup
    .string()
    .required("Enter a valid domain name.")
    .test("valid-domain", "Enter a valid domain name.", value => {
      let parsed = parseDomain(value);
      return value && (parsed.type == ParseResultType.Listed || parsed.type == ParseResultType.NotListed) && value.includes(".");
    })
});

const initialValues = {
  domain: ""
};

function AddDomainSettings() {
  const user = useSelector((state) => state.auth.user, shallowEqual),
    [loading, setLoading] = useState(false),
    dispatch = useDispatch(),
    formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: values => sendForm(values)
    }),
    history = useHistory();

  function getInputClasses(formik, fieldname) {
    if(formik.touched[fieldname] && formik.errors[fieldname])
      return "is-invalid";
    if(formik.touched[fieldname] && !formik.errors[fieldname])
      return "is-valid";
    return "";
  }

  async function sendForm(values, loading = "form") {
    setLoading(loading);

    let res = await api.user.addDomain(values.domain);

    setLoading(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success("The domain has been added!");

    updateCurrentUser(dispatch);

    history.push("/user/domains/" + res.data._id, { ignorePrompt: true });
  }

  return (
    <>
      <h1>
        <Link to="/user/domains" className="btn btn-back">
          <SVG src="/media/def-image/icons/back.svg" />
        </Link>
        Connect Existing Domain
      </h1>

      <div className="container-inner">
        <form onSubmit={formik.handleSubmit} className="card">
          <div className="card-header">
            <h2 className="card-title m-0">Enter Domain or Subdomain</h2>
            <p className="text-muted">
              A domain refers to the name of a website, while a subdomain is a smaller part of the same website. For example, if “mysite.net” is the main domain, then “blog.mysite.net” is a subdomain.
            </p>
          </div>
          <div className="card-body">
            <div className="form-group">
              <label className="form-label">Domain Name</label>
              <input type="text" autoFocus className={"form-control " + getInputClasses(formik, "domain")} {...formik.getFieldProps("domain")} />
              {formik.touched.domain && formik.errors.domain && <div className="field-error">{formik.errors.domain}</div>}
            </div>
          </div>

          <div className="card-footer text-right">
            <Link to="/user/domains" className="btn btn-secondary mr-2">Cancel</Link>
            <button type="submit" className={"btn btn-primary " + (loading == "form" ? "loading spinner" : "")} disabled={!formik.isValid || !formik.dirty}>Continue</button>
          </div>
        </form>
      </div>

      <UnsavedFormGuard formik={formik} onSaveAsync={async () => sendForm(formik.values, "modal")} loading={loading == "modal"} />
    </>
  );
}

export default connect(null, actions.auth)(AddDomainSettings);
