import React, { useState, useEffect } from "react";
import api from "../../../../redux/api";
import { Message, sleep, Writting } from "./common";
import LastMessage from "./LastMessage";
import AwsS3Dropzone from "../../generic/AwsS3Dropzone";

function UploadPictureMessage({ next, scroll, state, update, user }) {
    let [uploadError, setUploadError] = useState(),
        [image, setImage] = useState(),
        [sent, setSent] = useState(false),
        [loading, setLoading] = useState(false),
        [skip, setSkip] = useState(false),
        [error, setError] = useState(null);

    let submit = async (ev, value) => {
        ev.preventDefault();

        setSent(true);
        setLoading(true);

        await sleep(2000);

        let data = {
            image
        };

        let res = await api.user.onboardingStep("upload-profile-picture", data);

        setLoading(false);

        if(!res || !res.success) {
            setError((res && res.error) || "I’m sorry, I wasn’t able to process that. Please, try again.");
            setTimeout(() => next(UploadPictureMessage), 1000);
            return;
        }

        next(LastMessage, data);

        update();
    };

    function skipOnClick() {
        setSkip(true);
        setSent(true);
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            next(LastMessage);
        }, 1500);
    }

    function uploadOnError(error) {
        setUploadError(error);
    }

    function uploadOnComplete(files) {
        setImage(files[0].amazonS3Url);
    }

    useEffect(() => {
        scroll();
    }, [sent, loading, error]);

    return (
        <>
            <Message>
                Got it!
            </Message>
            <Message delay={500}>
                Let’s upload {state.firstName || user.firstName} {state.lastName || user.lastName}’s logo or profile picture. You can always change it later.
            </Message>
            <Message delay={1000} className="episodes-upload">
                <div className={"dropzone-artwork-container dropzone-avatar " + (sent ? "" : "mb-4 ") + (!!image ? "uploaded" : "")}>
                    <AwsS3Dropzone
                        allowMultipleUpload={false}
                        onError={uploadOnError}
                        onUploadComplete={uploadOnComplete}
                        forceSquareImageFormat={true}
                        fileType={"image/jpg, image/jpeg, image/png"}
                        uploadType="artwork"
                        errorMessage={uploadError}
                        description="Select square png or jpg file (min. 500 x 500 px)" />
                    <div className="dropzone-uploaded-image">
                        <div className="image-input image-input-outline" style={{ backgroundImage: "url(" + image + ")" }}>
                            {image && !sent && (
                                <span onClick={() => setImage(null)} className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove" data-toggle="tooltip" title="Remove image">
                                    <i className="ki ki-bold-close icon-xs text-muted" />
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                {!sent && (
                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-secondary mr-3" onClick={skipOnClick}>Skip</button>
                        <button type="button" className="btn btn-primary" disabled={!image} onClick={submit}>Next</button>
                    </div>
                )}
            </Message>
            {skip && (
                <Message incoming>
                    Skip
                </Message>
            )}
            {loading && <Writting delay={1000} />}
            {error && (
                <Message>{error}</Message>
            )}
        </>
    );
}

export default UploadPictureMessage;